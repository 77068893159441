const actions = {
  VIEW_ALL_CLUB_BEGIN: 'VIEW_ALL_CLUB_BEGIN',
  VIEW_ALL_CLUB_SUCCESS: 'VIEW_ALL_CLUB_SUCCESS',
  VIEW_ALL_CLUB_ERR: 'VIEW_ALL_CLUB_ERR',
  VIEW_CLUB_BEGIN: 'VIEW_CLUB_BEGIN',
  VIEW_CLUB_SUCCESS: 'VIEW_CLUB_SUCCESS',
  VIEW_CLUB_ERR: 'VIEW_CLUB_ERR',

  viewClubBegin: () => {
    return {
      type: actions.VIEW_CLUB_BEGIN,
    };
  },

  viewClubSuccess: (data) => {
    return {
      type: actions.VIEW_CLUB_SUCCESS,
      data,
    };
  },

  viewClubErr: (err) => {
    return {
      type: actions.VIEW_CLUB_ERR,
      err,
    };
  },

  viewAllClubBegin: () => {
    return {
      type: actions.VIEW_ALL_CLUB_BEGIN,
    };
  },

  viewAllClubSuccess: (data) => {
    return {
      type: actions.VIEW_ALL_CLUB_SUCCESS,
      data,
    };
  },

  viewAllClubErr: (err) => {
    return {
      type: actions.VIEW_ALL_CLUB_ERR,
      err,
    };
  },
};

export default actions;
