/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Upload, DatePicker, Select, notification, TimePicker } from 'antd';
import { FormValidationWrap, VerticalFormStyleWrap } from './overview/Style';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import locale from 'antd/es/date-picker/locale/fr_FR'; // Import the French locale
import { useDispatch, useSelector } from 'react-redux';
import { viewClasseData } from '../../redux/classe/actionCreator';
import { addExerciceData } from '../../redux/exercice/actionCreator';
import { useNavigate } from 'react-router-dom';

function AddExerciceForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [image, setImage] = useState();

  useEffect(() => {
    dispatch(viewClasseData());
  }, []);
  const [form] = Form.useForm();
  const [niveau, setNiveau] = useState();
  const [classesSelect, setClassesSelect] = useState();

  const submitSuccess = () => {
    navigate(-1);
  };

  const { classes } = useSelector((state) => {
    return {
      classes: state.ClasseReducer.arrayData,
    };
  });
  useEffect(() => {
    const data = classes.filter((item) => item.niveau == niveau);
    const selectClasseData = data.map((item) => {
      return { value: item.id, label: item.titre };
    });
    setClassesSelect(selectClasseData);
  }, [niveau]);
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const handleSubmit = (values) => {
    values.date = values.date.format('YYYY-MM-DD');
    values.dateL = values.dateL.format('YYYY-MM-DD');
    values.heurL = values.heurL.format('HH:mm');
    values.heur = values.heur.format('HH:mm');
    const formData = new FormData();
    for (let key in values) {
      if (key != 'image') {
        formData.append(key, values[key]);
      }
    }
    if (image) {
      formData.append('image', image);
    }
    for (var pair of formData.entries()) {
    }
    dispatch(addExerciceData(formData, submitSuccess));
  };
  const props = {
    listType: 'picture',
    beforeUpload: (file) => {
      setImage(file);
      return false;
    },
  };
  const handleAnnuler = () => {
    navigate(-1);
  };
  return (
    <>
      <Main>
        <Row gutter={25}>
          <Col xs={24}>
            <FormValidationWrap>
              <VerticalFormStyleWrap>
                <Cards headless>
                  <Form name="sDash_validation-form" form={form} layout="vertical" onFinish={handleSubmit}>
                    <div className="form-title">Ajouter Un Exercice </div>
                    <Row gutter={30}>
                      <Col md={24} xs={24}>
                        <Form.Item
                          name="matiere"
                          label="Matière"
                          rules={[{ required: true, message: 'Matière est obligatoire !' }]}
                        >
                          <Input placeholder="Matière" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="date"
                          label="Date Debut"
                          rules={[{ required: true, message: 'Date debut est obligatoire!' }]}
                        >
                          <DatePicker format="yyyy/MM/DD" locale={locale} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="dateL"
                          label="Date Limite"
                          rules={[{ required: true, message: 'Date Limite est obligatoire!' }]}
                        >
                          <DatePicker format="yyyy/MM/DD" locale={locale} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Heur Limite Debut"
                          name="heur"
                          rules={[{ required: true, message: 'Heur limite debut est obligatoire!' }]}
                        >
                          <TimePicker format="HH:mm" locale={locale} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Heur Limite Fin"
                          name="heurL"
                          rules={[{ required: true, message: 'Heur limite fin est obligatoire!' }]}
                        >
                          <TimePicker format="HH:mm" locale={locale} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item label="Niveau" rules={[{ required: true, message: 'Niveau est obligatoire!' }]}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Niveau"
                            onChange={(e) => setNiveau(e)}
                            options={[
                              { value: '1', label: '1ère année' },
                              { value: '2', label: '2ème année' },
                              { value: '3', label: '3ème année' },
                              { value: '4', label: '4ème année' },
                              { value: '5', label: '5ème année' },
                              { value: '6', label: '6ème année' },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="classe_id"
                          label="Classe"
                          rules={[{ required: true, message: 'Classe est obligatoire!' }]}
                        >
                          <Select style={{ width: '100%' }} placeholder="Classe" options={classesSelect} />
                        </Form.Item>
                      </Col>
                      <Col md={24} xs={24}>
                        <Form.Item
                          name="description"
                          label="Description"
                          rules={[{ required: true, message: 'Description est obligatoire !' }]}
                        >
                          <Input.TextArea placeholder="Description ..." />
                        </Form.Item>
                      </Col>
                      <Col md={24} xs={24}>
                        <Form.Item valuePropName="fileList" getValueFromEvent={normFile} label="Photo">
                          <Upload.Dragger maxCount={1} name="files" {...props}>
                            <p className="ant-upload-hint">sélectionner ou faire glisser des fichiers ici</p>
                            <p className="ant-upload-text">JPG, PNG or PDF, file size pas plus que 10MB</p>
                          </Upload.Dragger>
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="edulink-form-action mt-20">
                      <Button htmlType="reset" type="light" size="large" onClick={handleAnnuler}>
                        Annuler
                      </Button>
                      <Button htmlType="submit" type="danger" size="large">
                        Ajouter
                      </Button>
                    </div>
                  </Form>
                </Cards>
              </VerticalFormStyleWrap>
            </FormValidationWrap>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default AddExerciceForm;
