// src/CustomBarChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { Chart } from 'chart.js';

// Register a plugin to round all corners of bars
const roundedBar = {
  id: 'roundedBar',
  beforeDraw: (chart) => {
    const ctx = chart.ctx;

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const { borderRadius } = dataset;

      chart.getDatasetMeta(datasetIndex).data.forEach((bar) => {
        const { x, y, base, width } = bar;
        const radius = Math.min(borderRadius, width / 2, (base - y) / 2);

        ctx.save();
        ctx.beginPath();

        // Top left corner
        ctx.moveTo(x - width / 2 + radius, y);
        ctx.lineTo(x + width / 2 - radius, y);
        ctx.quadraticCurveTo(x + width / 2, y, x + width / 2, y + radius);

        // Bottom right corner
        ctx.lineTo(x + width / 2, base - radius);
        ctx.quadraticCurveTo(x + width / 2, base, x + width / 2 - radius, base);

        // Bottom left corner
        ctx.lineTo(x - width / 2 + radius, base);
        ctx.quadraticCurveTo(x - width / 2, base, x - width / 2, base - radius);

        // Top left corner
        ctx.lineTo(x - width / 2, y + radius);
        ctx.quadraticCurveTo(x - width / 2, y, x - width / 2 + radius, y);

        ctx.closePath();
        ctx.fillStyle = bar.options.backgroundColor;
        ctx.fill();
        ctx.restore();
      });
    });
  },
};

// Register the plugin
Chart.register(roundedBar);

const CustomBarChart = ({ labels, datasets }) => {
  const colors = ['#A1E3CB', '#1C1C1C', '#A8C5DA', '#B1E3FF', '#95A4FC'];

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: datasets,
        backgroundColor: datasets.map(() => colors[Math.floor(Math.random() * colors.length)]),
        borderWidth: 0,
        borderRadius: 10,
        barThickness: 30,
        borderSkipped: false,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return Number.isInteger(value) ? value : null;
          },
        },
        beginAtZero: true,
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: true,
        },
      },
    },
    plugins: {
      roundedBar: true,
      legend: {
        display: false,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default CustomBarChart;
