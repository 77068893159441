import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import { useDispatch, useSelector } from 'react-redux';
import { viewEventData } from '../../../../redux/parent/event/actionCreator';

const AllPosts = lazy(() => import('./timeline/Posts'));

function Events() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { events } = useSelector((state) => {
    return {
      events: state.ParentEventReducer.own,
    };
  });
  useEffect(() => {
    dispatch(viewEventData());
  }, []);

  useEffect(() => {
    if (events) {
      const localData = events.map((item) => {
        const [date, heur] = item.dateHeur.split(' ');
        return {
          titre: item.titre,
          date: date,
          heur: heur,
          adresse: item.adresse,
          description: item.description,
          frais: item.frais,
          created_at: item.created_at,
          image: item.image,
          type: 'Evenement',
        };
      });
      localData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setData(localData);
    }
  }, [events]);
  useEffect(() => {
  }, [data]);
  return (
    <Row gutter={25}>
      <Col lg={24} xs={24}>
        <Suspense
          fallback={
            <Cards headless>
              <Skeleton active />
            </Cards>
          }
        ></Suspense>
        {data.map((item) => {
          return (
            <Suspense
              key={item}
              fallback={
                <Cards headless>
                  <Skeleton active />
                </Cards>
              }
            >
              <AllPosts item={item} />
            </Suspense>
          );
        })}
      </Col>
    </Row>
  );
}

export default Events;
