import { FaRegCircle } from 'react-icons/fa6';
import { Menu, Drawer } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import './style.css';
function ResponsiveMenuItems({ isOpen, onClose }) {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;

  function getItem(label, key, icon, path) {
    return {
      key,
      icon,
      label,
      path,
    };
  }

  const { role } = useSelector((state) => {
    return {
      role: state.auth.role,
    };
  });

  const path = `/${role}`;

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState([`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`]);

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items =
    role == 'admin'
      ? [
          getItem(
            <NavLink onClick={onClose} to={`${path}/statistique`}>
              {t('Statistiques')}
            </NavLink>,
            'statistques',
            <FaRegCircle className="sidebar-icon" />,
            `${path}/statistique`,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/clubs`}>
              {t('Clubs & Activités')}
            </NavLink>,
            'clubs',
            <FaRegCircle className="sidebar-icon" />,
            `${path}/clubs`,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/events`}>
              {t('Evènements')}
            </NavLink>,
            'events',
            <FaRegCircle className="sidebar-icon" />,
            `${path}/events`,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/actualite`}>
              {t('actualite')}
            </NavLink>,
            'actualite',
            <FaRegCircle className="sidebar-icon" />,
            `${path}/actualite`,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/inscription`}>
              {t('inscription')}
            </NavLink>,
            'inscription',
            <FaRegCircle className="sidebar-icon" />,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/cantine`}>
              {t('cantine')}
            </NavLink>,
            'cantine',
            <FaRegCircle className="sidebar-icon" />,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/classes`}>
              {t('Classes')}
            </NavLink>,
            'classes',
            <FaRegCircle className="sidebar-icon" />,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/emploi`}>
              {t('emploi du temps')}
            </NavLink>,
            'emploi',
            <FaRegCircle className="sidebar-icon" />,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/absenceeleve`}>
              {t('absence elève')}
            </NavLink>,
            'absenceeleve',
            <FaRegCircle className="sidebar-icon" />,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/exercices`}>
              {t('exercices')}
            </NavLink>,
            'exercices',
            <FaRegCircle className="sidebar-icon" />,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/reunion`}>
              {t('Réunion')}
            </NavLink>,
            'reunion',
            <FaRegCircle className="sidebar-icon" />,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/absenceprofs`}>
              {t('absence profs')}
            </NavLink>,
            'absenceprofs',
            <FaRegCircle className="sidebar-icon" />,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/notes`}>
              {t('notes')}
            </NavLink>,
            'notes',
            <FaRegCircle className="sidebar-icon" />,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/bulletin`}>
              {t('bulletins')}
            </NavLink>,
            'bulletin',
            <FaRegCircle className="sidebar-icon" />,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/paymentmethods`}>
              {t('methodes de payment')}
            </NavLink>,
            'paymentmethods',
            <FaRegCircle className="sidebar-icon" />,
          ),

          getItem(
            <NavLink onClick={onClose} to={`${path}/personnel`}>
              {t('personnel')}
            </NavLink>,
            'personnel',
            <FaRegCircle className="sidebar-icon" />,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/charge`}>
              {t('charge')}
            </NavLink>,
            'charge',
            <FaRegCircle className="sidebar-icon" />,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/typecharge`}>
              {t('Type Charge')}
            </NavLink>,
            'Type Charge',
            <FaRegCircle className="sidebar-icon" />,
          ),
        ]
      : role == 'parents'
      ? [
          getItem(
            <NavLink onClick={onClose} to={`${path}/profile/timeline`}>
              {t('profil')}
            </NavLink>,
            'profile',
            <FaRegCircle className="sidebar-icon" />,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/calendar`}>
              {t('calendrier')}
            </NavLink>,
            'calendar',
            <FaRegCircle className="sidebar-icon" />,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/emploi`}>
              {t('emploi')}
            </NavLink>,
            'emploi',
            <FaRegCircle className="sidebar-icon" />,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/clubs`}>
              {t('clubs')}
            </NavLink>,
            'clubs',
            <FaRegCircle className="sidebar-icon" />,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/events`}>
              {t('events')}
            </NavLink>,
            'events',
            <FaRegCircle className="sidebar-icon" />,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/cantine`}>
              {t('cantine')}
            </NavLink>,
            'cantine',
            <FaRegCircle className="sidebar-icon" />,
          ),
        ]
      : role == 'professeur'
      ? [
          getItem(
            <NavLink onClick={onClose} to={`${path}`}>
              {t('absence elève')}
            </NavLink>,
            'absenceeleve',
            <FaRegCircle className="sidebar-icon" />,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/exercices`}>
              {t('exercices')}
            </NavLink>,
            'exercices',
            <FaRegCircle className="sidebar-icon" />,
          ),
          getItem(
            <NavLink onClick={onClose} to={`${path}/bulletin`}>
              {t('bulletins')}
            </NavLink>,
            'bulletin',
            <FaRegCircle className="sidebar-icon" />,
          ),

          getItem(
            <NavLink onClick={onClose} to={`${path}/notes`}>
              {t('notes')}
            </NavLink>,
            'notes',
            <FaRegCircle className="sidebar-icon" />,
          ),
        ]
      : [
          getItem(
            <NavLink onClick={onClose} to={`${path}/`}>
              {t('admins')}
            </NavLink>,
            'admins',
            <FaRegCircle className="sidebar-icon" />,
          ),
        ];

  return (
    <Drawer title={null} placement={'left'} closable={false} onClose={onClose} open={isOpen}>
      {items.map((item) => {
        return (
          <div
            key={item.key}
            className={`${item.path && item.path === pathname ? 'responsive-nav-item-active' : 'responsive-nav-item'}`}
          >
            <div>{item.icon}</div>
            <div>{item.label}</div>
          </div>
        );
      })}
    </Drawer>
  );
}

export default ResponsiveMenuItems;
