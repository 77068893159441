import React, { useState, lazy, Suspense, useLayoutEffect, useEffect } from 'react';
import { Row, Col, Spin, Menu } from 'antd';
import UilAlignLeft from '@iconscout/react-unicons/icons/uil-align-left';
import UilAlignRight from '@iconscout/react-unicons/icons/uil-align-right';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import UilTimes from '@iconscout/react-unicons/icons/uil-times';
import { NoteNav, NoteWrapper } from './style';
import { Main } from '../styled';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import { MailOutlined } from '@ant-design/icons';
import ClassForm from './ClassForm';
import { viewClasseData } from '../../redux/classe/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { viewInscriptionData } from '../../redux/inscription/actionCreator';

const { SubMenu } = Menu;

const All = lazy(() => import('./overview/all'));

function Classes() {
  const [eleve, setEleve] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const dispatch = useDispatch();
  const { inscriptions, classes } = useSelector((state) => {
    return {
      classes: state.ClasseReducer.arrayData,
      inscriptions: state.InscriptionReducer.arrayData,
    };
  });
  useEffect(() => {
    dispatch(viewClasseData());
    dispatch(viewInscriptionData());
  }, []);
  useEffect(() => {
    if (selectedClass) {
      const eleveList = inscriptions.filter((item) => item.classe.id == selectedClass.id);
      setEleve(eleveList);
    }
  }, [selectedClass]);

  const [open, setOpen] = useState(false);
  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const [state, setState] = useState({
    visible: false,
    modalType: 'primary',
    checked: [],
    responsive: 0,
    collapsed: false,
  });

  const { responsive, collapsed } = state;

  useLayoutEffect(() => {
    function updateSize() {
      const width = window.innerWidth;
      setState({ responsive: width });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const toggleCollapsed = () => {
    setState({
      ...state,
      collapsed: !collapsed,
    });
  };

  return (
    <>
      <Main>
        <ClassForm open={open} closeModal={closeModal} title="Ajouter un classe" />
        <NoteWrapper>
          <Row className="justify-content-center" gutter={25}>
            <Col className="trigger-col" xxl={5} xl={7} lg={9} xs={24}>
              <div className="sidebar-card">
                <Cards headless>
                  <div className="note-sidebar-top">
                    <Button type="danger" size="default" block style={{ borderRadius: '17px' }} onClick={openModal}>
                      <UilPlus /> Ajouter Un Classe
                    </Button>
                  </div>
                  <div className="note-sidebar-bottom">
                    <NoteNav>
                      <Menu mode="inline">
                        {['1', '2', '3', '4', '5', '6'].map((niveau) => (
                          <SubMenu
                            key={niveau}
                            title={
                              <span>
                                <MailOutlined />
                                <span>Niveau {niveau}</span>
                              </span>
                            }
                          >
                            {classes.map(
                              (item, index) =>
                                item.niveau == niveau && (
                                  <Menu.Item key={index + 1} onClick={() => setSelectedClass(item)}>
                                    {item.niveau} {item.niveau == 1 ? 'ère' : 'ème'} année {item.titre}
                                  </Menu.Item>
                                ),
                            )}
                          </SubMenu>
                        ))}
                      </Menu>
                    </NoteNav>
                  </div>
                </Cards>
              </div>
            </Col>
            <Col xxl={19} xl={17} lg={15} xs={24}>
              <Suspense
                fallback={
                  <div className="spin">
                    <Spin />
                  </div>
                }
              >
                <All classe={selectedClass} eleve={eleve} />
              </Suspense>
            </Col>
          </Row>
        </NoteWrapper>
      </Main>
    </>
  );
}

export default Classes;
