import { Form, Input, Select, TimePicker, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button } from '../../components/buttons/buttons';
import { BasicFormWrapper } from '../styled';
import { ReunionFormStyleWrap } from './Style';
import { useDispatch, useSelector } from 'react-redux';
import { viewMatiereData } from '../../redux/matiere/actionCreator';

function SeanceForm({ onHandleAddEvent, eventData, profs, opened, niveau }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { matieres } = useSelector((state) => {
    return {
      matieres: state.MatiereReducer.arrayData,
    };
  });
  const [matieresData, setMatieresData] = useState([]);
  useEffect(() => {
    dispatch(viewMatiereData());
  }, []);
  const filterMatieres = (level) => {
    setMatieresData(
      matieres.filter((item) => item.niveau == level).map((item) => ({ label: item.nom, value: item.nom })),
    );
  };
  useEffect(() => {
    if (niveau && matieres) {
      filterMatieres(niveau);
    }
  }, [niveau, matieres]);

  useEffect(() => {
    if (!opened) {
      form.resetFields();
    }
  }, [opened]);
  useEffect(() => {
    if (eventData) {
      console.log(eventData);
      form.setFieldsValue({
        matiere: eventData.matiere || '',
        salle: eventData.salle || '',
        jour: eventData.jour || undefined,
        heurDebut: eventData.heurDebut || null,
        heurFin: eventData.heurFin || null,
        typeCours: eventData.typeCours || '',
        professeur_id: eventData.professeur_id || undefined,
      });
    } else {
      form.setFieldsValue({
        matiere: '',
        salle: '',
        jour: undefined,
        heurDebut: null,
        heurFin: null,
        typeCours: '',
        professeur_id: undefined,
      });
    }
  }, [eventData, form]);

  const reset = () => {};

  const handleSubmit = (values) => {
    if (values.heurDebut > values.heurFin) {
      return message.error('Start hour must be before end hour');
    }
    values.heurDebut = values.heurDebut.format('HH:mm:ss');
    values.heurFin = values.heurFin.format('HH:mm:ss');

    if (eventData && eventData.id) {
      values.id = eventData.id;
    }
    onHandleAddEvent(values);
    reset();
  };

  return (
    <BasicFormWrapper>
      <ReunionFormStyleWrap>
        <Form form={form} name="addSeanceForm" onFinish={handleSubmit}>
          <div className="edulink-event-form-input">
            <span className="edulink-event-form-label">Matiere :</span>
            <Form.Item name="matiere" rules={[{ required: true, message: 'La matiere est obligatoire !' }]}>
              <Select placeholder="Choisir une matiere" options={matieresData} />
            </Form.Item>
          </div>
          <div className="edulink-event-form-input">
            <span className="edulink-event-form-label">Salle :</span>
            <Form.Item name="salle" rules={[{ required: true, message: 'La salle est obligatoire !' }]}>
              <Select
                options={[
                  {
                    label: 'salle 1',
                    value: 'salle 1',
                  },
                  {
                    label: 'salle 2',
                    value: 'salle 2',
                  },
                  {
                    label: 'salle 3',
                    value: 'salle 3',
                  },
                  {
                    label: 'salle 4',
                    value: 'salle 4',
                  },
                ]}
                placeholder="Choisir une salle"
              />
            </Form.Item>
          </div>
          <div className="edulink-event-form-input">
            <span className="edulink-event-form-label">Jour :</span>
            <Form.Item name="jour" rules={[{ required: true, message: 'Le jour est obligatoire !' }]}>
              <Select
                options={[
                  { value: 'lundi', label: 'lundi' },
                  { value: 'mardi', label: 'mardi' },
                  { value: 'mercredi', label: 'mercredi' },
                  { value: 'jeudi', label: 'jeudi' },
                  { value: 'vendredi', label: 'vendredi' },
                  { value: 'samedi', label: 'samedi' },
                ]}
                placeholder="Choisir le jour"
              />
            </Form.Item>
          </div>
          <div className="edulink-event-form-input">
            <span className="edulink-event-form-label">Heur Debut :</span>
            <Form.Item name="heurDebut" rules={[{ required: true, message: 'La heur debut est obligatoire !' }]}>
              <TimePicker format="HH:mm" />
            </Form.Item>
          </div>
          <div className="edulink-event-form-input">
            <span className="edulink-event-form-label">Heur Fin :</span>
            <Form.Item name="heurFin" rules={[{ required: true, message: 'La heur fin est obligatoire !' }]}>
              <TimePicker format="HH:mm" />
            </Form.Item>
          </div>
          <div className="edulink-event-form-input">
            <span className="edulink-event-form-label">Type Cours :</span>
            <Form.Item name="typeCours" rules={[{ required: true, message: 'Le type cours est obligatoire !' }]}>
              <Select
                options={[
                  {
                    label: 'TP',
                    value: 'tp',
                  },
                  {
                    label: 'Cours',
                    value: 'cours',
                  },
                  {
                    label: 'Oral',
                    value: 'oral',
                  },
                  {
                    label: 'Exercice',
                    value: 'exercice',
                  },
                ]}
                placeholder="Choisir le type cours"
              />
            </Form.Item>
          </div>
          <div className="edulink-event-form-input">
            <span className="edulink-event-form-label">Prof :</span>
            <Form.Item name="professeur_id" rules={[{ required: true, message: 'Le jour est obligatoire !' }]}>
              <Select options={profs} placeholder="Choisir le prof" />
            </Form.Item>
          </div>
          <div className="add-event-footer text-right">
            <Button htmlType="submit" className="btn-save" type="primary">
              Enregistrer
            </Button>
          </div>
        </Form>
      </ReunionFormStyleWrap>
    </BasicFormWrapper>
  );
}

export default SeanceForm;
