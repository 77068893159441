import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  addAbsenceProfBegin,
  addAbsenceProfSuccess,
  addAbsenceProfErr,

  viewAbsenceProfBegin,
  viewAbsenceProfSuccess,
  viewAbsenceProfErr,

  deleteAbsenceProfBegin,
  deleteAbsenceProfSuccess,
  deleteAbsenceProfErr,
} = actions;

const viewAbsenceProfData = (date) => {
  return async (dispatch) => {
    dispatch(viewAbsenceProfBegin());
    try {
      const response = await DataService.get('/absenceProf/view', { date });
      if (response.status === 200) {
        dispatch(viewAbsenceProfSuccess(response.data.payload));
      } else {
        dispatch(viewAbsenceProfErr('err'));
      }
    } catch (err) {
      dispatch(viewAbsenceProfErr('err'));
    }
  };
};

const addAbsenceProfData = (prof_id) => {
  return async (dispatch) => {
    dispatch(addAbsenceProfBegin());
    try {
      const response = await DataService.post('/absenceProf/add', { prof_id });

      if (response.status === 200) {
        dispatch(addAbsenceProfSuccess(response.data.payload));
      } else {
        dispatch(addAbsenceProfErr('err'));
      }
    } catch (err) {
      dispatch(addAbsenceProfErr('err'));
    }
  };
};
const deleteAbsenceProfData = (abcence) => {
  return async (dispatch) => {
    dispatch(deleteAbsenceProfBegin());
    try {
      const response = await DataService.delete(`/absenceProf/delete/${abcence.id}`);
      if (response.status === 200) {
        dispatch(deleteAbsenceProfSuccess(abcence));
      } else {
        dispatch(deleteAbsenceProfErr('err'));
      }
    } catch (err) {
      dispatch(deleteAbsenceProfErr('err'));
    }
  };
};

export { viewAbsenceProfData, deleteAbsenceProfData, addAbsenceProfData };
