import { message } from 'antd';
import actions from './actions';

const initialState = {
  data: [],
  error: null,
};

const { VIEW_EMPLOI_BEGIN, VIEW_EMPLOI_SUCCESS, VIEW_EMPLOI_ERR } = actions;

const EmploiReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case VIEW_EMPLOI_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_EMPLOI_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case VIEW_EMPLOI_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default EmploiReducer;
