export const transformData = (data) => {
  // Transform club registrations
  const clubRegistrations = data.clubRegistrations.map((club) => ({
    id: club.id,
    title: club.titre,
    day: club.jour,
    time: club.heur,
    address: club.adresse,
    image: club.image,
    description: club.description,
    fees: club.frais,
    organizationId: club.organisation_id,
    createdAt: club.created_at,
    updatedAt: club.updated_at,
    method: club.methode,
    installments: club.ListTranche
      ? club.ListTranche.map((tranche) => {
          const nameKey = Object.keys(tranche).find((key) => key.startsWith('nomTranche'));
          const amountKey = Object.keys(tranche).find((key) => key.startsWith('montantTranche'));
          const name = tranche[nameKey];
          const amount = parseFloat(tranche[amountKey]);

          return {
            name,
            amount,
            paid: tranche.paid,
          };
        })
      : [],
    paid: club.paid,
  }));

  // Transform event registrations
  const eventRegistrations = data.eventRegistrations.map((event) => ({
    id: event.id,
    title: event.titre,
    fees: event.frais,
    description: event.description,
    address: event.adresse,
    image: event.image,
    dateTime: event.dateHeur,
    organizationId: event.organisation_id,
    createdAt: event.created_at,
    updatedAt: event.updated_at,
    paid: event.paid,
  }));

  // Transform tranches
  const installments = data.tranches.map((tranche) => ({
    name: tranche.nomTranche,
    amount: parseFloat(
      tranche.montantTranche,
    ),
    paid: tranche.paid,
  }));

  const otherInstallments = data.autreFrais.map((tranche) => ({
    name: tranche.nom,
    amount: parseFloat(
      tranche.montant,
    ),
    paid: tranche.paid,
  }));

  // Return transformed data
  return {
    clubRegistrations,
    eventRegistrations,
    totalClubAmount: data.totalMontantForClubs,
    totalEventFees: data.totalFraisForEvents,
    totalRegistration: data.inscriptionTotal,
    method: data.methode,
    installments,
    otherInstallments,
  };
};
