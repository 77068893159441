import React, { useState, useEffect } from 'react';
import { Layout, Row, Col } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import MenueItems from './MenueItems';
import { FooterStyle, LayoutContainer, SmallScreenAuthInfo, TopMenuSearch } from './Style';
import TopMenu from './TopMenu';
import Search from '../components/utilities/auth-info/Search';
import AuthInfo from '../components/utilities/auth-info/info';
import ResponsiveMenuItems from './ResponsiveMenueItems';
import propTypes from 'prop-types';
import { MenuOutlined } from '@ant-design/icons';

const { theme } = require('../config/theme/themeVariables');
const { Header, Sider, Content } = Layout;

const ThemeLayout = (WrappedComponent) => {
  const LayoutComponent = ({ layoutMode, rtl, topMenu }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [hide, setHide] = useState(true);
    const [openSideBarMenu, setOpenSideBarMenu] = useState(false);
    const handleOpenSidebarMenu = () => {
      setOpenSideBarMenu(true);
    };
    const handleCloseSidebarMenu = () => {
      setOpenSideBarMenu(false);
    };

    useEffect(() => {
      const updateDimensions = () => {
        setCollapsed(window.innerWidth < 990);
      };

      window.addEventListener('resize', updateDimensions);
      updateDimensions();

      return () => {
        window.removeEventListener('resize', updateDimensions);
      };
    }, []);

    const toggleCollapsed = () => {
      setCollapsed(!collapsed);
    };

    const toggleCollapsedMobile = () => {
      if (window.innerWidth <= 990) {
        setCollapsed(!collapsed);
      }
    };

    const SideBarStyle = {
      margin: '0 0 0 0',
      padding: '20px 20px 55px 0',
      overflow: 'hidden',
      height: '100vh',
      position: 'fixed',
      left: 0,
      zIndex: 988,
      borderRadius: '0px 20px 20px 0px',
      display: collapsed ? 'none' : '',
    };

    const renderView = ({ style }) => {
      const customStyle = {
        marginRight: 'auto',
        [rtl ? 'marginLeft' : 'marginRight']: '-17px',
      };
      return <div style={{ ...style, ...customStyle }} />;
    };

    const renderThumbVertical = ({ style }) => {
      const thumbStyle = {
        borderRadius: 6,
        backgroundColor: '#F1F2F6',
        [rtl ? 'right' : 'left']: '2px',
      };
      return <div style={{ ...style, ...thumbStyle }} />;
    };

    const renderThumbHorizontal = ({ style }) => {
      const thumbStyle = {
        borderRadius: 6,
        backgroundColor: '#F1F2F6',
      };
      return <div style={{ ...style, ...thumbStyle }} />;
    };

    return (
      <LayoutContainer>
        <Layout className="layout">
          <Layout>
            <ThemeProvider theme={theme}>
              <Sider width={280} style={SideBarStyle} theme={layoutMode === 'lightMode' ? 'light' : 'dark'}>
                <Scrollbars
                  className="custom-scrollbar"
                  autoHide
                  autoHideTimeout={500}
                  autoHideDuration={200}
                  renderThumbHorizontal={renderThumbHorizontal}
                  renderThumbVertical={renderThumbVertical}
                  renderView={renderView}
                >
                  <MenueItems />
                </Scrollbars>
              </Sider>
            </ThemeProvider>
            <ResponsiveMenuItems isOpen={openSideBarMenu} onClose={handleCloseSidebarMenu} />
            <Layout className="atbd-main-layout">
              <Content>
                <Header>
                  <div className="edulink-header-content d-flex">
                    <div
                      style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                    >
                      {window.innerWidth < 990 ? (
                        <div
                          style={{
                            height: '30px',
                            width: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                          onClick={handleOpenSidebarMenu}
                        >
                          <MenuOutlined style={{ fontSize: '20px', color: '#A0A0A0' }} />
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <div className="edulink-mobile-action">
                        <AuthInfo />
                      </div>
                    </div>
                  </div>
                </Header>
                <WrappedComponent />
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </LayoutContainer>
    );
  };

  const mapStateToProps = (state) => {
    return {
      layoutMode: state.ChangeLayoutMode.mode,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  };

  LayoutComponent.propTypes = {
    layoutMode: propTypes.string,
    rtl: propTypes.bool,
    topMenu: propTypes.bool,
  };

  return connect(mapStateToProps)(LayoutComponent);
};

export default ThemeLayout;
