import { message } from 'antd';
import actions from './actions';

const initialState = {
  arrayData: [],
  singleData:null,
  loading: false,
  error: null,
};

const {
  VIEW_PERSONNEL_BEGIN,
  VIEW_PERSONNEL_SUCCESS,
  VIEW_PERSONNEL_ERR,

  VIEW_SINGLE_PERSONNEL_BEGIN,
  VIEW_SINGLE_PERSONNEL_SUCCESS,
  VIEW_SINGLE_PERSONNEL_ERR,

  ADD_PERSONNEL_BEGIN,
  ADD_PERSONNEL_SUCCESS,
  ADD_PERSONNEL_ERR,

  DELETE_PERSONNEL_BEGIN,
  DELETE_PERSONNEL_SUCCESS,
  DELETE_PERSONNEL_ERR,

  UPDATE_PERSONNEL_BEGIN,
  UPDATE_PERSONNEL_SUCCESS,
  UPDATE_PERSONNEL_ERR,
} = actions;

const PersonnelReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_PERSONNEL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PERSONNEL_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case UPDATE_PERSONNEL_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_PERSONNEL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_PERSONNEL_SUCCESS:
      return {
        ...state,
        arrayData:data,
        loading: false,
      };
    case VIEW_PERSONNEL_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_SINGLE_PERSONNEL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_SINGLE_PERSONNEL_SUCCESS:
      return {
        ...state,
        singleData:data,
        loading: false,
      };
    case VIEW_SINGLE_PERSONNEL_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_PERSONNEL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PERSONNEL_SUCCESS:
      const arrayData = state.arrayData.filter(
        (item) => item.id !== data
      );
      return {
        ...state,
        arrayData,  
        loading: false,
      };
    case DELETE_PERSONNEL_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_PERSONNEL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_PERSONNEL_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case ADD_PERSONNEL_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default PersonnelReducer;
