import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Select } from 'antd';
import { viewFieldSuccesRateStatisticsData } from '../../../redux/statistics/actionCreator';
import { viewClasseData } from '../../../redux/classe/actionCreator';
import CustomBarChart from '../CustomBarChart';

const ClassSuccessRate = () => {
  const dispatch = useDispatch();
  const [classesData, setClassesData] = useState();
  const [classe, setClasse] = useState();

  const { statistics, classes } = useSelector((state) => {
    return {
      statistics: state.StatisticsReducer.fieldSuccesRate,
      classes: state.ClasseReducer.arrayData,
    };
  });

  useEffect(() => {
    dispatch(viewClasseData());
  }, []);

  useEffect(() => {
    if (classe) {
      console.log(classe);

      dispatch(viewFieldSuccesRateStatisticsData(classe));
    }
  }, [classe]);

  useEffect(() => {
    if (classes) {
      console.log(classes);

      const data = classes.map((item) => ({
        label: item.classe,
        value: item.id,
      }));
      console.log(data);

      setClassesData(data);
    }
  }, [classes]);

  useEffect(() => {
    if (classesData && classesData.length > 0) {
      console.log(classesData);
      setClasse(classesData[0].value);
    }
  }, [classesData]);

  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);

  useEffect(() => {
    if (statistics) {
      const keys = statistics.map((item) => item.matiere);
      const values = statistics.map((item) => parseFloat(item.success_rate));

      console.log(keys);

      setLabels(keys);
      setDatasets(values);
    }
  }, [statistics]);

  return (
    <Cards headless>
      <div
        style={{
          height: '40vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '18px',
            fontWeight: '500',
            marginBottom: '10px',
            color: '#333',
            width: '100%',
          }}
        >
          <div>Taux de réussite par Classe</div>
          <Select
            style={{ height: '40px', width: '150px' }}
            placeholder="Classe"
            options={classesData}
            value={classe}
            onChange={(value) => setClasse(value)}
          />
        </div>
        <CustomBarChart labels={labels} datasets={datasets} title="Inscriptions by Month for 2024" beginAtZero={true} />
      </div>
    </Cards>
  );
};

export default ClassSuccessRate;
