import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  addCantineBegin,
  addCantineSuccess,
  addCantineErr,
  updateCantineBegin,
  updateCantineSuccess,
  updateCantineErr,
  viewCantineBegin,
  viewCantineSuccess,
  viewCantineErr,
  viewSingleCantineBegin,
  viewSingleCantineSuccess,
  viewSingleCantineErr,
  deleteCantineBegin,
  deleteCantineSuccess,
  deleteCantineErr,
} = actions;

const viewCantineData = (start_date, end_date) => {
  return async (dispatch) => {
    dispatch(viewCantineBegin());
    try {
      const response = await DataService.get('/cantine/view', {
        start_date,
        end_date,
      });

      if (response.status === 200) {
        dispatch(viewCantineSuccess(response.data.payload));
      } else {
        dispatch(viewCantineErr('err'));
      }
    } catch (err) {
      dispatch(viewCantineErr('err'));
    }
  };
};

const viewSingleCantineData = (id) => {
  return async (dispatch) => {
    dispatch(viewSingleCantineBegin());
    try {
      const response = await DataService.get(`/cantine/singleInfo/${id}`);
      if (response.status === 200) {
        dispatch(viewSingleCantineSuccess(response.data.payload));
      } else {
        dispatch(viewSingleCantineErr('err'));
      }
    } catch (err) {
      dispatch(viewSingleCantineErr('err'));
    }
  };
};

const addCantineData = (data, callback) => {
  return async (dispatch) => {
    dispatch(addCantineBegin());
    try {
      const response = await DataService.post('/cantine/add', data);
      if (response.status === 200) {
        dispatch(addCantineSuccess());
        callback();
        message.success(response.data.message);
      } else {
        dispatch(addCantineErr('err'));
      }
    } catch (err) {
      dispatch(addCantineErr(err));
    }
  };
};
const deleteCantineData = (data) => {
  return async (dispatch) => {
    dispatch(deleteCantineBegin());
    try {
      const response = await DataService.delete(`/cantine/delete/Repas/${data} `);
      if (response.status == 200) {
        dispatch(deleteCantineSuccess(data));
        message.success(response.data.message);
      } else {
        dispatch(deleteCantineErr('err'));
      }
    } catch (err) {
      dispatch(deleteCantineErr('err'));
    }
  };
};
const updateCantineData = (data, callback) => {
  return async (dispatch) => {
    dispatch(updateCantineBegin());
    try {
      const response = await DataService.put(`/cantine/updated`, data);
      if (response.status === 200) {
        dispatch(updateCantineSuccess(data));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(updateCantineErr('err'));
      }
    } catch (err) {
      dispatch(updateCantineErr(err));
    }
  };
};

export { viewCantineData, viewSingleCantineData, updateCantineData, deleteCantineData, addCantineData };
