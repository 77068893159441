const actions = {
  VIEW_REUNION_BEGIN: 'VIEW_REUNION_BEGIN',
  VIEW_REUNION_SUCCESS: 'VIEW_REUNION_SUCCESS',
  VIEW_REUNION_ERR: 'VIEW_REUNION_ERR',

  VIEW_SINGLE_REUNION_BEGIN: 'VIEW_SINGLE_REUNION_BEGIN',
  VIEW_SINGLE_REUNION_SUCCESS: 'VIEW_SINGLE_REUNION_SUCCESS',
  VIEW_SINGLE_REUNION_ERR: 'VIEW_SINGLE_REUNION_ERR',

  ADD_REUNION_BEGIN: 'ADD_REUNION_BEGIN',
  ADD_REUNION_SUCCESS: 'ADD_REUNION_SUCCESS',
  ADD_REUNION_ERR: 'ADD_REUNION_ERR',

  DELETE_REUNION_BEGIN: 'DELETE_REUNION_BEGIN',
  DELETE_REUNION_SUCCESS: 'DELETE_REUNION_SUCCESS',
  DELETE_REUNION_ERR: 'DELETE_REUNION_ERR',

  UPDATE_REUNION_BEGIN: 'UPDATE_REUNION_BEGIN',
  UPDATE_REUNION_SUCCESS: 'UPDATE_REUNION_SUCCESS',
  UPDATE_REUNION_ERR: 'UPDATE_REUNION_ERR',

  CHANGE_START_DATE_BEGIN: 'CHANGE_START_DATE_BEGIN',
  CHANGE_START_DATE_SUCCESS: 'CHANGE_START_DATE_SUCCESS',
  CHANGE_START_DATE_ERR: 'CHANGE_START_DATE_ERR',

  CHANGE_END_DATE_BEGIN: 'CHANGE_END_DATE_BEGIN',
  CHANGE_END_DATE_SUCCESS: 'CHANGE_END_DATE_SUCCESS',
  CHANGE_END_DATE_ERR: 'CHANGE_END_DATE_ERR',

  CHANGE_SELECTED_CLASSE_BEGIN: 'CHANGE_SELECTED_CLASSE_BEGIN',
  CHANGE_SELECTED_CLASSE_SUCCESS: 'CHANGE_SELECTED_CLASSE_SUCCESS',
  CHANGE_SELECTED_CLASSE_ERR: 'CHANGE_SELECTED_CLASSE_ERR',

  viewReunionBegin: () => {
    return {
      type: actions.VIEW_REUNION_BEGIN,
    };
  },

  viewReunionSuccess: (data) => {
    return {
      type: actions.VIEW_REUNION_SUCCESS,
      data,
    };
  },

  viewReunionErr: (err) => {
    return {
      type: actions.VIEW_REUNION_ERR,
      err,
    };
  },

  viewSingleReunionBegin: () => {
    return {
      type: actions.VIEW_SINGLE_REUNION_BEGIN,
    };
  },

  viewSingleReunionSuccess: (data) => {
    return {
      type: actions.VIEW_SINGLE_REUNION_SUCCESS,
      data,
    };
  },

  viewSingleReunionErr: (err) => {
    return {
      type: actions.VIEW_SINGLE_REUNION_ERR,
      err,
    };
  },

  updateReunionBegin: () => {
    return {
      type: actions.UPDATE_REUNION_BEGIN,
    };
  },

  updateReunionSuccess: (data) => {
    return {
      type: actions.UPDATE_REUNION_SUCCESS,
      data,
    };
  },

  updateReunionErr: (err) => {
    return {
      type: actions.UPDATE_REUNION_ERR,
      err,
    };
  },
  deleteReunionBegin: () => {
    return {
      type: actions.DELETE_REUNION_BEGIN,
    };
  },

  deleteReunionSuccess: (data) => {
    return {
      type: actions.DELETE_REUNION_SUCCESS,
      data,
    };
  },

  deleteReunionErr: (err) => {
    return {
      type: actions.DELETE_REUNION_ERR,
      err,
    };
  },
  addReunionBegin: () => {
    return {
      type: actions.ADD_REUNION_BEGIN,
    };
  },

  addReunionSuccess: (data) => {
    return {
      type: actions.ADD_REUNION_SUCCESS,
      data,
    };
  },

  addReunionErr: (err) => {
    return {
      type: actions.ADD_REUNION_ERR,
      err,
    };
  },
  changeStartDateBegin: () => {
    return {
      type: actions.CHANGE_START_DATE_BEGIN,
    };
  },

  changeStartDateSuccess: (data) => {
    return {
      type: actions.CHANGE_START_DATE_SUCCESS,
      data,
    };
  },

  changeStartDateErr: (err) => {
    return {
      type: actions.CHANGE_START_DATE_ERR,
      err,
    };
  },
  changeEndDateBegin: () => {
    return {
      type: actions.CHANGE_END_DATE_BEGIN,
    };
  },

  changeEndDateSuccess: (data) => {
    return {
      type: actions.CHANGE_END_DATE_SUCCESS,
      data,
    };
  },

  changeEndDateErr: (err) => {
    return {
      type: actions.CHANGE_END_DATE_ERR,
      err,
    };
  },
  changeSelectedClasseBegin: () => {
    return {
      type: actions.CHANGE_SELECTED_CLASSE_BEGIN,
    };
  },

  changeSelectedClasseSuccess: (data) => {
    return {
      type: actions.CHANGE_SELECTED_CLASSE_SUCCESS,
      data,
    };
  },

  changeSelectedClasseErr: (err) => {
    return {
      type: actions.CHANGE_SELECTED_CLASSE_ERR,
      err,
    };
  },
};

export default actions;
