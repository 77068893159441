import { message } from 'antd';
import actions from './actions';

const initialState = {
  arrayData: [],
  singleData: null,
  loading: false,
  error: null,
};

const {
  VIEW_EVENT_BEGIN,
  VIEW_EVENT_SUCCESS,
  VIEW_EVENT_ERR,

  VIEW_SINGLE_EVENT_BEGIN,
  VIEW_SINGLE_EVENT_SUCCESS,
  VIEW_SINGLE_EVENT_ERR,

  ATTACH_EVENT_BEGIN,
  ATTACH_EVENT_SUCCESS,
  ATTACH_EVENT_ERR,

  ADD_EVENT_BEGIN,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_ERR,

  DELETE_EVENT_BEGIN,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_ERR,

  UPDATE_EVENT_BEGIN,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_ERR,
} = actions;

const EventReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_EVENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case UPDATE_EVENT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_EVENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_EVENT_SUCCESS:
      return {
        ...state,
        arrayData: data,
        loading: false,
      };
    case VIEW_EVENT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_SINGLE_EVENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_SINGLE_EVENT_SUCCESS:
      return {
        ...state,
        singleData: data,
        loading: false,
      };
    case VIEW_SINGLE_EVENT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ATTACH_EVENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ATTACH_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ATTACH_EVENT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_EVENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_EVENT_SUCCESS:
      const arrayData = state.arrayData.filter((item) => item.id !== data);
      return {
        ...state,
        arrayData,
        loading: false,
      };
    case DELETE_EVENT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_EVENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_EVENT_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case ADD_EVENT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default EventReducer;
