import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  addEmploiBegin,
  addEmploiSuccess,
  addEmploiErr,
  updateEmploiBegin,
  updateEmploiSuccess,
  updateEmploiErr,
  viewEmploiBegin,
  viewEmploiSuccess,
  viewEmploiErr,
  viewSingleEmploiBegin,
  viewSingleEmploiSuccess,
  viewSingleEmploiErr,
  deleteEmploiBegin,
  deleteEmploiSuccess,
  deleteEmploiErr,
} = actions;

const viewEmploiData = () => {
  return async (dispatch) => {
    dispatch(viewEmploiBegin());
    try {
      const response = await DataService.get('/emploi/view');
      if (response.status === 200) {
        dispatch(viewEmploiSuccess(response.data.payload));
      } else {
        dispatch(viewEmploiErr('err'));
      }
    } catch (err) {
      dispatch(viewEmploiErr('err'));
    }
  };
};

const viewSingleEmploiData = (id) => {
  return async (dispatch) => {
    dispatch(viewSingleEmploiBegin());
    try {
      const response = await DataService.get(`/emploi/singleInfo/${id}`);

      if (response.status === 200) {
        dispatch(viewSingleEmploiSuccess(response.data.payload));
      } else {
        dispatch(viewSingleEmploiErr('err'));
      }
    } catch (err) {
      dispatch(viewSingleEmploiErr('err'));
    }
  };
};

const addEmploiData = (data, callback) => {
  return async (dispatch) => {
    dispatch(addEmploiBegin());
    try {
      const response = await DataService.post('/emploi/add', data);

      if (response.status === 200) {
        dispatch(addEmploiSuccess(response.data.payload));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(addEmploiErr('err'));
        
      }
    } catch (err) {
      dispatch(addEmploiErr('err'));
      
    }
  };
};
const deleteEmploiData = (id) => {
  return async (dispatch) => {
    dispatch(deleteEmploiBegin());
    try {
      const response = await DataService.delete(`/emploi/delete/${id}`);
      if (response.status === 200) {
        dispatch(deleteEmploiSuccess(id));
        message.success(response.data.message);
      } else {
        dispatch(deleteEmploiErr('err'));
        
      }
    } catch (err) {
      dispatch(deleteEmploiErr('err'));
      
    }
  };
};
const updateEmploiData = (id, data, callback) => {
  return async (dispatch) => {
    dispatch(updateEmploiBegin());
    try {
      const response = await DataService.put(`/emploi/updated/${id}`, data);

      if (response.status === 200) {
        dispatch(updateEmploiSuccess(response.data.payload));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(updateEmploiErr('err'));
        
      }
    } catch (err) {
      dispatch(updateEmploiErr('err'));
      
    }
  };
};

export { viewEmploiData, viewSingleEmploiData, updateEmploiData, deleteEmploiData, addEmploiData };
