/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, Upload, TimePicker, DatePicker, message } from 'antd';
import { FormValidationWrap, VerticalFormStyleWrap } from './Style';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import locale from 'antd/es/date-picker/locale/fr_FR'; // Import the French locale
import { addEventData } from '../../redux/event/actionCreator';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { getItem } from '../../utility/localStorageControl';

function EventForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState();
  const { loading } = useSelector((state) => {
    return {
      loading: state.EventReducer.loading,
    };
  });

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const addSuccess = () => {
    navigate(-1);
  };

  const handleSubmit = (values) => {

    const date = values.date.format('yyyy-MM-DD');
    const time = values.heur.format('HH:mm');

    values.dateHeur = `${date} ${time}`;
    if (fileName) {
      values.image = fileName;
    }

    dispatch(addEventData(values, addSuccess));
  };
  const [form] = Form.useForm();
  const handleAnnuler = () => {
    navigate(-1);
  };

  const props = {
    name: 'file',
    multiple: false,
    listType: 'picture',
    customRequest({ file, onSuccess, onError }) {
      const formData = new FormData();
      formData.append('file', file);
      axios
        .post('https://edulinkbackend.edulink.tn/api/image/upload', formData, {
          headers: {
            Authorization: `Bearer ${getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          onSuccess(response.data, file);
          setFileName(response.data.payload);
        });
    },
    beforeUpload(file) {
      return true;
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  return (
    <>
      <Main>
        <Row gutter={25}>
          <Col xs={24}>
            <FormValidationWrap>
              <VerticalFormStyleWrap>
                <Cards headless>
                  <Form name="event-form" form={form} layout="vertical" onFinish={handleSubmit}>
                    <div className="form-title">Ajouter Un Evènement</div>
                    <Row gutter={30}>
                      <Col md={24} xs={24}>
                        <Form.Item
                          name="titre"
                          label="Titre d'évènement"
                          rules={[{ required: true, message: "Titre d'évènement est obligatoire !" }]}
                        >
                          <Input placeholder="Titre d'évènement" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="adresse"
                          label="Adresse"
                          rules={[{ required: true, message: 'Adresse est obligatoire!' }]}
                        >
                          <Input placeholder="Adresse" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="frais"
                          label="Frais"
                          rules={[{ required: true, message: 'Frais est obligatoire!' }]}
                        >
                          <Input placeholder="Frais" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="date"
                          label="Date de début"
                          rules={[{ required: true, message: 'Date de début est obligatoire!' }]}
                        >
                          <DatePicker locale={locale} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="heur"
                          label="Heure de debut"
                          rules={[{ required: true, message: 'Heure de debut est obligatoire!' }]}
                        >
                          <TimePicker format="HH:mm" locale={locale} />
                        </Form.Item>
                      </Col>
                      <Col md={24} xs={24}>
                        <Form.Item
                          name="description"
                          label="Description"
                          rules={[{ required: true, message: 'Description est obligatoire !' }]}
                        >
                          <Input.TextArea placeholder="Description ..." />
                        </Form.Item>
                      </Col>
                      <Col md={24} xs={24}>
                        <Form.Item valuePropName="fileList" getValueFromEvent={normFile} label="Photo d'évènement">
                          <Upload.Dragger {...props} maxCount={1}>
                            <p className="ant-upload-hint">sélectionner ou faire glisser des fichiers ici</p>
                            <p className="ant-upload-text">JPG, PNG or PDF, file size pas plus que 10MB</p>
                          </Upload.Dragger>
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="edulink-form-action mt-20">
                      <Button htmlType="reset" type="light" size="large" onClick={handleAnnuler}>
                        Annuler
                      </Button>
                      {loading ? (
                        <Button type="danger" size="large">
                          Chargement...
                        </Button>
                      ) : (
                        <Button htmlType="submit" type="danger" size="large">
                          Ajouter
                        </Button>
                      )}
                    </div>
                  </Form>
                </Cards>
              </VerticalFormStyleWrap>
            </FormValidationWrap>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default EventForm;
