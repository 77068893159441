import { Card } from 'antd';
import Styled from 'styled-components';

const BtnWraper = (theme) => `
    .ant-card-head {
      display: flex;
      justify-content: space-evenly;
      .ant-card-head-wrapper{
        width: 100%;
      }
      .ant-tabs.ant-tabs-top.ant-card-head-tabs.ant-tabs-large.ant-tabs-line{
        width: 100%;
        display: block;
      }
      .ant-tabs-nav-scroll {
        display: flex;
        justify-content: flex-end;
      }
      .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        display: none !important;
      }
      .ant-tabs-nav .ant-tabs-tab {
        border-radius: 2px;
        height: 30px;
        margin: 0px;
        ${theme.rtl ? 'margin-right' : 'margin-left'}: -1px;
        display: inline-flex;
        align-items: center;
        border: 1px solid ${theme['border-color-base']};
        background: #fff;
        span svg {
          ${theme.rtl ? 'padding-right' : 'padding-left'}: 5px !important;
        }
      }
      .ant-tabs-nav .ant-tabs-tab-active {
        font-weight: 500;
        border: 1px solid ${theme['primary-color']};
        background: ${theme['primary-color']};
        color: #fff;
        border-radius: 2px;
        height: 30px;
        margin: 0px;
        display: inline-flex;
        align-items: center;
      }
      .ant-tabs-bar {
        border: none;
      }
      .ant-tabs-nav-wrap {
        margin-bottom: 0px;
        overflow: hidden;
    }
    }
    .ant-card-head .ant-tabs.ant-tabs-top.ant-card-head-tabs.ant-tabs-large.ant-tabs-line {
      width: 100%;
      display: block;
      justify-content: flex-end;
      height: 73px;
      padding-top: 8px;
  }
`;

const CardFrame = Styled(Card)`
  &.chat-preview{
      height:100%;
  }
  &.chat-room{
    height:100%;
}

  ${({ isbutton, theme }) => isbutton && BtnWraper(theme)}
  margin-bottom: 25px !important;
  background-color: ${({ theme }) => theme[theme.mainContent]['white-background']};
  border-radius:20px;
  .ant-card-head{
    border-color: ${({ theme }) => theme[theme.mainContent]['border-color-default']};
    .ant-card-head-title{
      padding: 7px 0;
      font-size: 18px;
      font-weight: 600;
      color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
    }
  }
  .ant-card-body{
    padding: ${({ bodypadding }) => (bodypadding ? `${bodypadding} !important` : '25px !important')};
    @media only screen and (max-width: 575px){
      padding: ${({ bodypadding }) => (bodypadding ? `${bodypadding} !important` : '15px !important')};
    }
    table{
      .ant-tag{
        line-height: 18px;
        border: 0 none;
        text-transform: uppercase;
        font-size: 10px;
        color: #fff;
        &.early{
          background: ${({ theme }) => theme['primary-color']}
        }
        &.late{
          background: ${({ theme }) => theme[theme.mainContent].Warning}
        }
        &.complete{
          background: ${({ theme }) => theme[theme.mainContent].success}
        }
        &.progress{
          background: ${({ theme }) => theme[theme.mainContent].danger}
        }
      }
    }
  }
  .ant-card-head-title .ant-page-header-heading-title{
    font-weight: 500;
  }
  .ant-card.ant-card-bordered {
      border-radius: 5px;
      overflow: hidden;
      border: none;
  }
  .custom-label {
    font-size: 13px;
    color: #868eae;
    display: inline-flex;
    align-items: center;
  }
  .custom-label:not(:last-child) {
    ${({ theme }) => (theme.rtl ? ' margin-left' : ' margin-right')} : 20px;
  }

  .custom-label span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    ${({ theme }) => (theme.rtl ? ' margin-left' : ' margin-right')}: 7px;
  }

  .ant-card-extra  {
    padding: 12px 0;
    .ant-dropdown-trigger{
      line-height: 0;
      order: 1;
      ${({ theme }) => (!theme.rtl ? ' margin-left' : ' margin-right')}: 20px !important;
      @media only screen and (max-width: 575px){
        ${({ theme }) => (!theme.rtl ? ' margin-left' : ' margin-right')}: 0 !important;
      }
    }
  }

  .growth-downward h1 sub,
  .growth-upward h1 sub {
    font-size: 14px;
    font-weight: 600;
    bottom: 0;
    ${({ theme }) => (!theme.rtl ? ' left' : ' right')}: 5px;
  }
  .calendar-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 5px;
    @media only screen and (max-width: 1000px){
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 10px;
    }
    .calendar-header__left{
        display: flex;
        align-items: center;
        position: relative;
        @media only screen and (max-width: 479px){
            flex-flow: column;
        }
        .btn-today{
            font-size: 14px;
            font-weight: 500;
            height: 34px;
            padding: 0 15px;
            border-radius: 4px;
            text-transform: capitalize;
            cursor: pointer;
            color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
            border: 1px solid ${({ theme }) => theme[theme.mainContent]['border-color-default']};
            background-color: ${({ theme }) => theme[theme.mainContent]['white-background']};
            @media only screen and (max-width: 479px){
                margin-bottom: 15px;
            }
        }
        .react-calendar{
            ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 30px;
            padding: 0;
            border: none;
            .react-calendar__navigation{
                align-items: center;
                margin: 0;
                border: none;
                .react-calendar__navigation__label{
                    padding: 0 20px;
                }
                .react-calendar__navigation__arrow {
                    border: 1px solid ${({ theme }) => theme[theme.mainContent]['border-color-default']};
                    border-radius: 5px;
                    width: 34px;
                    height: 34px;
                    svg,
                    img{
                        width: 14px;
                    }
                }
            }
            .react-calendar__viewContainer{
                position: absolute;
                background: ${({ theme }) => theme[theme.mainContent]['white-background']};
                z-index: 999;
                border: 1px solid #ddd;
                display: none;
                &.show{
                    display: block;
                }
            }
        }
        .calender-head__navigation{
            display: flex;
            align-items: center;
            margin-left: 10px;
            @media only screen and (max-width: 479px){
                ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 0px;
            }
            .btn-navigate{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 34px;
                height: 34px;
                border-radius:50px;
                padding: 0;
                cursor: pointer;
                border: 2px solid ${({ theme }) => theme[theme.mainContent]['border-color-default']};
                background-color: ${({ theme }) => theme[theme.mainContent]['white-background']};       
                i,
                svg{
                    color: ${({ theme }) => theme[theme.mainContent]['light-text']};
                }
            }
            .date-label{
                display: inline-block;
                margin: 0 20px;
                font-size: 16px;
                font-weight: 500;
                color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
                @media only screen and (max-width: 575px){
                  font-size: 12px;
                }
            }
        }
    }
    .calendar-header__right {
        display: flex;
        align-items:center;
        column-gap:10px;
        @media only screen and (max-width: 1000px){
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 10px;
        }
        svg{
            width: 14px;
        }
        ul{
            margin-bottom: 0;
            li{
                display: inline-block;
                &:first-child{
                    ${({ theme }) => (theme.rtl ? 'border-right' : 'border-left')}: 1px solid ${({ theme }) =>theme[theme.mainContent]['border-color-default']};
                }
                &:not(:first-child){
                    a{
                        ${({ theme }) => (theme.rtl ? 'border-top-right-radius' : 'border-top-left-radius')}: 0px;
                        ${({ theme }) =>
                          theme.rtl ? 'border-bottom-right-radius' : 'border-bottom-left-radius'}: 0px;
                    }
                }
                &:not(:last-child){
                    a{
                        ${({ theme }) => (theme.rtl ? 'border-top-left-radius' : 'border-top-right-radius')}: 0px;
                        ${({ theme }) =>
                          theme.rtl ? 'border-bottom-left-radius' : 'border-bottom-right-radius'}: 0px;
                    }
                }
                a{
                    font-size: 13px;
                    font-weight: 500;
                    color: ${({ theme }) => theme[theme.mainContent]['gray-light-text']};
                    border: 1px solid ${({ theme }) => theme[theme.mainContent]['border-color-default']};
                    ${({ theme }) => (theme.rtl ? 'border-right' : 'border-left')}: 0px;
                    display: block;
                    border-radius: 4px;
                    padding: 6px 13.24px;
                    &.active{
                        color: #fff;
                        border-color: ${({ theme }) => theme['primary-color']};
                        background-color: ${({ theme }) => theme['primary-color']};
                    }
                }
            }
        }
        .schedule-list{
            margin-left: 20px;
            ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 20px;
            display: flex;
            align-items: center;
            color: ${({ theme }) => theme[theme.mainContent]['gray-light-text']};
            @media only screen and (max-width: 479px){
                margin: 15px 0 0 0;
            }
            svg,
            img,
            i{
                margin-right: 6px;
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 6px;
                color: ${({ theme }) => theme['primary-color']};
            }
            &.active{
                color: ${({ theme }) => theme['primary-color']};
            }
            &.danger-active{
              color: ${({ theme }) => theme['danger-color']};
          }
        }
    }
}
 
`;

export { CardFrame };
