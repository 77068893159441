import { Col } from 'antd';
import React, { useEffect } from 'react';
import GainStatistics from './GainStatistics';
import InscriptionClubStatistics from './ClubStatistics';
import { Cards } from '../../../components/cards/frame/cards-frame';
import image1 from '../../../static/svg/1.svg';
import image2 from '../../../static/svg/2.svg';
import image3 from '../../../static/svg/3.svg';
import {
  viewTotalClubInscriptionStatisticsData,
  viewTotalGainPrevisionnelsClubStatisticsData,
  viewTotalGainRealisesClubStatisticsData,
} from '../../../redux/statistics/actionCreator';
import { useDispatch, useSelector } from 'react-redux';

function ClubCards() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewTotalClubInscriptionStatisticsData());
    dispatch(viewTotalGainPrevisionnelsClubStatisticsData());
    dispatch(viewTotalGainRealisesClubStatisticsData());

  }, []);

  const { totalInscriptions, gainsPrevisionnels, gainsRealises } = useSelector((state) => {
    return {
      totalInscriptions: state.StatisticsReducer.totalClubInscriptions,
      gainsPrevisionnels: state.StatisticsReducer.gainsPrevisionnelsClubs,
      gainsRealises: state.StatisticsReducer.gainsRealisesClubs,
    };
  });

  return (
    <>
      <Col xxl={8} lg={8} sm={8} xs={24}>
        <Cards headless>
          <div
            style={{
              height: '20vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <img src={image1} />
            <span style={{ fontWeight: 300, fontSize: '14px', textAlign: 'center' }}>
              Nombre des inscrits dans tous les clubs
            </span>
            <span style={{ fontWeight: 700, fontSize: '28px', color: 'black' }}>{totalInscriptions}</span>
          </div>
        </Cards>
      </Col>
      <Col xxl={8} lg={8} sm={8} xs={24}>
        <Cards headless>
          <div
            style={{
              height: '20vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <img src={image2} />
            <span style={{ fontWeight: 300, fontSize: '14px', textAlign: 'center' }}>
              Gain réalisé par tous les clubs Prévisionnel{' '}
            </span>
            <span style={{ fontWeight: 700, fontSize: '28px', color: 'black' }}>{gainsPrevisionnels} TND</span>
          </div>
        </Cards>
      </Col>
      <Col xxl={8} lg={8} sm={8} xs={24}>
        <Cards headless>
          <div
            style={{
              height: '20vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <img src={image3} />
            <span style={{ fontWeight: 300, fontSize: '14px', textAlign: 'center' }}>
              Gain réalisé par tous les clubs Réel
            </span>
            <span style={{ fontWeight: 700, fontSize: '28px', color: 'black' }}>{gainsRealises} TND</span>
          </div>
        </Cards>
      </Col>
    </>
  );
}

export default ClubCards;
