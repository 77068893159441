const actions = {
  VIEW_CLUB_BEGIN: 'VIEW_CLUB_BEGIN',
  VIEW_CLUB_SUCCESS: 'VIEW_CLUB_SUCCESS',
  VIEW_CLUB_ERR: 'VIEW_CLUB_ERR',

  VIEW_SINGLE_CLUB_BEGIN: 'VIEW_SINGLE_CLUB_BEGIN',
  VIEW_SINGLE_CLUB_SUCCESS: 'VIEW_SINGLE_CLUB_SUCCESS',
  VIEW_SINGLE_CLUB_ERR: 'VIEW_SINGLE_CLUB_ERR',

  ATTACH_CLUB_BEGIN: 'ATTACH_CLUB_BEGIN',
  ATTACH_CLUB_SUCCESS: 'ATTACH_CLUB_SUCCESS',
  ATTACH_CLUB_ERR: 'ATTACH_CLUB_ERR',

  ADD_CLUB_BEGIN: 'ADD_CLUB_BEGIN',
  ADD_CLUB_SUCCESS: 'ADD_CLUB_SUCCESS',
  ADD_CLUB_ERR: 'ADD_CLUB_ERR',

  DELETE_CLUB_BEGIN: 'DELETE_CLUB_BEGIN',
  DELETE_CLUB_SUCCESS: 'DELETE_CLUB_SUCCESS',
  DELETE_CLUB_ERR: 'DELETE_CLUB_ERR',

  UPDATE_CLUB_BEGIN: 'UPDATE_CLUB_BEGIN',
  UPDATE_CLUB_SUCCESS: 'UPDATE_CLUB_SUCCESS',
  UPDATE_CLUB_ERR: 'UPDATE_CLUB_ERR',

  viewClubBegin: () => {
    return {
      type: actions.VIEW_CLUB_BEGIN,
    };
  },

  viewClubSuccess: (data) => {
    return {
      type: actions.VIEW_CLUB_SUCCESS,
      data,
    };
  },

  viewClubErr: (err) => {
    return {
      type: actions.VIEW_CLUB_ERR,
      err,
    };
  },

  attachClubBegin: () => {
    return {
      type: actions.ATTACH_CLUB_BEGIN,
    };
  },

  attachClubSuccess: (data) => {
    return {
      type: actions.ATTACH_CLUB_SUCCESS,
      data,
    };
  },

  attachClubErr: (err) => {
    return {
      type: actions.ATTACH_CLUB_ERR,
      err,
    };
  },

  viewSingleClubBegin: () => {
    return {
      type: actions.VIEW_SINGLE_CLUB_BEGIN,
    };
  },

  viewSingleClubSuccess: (data) => {
    return {
      type: actions.VIEW_SINGLE_CLUB_SUCCESS,
      data,
    };
  },

  viewSingleClubErr: (err) => {
    return {
      type: actions.VIEW_SINGLE_CLUB_ERR,
      err,
    };
  },

  updateClubBegin: () => {
    return {
      type: actions.UPDATE_CLUB_BEGIN,
    };
  },

  updateClubSuccess: (data) => {
    return {
      type: actions.UPDATE_CLUB_SUCCESS,
      data,
    };
  },

  updateClubErr: (err) => {
    return {
      type: actions.UPDATE_CLUB_ERR,
      err,
    };
  },
  deleteClubBegin: () => {
    return {
      type: actions.DELETE_CLUB_BEGIN,
    };
  },

  deleteClubSuccess: (data) => {
    return {
      type: actions.DELETE_CLUB_SUCCESS,
      data,
    };
  },

  deleteClubErr: (err) => {
    return {
      type: actions.DELETE_CLUB_ERR,
      err,
    };
  },
  addClubBegin: () => {
    return {
      type: actions.ADD_CLUB_BEGIN,
    };
  },

  addClubSuccess: (data) => {
    return {
      type: actions.ADD_CLUB_SUCCESS,
      data,
    };
  },

  addClubErr: (err) => {
    return {
      type: actions.ADD_CLUB_ERR,
      err,
    };
  },
};

export default actions;
