const actions = {
  VIEW_CLASSE_BEGIN: 'VIEW_CLASSE_BEGIN',
  VIEW_CLASSE_SUCCESS: 'VIEW_CLASSE_SUCCESS',
  VIEW_CLASSE_ERR: 'VIEW_CLASSE_ERR',

  viewClasseBegin: () => {
    return {
      type: actions.VIEW_CLASSE_BEGIN,
    };
  },

  viewClasseSuccess: (data) => {
    return {
      type: actions.VIEW_CLASSE_SUCCESS,
      data,
    };
  },

  viewClasseErr: (err) => {
    return {
      type: actions.VIEW_CLASSE_ERR,
      err,
    };
  },
};

export default actions;
