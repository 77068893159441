import Styled from 'styled-components';



const VerticalFormStyleWrap = Styled.div`
    .form-title{
        color: ${({ theme }) => theme['danger-color']}; 
        text-align: center;
        font-size: 30px;
        line-height: 24px;
        margin-bottom:40px;
    }
    .switch-container{
        display:flex;
        align-items:center;
        column-gap:10px;
        margin-bottom:20px;
    }
    .label{
        font-size: 14px;
        color:#0A0A0A;
        font-weight:500;
    }
    .ant-picker{
        min-height: 50px;
        width:100%;
    }
    .ant-picker-input > input{
        margin: 12px 20px;
        color:black;
        &::placeholder{
            color: #bfbfbf !important;
        }
    }
    .ant-select-selector{
        min-height: 50px;
        width:100%;
            .ant-select-selection-placeholder{
                padding-top:6px;
            }
            .ant-select-selection-item{
                padding-top:6px;
            }
    }
    .ant-upload.ant-upload-drag{
        border-radius: 29px;
        border: 1px dashed #00AEDB;
        border-spacing: 15px;
    }
    .ant-card{
        margin-bottom: 25px
    }
    .ant-input-affix-wrapper > input.ant-input{
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .ant-input-affix-wrapper .ant-input-prefix svg{
        width: 16px;
        height: 16px;
        color: ${({ theme }) => theme['gray-color']};
    }
    .edulink-form-action{
        margin: -7.5px;
        button{
            font-size: 14px;
            font-weight: 500;
            border-radius: 6px;
            margin: 7.5px;
            padding: 6.4px 19px;
            &.ant-btn-light{
                height: 44px;
                color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
                background-color: ${({ theme }) => theme[theme.mainContent]['border-color-default']};
                border-color: ${({ theme }) => theme[theme.mainContent]['border-color-default']};
            }
            &.ant-btn-danger{
                color: white;
                background: ${({ theme }) => theme['danger-color']};
                border:none;
            }
            &:hover{
                &.ant-btn-danger{
                    color: white;
                    background: ${({ theme }) => theme['danger-hover']};
                }
            }
        }
        .ant-form-item{
            margin-bottom: 25px !important;
        }
        .ant-btn-light{
            background-color: ${({ theme }) => theme[theme.mainContent]['main-background-light']};
        }
    }
`;
const FormValidationWrap = Styled.div`
    .ant-card-body{
        padding: 30px 25px 50px 25px !important;
    }
    .ant-form-item-has-error{
        .ant-form-item-explain{
            font-size: 13px;
        }
    }
    .ant-form-item{
        .ant-form-item-label{
            >label{
                color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
            }
        }
    }
    .sDash_agree-check{
        .ant-form-item-control-input{
            min-height: auto;
        }
        .ant-form-item-has-error{
            .ant-checkbox-wrapper{
                span{
                    font-size: 13px;
                    color: ${({ theme }) => theme['danger-color']};
                }
                .ant-checkbox{
                    border-color: ${({ theme }) => theme['danger-color']}30;
                    &.ant-checkbox-checked{
                        border-color: ${({ theme }) => theme['danger-color']}30;
                        &:after{
                            border-color: ${({ theme }) => theme['danger-color']}30;
                        }
                        .ant-checkbox-inner{
                            &:after{
                                border-color: ${({ theme }) => theme['danger-color']};
                            }
                        }
                    }
                    .ant-checkbox-inner{
                        border-color: ${({ theme }) => theme['danger-color']}30;
                        background-color: ${({ theme }) => theme['danger-color']}30;
                    }
                }
            }
        }
        .ant-form-item-explain{
            margin-left: 25px;
            font-size: 13px;
        }
    }
    .edulink-form-action{
        display:flex;
        justify-content:end;
        button{
            border-radius: 7px;
            padding: 6.4px 29.2px;
            height: 44px;
        }
    }
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, 
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover{
        background-color: ${({ theme }) => theme[theme.mainContent]['input-bg']};
    }
    .ant-picker-status-error {
         border-color: #ff4d4f;
    }
    .ant-upload-status-error{
        background-color:red;
    }
    
`;
const ReunionFormStyleWrap = Styled.div`
    label{
        font-weight: 400 !important;
        color: ${({ theme }) => theme[theme.mainContent]['light-text']} !important;
        margin-bottom: 0 !important;
    }
    .edulink-event-form-input,
    .edulink-event-timeselection{
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      .edulink-event-form-label,
      .edulink-event-timeselection__label{
        min-width: 90px;
      }
      textarea{
        min-height: 100px;
      }
    }
    .edulink-event-form-input{
      .ant-form-item{
        width: 100%;
      }
    }
    .edulink-event-timeselection{
      .ant-form-item{
        margin: 0 10px 0 !important;
      }
    }
    .edulink-event-timeselection__input{
      width: 100%;
      margin: 0 -10px;
      .ant-picker{
        min-width: auto;
        width: 100%;
        min-height: 50px;
      }
    }
    .ant-select-selector{
      min-height: 50px;
      .ant-select-selection-item{
        display: flex;
        align-items: center;
      }
    }
    .ant-picker-input > input{
      margin-top: 3px;
    }
    .ant-picker{
      min-width: auto;
      width: 100%;

      &:not(:last-child){
          ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
      }
    }
    .ant-picker-input{
        padding: 0 25px;
        position: relative;
        @media only screen and (max-width: 575px){
            padding: 0 20px 0 25px;
        }
        .ant-picker-suffix{
            position: absolute;
            ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 10px;
            top: 50%;
            transform: translateY(-50%);
            @media only screen and (max-width: 575px){
                ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 10px;
            }
            svg{
                color: ${({ theme }) => theme[theme.mainContent]['light-text']};
            }
        }
    }
    .add-event-footer{
        .ant-btn-white{
            color: ${({ theme }) => theme[theme.mainContent]['light-text']} !important;
        }
        .btn-save{
            height: 38px;
            padding: 0 25px;
        }
    }
`;

export {
  VerticalFormStyleWrap,
  FormValidationWrap,
  ReunionFormStyleWrap
};
