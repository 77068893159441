import actions from './actions';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const {
  VIEW_PROF_BEGIN,
  VIEW_PROF_SUCCESS,
  VIEW_PROF_ERR,

} = actions;

const ProfReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case VIEW_PROF_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_PROF_SUCCESS:
      return {
        ...state,
        data: data,
        loading: false,
      };
    case VIEW_PROF_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default ProfReducer;
