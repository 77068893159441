import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  addClasseBegin,
  addClasseSuccess,
  addClasseErr,
  updateClasseBegin,
  updateClasseSuccess,
  updateClasseErr,
  viewClasseBegin,
  viewClasseSuccess,
  viewClasseErr,
  viewSingleClasseBegin,
  viewSingleClasseSuccess,
  viewSingleClasseErr,
  deleteClasseBegin,
  deleteClasseSuccess,
  deleteClasseErr,
} = actions;

const viewClasseData = () => {
  return async (dispatch) => {
    dispatch(viewClasseBegin());
    try {
      const response = await DataService.get('/classe/view');

      if (response.status === 200) {
        dispatch(viewClasseSuccess(response.data.payload));
      } else {
        dispatch(viewClasseErr('err'));
      }
    } catch (err) {
      dispatch(viewClasseErr('err'));
    }
  };
};

const viewSingleClasseData = (id) => {
  return async (dispatch) => {
    dispatch(viewSingleClasseBegin());
    try {
      const response = await DataService.get(`/classe/singleInfo/${id}`);
      if (response.status === 200) {
        dispatch(viewSingleClasseSuccess(response.data.payload));
      } else {
        dispatch(viewSingleClasseErr('err'));
      }
    } catch (err) {
      dispatch(viewSingleClasseErr('err'));
    }
  };
};

const addClasseData = (data, callback) => {
  return async (dispatch) => {
    dispatch(addClasseBegin());
    try {
      const response = await DataService.post('/classe/add', data);
      if (response.status === 200) {
        dispatch(addClasseSuccess(response.data.payload[0]));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(addClasseErr('err'));
        
      }
    } catch (err) {
      dispatch(addClasseErr('err'));
      
    }
  };
};
const deleteClasseData = (id) => {
  return async (dispatch) => {
    dispatch(deleteClasseBegin());
    try {
      const response = await DataService.delete(`/classe/delete/${id}`);
      if (response.status === 200) {
        dispatch(deleteClasseSuccess(id));
        message.success(response.data.message);
      } else {
        dispatch(deleteClasseErr('err'));
        
      }
    } catch (err) {
      dispatch(deleteClasseErr(err));
      
    }
  };
};
const updateClasseData = (id, data, callback) => {
  return async (dispatch) => {
    dispatch(updateClasseBegin());

    try {
      const response = await DataService.put(`/classe/updated/${id}`, data);
      if (response.status === 200) {
        dispatch(updateClasseSuccess(response.data.payload));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(updateClasseErr('err'));
        
      }
    } catch (err) {
      dispatch(updateClasseErr('err'));
      
    }
  };
};
export { viewClasseData, viewSingleClasseData, updateClasseData, deleteClasseData, addClasseData };
