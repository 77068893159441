const actions = {
  VIEW_EVENT_BEGIN: 'VIEW_EVENT_BEGIN',
  VIEW_EVENT_SUCCESS: 'VIEW_EVENT_SUCCESS',
  VIEW_EVENT_ERR: 'VIEW_EVENT_ERR',

  VIEW_SINGLE_EVENT_BEGIN: 'VIEW_SINGLE_EVENT_BEGIN',
  VIEW_SINGLE_EVENT_SUCCESS: 'VIEW_SINGLE_EVENT_SUCCESS',
  VIEW_SINGLE_EVENT_ERR: 'VIEW_SINGLE_EVENT_ERR',

  ATTACH_EVENT_BEGIN: 'ATTACH_EVENT_BEGIN',
  ATTACH_EVENT_SUCCESS: 'ATTACH_EVENT_SUCCESS',
  ATTACH_EVENT_ERR: 'ATTACH_EVENT_ERR',

  ADD_EVENT_BEGIN: 'ADD_EVENT_BEGIN',
  ADD_EVENT_SUCCESS: 'ADD_EVENT_SUCCESS',
  ADD_EVENT_ERR: 'ADD_EVENT_ERR',

  DELETE_EVENT_BEGIN: 'DELETE_EVENT_BEGIN',
  DELETE_EVENT_SUCCESS: 'DELETE_EVENT_SUCCESS',
  DELETE_EVENT_ERR: 'DELETE_EVENT_ERR',

  UPDATE_EVENT_BEGIN: 'UPDATE_EVENT_BEGIN',
  UPDATE_EVENT_SUCCESS: 'UPDATE_EVENT_SUCCESS',
  UPDATE_EVENT_ERR: 'UPDATE_EVENT_ERR',

  viewEventBegin: () => {
    return {
      type: actions.VIEW_EVENT_BEGIN,
    };
  },

  viewEventSuccess: (data) => {
    return {
      type: actions.VIEW_EVENT_SUCCESS,
      data,
    };
  },

  viewEventErr: (err) => {
    return {
      type: actions.VIEW_EVENT_ERR,
      err,
    };
  },

  viewSingleEventBegin: () => {
    return {
      type: actions.VIEW_SINGLE_EVENT_BEGIN,
    };
  },

  viewSingleEventSuccess: (data) => {
    return {
      type: actions.VIEW_SINGLE_EVENT_SUCCESS,
      data,
    };
  },

  viewSingleEventErr: (err) => {
    return {
      type: actions.VIEW_SINGLE_EVENT_ERR,
      err,
    };
  },
  attachEventBegin: () => {
    return {
      type: actions.ATTACH_EVENT_BEGIN,
    };
  },

  attachEventSuccess: (data) => {
    return {
      type: actions.ATTACH_EVENT_SUCCESS,
      data,
    };
  },

  attachEventErr: (err) => {
    return {
      type: actions.ATTACH_EVENT_ERR,
      err,
    };
  },
  updateEventBegin: () => {
    return {
      type: actions.UPDATE_EVENT_BEGIN,
    };
  },

  updateEventSuccess: (data) => {
    return {
      type: actions.UPDATE_EVENT_SUCCESS,
      data,
    };
  },

  updateEventErr: (err) => {
    return {
      type: actions.UPDATE_EVENT_ERR,
      err,
    };
  },
  deleteEventBegin: () => {
    return {
      type: actions.DELETE_EVENT_BEGIN,
    };
  },

  deleteEventSuccess: (data) => {
    return {
      type: actions.DELETE_EVENT_SUCCESS,
      data,
    };
  },

  deleteEventErr: (err) => {
    return {
      type: actions.DELETE_EVENT_ERR,
      err,
    };
  },
  addEventBegin: () => {
    return {
      type: actions.ADD_EVENT_BEGIN,
    };
  },

  addEventSuccess: (data) => {
    return {
      type: actions.ADD_EVENT_SUCCESS,
      data,
    };
  },

  addEventErr: (err) => {
    return {
      type: actions.ADD_EVENT_ERR,
      err,
    };
  },
};

export default actions;
