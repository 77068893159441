/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Upload, DatePicker, Select, notification, TimePicker } from 'antd';
import { FormValidationWrap, VerticalFormStyleWrap } from './overview/Style';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import locale from 'antd/es/date-picker/locale/fr_FR'; // Import the French locale
import { useDispatch, useSelector } from 'react-redux';
import { updateClasseData, viewClasseData } from '../../redux/classe/actionCreator';
import { addExerciceData, updateExerciceData, viewSingleExerciceData } from '../../redux/exercice/actionCreator';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
function UpdateExerciceForm() {
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewClasseData());
    dispatch(viewSingleExerciceData(id));
  }, []);
  const [form] = Form.useForm();
  const [niveau, setNiveau] = useState(null);
  const [classesSelect, setClassesSelect] = useState();
  const [date, setDate] = useState();
  const [dateL, setDateL] = useState();
  const [heur, setHeur] = useState();
  const [heurL, setHeurL] = useState();
  const [matiere, setMatiere] = useState('');
  const [description, setDescription] = useState('');
  const [classeId, setClasseId] = useState('');


  const submitSuccess = () => {
    navigate(-1);
  };

  const submit = () => {
    const data = {};
    data.matiere = matiere;
    data.date = typeof date == 'string' ? date : date.format('YYYY-MM-DD');
    data.dateL = typeof dateL == 'string' ? dateL : dateL.format('YYYY-MM-DD');
    data.heur = moment(heur).format('HH:mm:ss');
    data.heurL = moment(heurL).format('HH:mm:ss');
    data.description = description;
    data.classe_id = classeId;
    dispatch(updateExerciceData(id, data, submitSuccess));
  };
  const { classes, exercice } = useSelector((state) => {
    return {
      classes: state.ClasseReducer.arrayData,
      exercice: state.ExerciceReducer.singleData,
    };
  });
  useEffect(() => {
    if (exercice) {
      setDate(exercice.date);
      setDateL(exercice.dateL);
      setHeur(moment(exercice.heur, 'HH:mm'));
      setHeurL(moment(exercice.heurL, 'HH:mm'));
      setMatiere(exercice.matiere);
      setDescription(exercice.description);
      setClasseId(exercice.classe_id);
      const classe = classes.filter((item) => item.id == exercice.classe_id);
      setNiveau(classe[0].niveau);
    }
  }, [exercice]);

  useEffect(() => {
    if (exercice) {
      const classe = classes.filter((item) => item.id == exercice.classe_id);
      if (niveau && niveau != classe[0].niveau) {
        setClasseId(null);
      }
    }
    const localExerice = classes.filter((item) => item.niveau == niveau);
    const selectClasseData = localExerice.map((item) => {
      return { value: item.id, label: item.titre };
    });
    setClassesSelect(selectClasseData);
  }, [niveau]);
  const handleAnnuler = () => {
    navigate(-1);
  };
  return (
    <>
      <Main>
        <Row gutter={25}>
          <Col xs={24}>
            <FormValidationWrap>
              <VerticalFormStyleWrap>
                <Cards headless>
                  <Form name="sDash_validation-form" form={form} layout="vertical" onFinish={submit}>
                    <div className="form-title">Mettre l'exercice à jour </div>
                    <Row gutter={30}>
                      <Col md={24} xs={24}>
                        <Form.Item label="Matière" rules={[{ required: true, message: 'Matière est obligatoire !' }]}>
                          <Input placeholder="Matière" value={matiere} onChange={(e) => setMatiere(e.target.value)} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Date Debut"
                          rules={[{ required: true, message: 'Date debut est obligatoire!' }]}
                        >
                          <DatePicker
                            format="yyyy/MM/DD"
                            locale={locale}
                            value={date && moment(date)}
                            onChange={(e) => setDate(e)}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Date Limite"
                          rules={[{ required: true, message: 'Date Limite est obligatoire!' }]}
                        >
                          <DatePicker
                            format="yyyy/MM/DD"
                            locale={locale}
                            value={dateL && moment(dateL)}
                            onChange={(e) => setDateL(e)}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Heur Limite Debut"
                          rules={[{ required: true, message: 'Heur limite debut est obligatoire!' }]}
                        >
                          <TimePicker
                            format="HH:mm"
                            locale={locale}
                            value={heur && moment(heur)}
                            onChange={(e) => setHeur(e)}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Heur Limite Fin"
                          rules={[{ required: true, message: 'Heur limite fin est obligatoire!' }]}
                        >
                          <TimePicker
                            format="HH:mm"
                            locale={locale}
                            value={heurL && moment(heurL)}
                            onChange={(e) => setHeurL(e)}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item label="Niveau" rules={[{ required: true, message: 'Niveau est obligatoire!' }]}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Niveau"
                            value={niveau}
                            onChange={(e) => setNiveau(e)}
                            options={[
                              { value: '1', label: '1ère année' },
                              { value: '2', label: '2ème année' },
                              { value: '3', label: '3ème année' },
                              { value: '4', label: '4ème année' },
                              { value: '5', label: '5ème année' },
                              { value: '6', label: '6ème année' },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item label="Classe" rules={[{ required: true, message: 'Classe est obligatoire!' }]}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Classe"
                            value={classeId}
                            onChange={(e) => setClasseId(e)}
                            options={classesSelect}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={24} xs={24}>
                        <Form.Item
                          label="Description"
                          rules={[{ required: true, message: 'Description est obligatoire !' }]}
                        >
                          <Input.TextArea
                            placeholder="Description ..."
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="edulink-form-action mt-20">
                      <Button htmlType="reset" type="light" size="large" onClick={handleAnnuler}>
                        Annuler
                      </Button>
                      <Button htmlType="submit" type="danger" size="large">
                        Ajouter
                      </Button>
                    </div>
                  </Form>
                </Cards>
              </VerticalFormStyleWrap>
            </FormValidationWrap>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default UpdateExerciceForm;
