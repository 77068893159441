import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { viewFactureData } from '../inscription/actionCreator';

const {
  addClubBegin,
  addClubSuccess,
  addClubErr,
  updateClubBegin,
  updateClubSuccess,
  updateClubErr,
  viewClubBegin,
  viewClubSuccess,
  viewClubErr,
  attachClubBegin,
  attachClubSuccess,
  attachClubErr,
  viewSingleClubBegin,
  viewSingleClubSuccess,
  viewSingleClubErr,
  deleteClubBegin,
  deleteClubSuccess,
  deleteClubErr,
} = actions;

const viewClubData = () => {
  return async (dispatch) => {
    dispatch(viewClubBegin());
    try {
      const response = await DataService.get('/clubActivite/view');
      if (response.status === 200) {
        dispatch(viewClubSuccess(response.data.payload));
      } else {
        dispatch(viewClubErr('err'));
      }
    } catch (err) {
      dispatch(viewClubErr('err'));
    }
  };
};

const viewSingleClubData = (id) => {
  return async (dispatch) => {
    dispatch(viewSingleClubBegin());
    try {
      const response = await DataService.get(`/clubActivite/singleInfo/${id}`);
      if (response.status === 200) {
        dispatch(viewSingleClubSuccess(response.data.payload[0]));
      } else {
        dispatch(viewSingleClubErr('err'));
      }
    } catch (err) {
      dispatch(viewSingleClubErr('err'));
    }
  };
};

const addClubData = (data, callback) => {
  return async (dispatch) => {
    dispatch(addClubBegin());
    try {
      const response = await DataService.post(`/clubActivite/add`, data, { 'Content-Type': 'multipart/form-data' });
      if (response.status === 200) {
        dispatch(addClubSuccess(response.data.payload));
        message.success(response.data.message);
        callback();
      } else {
        dispatch(addClubErr('err'));
      }
    } catch (err) {
      dispatch(addClubErr('err'));
    }
  };
};
const deleteClubData = (id) => {
  return async (dispatch) => {
    dispatch(deleteClubBegin());
    try {
      const response = await DataService.delete(`/clubActivite/delete/${id}`);
      if (response.status === 200) {
        dispatch(deleteClubSuccess(id));
        message.success(response.data.message);
      } else {
        dispatch(deleteClubErr('err'));
      }
    } catch (err) {
      dispatch(deleteClubErr('err'));
    }
  };
};
const updateClubData = (id, data, callback) => {
  return async (dispatch) => {
    dispatch(updateClubBegin());
    try {
      const response = await DataService.post(`/clubActivite/updated/${id}`, data, {
        'Content-Type': 'multipart/form-data',
      });
      if (response.status === 200) {
        dispatch(updateClubSuccess(response.data.payload));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(updateClubErr('err'));
      }
    } catch (err) {
      dispatch(updateClubErr('err'));
    }
  };
};
const AttachClub = (id, club, callback) => {
  return async (dispatch) => {
    dispatch(attachClubBegin());
    try {
      const response = await DataService.post(`/inscription/attachClub/${id}/${club}`);
      if (response.status === 200) {
        dispatch(attachClubSuccess(response.data.payload));
        dispatch(viewFactureData(id));
        callback();
      } else {
        dispatch(attachClubErr('err'));
      }
    } catch (err) {
      dispatch(attachClubErr('err'));
    }
  };
};

export { viewClubData, viewSingleClubData, updateClubData, deleteClubData, addClubData, AttachClub };
