import React, { useEffect, useState } from 'react';
import { Row, Col, Modal } from 'antd';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import DataTable from '../../components/table/DataTable';
import { Link } from 'react-router-dom';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import UilTrashAlt from '@iconscout/react-unicons/icons/uil-trash-alt';
import { useDispatch } from 'react-redux';
import { addChargeData, deleteChargeData, updateChargeData, viewChargeData } from '../../redux/charge/actionCreator';
import { useSelector } from 'react-redux';
import { ExclamationCircleFilled } from '@ant-design/icons';
import Update from './Update';
import Add from './Add';
import { viewTypeChargeData } from '../../redux/typeCharge/actionCreator';

function Charge() {
  const { confirm } = Modal;
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewChargeData());
    dispatch(viewTypeChargeData());
  }, []);

  const { charge, typesCharge } = useSelector((state) => {
    return {
      charge: state.ChargeReducer.arrayData,
      typesCharge: state.TypeChargeReducer.arrayData,
    };
  });
  const [selectedItem, setSelectedItem] = useState();
  const [openAdd, setOpenAdd] = useState();
  const handleAdd = () => {
    setOpenAdd(true);
  };
  const handleAddCancel = () => {
    setOpenAdd(false);
  };
  const [openUpdate, setOpenUpdate] = useState();
  const handleUpdate = (data) => {
    setSelectedItem(data);
    setOpenUpdate(true);
  };
  const handleUpdateCancel = () => {
    setOpenUpdate(false);
  };

  const addSuccess = () => {
    handleAddCancel();
    dispatch(viewChargeData());
    dispatch(viewTypeChargeData());
  };
  const updateSuccess = () => {
    handleUpdateCancel();
    dispatch(viewChargeData());
  };

  const handleDelete = (id) => {
    dispatch(deleteChargeData(id));
  };

  const updateAction = (values) => {
    dispatch(updateChargeData(selectedItem.id, values, updateSuccess));
  };

  const addAction = (values) => {
    if (values.newType) {
      values.nomListCharge = values.newType;
      delete values.newType;
    }
    dispatch(addChargeData(values, addSuccess));
  };
  const showConfirm = (id) => {
    confirm({
      title: "Voulez-vous supprimer l'événement ?",
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      cancelButtonProps: {
        type: 'white',
      },
      onOk() {
        handleDelete(id);
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    const localData = [];
    charge.map((element) => {
      localData.push({
        ...element,

        typeCharge: element.list_charge.nom,
        charge: (
          <div style={{ fontSize: '.8rem' }}>
            <div>Code : {element.code}</div>
            <div>Type : {element.list_charge.nom}</div>
            <div>Montant : {element.montantF} TND</div>
            <div>Payé : {element.montantF} TND</div>
            <div>Reste : {element.rest} TND</div>
          </div>
        ),
        action: (
          <div className="table-actions">
            <Link className="#" onClick={() => handleUpdate(element)}>
              <UilEdit />
            </Link>
            <Link
              className="delete"
              to="#"
              onClick={() => {
                showConfirm(element.id);
              }}
            >
              <UilTrashAlt />
            </Link>
          </div>
        ),
      });
    });
    setData(localData);
  }, [charge]);

  const dataTableColumn = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Type Charge',
      dataIndex: 'typeCharge',
      key: 'typeCharge',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'MontantF',
      dataIndex: 'montantF',
      key: 'montantF',
    },
    ,
    {
      title: 'MontantP',
      dataIndex: 'montantP',
      key: 'montantP',
    },
    ,
    {
      title: 'Reste',
      dataIndex: 'rest',
      key: 'rest',
    },

    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '60px',
    },
  ];

  const responsiveDataTableColumn = [
    {
      title: 'Charge',
      dataIndex: 'charge',
      key: 'charge',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '60px',
    },
  ];

  return (
    <>
      {typesCharge && (
        <Add open={openAdd} handleAddCancel={handleAddCancel} action={addAction} typesCharge={typesCharge} />
      )}
      <Update
        open={openUpdate}
        action={updateAction}
        handleUpdateCancel={handleUpdateCancel}
        data={selectedItem}
        typesCharge={typesCharge}
      />
      <Main>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
            <Cards headless>
              <DataTable
                filterOption
                filterOnchange
                buttonName="Ajouter Charge"
                btnAction={handleAdd}
                tableData={data}
                columns={window.innerWidth < 990 ? responsiveDataTableColumn : dataTableColumn}
              />
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default Charge;
