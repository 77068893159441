import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  addPersonnelBegin,
  addPersonnelSuccess,
  addPersonnelErr,
  updatePersonnelBegin,
  updatePersonnelSuccess,
  updatePersonnelErr,
  viewPersonnelBegin,
  viewPersonnelSuccess,
  viewPersonnelErr,
  viewSinglePersonnelBegin,
  viewSinglePersonnelSuccess,
  viewSinglePersonnelErr,
  deletePersonnelBegin,
  deletePersonnelSuccess,
  deletePersonnelErr,
} = actions;

const viewPersonnelData = () => {
  return async (dispatch) => {
    dispatch(viewPersonnelBegin());
    try {
      const response = await DataService.get('/personnel/view');
      if (response.status === 200) {
        dispatch(viewPersonnelSuccess(response.data.payload));
      } else {
        dispatch(viewPersonnelErr('err'));
      }
    } catch (err) {
      dispatch(viewPersonnelErr('err'));
    }
  };
};

const viewSinglePersonnelData = (id) => {
  return async (dispatch) => {
    dispatch(viewSinglePersonnelBegin());
    try {
      const response = await DataService.get(`/personnel/singleInfo/${id}`);

      if (response.status === 200) {
        dispatch(viewSinglePersonnelSuccess(response.data.payload));
      } else {
        dispatch(viewSinglePersonnelErr('err'));
      }
    } catch (err) {
      dispatch(viewSinglePersonnelErr('err'));
    }
  };
};

const addPersonnelData = (data, callback, faiCallback) => {
  return async (dispatch) => {
    dispatch(addPersonnelBegin());
    try {
      const response = await DataService.post('/personnel/add', data);

      if (response.status === 200) {
        dispatch(addPersonnelSuccess(response.data.payload));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(addPersonnelErr('err'));
        
      }
    } catch (err) {
      dispatch(addPersonnelErr('err'));
      
    }
  };
};
const deletePersonnelData = (id, callback) => {
  return async (dispatch) => {
    dispatch(deletePersonnelBegin());
    try {
      const response = await DataService.delete(`/personnel/delete/${id}`);
      if (response.status === 200) {
        dispatch(deletePersonnelSuccess(id));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(deletePersonnelErr('err'));
        
      }
    } catch (err) {
      
      dispatch(deletePersonnelErr('err'));
    }
  };
};
const updatePersonnelData = (id, data, callback, faiCallback) => {
  return async (dispatch) => {
    dispatch(updatePersonnelBegin());
    try {
      const response = await DataService.put(`/personnel/updated/${id}`, data);

      if (response.status === 200) {
        dispatch(updatePersonnelSuccess(response.data.payload));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(updatePersonnelErr('err'));
        
      }
    } catch (err) {
      
      dispatch(updatePersonnelErr('err'));
    }
  };
};

export { viewPersonnelData, viewSinglePersonnelData, updatePersonnelData, deletePersonnelData, addPersonnelData };
