/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Upload, TimePicker, notification, Select, message } from 'antd';
import { FormValidationWrap, VerticalFormStyleWrap } from './overview/Style';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import locale from 'antd/es/date-picker/locale/fr_FR'; // Import the French locale
import { useDispatch, useSelector } from 'react-redux';
import { addClubData } from '../../redux/club/actionCreator';
import { useNavigate } from 'react-router-dom';
import { getItem } from '../../utility/localStorageControl';
import axios from 'axios';

function ClubForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => {
    return {
      loading: state.ClubReducer.loading,
    };
  });
  const [fileName, setFileName] = useState();
  const [tranches, SetTranches] = useState([]);
  const [methode, SetMethode] = useState('1');
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  useEffect(() => {
    if (methode == 1) {
      const key = new Date().valueOf();

      SetTranches([
        {
          key,
          tranche: '',
          montant: '',
        },
      ]);
    } else {
      SetTranches([]);
    }
  }, [methode]);
  const addTranche = () => {
    const key = new Date().valueOf();
    SetTranches((tranches) => [...tranches, { tranche: '', montant: '', key }]);
  };
  const deleteTranche = (key) => {
    const tranchesUpdated = tranches.filter((item) => item.key != key);
    SetTranches(tranchesUpdated);
  };
  const addSuccess = () => {
    navigate(-1);
  };

  const handleSubmit = (values) => {
    values.heur = values.heur.format('HH:mm');
    const formData = new FormData();
    let frais = 0;
    if (methode == 1) {
      formData.append('donne[methode]', 'par tranche');
      for (const key in values) {
        if (key.startsWith('tranche-')) {
          formData.append(`donne[tranche][]`, values[key]);
          delete values[key];
        } else if (key.startsWith('montant-')) {
          formData.append(`donne[montant][]`, values[key]);
          frais += parseInt(values[key]);
          delete values[key];
        }
      }

      if (frais != values.frais) {
        message.error('Total des tranches est incorrecte');
        return;
      }

    } else {
      formData.append('donne[methode]', 'UNE SEUL FOIS');
      formData.append('donne[montant]', values.frais);
    }
    if (fileName) {
      formData.append('image', fileName);
    }
    for (let key in values) {
      formData.append(key, values[key]);
    }
    for (var pair of formData.entries()) {
    }
    dispatch(addClubData(formData, addSuccess));
  };
  const props = {
    name: 'file',
    multiple: false,
    listType: 'picture',
    customRequest({ file, onSuccess, onError }) {
      const formData = new FormData();
      formData.append('file', file);
      axios.post('https://edulinkbackend.edulink.tn/api/image/upload', formData, {
          headers: {
            Authorization: `Bearer ${getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          onSuccess(response.data, file);
          
          setFileName(response.data.payload);
        });
    },
    beforeUpload(file) {
      return true;
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const [form] = Form.useForm();
  const handleAnnuler = () => {
    navigate(-1);
  };
  return (
    <>
      <Main>
        <Row gutter={25}>
          <Col xs={24}>
            <FormValidationWrap>
              <VerticalFormStyleWrap>
                <Cards headless>
                  <Form name="clubform" form={form} layout="vertical" onFinish={handleSubmit}>
                    <div className="form-title">Ajouter Un Club</div>
                    <Row gutter={30}>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="titre"
                          label="Titre du club"
                          rules={[{ required: true, message: 'Titre du club est obligatoire !' }]}
                        >
                          <Input placeholder="Titre du club" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="jour"
                          label="Jour"
                          rules={[{ required: true, message: 'Jour est obligatoire!' }]}
                        >
                          <Input placeholder="Date" />
                        </Form.Item>
                      </Col>

                      <Col md={12} xs={24}>
                        <Form.Item
                          name="heur"
                          label="Heure de début"
                          rules={[{ required: true, message: 'Heure de début est obligatoire!' }]}
                        >
                          <TimePicker format="HH:mm" locale={locale} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="adresse"
                          label="Adresse"
                          rules={[{ required: true, message: 'Adresse est obligatoire!' }]}
                        >
                          <Input placeholder="Adresse" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item name="methode" label="Methode de paiment">
                          <Select
                            defaultValue={'1'}
                            onChange={(e) => SetMethode(e)}
                            options={[
                              { value: '1', label: 'Par tranche' },
                              { value: '2', label: 'Une seul fois' },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="frais"
                          label="Frais"
                          rules={[{ required: true, message: 'Frais est obligatoire!' }]}
                        >
                          <Input placeholder="Frais" />
                        </Form.Item>
                      </Col>
                      {methode == '1' &&
                        tranches.map((item, index) => (
                          <>
                            <Col md={11} xs={10}>
                              <Form.Item
                                name={`tranche-${item.key}`}
                                label="Nom tranche"
                                rules={[{ required: true, message: 'Nom tranche est obligatoire!' }]}
                              >
                                <Input placeholder="tranche" />
                              </Form.Item>
                            </Col>
                            <Col md={11} xs={10}>
                              <Form.Item
                                name={`montant-${item.key}`}
                                label="Montant"
                                rules={[{ required: true, message: 'Montant est obligatoire!' }]}
                              >
                                <Input placeholder="montant" />
                              </Form.Item>
                            </Col>
                            <Col md={2} xs={4}>
                              {index == tranches.length - 1 ? (
                                <div
                                  style={{
                                    backgroundColor: '#00AEDB',
                                    marginTop: '40px',
                                    height: '30px',
                                    borderRadius: '6px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: 'white',
                                    fontSize: '1.2rem',
                                  }}
                                  onClick={addTranche}
                                >
                                  +
                                </div>
                              ) : (
                                <div
                                  style={{
                                    backgroundColor: '#00AEDB',
                                    marginTop: '40px',
                                    height: '30px',
                                    borderRadius: '6px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: 'white',
                                    fontSize: '1.2rem',
                                  }}
                                  onClick={() => deleteTranche(item.key)}
                                >
                                  -
                                </div>
                              )}
                            </Col>
                          </>
                        ))}
                      <Col md={24} xs={24}>
                        <Form.Item
                          name="description"
                          label="Description"
                          rules={[{ required: true, message: 'Description est obligatoire !' }]}
                        >
                          <Input.TextArea placeholder="Description ..." />
                        </Form.Item>
                      </Col>
                      <Col md={24} xs={24}>
                        <Form.Item valuePropName="fileList" getValueFromEvent={normFile} label="Photo du Club">
                          <Upload.Dragger maxCount={1} name="files" {...props}>
                            <p className="ant-upload-hint">sélectionner ou faire glisser des fichiers ici</p>
                            <p className="ant-upload-text">JPG, PNG or PDF, file size pas plus que 10MB</p>
                          </Upload.Dragger>
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="edulink-form-action mt-20">
                      <Button htmlType="reset" type="light" size="large" onClick={handleAnnuler}>
                        Annuler
                      </Button>
                      {loading ? (
                        <Button type="danger" size="large">
                          Chargement...
                        </Button>
                      ) : (
                        <Button htmlType="submit" type="danger" size="large">
                          Ajouter
                        </Button>
                      )}
                    </div>
                  </Form>
                </Cards>
              </VerticalFormStyleWrap>
            </FormValidationWrap>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default ClubForm;
