import { DatePicker, Form, Input, Modal, Select } from 'antd';
import React, { useState } from 'react';
import { BasicFormWrapper } from '../styled';
import { Button } from '../../components/buttons/buttons';
import { ReunionFormStyleWrap } from './Style';

function Update({ open, handleAddCancel, action }) {
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    action(values);
  };
  return (
    <Modal
      className="edulink-event-form"
      footer={null}
      closable
      type="primary"
      title={'Nouvelle matiere'}
      open={open}
      onCancel={handleAddCancel}
    >
      <BasicFormWrapper>
        <ReunionFormStyleWrap>
          <Form form={form} name="addReunion" onFinish={handleSubmit}>
            <div className="edulink-event-form-input">
              <span className="edulink-event-form-label">Nom :</span>
              <Form.Item name="nom" rules={[{ required: true, message: 'Le code est obligatoire !' }]}>
                <Input placeholder="Choisir un nom" />
              </Form.Item>
            </div>
            <div className="edulink-event-form-input">
              <span className="edulink-event-form-label">Niveau :</span>
              <Form.Item name="niveau" rules={[{ required: true, message: 'Le type est obligatoire !' }]}>
                <Select
                  style={{ width: '100%' }}
                  placeholder="Niveau"
                  options={[
                    { value: '1', label: '1ère année' },
                    { value: '2', label: '2ème année' },
                    { value: '3', label: '3ème année' },
                    { value: '4', label: '4ème année' },
                    { value: '5', label: '5ème année' },
                    { value: '6', label: '6ème année' },
                  ]}
                />
              </Form.Item>
            </div>

            <div className="add-event-footer text-right">
              <Button htmlType="submit" className="btn-save" type="primary">
                Enregistrer
              </Button>
            </div>
          </Form>
        </ReunionFormStyleWrap>
      </BasicFormWrapper>
    </Modal>
  );
}

export default Update;
