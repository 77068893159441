import { message } from 'antd';
import actions from './actions';

const initialState = {
  data: [],
  singleData: null,
  loading: false,
  error: null,
};

const { VIEW_MATIERE_BEGIN, VIEW_MATIERE_SUCCESS, VIEW_MATIERE_ERR } = actions;

const MatiereProfReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case VIEW_MATIERE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_MATIERE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case VIEW_MATIERE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export default MatiereProfReducer;
