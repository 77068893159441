const actions = {
  VIEW_EMPLOI_BEGIN: 'VIEW_EMPLOI_BEGIN',
  VIEW_EMPLOI_SUCCESS: 'VIEW_EMPLOI_SUCCESS',
  VIEW_EMPLOI_ERR: 'VIEW_EMPLOI_ERR',

  viewEmploiBegin: () => {
    return {
      type: actions.VIEW_EMPLOI_BEGIN,
    };
  },

  viewEmploiSuccess: (data) => {
    return {
      type: actions.VIEW_EMPLOI_SUCCESS,
      data,
    };
  },

  viewEmploiErr: (err) => {
    return {
      type: actions.VIEW_EMPLOI_ERR,
      err,
    };
  },
};

export default actions;
