const actions = {
  VIEW_INSCRIPTION_BEGIN: 'VIEW_INSCRIPTION_BEGIN',
  VIEW_INSCRIPTION_SUCCESS: 'VIEW_INSCRIPTION_SUCCESS',
  VIEW_INSCRIPTION_ERR: 'VIEW_INSCRIPTION_ERR',

  viewInscriptionBegin: () => {
    return {
      type: actions.VIEW_INSCRIPTION_BEGIN,
    };
  },

  viewInscriptionSuccess: (data) => {
    return {
      type: actions.VIEW_INSCRIPTION_SUCCESS,
      data,
    };
  },

  viewInscriptionErr: (err) => {
    return {
      type: actions.VIEW_INSCRIPTION_ERR,
      err,
    };
  },
};

export default actions;
