import actions from './actions';
import { DataService } from '../../../config/dataService/dataService';
import { getItem } from '../../../utility/localStorageControl';

const { viewAllClubBegin, viewAllClubSuccess, viewAllClubErr, viewClubBegin, viewClubSuccess, viewClubErr } = actions;

const viewAllClubData = () => {
  return async (dispatch) => {
    dispatch(viewAllClubBegin());
    try {
      const userData = getItem('user');
      const response = await DataService.get(`/clubActivites/getInscriptionClubs/${userData.id}`);
      if (response.status === 200) {
        dispatch(viewAllClubSuccess(response.data.allClubs));
      } else {
        dispatch(viewAllClubErr('err'));
      }
    } catch (err) {
      console.error(err);
      dispatch(viewAllClubErr('err'));
    }
  };
};

const viewClubData = () => {
  return async (dispatch) => {
    dispatch(viewClubBegin());
    try {
      const userData = getItem('user');
      const response = await DataService.get(`/clubActivites/getInscriptionClubs/${userData.id}`);
      if (response.status === 200) {
        dispatch(viewClubSuccess(response.data.clubs));
      } else {
        dispatch(viewClubErr('err'));
      }
    } catch (err) {
      console.error(err);
      dispatch(viewClubErr('err'));
    }
  };
};

export { viewAllClubData, viewClubData };
