const actions = {
  VIEW_CANTINE_BEGIN: 'VIEW_CANTINE_BEGIN',
  VIEW_CANTINE_SUCCESS: 'VIEW_CANTINE_SUCCESS',
  VIEW_CANTINE_ERR: 'VIEW_CANTINE_ERR',

  VIEW_SINGLE_CANTINE_BEGIN: 'VIEW_SINGLE_CANTINE_BEGIN',
  VIEW_SINGLE_CANTINE_SUCCESS: 'VIEW_SINGLE_CANTINE_SUCCESS',
  VIEW_SINGLE_CANTINE_ERR: 'VIEW_SINGLE_CANTINE_ERR',

  ADD_CANTINE_BEGIN: 'ADD_CANTINE_BEGIN',
  ADD_CANTINE_SUCCESS: 'ADD_CANTINE_SUCCESS',
  ADD_CANTINE_ERR: 'ADD_CANTINE_ERR',

  DELETE_CANTINE_BEGIN: 'DELETE_CANTINE_BEGIN',
  DELETE_CANTINE_SUCCESS: 'DELETE_CANTINE_SUCCESS',
  DELETE_CANTINE_ERR: 'DELETE_CANTINE_ERR',

  UPDATE_CANTINE_BEGIN: 'UPDATE_CANTINE_BEGIN',
  UPDATE_CANTINE_SUCCESS: 'UPDATE_CANTINE_SUCCESS',
  UPDATE_CANTINE_ERR: 'UPDATE_CANTINE_ERR',

  viewCantineBegin: () => {
    return {
      type: actions.VIEW_CANTINE_BEGIN,
    };
  },

  viewCantineSuccess: (data) => {
    return {
      type: actions.VIEW_CANTINE_SUCCESS,
      data,
    };
  },

  viewCantineErr: (err) => {
    return {
      type: actions.VIEW_CANTINE_ERR,
      err,
    };
  },

  viewSingleCantineBegin: () => {
    return {
      type: actions.VIEW_SINGLE_CANTINE_BEGIN,
    };
  },

  viewSingleCantineSuccess: (data) => {
    return {
      type: actions.VIEW_SINGLE_CANTINE_SUCCESS,
      data,
    };
  },

  viewSingleCantineErr: (err) => {
    return {
      type: actions.VIEW_SINGLE_CANTINE_ERR,
      err,
    };
  },

  updateCantineBegin: () => {
    return {
      type: actions.UPDATE_CANTINE_BEGIN,
    };
  },

  updateCantineSuccess: (data) => {
    return {
      type: actions.UPDATE_CANTINE_SUCCESS,
      data,
    };
  },

  updateCantineErr: (err) => {
    return {
      type: actions.UPDATE_CANTINE_ERR,
      err,
    };
  },
  deleteCantineBegin: () => {
    return {
      type: actions.DELETE_CANTINE_BEGIN,
    };
  },

  deleteCantineSuccess: (data) => {
    return {
      type: actions.DELETE_CANTINE_SUCCESS,
      data,
    };
  },

  deleteCantineErr: (err) => {
    return {
      type: actions.DELETE_CANTINE_ERR,
      err,
    };
  },
  addCantineBegin: () => {
    return {
      type: actions.ADD_CANTINE_BEGIN,
    };
  },

  addCantineSuccess: (data) => {
    return {
      type: actions.ADD_CANTINE_SUCCESS,
      data,
    };
  },

  addCantineErr: (err) => {
    return {
      type: actions.ADD_CANTINE_ERR,
      err,
    };
  },
};

export default actions;
