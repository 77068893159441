import React, { useEffect, useState } from 'react';
import { Row, Col, Modal } from 'antd';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import DataTable from '../../components/table/DataTable';
import { Link } from 'react-router-dom';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import UilTrashAlt from '@iconscout/react-unicons/icons/uil-trash-alt';
import { useDispatch } from 'react-redux';
import {
  addMatiereData,
  deleteMatiereData,
  updateMatiereData,
  viewMatiereData,
} from '../../redux/matiere/actionCreator';
import { useSelector } from 'react-redux';
import { ExclamationCircleFilled } from '@ant-design/icons';
import Update from './Update';
import Add from './Add';

function Matiere() {
  const { confirm } = Modal;
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewMatiereData());
  }, []);

  const { matieres } = useSelector((state) => {
    return {
      matieres: state.MatiereReducer.arrayData,
    };
  });
  const [selectedItem, setSelectedItem] = useState();
  const [openAdd, setOpenAdd] = useState();
  const handleAdd = () => {
    setOpenAdd(true);
  };
  const handleAddCancel = () => {
    setOpenAdd(false);
  };

  const [openUpdate, setOpenUpdate] = useState();
  const handleUpdate = (data) => {
    setSelectedItem(data);
    setOpenUpdate(true);
  };
  const handleUpdateCancel = () => {
    setOpenUpdate(false);
  };

  const addSuccess = () => {
    handleAddCancel();
    dispatch(viewMatiereData());
  };
  const updateSuccess = () => {
    handleUpdateCancel();
    dispatch(viewMatiereData());
  };

  const handleDelete = (id) => {
    dispatch(deleteMatiereData(id));
  };

  const updateAction = (values) => {
    dispatch(updateMatiereData(selectedItem.id, values, updateSuccess));
  };

  const addAction = (values) => {
    dispatch(addMatiereData(values, addSuccess));
  };
  const showConfirm = (id) => {
    confirm({
      title: "Voulez-vous supprimer l'événement ?",
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      cancelButtonProps: {
        type: 'white',
      },
      onOk() {
        handleDelete(id);
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    const localData = [];

    matieres.map((element) => {
      localData.push({
        ...element,
        matiere: (
          <div style={{ fontSize: '.8rem' }}>
            <div>Nom : {element.nom}</div>
            <div>Niveau : {element.niveau}</div>
          </div>
        ),
        action: (
          <div className="table-actions">
            <Link className="#" onClick={() => handleUpdate(element)}>
              <UilEdit />
            </Link>
            <Link
              className="delete"
              to="#"
              onClick={() => {
                showConfirm(element.id);
              }}
            >
              <UilTrashAlt />
            </Link>
          </div>
        ),
      });
    });
    setData(localData);
  }, [matieres]);

  const dataTableColumn = [
    {
      title: 'Nom',
      dataIndex: 'nom',
      key: 'nom',
    },
    {
      title: 'Niveau',
      dataIndex: 'niveau',
      key: 'niveau',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '60px',
    },
  ];

  const responsiveDataTableColumn = [
    {
      title: 'Matiere',
      dataIndex: 'matiere',
      key: 'matiere',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '60px',
    },
  ];

  return (
    <>
      <Update open={openUpdate} action={updateAction} handleUpdateCancel={handleUpdateCancel} data={selectedItem} />
      <Add open={openAdd} action={addAction} handleAddCancel={handleAddCancel} data={selectedItem} />
      <Main>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
            <Cards headless>
              <DataTable
                filterOption
                filterOnchange
                buttonName="Ajouter Matiere"
                btnAction={handleAdd}
                tableData={data}
                columns={window.innerWidth < 990 ? responsiveDataTableColumn : dataTableColumn}
              />
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default Matiere;
