import UilCalender from '@iconscout/react-unicons/icons/uil-calender';
import UilClock from '@iconscout/react-unicons/icons/uil-clock';
import UilMultiply from '@iconscout/react-unicons/icons/uil-multiply';
import UilNotes from '@iconscout/react-unicons/icons/uil-notes';
import UilBuilding from '@iconscout/react-unicons/icons/uil-building';
import { Col, Modal, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import { CalendarWrapper, EventModalStyleWrap } from './Style';
import { Main } from '../styled';
import { startOfWeek, addDays, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { viewEmploiData } from '../../redux/parent/emploi/actionCreator';

function EmploiParent() {
  const dispatch = useDispatch();
  const currentDate = new Date();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedEvent, setSlectedEvent] = useState();
  const [emploiData, setEmploiData] = useState([]);
  const { emploi, class_id } = useSelector((state) => {
    return {
      emploi: state.ParentEmploiReducer.data,
      class_id: state.auth.data.classe_id,
    };
  });

  const renderClendarData = () => {
    const rows = [];
    for (const hour of timeSlots) {
      const cells = [];
      for (const day of weekDays) {
        const highlighted = isHighlightedCell(format(day, 'EEEE', { locale: fr }), hour);
        let cellData;
        if (highlighted) {
          let eventData = highlighted[0];
          eventData.day = moment(day, 'MM-DD-YYYY');
          cellData = {
            highlighted: true,
            data: eventData,
            height: `${(highlighted[1] - 1) * 50 + 45}px`,
            day: format(day, 'EEEE', { locale: fr }),
          };
        } else {
          cellData = {
            highlighted: false,
            day: format(day, 'EEEE', { locale: fr }),
          };
        }
        cells.push(cellData);
      }
      rows.push({ hour, cells });
    }
    setData(rows);
  };
  useEffect(() => {
    dispatch(viewEmploiData());
  }, []);

  useEffect(() => {
    renderClendarData();
  }, [emploiData]);

  useEffect(() => {
    const localData = emploi.filter((item) => item.classe_id == class_id);
    setEmploiData(localData);
  }, [emploi, class_id]);

  const getWeekDays = (startDate) => {
    const days = [];
    for (let i = 1; i <= 7; i++) {
      days.push(addDays(startDate, i));
    }
    return days;
  };

  const getTimeSlots = () => {
    const timeSlots = [];
    for (let i = 0; i <= 23; i++) {
      timeSlots.push(i);
    }
    return timeSlots;
  };

  const isHighlightedCell = (day, hour) => {
    const item = emploiData.find((item) => {
      const [hours] = item.heurDebut.split(':');
      const intHours = parseInt(hours);

      return hour === intHours && day === item.jour;
    });
    if (item) {
      const [start] = item.heurDebut.split(':');
      const [end] = item.heurFin.split(':');

      return [item, end - start];
    }
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
  };

  const showModal = (event) => {
    setSlectedEvent(event);
    setOpen(true);
  };

  const handleCancel = () => {
    setSlectedEvent(null);
    setOpen(false);
  };

  const weekDays = getWeekDays(startOfWeek(currentDate));
  const timeSlots = getTimeSlots();

  return (
    <>
      <Main>
        <CalendarWrapper className="edulink-calendar-wrap">
          <Row gutter={25}>
            <Col xxl={24} xl={24} xs={24}>
              <Modal
                open={open}
                onCancel={handleCancel}
                closable={false}
                footer={null}
                title={
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {selectedEvent && `Matiere : ${selectedEvent.matiere}`}
                    <div style={{ display: 'flex', alignItems: 'center', columnGap: '7px', cursor: 'pointer' }}>
                      <UilMultiply size={16} onClick={handleCancel} />
                    </div>
                  </div>
                }
              >
                <EventModalStyleWrap>
                  {selectedEvent && (
                    <div className="edulink-event-details">
                      <ul>
                        <li>
                          <UilCalender />
                          <span className="edulink-event-label">Jour:</span>
                          <span className="edulink-event-text">
                            <strong>{selectedEvent.jour}</strong>
                          </span>
                        </li>
                        <li>
                          <UilClock />
                          <span className="edulink-event-label">Temps:</span>
                          <span className="edulink-event-text">
                            <strong>
                              {formatTime(selectedEvent.heurDebut)} - {formatTime(selectedEvent.heurFin)}
                            </strong>
                          </span>
                        </li>
                        <li>
                          <UilBuilding />
                          <span className="edulink-event-label">Salle:</span>
                          <span className="edulink-event-text">
                            <strong>{selectedEvent.salle}</strong>
                          </span>
                        </li>
                        <li>
                          <UilNotes />
                          <span className="edulink-event-label">Type cours:</span>
                          <span className="edulink-event-text">
                            <strong>{selectedEvent.typeCours}</strong>
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}
                </EventModalStyleWrap>
              </Modal>
              <div className="calendar-container">
                <div>
                  <CustomToolbar />
                </div>
                <div className="calendar-cells-container">
                  <table className="calendar" rules="none">
                    <thead>
                      <tr>
                        <th>
                          <UilClock size={20} />
                        </th>
                        {weekDays.map((day) => (
                          <th key={day} className="day-header">
                            {format(day, 'EEEE', { locale: fr })}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index}>
                          <td className="cell">{`${item.hour < 10 ? '0' : ''}${item.hour}:00`}</td>
                          {item.cells.map((day, index) => (
                            <td key={index} className={`day-cell ${day.highlighted ? 'highlighted-cell' : ''}`}>
                              {day.highlighted ? (
                                <div
                                  onClick={() => {
                                    showModal(day.data);
                                  }}
                                  className={`meeting absences`}
                                  style={{
                                    height: day.height,
                                  }}
                                >
                                  <div className="from-to-container">
                                    <div className={`from-to-badge absences-badge`}>
                                      {formatTime(day.data.heurDebut)}
                                    </div>
                                    <div className={`from-to-badge absences-badge`}>{formatTime(day.data.heurFin)}</div>
                                  </div>
                                  <div className={`cell-title absences-title`}>{day.data.matiere}</div>
                                </div>
                              ) : (
                                <div className="empty-cell"></div>
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        </CalendarWrapper>
      </Main>
    </>
  );
}

const CustomToolbar = ({}) => {
  return (
    <div className="calendar-header">
      <div className="calendar-header__left" style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}></div>
      <div className="calendar-header__right"></div>
    </div>
  );
};

export default EmploiParent;
