import UilImport from '@iconscout/react-unicons/icons/uil-import';
import UilPrint from '@iconscout/react-unicons/icons/uil-print';
import { Col, Row, Table } from 'antd';
import { InvoiceAction, InvoiceLetterBox, OrderSummary, ProductTable } from './Style';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import Heading from '../../components/heading/heading';
import { Main } from '../styled';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { viewBulletinData } from '../../redux/note/actionCreator';
import moment from 'moment';
function Bulletin() {
  const location = useLocation();
  const { inscription } = location.state;
  const params = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const { id } = params;
  useEffect(() => {
    if (id) {
      dispatch(viewBulletinData(id));
    }
  }, [id]);

  const { bulletin, organisation } = useSelector((state) => {
    return {
      bulletin: state.NoteReducer.bulletin,
      organisation: state.auth.data,
    };
  });

  useEffect(() => {
    if (bulletin) {
      const matieres = bulletin.ListeMatiere.map((element, index) => {
        return {
          key: index + 1,
          row: index + 1,
          matiere: (
            <>
              <div className="">
                <Heading className="product-info-title" as="h6">
                  {element.matiere}
                </Heading>
                {/* <ul className="info-list">
                  <li>
                    <span className="info-title"> Prof :</span>
                    <span>Ahmed Andolsi</span>
                  </li>
                </ul> */}
              </div>
            </>
          ),
          oral: <span>{element.oral}</span>,
          controle: <span>{element.controle}</span>,
          examen: <span>{element.examen}</span>,
          moyenne: <span>{parseFloat(element.moyenne).toFixed(2)}</span>,
        };
      });
      setData(matieres);
    }
  }, [bulletin]);

  const invoiceTableColumns = [
    {
      title: '#',
      dataIndex: 'row',
      key: 'row',
    },
    {
      title: 'Matière',
      dataIndex: 'matiere',
      key: 'matiere',
    },
    {
      title: 'Oral',
      dataIndex: 'oral',
      key: 'oral',
    },
    {
      title: 'Controle',
      dataIndex: 'controle',
      key: 'controle',
    },
    {
      title: 'Examen',
      dataIndex: 'examen',
      key: 'examen',
    },
    {
      title: 'Moyenne',
      dataIndex: 'moyenne',
      key: 'moyenne',
    },
  ];

  const printInvoice = () => {
    window.print();
  };

  return (
    <div className="invoice-area">
      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <div className="invoice-content-wrap" id="print-invoice-content">
                <InvoiceLetterBox>
                  <div className="invoice-letter-inner">
                    <article className="invoice-author">
                      <Heading className="invoice-author__title" as="h3">
                        Bulletin
                      </Heading>
                      <p>Date de naissance : {moment(inscription.dateNaiss).format('DD MMMM YYYY')}</p>
                      {/* <p>Date d'emmision: 26 novembre 2023</p> */}
                      <p>
                        Elève : {inscription.prenom} {inscription.nom}
                      </p>
                    </article>
                    <div className="invoice-barcode"></div>
                    <address className="invoice-customer">
                      <Heading className="invoice-customer__title" as="h5">
                        Edulink
                      </Heading>
                      <p>
                        {organisation.organisation} <br />
                        {organisation.adresse}  <br />
                        {organisation.numTel} 
                      </p>
                    </address>
                  </div>
                </InvoiceLetterBox>

                <br />
                <br />
                <ProductTable>
                  <div className="table-invoice table-responsive">
                    <Table dataSource={data} columns={invoiceTableColumns} pagination={false} />
                  </div>
                </ProductTable>

                <Row justify="end">
                  <Col xxl={4} xl={5} sm={8} xs={14} offset={10}>
                    <OrderSummary>
                      <div className="invoice-summary-inner">
                        <ul className="summary-list">
                          <li>
                            <span className="summary-list-title">
                              Total Coefficient : {bulletin && bulletin.TotalCoeff}
                            </span>
                          </li>
                          <li>
                            <span className="summary-list-title">
                              Taux D'absence : {bulletin && bulletin.TotalAbsence} fois
                            </span>
                          </li>
                          <li>
                            <span className="summary-list-title">
                              Moyenne Generale : {bulletin && bulletin.MoyenneGeneral}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </OrderSummary>
                  </Col>
                </Row>
                <Row justify="end">
                  <Col lg={12} md={18} sm={24} offset={0}>
                    <InvoiceAction>
                      <Button size="default" shape="round" type="default" onClick={() => printInvoice()}>
                        <UilPrint />
                        Print
                      </Button>
                      <Button size="default" shape="round" type="primary">
                        <UilImport />
                        Download
                      </Button>
                    </InvoiceAction>
                  </Col>
                </Row>
              </div>
            </Cards>
          </Col>
        </Row>
      </Main>
    </div>
  );
}

export default Bulletin;
