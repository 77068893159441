import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../../config/dataService/dataService';

const { viewFactureBegin, viewFactureSuccess, viewFactureErr } = actions;

const viewFactureData = (id) => {
  return async (dispatch) => {
    dispatch(viewFactureBegin());
    try {
      const response = await DataService.get(`/factures/viewFactureParent`);
      if (response.status === 200) {
        dispatch(viewFactureSuccess(response.data));
      } else {
        dispatch(viewFactureErr('err'));
      }
    } catch (err) {
      dispatch(viewFactureErr('err'));
    }
  };
};

export {
  viewFactureData,
};
