import { Spin } from 'antd';
import React, { Suspense } from 'react';
import { AuthenticationWrap } from './overview/style';

const AuthLayout = (WraperContent) => {
  return function () {
    return (
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <AuthenticationWrap style={{ backgroundColor:"#DDF1F7"}}>
          <div className="edulink-authentication-wrap">
            <div className="edulink-authentication-brand">
              <img src={require(`../../../static/img/logo.png`)} style={{height:"80px"}} />
            </div>
            <WraperContent />
          </div>
        </AuthenticationWrap>
      </Suspense>
    );
  };
};

export default AuthLayout;
