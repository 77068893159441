import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import Exercices from '../../container/prof/exercices/Exercices';
import AddExerciceForm from '../../container/prof/exercices/AddForm';
import UpdateExerciceForm from '../../container/prof/exercices/UpdateForm';
import AbsenceEleve from '../../container/prof/absence_eleve/Calendar';
import Notes from '../../container/prof/notes/Notes';
import Bulletin from '../../container/prof/bulletin/Bulletin';
import Bulletins from '../../container/prof/bulletin/Notes';
import NotFound from '../../container/pages/404';
function PagesRoute() {
  return (
    <Routes>
      <Route path="notes" element={<Notes />} />
      <Route path="bulletin">
        <Route index element={<Bulletins />} />
        <Route path="details/:id" element={<Bulletin />} />
      </Route>
      <Route index element={<AbsenceEleve />} />
      <Route path="exercices">
        <Route index element={<Exercices />} />
        <Route path="form" element={<AddExerciceForm />} />
        <Route path="update/:id" element={<UpdateExerciceForm />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
