import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, DatePicker, Select, Switch } from 'antd';
import { FormValidationWrap, VerticalFormStyleWrap } from './Style';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { useDispatch } from 'react-redux';
import { addPersonnelData } from '../../redux/personnel/actionCreator';
import { useNavigate } from 'react-router-dom';

function AddPersonnelForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cnss, setCnss] = useState(false);
  const [prime, setPrime] = useState(false);

  const [form] = Form.useForm();

  const submitSuccess = () => {
    navigate(-1);
  };

  const submit = (values) => {
    values.dateNaiss = values.dateNaiss.format('YYYY-MM-DD');
    values.checkCNSS = cnss;
    values.checkPrime = prime;
    dispatch(addPersonnelData(values, submitSuccess));
  };
  const handleAnnuler = () => {
    navigate(-1);
  };
  return (
    <>
      <Main>
        <Row gutter={25}>
          <Col xs={24}>
            <FormValidationWrap>
              <VerticalFormStyleWrap>
                <Cards headless>
                  <Form name="add-personnel" form={form} layout="vertical" onFinish={submit}>
                    <div className="form-title">Ajouter Personnel</div>
                    <Row gutter={30}>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="nom"
                          label="Prénom Personnel"
                          rules={[{ required: true, message: 'Prénom de personnel est obligatoire !' }]}
                        >
                          <Input placeholder="Nom personnel" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="prenom"
                          label="Nom Personnel"
                          rules={[{ required: true, message: 'Nom de Personnel est obligatoire !' }]}
                        >
                          <Input placeholder="Prénom personnel" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="dateNaiss"
                          label="Date De Naissance"
                          rules={[{ required: true, message: 'Date de naissance est obligatoire!' }]}
                        >
                          <DatePicker placeholder="Date De Naissance" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="num"
                          label="Téléphone"
                          rules={[{ required: true, message: 'Téléphone est obligatoire!' }]}
                        >
                          <Input placeholder="Téléphone" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="salaire"
                          label="Salaire"
                          rules={[{ required: true, message: 'Salaire est obligatoire !' }]}
                        >
                          <Input placeholder="Salaire" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Contrat"
                          name="nomCompltSalaire"
                          rules={[{ required: true, message: 'Contrat est obligatoire!' }]}
                        >
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Contrat"
                            options={[
                              { value: 'CIVP', label: 'CIVP' },
                              { value: 'CDD', label: 'CDD' },
                              { value: 'CDI', label: 'CDI' },
                              { value: 'Karama', label: 'Karama' },
                              { value: 'Stagiaire', label: 'Stagiaire' },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="montantCompltSalaire"
                          label="Montant Complementaire"
                          rules={[{ required: true, message: 'Montant complementaire est obligatoire !' }]}
                        >
                          <Input placeholder="Montant complementaire" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Role"
                          name="role"
                          rules={[{ required: true, message: 'Role est obligatoire!' }]}
                        >
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Role"
                            options={[
                              { value: 'professeur', label: 'Professeur' },
                              { value: 'Administrateur', label: 'Administrateur' },
                              { value: 'Scolarité', label: 'Scolarité' },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="switch-container">
                      <div className="label">CNSS</div>{' '}
                      <Switch value={cnss} onChange={(e) => setCnss(e)} size="large" />
                    </div>
                    {cnss && (
                      <Row gutter={30}>
                        <Col md={12} xs={24}>
                          <Form.Item
                            label="Type"
                            name="typeCNSS"
                            rules={[{ required: true, message: 'Type est obligatoire!' }]}
                          >
                            <Select
                              style={{ width: '100%' }}
                              placeholder="Type"
                              options={[
                                { value: 'MONTANT', label: 'Montant' },
                                { value: 'POURCENTAGE', label: 'Pourcentage' },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                          <Form.Item
                            name="montantCNSS"
                            label="Montant CNSS"
                            rules={[{ required: true, message: 'Montant CNSS de personnel est obligatoire !' }]}
                          >
                            <Input placeholder="Montant CNSS" />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    <div className="switch-container">
                      <div className="label">Prime</div>{' '}
                      <Switch value={prime} onChange={(e) => setPrime(e)} size="large" />
                    </div>
                    {prime && (
                      <Row gutter={30}>
                        <Col md={24} xs={24}>
                          <Form.Item
                            name="montantPrime"
                            label="Montant"
                            rules={[{ required: true, message: 'Montant du prime est obligatoire !' }]}
                          >
                            <Input placeholder="Montant prime" />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    <div className="edulink-form-action mt-20">
                      <Button htmlType="reset" type="light" size="large" onClick={handleAnnuler}>
                        Annuler
                      </Button>
                      <Button htmlType="submit" type="danger" size="large">
                        Ajouter
                      </Button>
                    </div>
                  </Form>
                </Cards>
              </VerticalFormStyleWrap>
            </FormValidationWrap>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default AddPersonnelForm;
