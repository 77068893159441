const actions = {
  VIEW_YEARLY_INSCRIPTION_BEGIN: 'VIEW_YEARLY_INSCRIPTION_BEGIN',
  VIEW_YEARLY_INSCRIPTION_SUCCESS: 'VIEW_YEARLY_INSCRIPTION_SUCCESS',
  VIEW_YEARLY_INSCRIPTION_ERR: 'VIEW_YEARLY_INSCRIPTION_ERR',

  VIEW_YEARLY_INSCRIPTION_BY_LEVEL_BEGIN: 'VIEW_YEARLY_INSCRIPTION_BY_LEVEL_BEGIN',
  VIEW_YEARLY_INSCRIPTION_BY_LEVEL_SUCCESS: 'VIEW_YEARLY_INSCRIPTION_BY_LEVEL_SUCCESS',
  VIEW_YEARLY_INSCRIPTION_BY_LEVEL_ERR: 'VIEW_YEARLY_INSCRIPTION_BY_LEVEL_ERR',

  VIEW_MONTHLY_INSCRIPTION_BEGIN: 'VIEW_MONTHLY_INSCRIPTION_BEGIN',
  VIEW_MONTHLY_INSCRIPTION_SUCCESS: 'VIEW_MONTHLY_INSCRIPTION_SUCCESS',
  VIEW_MONTHLY_INSCRIPTION_ERR: 'VIEW_MONTHLY_INSCRIPTION_ERR',

  VIEW_CLUB_INSCRIPTION_BEGIN: 'VIEW_CLUB_INSCRIPTION_BEGIN',
  VIEW_CLUB_INSCRIPTION_SUCCESS: 'VIEW_CLUB_INSCRIPTION_SUCCESS',
  VIEW_CLUB_INSCRIPTION_ERR: 'VIEW_CLUB_INSCRIPTION_ERR',

  VIEW_TOTAL_CLUB_INSCRIPTION_BEGIN: 'VIEW_TOTAL_CLUB_INSCRIPTION_BEGIN',
  VIEW_TOTAL_CLUB_INSCRIPTION_SUCCESS: 'VIEW_TOTAL_CLUB_INSCRIPTION_SUCCESS',
  VIEW_TOTAL_CLUB_INSCRIPTION_ERR: 'VIEW_TOTAL_CLUB_INSCRIPTION_ERR',

  VIEW_TOTAL_GAIN_PREVESIONNELS_CLUB_BEGIN: 'VIEW_TOTAL_GAIN_PREVESIONNELS_CLUB_BEGIN',
  VIEW_TOTAL_GAIN_PREVESIONNELS_CLUB_SUCCESS: 'VIEW_TOTAL_GAIN_PREVESIONNELS_CLUB_SUCCESS',
  VIEW_TOTAL_GAIN_PREVESIONNELS_CLUB_ERR: 'VIEW_TOTAL_GAIN_PREVESIONNELS_CLUB_ERR',

  VIEW_TOTAL_GAIN_REALISES_CLUB_BEGIN: 'VIEW_TOTAL_GAIN_REALISES_CLUB_BEGIN',
  VIEW_TOTAL_GAIN_REALISES_CLUB_SUCCESS: 'VIEW_TOTAL_GAIN_REALISES_CLUB_SUCCESS',
  VIEW_TOTAL_GAIN_REALISES_CLUB_ERR: 'VIEW_TOTAL_GAIN_REALISES_CLUB_ERR',

  VIEW_CLUB_DEPENSE_BEGIN: 'VIEW_CLUB_DEPENSE_BEGIN',
  VIEW_CLUB_DEPENSE_SUCCESS: 'VIEW_CLUB_DEPENSE_SUCCESS',
  VIEW_CLUB_DEPENSE_ERR: 'VIEW_CLUB_DEPENSE_ERR',

  VIEW_AVREAGE_GRADES_PER_LEVEL_BEGIN: 'VIEW_AVREAGE_GRADES_PER_LEVEL_BEGIN',
  VIEW_AVREAGE_GRADES_PER_LEVEL_SUCCESS: 'VIEW_AVREAGE_GRADES_PER_LEVEL_SUCCESS',
  VIEW_AVREAGE_GRADES_PER_LEVEL_ERR: 'VIEW_AVREAGE_GRADES_PER_LEVEL_ERR',

  VIEW_AVREAGE_GRADES_BEGIN: 'VIEW_AVREAGE_GRADES_BEGIN',
  VIEW_AVREAGE_GRADES_SUCCESS: 'VIEW_AVREAGE_GRADES_SUCCESS',
  VIEW_AVREAGE_GRADES_ERR: 'VIEW_AVREAGE_GRADES_ERR',

  VIEW_RANK_PER_LEVEL_BEGIN: 'VIEW_RANK_PER_LEVEL_BEGIN',
  VIEW_RANK_PER_LEVEL_SUCCESS: 'VIEW_RANK_PER_LEVEL_SUCCESS',
  VIEW_RANK_PER_LEVEL_ERR: 'VIEW_RANK_PER_LEVEL_ERR',

  VIEW_RANK_BEGIN: 'VIEW_RANK_BEGIN',
  VIEW_RANK_SUCCESS: 'VIEW_RANK_SUCCESS',
  VIEW_RANK_ERR: 'VIEW_RANK_ERR',

  VIEW_FINANCE_OVERVIEW_BEGIN: 'VIEW_FINANCE_OVERVIEW_BEGIN',
  VIEW_FINANCE_OVERVIEW_SUCCESS: 'VIEW_FINANCE_OVERVIEW_SUCCESS',
  VIEW_FINANCE_OVERVIEW_ERR: 'VIEW_FINANCE_OVERVIEW_ERR',

  VIEW_FINANCE_CHARGE_BEGIN: 'VIEW_FINANCE_CHARGE_BEGIN',
  VIEW_FINANCE_CHARGE_SUCCESS: 'VIEW_FINANCE_CHARGE_SUCCESS',
  VIEW_FINANCE_CHARGE_ERR: 'VIEW_FINANCE_CHARGE_ERR',

  VIEW_FIELD_SUCCESS_RATE_BEGIN: 'VIEW_FIELD_SUCCESS_RATE_BEGIN',
  VIEW_FIELD_SUCCESS_RATE_SUCCESS: 'VIEW_FIELD_SUCCESS_RATE_SUCCESS',
  VIEW_FIELD_SUCCESS_RATE_ERR: 'VIEW_FIELD_SUCCESS_RATE_ERR',

  VIEW_LEVEL_FIELDS_SUCCESS_RATE_BEGIN: 'VIEW_LEVEL_FIELDS_SUCCESS_RATE_BEGIN',
  VIEW_LEVEL_FIELDS_SUCCESS_RATE_SUCCESS: 'VIEW_LEVEL_FIELDS_SUCCESS_RATE_SUCCESS',
  VIEW_LEVEL_FIELDS_SUCCESS_RATE_ERR: 'VIEW_LEVEL_FIELDS_SUCCESS_RATE_ERR',

  VIEW_FINANCE_TRANSPORT_BEGIN: 'VIEW_FINANCE_TRANSPORT_BEGIN',
  VIEW_FINANCE_TRANSPORT_SUCCESS: 'VIEW_FINANCE_TRANSPORT_SUCCESS',
  VIEW_FINANCE_TRANSPORT_ERR: 'VIEW_FINANCE_TRANSPORT_ERR',

  viewYearlyInscriptionStatisticsBegin: () => {
    return {
      type: actions.VIEW_YEARLY_INSCRIPTION_BEGIN,
    };
  },

  viewYearlyInscriptionStatisticsSuccess: (data) => {
    return {
      type: actions.VIEW_YEARLY_INSCRIPTION_SUCCESS,
      data,
    };
  },

  viewYearlyInscriptionStatisticsErr: (err) => {
    return {
      type: actions.VIEW_YEARLY_INSCRIPTION_ERR,
      err,
    };
  },

  viewYearlyInscriptionByLevelStatisticsBegin: () => {
    return {
      type: actions.VIEW_YEARLY_INSCRIPTION_BY_LEVEL_BEGIN,
    };
  },

  viewYearlyInscriptionByLevelStatisticsSuccess: (data) => {
    return {
      type: actions.VIEW_YEARLY_INSCRIPTION_BY_LEVEL_SUCCESS,
      data,
    };
  },

  viewYearlyInscriptionByLevelStatisticsErr: (err) => {
    return {
      type: actions.VIEW_YEARLY_INSCRIPTION_BY_LEVEL_ERR,
      err,
    };
  },

  viewMonthlyInscriptionStatisticsBegin: () => {
    return {
      type: actions.VIEW_MONTHLY_INSCRIPTION_BEGIN,
    };
  },

  viewMonthlyInscriptionStatisticsSuccess: (data) => {
    return {
      type: actions.VIEW_MONTHLY_INSCRIPTION_SUCCESS,
      data,
    };
  },

  viewMonthlyInscriptionStatisticsErr: (err) => {
    return {
      type: actions.VIEW_MONTHLY_INSCRIPTION_ERR,
      err,
    };
  },

  viewClubInscriptionStatisticsBegin: () => {
    return {
      type: actions.VIEW_CLUB_INSCRIPTION_BEGIN,
    };
  },

  viewClubInscriptionStatisticsSuccess: (data) => {
    return {
      type: actions.VIEW_CLUB_INSCRIPTION_SUCCESS,
      data,
    };
  },

  viewClubInscriptionStatisticsErr: (err) => {
    return {
      type: actions.VIEW_TOTAL_CLUB_INSCRIPTION_ERR,
      err,
    };
  },

  viewTotalClubInscriptionStatisticsBegin: () => {
    return {
      type: actions.VIEW_TOTAL_CLUB_INSCRIPTION_BEGIN,
    };
  },

  viewTotalClubInscriptionStatisticsSuccess: (data) => {
    return {
      type: actions.VIEW_TOTAL_CLUB_INSCRIPTION_SUCCESS,
      data,
    };
  },

  viewTotalClubInscriptionStatisticsErr: (err) => {
    return {
      type: actions.VIEW_CLUB_INSCRIPTION_ERR,
      err,
    };
  },

  viewTotalGainPrevisionnelsClubStatisticsBegin: () => {
    return {
      type: actions.VIEW_TOTAL_GAIN_PREVESIONNELS_CLUB_BEGIN,
    };
  },

  viewTotalGainPrevisionnelsClubStatisticsSuccess: (data) => {
    return {
      type: actions.VIEW_TOTAL_GAIN_PREVESIONNELS_CLUB_SUCCESS,
      data,
    };
  },

  viewTotalGainPrevisionnelsClubStatisticsErr: (err) => {
    return {
      type: actions.VIEW_TOTAL_GAIN_PREVESIONNELS_CLUB_ERR,
      err,
    };
  },

  viewTotalGainRealisesClubStatisticsBegin: () => {
    return {
      type: actions.VIEW_TOTAL_GAIN_REALISES_CLUB_BEGIN,
    };
  },

  viewTotalGainRealisesClubStatisticsSuccess: (data) => {
    return {
      type: actions.VIEW_TOTAL_GAIN_REALISES_CLUB_SUCCESS,
      data,
    };
  },

  viewTotalGainRealisesClubStatisticsErr: (err) => {
    return {
      type: actions.VIEW_TOTAL_GAIN_REALISES_CLUB_ERR,
      err,
    };
  },

  viewClubDepenseStatisticsBegin: () => {
    return {
      type: actions.VIEW_CLUB_DEPENSE_BEGIN,
    };
  },

  viewClubDepenseStatisticsSuccess: (data) => {
    return {
      type: actions.VIEW_CLUB_DEPENSE_SUCCESS,
      data,
    };
  },

  viewClubDepenseStatisticsErr: (err) => {
    return {
      type: actions.VIEW_CLUB_DEPENSE_ERR,
      err,
    };
  },

  viewAvreageGradesPerLevelStatisticsBegin: () => {
    return {
      type: actions.VIEW_AVREAGE_GRADES_PER_LEVEL_BEGIN,
    };
  },

  viewAvreageGradesPerLevelStatisticsSuccess: (data) => {
    return {
      type: actions.VIEW_AVREAGE_GRADES_PER_LEVEL_SUCCESS,
      data,
    };
  },

  viewAvreageGradesPerLevelStatisticsErr: (err) => {
    return {
      type: actions.VIEW_AVREAGE_GRADES_PER_LEVEL_ERR,
      err,
    };
  },
  viewAvreageGradesStatisticsBegin: () => {
    return {
      type: actions.VIEW_AVREAGE_GRADES_BEGIN,
    };
  },

  viewAvreageGradesStatisticsSuccess: (data) => {
    return {
      type: actions.VIEW_AVREAGE_GRADES_SUCCESS,
      data,
    };
  },

  viewAvreageGradesStatisticsErr: (err) => {
    return {
      type: actions.VIEW_AVREAGE_GRADES_ERR,
      err,
    };
  },
  viewRankPerLevelStatisticsBegin: () => {
    return {
      type: actions.VIEW_RANK_PER_LEVEL_BEGIN,
    };
  },

  viewRankPerLevelStatisticsSuccess: (data) => {
    return {
      type: actions.VIEW_RANK_PER_LEVEL_SUCCESS,
      data,
    };
  },

  viewRankPerLevelStatisticsErr: (err) => {
    return {
      type: actions.VIEW_RANK_PER_LEVEL_ERR,
      err,
    };
  },
  viewRankStatisticsBegin: () => {
    return {
      type: actions.VIEW_RANK_BEGIN,
    };
  },

  viewRankStatisticsSuccess: (data) => {
    return {
      type: actions.VIEW_RANK_SUCCESS,
      data,
    };
  },

  viewRankStatisticsErr: (err) => {
    return {
      type: actions.VIEW_RANK_ERR,
      err,
    };
  },

  viewFinanceOverviewStatisticsBegin: () => {
    return {
      type: actions.VIEW_FINANCE_OVERVIEW_BEGIN,
    };
  },

  viewFinanceOverviewStatisticsSuccess: (data) => {
    return {
      type: actions.VIEW_FINANCE_OVERVIEW_SUCCESS,
      data,
    };
  },

  viewFinanceOverviewStatisticsErr: (err) => {
    return {
      type: actions.VIEW_FINANCE_OVERVIEW_ERR,
      err,
    };
  },

  viewFinanceChargeStatisticsBegin: () => {
    return {
      type: actions.VIEW_FINANCE_CHARGE_BEGIN,
    };
  },

  viewFinanceChargeStatisticsSuccess: (data) => {
    return {
      type: actions.VIEW_FINANCE_CHARGE_SUCCESS,
      data,
    };
  },

  viewFinanceChargeStatisticsErr: (err) => {
    return {
      type: actions.VIEW_FINANCE_CHARGE_ERR,
      err,
    };
  },

  viewFieldSuccesRateStatisticsBegin: () => {
    return {
      type: actions.VIEW_FIELD_SUCCESS_RATE_BEGIN,
    };
  },

  viewFieldSuccesRateStatisticsSuccess: (data) => {
    return {
      type: actions.VIEW_FIELD_SUCCESS_RATE_SUCCESS,
      data,
    };
  },

  viewFieldSuccesRateStatisticsErr: (err) => {
    return {
      type: actions.VIEW_FIELD_SUCCESS_RATE_ERR,
      err,
    };
  },
  viewLevelFieldsSuccesRateStatisticsBegin: () => {
    return {
      type: actions.VIEW_LEVEL_FIELDS_SUCCESS_RATE_BEGIN,
    };
  },

  viewLevelFieldsSuccesRateStatisticsSuccess: (data) => {
    return {
      type: actions.VIEW_LEVEL_FIELDS_SUCCESS_RATE_SUCCESS,
      data,
    };
  },

  viewLevelFieldsSuccesRateStatisticsErr: (err) => {
    return {
      type: actions.VIEW_LEVEL_FIELDS_SUCCESS_RATE_ERR,
      err,
    };
  },
  viewFinanceTransportStatisticsBegin: () => {
    return {
      type: actions.VIEW_FINANCE_TRANSPORT_BEGIN,
    };
  },

  viewFinanceTransportStatisticsSuccess: (data) => {
    return {
      type: actions.VIEW_FINANCE_TRANSPORT_SUCCESS,
      data,
    };
  },

  viewFinanceTransportStatisticsErr: (err) => {
    return {
      type: actions.VIEW_FINANCE_TRANSPORT_ERR,
      err,
    };
  },
};

export default actions;
