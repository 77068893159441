import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, notification, Modal } from 'antd';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import DataTable from '../../components/table/DataTable';
import { Link } from 'react-router-dom';
import UilEye from '@iconscout/react-unicons/icons/uil-eye';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import UilTrash from '@iconscout/react-unicons/icons/uil-trash-alt';
import { Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEventData, viewEventData } from '../../redux/event/actionCreator';
import moment from 'moment';
import { ExclamationCircleFilled } from '@ant-design/icons';

function Events() {
  const dispatch = useDispatch();
  const { confirm } = Modal;

  useEffect(() => {
    dispatch(viewEventData());
  }, []);

  const [data, setData] = useState([]);
  const { events } = useSelector((state) => {
    return {
      events: state.EventReducer.arrayData,
    };
  });

  const handleDelete = (id) => {
    dispatch(deleteEventData(id));
  };

  const showConfirm = (id) => {
    confirm({
      title: "Voulez-vous supprimer l'evenement ?",
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      cancelButtonProps: {
        type: 'white',
      },
      onOk() {
        handleDelete(id);
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    const localData = [];
    events.map((element) => {
      let [date, heur] = element.dateHeur.split(' ');
      const parsedTime = moment(heur, 'HH:mm:ss');
      const parsedDate = moment(date, 'yyyy-MM-dd');
      heur = parsedTime.format('HH:mm');
      date = parsedDate.format('DD-MM-yyyy');
      const shortDescription =
        element.description.length > 14 ? element.description.substring(0, 14) + ' ... ' : element.description;
      localData.push({
        ...element,
        date,
        heur,
        shortDescription,
        frais: element.frais == 0 ? 'gratuit' : `${element.frais} TND`,
        event: (
          <div style={{ fontSize: '.8rem' }}>
            <div>titre : {element.titre}</div>
            <div>Date : {date}</div>
            <div>Heur : {heur}</div>
            <div>Frais : {element.frais == 0 ? 'gratuit' : `${element.frais} TND`}</div>
          </div>
        ),
        action: (
          <div className="table-actions">
            <Link className="view" to="event" state={{ event: { ...element } }}>
              <UilEye />
            </Link>
            <Link className="edit" to="edit" state={{ event: { ...element } }}>
              <UilEdit />
            </Link>
            <Link
              className="delete"
              to="#"
              onClick={() => {
                showConfirm(element.id);
              }}
            >
              <UilTrash />
            </Link>
          </div>
        ),
      });
    });
    setData(localData);
  }, [events]);

  const dataTableColumn = [
    {
      title: 'Titre',
      dataIndex: 'titre',
      key: 'titre',
    },
    {
      title: 'Description',
      dataIndex: 'shortDescription',
      key: 'shortDescription',
    },
    {
      title: 'Adresse',
      dataIndex: 'adresse',
      key: 'adresse',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Heur',
      dataIndex: 'heur',
      key: 'heur',
    },
    {
      title: 'Frais',
      dataIndex: 'frais',
      key: 'frais',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '60px',
    },
  ];
  const responsiveDataTableColumn = [
    {
      title: 'Evenement',
      dataIndex: 'event',
      key: 'event',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '60px',
    },
  ];

  return (
    <>
      <Main>
        <div className="info-section">
          <div>
            <h1 style={{ fontSize: '36px', fontWeight: 700, color: '#FFF', margin: 0 }}>Evènements</h1>
            <p style={{ color: '#FFF' }}>
              Bienvenue, Admin ! Il s'agit de votre point central pour organiser et superviser tous les événements de
              l'école. Créez, mettez à jour et gérez facilement les événements pour vous assurer qu'ils se déroulent
              sans problème et qu'ils offrent une expérience mémorable à nos élèves.
            </p>
          </div>
          <img src={require(`../../static/img/pages/event.svg`).default} />
        </div>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
            <Cards headless>
              <DataTable
                filterOption
                filterOnchange
                buttonName="Ajouter Un Evènement"
                tableData={data}
                columns={window.innerWidth < 990 ? responsiveDataTableColumn : dataTableColumn}
              />
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default Events;
