import React, { useEffect, useState } from 'react';
import CustomBarChart from '../CustomBarChart';
import { useDispatch, useSelector } from 'react-redux';
import { viewYearlyInscriptionStatisticsData } from '../../../redux/statistics/actionCreator';
import { Main } from '../../styled';
import { Col, DatePicker, Row, Select } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import moment from 'moment';
const YearlyStatistics = ({ date }) => {
  const dispatch = useDispatch();
  const [niveau, setNiveau] = useState('1');

  const { statistics } = useSelector((state) => {
    return {
      statistics: state.StatisticsReducer.yearlyInscriptions,
    };
  });

  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);

  const handleYearChange = () => {
    if (statistics) {
      const data = statistics[niveau][date]['classes'];
      const keys = Object.keys(data);
      const values = Object.values(data);

      setLabels(keys);
      setDatasets(values);
    }
  };

  useEffect(() => {
    handleYearChange();
  }, [statistics, niveau]);

  return (
    <>
      {
        <Cards headless>
          <div
            style={{
              height: '50vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                fontSize: '18px',
                fontWeight: '500',
                marginBottom: '10px',
                color: '#333',
              }}
            >
              <div>Nombre d'élèves inscrits par classe</div>
              <Select
                placeholder="Niveau"
                value={niveau}
                onChange={(e) => setNiveau(e)}
                style={{ height: '40px' }}
                options={[
                  { value: '1', label: '1ère année' },
                  { value: '2', label: '2ème année' },
                  { value: '3', label: '3ème année' },
                  { value: '4', label: '4ème année' },
                  { value: '5', label: '5ème année' },
                  { value: '6', label: '6ème année' },
                ]}
              />
            </div>
            <div className="calendar-header"></div>

            <CustomBarChart
              labels={labels}
              datasets={datasets}
              title="Inscriptions by Month for 2024"
              beginAtZero={true}
            />
          </div>
        </Cards>
      }
    </>
  );
};

export default YearlyStatistics;
