const actions = {
  VIEW_CANTINE_BEGIN: 'VIEW_CANTINE_BEGIN',
  VIEW_CANTINE_SUCCESS: 'VIEW_CANTINE_SUCCESS',
  VIEW_CANTINE_ERR: 'VIEW_CANTINE_ERR',

  viewCantineBegin: () => {
    return {
      type: actions.VIEW_CANTINE_BEGIN,
    };
  },

  viewCantineSuccess: (data) => {
    return {
      type: actions.VIEW_CANTINE_SUCCESS,
      data,
    };
  },

  viewCantineErr: (err) => {
    return {
      type: actions.VIEW_CANTINE_ERR,
      err,
    };
  },
};

export default actions;
