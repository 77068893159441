const actions = {
  VIEW_REUNION_BEGIN: 'VIEW_REUNION_BEGIN',
  VIEW_REUNION_SUCCESS: 'VIEW_REUNION_SUCCESS',
  VIEW_REUNION_ERR: 'VIEW_REUNION_ERR',

  viewReunionBegin: () => {
    return {
      type: actions.VIEW_REUNION_BEGIN,
    };
  },

  viewReunionSuccess: (data) => {
    return {
      type: actions.VIEW_REUNION_SUCCESS,
      data,
    };
  },

  viewReunionErr: (err) => {
    return {
      type: actions.VIEW_REUNION_ERR,
      err,
    };
  },
};

export default actions;
