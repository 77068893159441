import React from 'react';
import CircularChart from '../CircularChart';
import { Cards } from '../../../components/cards/frame/cards-frame';

function TotalPaiment({ values }) {
  return (
    <>
      {
        <Cards headless>
          <div
            style={{
              height: '27vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              rowGap:"30px",
              padding: '0 20px 10px 20px',
            }}
          >
            <div
              style={{
                fontSize: '18px',
                fontWeight: '500',
                marginBottom: '10px',
                color: '#333',
              }}
            >
              Total paiement par mois
            </div>

            <CircularChart values={values} />
          </div>
        </Cards>
      }
    </>
  );
}

export default TotalPaiment;
