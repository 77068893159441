import { Row, Col, Modal, Form, Input } from 'antd';
import { BasicFormWrapper, Main } from '../styled';
import { PricingCard, Badge } from './Style';
import Heading from '../../components/heading/heading';
import { Button } from '../../components/buttons/buttons';
import UilTrash from '@iconscout/react-unicons/icons/uil-trash-alt';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { updateFraisData, viewFraisData, deleteFraisData } from '../../redux/frais/actionCreator';
import { ReunionFormStyleWrap } from '../absence_eleve/Style';
import { ExclamationCircleFilled } from '@ant-design/icons';

function AutreTranches() {
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const dispatch = useDispatch();
  const [openAddModel, setOpenAddModel] = useState(false);
  const { frais } = useSelector((state) => {
    return {
      frais: state.FraisReducer.data,
    };
  });
  const showAddModal = () => {
    setOpenAddModel(true);
  };

  const handleAddCancel = () => {
    setOpenAddModel(false);
  };

  const addSuccess = () => {
    handleAddCancel();
  };

  const handleDelete = (index) => {
    const updatedAutreFrais = frais[3].autreFrais.filter((_, i) => i !== index);
    const updatedData = frais.map((item, i) => (i === 3 ? { ...item, autreFrais: updatedAutreFrais } : item));
    dispatch(deleteFraisData({ listeFrais: updatedData }));
  };

  const showConfirm = (data) => {
    confirm({
      title: 'Voulez-vous supprimer la tranche ?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      cancelButtonProps: {
        type: 'white',
      },
      onOk() {
        handleDelete(data);
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    dispatch(viewFraisData());
  }, []);

  const handleSubmit = (values) => {
    const newItem = { [values.tranche]: parseInt(values.montant) };
    const updatedAutreFrais = [...frais[3].autreFrais, newItem];
    const updatedData = frais.map((item, index) => (index === 3 ? { autreFrais: updatedAutreFrais } : item));
    dispatch(updateFraisData({ listeFrais: updatedData }, addSuccess));
  };

  useEffect(() => {
  }, [frais]);
  return (
    <>
      <Modal
        className="edulink-event-form"
        footer={null}
        type="primary"
        title={'Ajouter une tranche'}
        open={openAddModel}
        onCancel={handleAddCancel}
      >
        <BasicFormWrapper>
          <ReunionFormStyleWrap>
            <Form form={form} name="addReunion" onFinish={handleSubmit}>
              <div className="edulink-event-form-input">
                <span className="edulink-event-form-label">Tranche</span>
                <Form.Item name="tranche" rules={[{ required: true, message: 'Matiere de reunion est obligatoire !' }]}>
                  <Input placeholder="Saisir le nom du tranche" />
                </Form.Item>
              </div>
              <div className="edulink-event-form-input">
                <span className="edulink-event-form-label">Montant</span>
                <Form.Item name="montant" rules={[{ required: true, message: 'Matiere de reunion est obligatoire !' }]}>
                  <Input placeholder="Saisir le montant du tranche" />
                </Form.Item>
              </div>
              {!frais && (
                <>
                  <div className="edulink-event-form-input">
                    <span className="edulink-event-form-label">Transport</span>
                    <Form.Item
                      name="fraisTransport"
                      rules={[{ required: true, message: 'Frais Transport est obligatoire !' }]}
                    >
                      <Input placeholder="Choisir une montant" />
                    </Form.Item>
                  </div>
                  <div className="edulink-event-form-input">
                    <span className="edulink-event-form-label">Cantine</span>
                    <Form.Item
                      name="fraisCantine"
                      rules={[{ required: true, message: 'Frais Cantine est obligatoire !' }]}
                    >
                      <Input placeholder="Choisir une montant" />
                    </Form.Item>
                  </div>
                  <div className="edulink-event-form-input">
                    <span className="edulink-event-form-label">Cantine</span>
                    <Form.Item
                      name="fraisScolarite"
                      rules={[{ required: true, message: 'Frais Scolarite est obligatoire !' }]}
                    >
                      <Input placeholder="Choisir une montant" />
                    </Form.Item>
                  </div>
                  <div className="edulink-event-form-input">
                    <span className="edulink-event-form-label">Mensuelle</span>
                    <Form.Item
                      name="fraisMensuelle"
                      rules={[{ required: true, message: 'Frais Mensuelle est obligatoire !' }]}
                    >
                      <Input placeholder="Choisir une montant" />
                    </Form.Item>
                  </div>
                </>
              )}

              <div className="add-event-footer text-right">
                <Button htmlType="submit" className="btn-save" type="primary">
                  Enregistrer
                </Button>
              </div>
            </Form>
          </ReunionFormStyleWrap>
        </BasicFormWrapper>
      </Modal>
      <Main>
        <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '20px' }}>
          <Button key="1" type="danger" size="default" style={{ borderRadius: '17px' }} onClick={showAddModal}>
            Ajouter une tranche
          </Button>
        </div>
        <Row gutter={25}>
          {frais &&
            frais[3].autreFrais.map((charge, index) => {
              const key = Object.keys(charge)[0];
              const value = charge[key];
              return (
                <Col xxl={8} lg={8} sm={12} xs={24}>
                  <PricingCard style={{ marginBottom: 30 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Badge className="pricing-badge" type="primary">
                        {key}
                      </Badge>
                      <div style={{ display: 'flex', columnGap: '5px' }}>
                        <UilTrash size={17} color={'black'} onClick={() => showConfirm(index)} />
                      </div>
                    </div>
                    <Heading className="price-amount" as="h3">
                      <sup className="currency"></sup>
                      {value} DT <sub className="pricing-validity">par tranche</sub>
                    </Heading>
                  </PricingCard>
                </Col>
              );
            })}
        </Row>
      </Main>
    </>
  );
}

export default AutreTranches;
