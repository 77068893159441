import { message } from 'antd';
import actions from './actions';

const initialState = {
  arrayData: [],
  singleData: null,
  loading: false,
  error: null,
};

const {
  VIEW_USER_BEGIN,
  VIEW_USER_SUCCESS,
  VIEW_USER_ERR,

  VIEW_SINGLE_USER_BEGIN,
  VIEW_SINGLE_USER_SUCCESS,
  VIEW_SINGLE_USER_ERR,

  ADD_USER_BEGIN,
  ADD_USER_SUCCESS,
  ADD_USER_ERR,

  DELETE_USER_BEGIN,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERR,

  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERR,
} = actions;

const UserReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        arrayData: state.arrayData.map((item) => (item.id === data.id ? data : item)),
        loading: false,
      };
    case UPDATE_USER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_USER_SUCCESS:
      return {
        ...state,
        arrayData: data,
        loading: false,
      };
    case VIEW_USER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_SINGLE_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_SINGLE_USER_SUCCESS:
      return {
        ...state,
        singleData: data,
        loading: false,
      };
    case VIEW_SINGLE_USER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_USER_SUCCESS:
      const arrayData = state.arrayData.filter((item) => item.id !== data);
      return {
        ...state,
        arrayData,
        loading: false,
      };
    case DELETE_USER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        arrayData: [...state.arrayData, data],
        loading: false,
      };
    case ADD_USER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default UserReducer;
