import React, { useEffect, useState } from 'react';
import { Row, Col, Modal } from 'antd';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import DataTable from '../../components/table/DataTable';
import { Link } from 'react-router-dom';
import Heading from '../../components/heading/heading';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import UilTrashAlt from '@iconscout/react-unicons/icons/uil-trash-alt';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { addUserData, deleteUserData, updateUserData, viewUserData } from '../../redux/superadmin/user/actionCreator';
import UserForm from './UserForm';

function User() {
  const { confirm } = Modal;
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [openAddModel, setOpenAddModel] = useState(false);
  const [selectedUser, setSlectedUser] = useState();
  useEffect(() => {
    dispatch(viewUserData());
  }, []);

  const { users } = useSelector((state) => {
    return {
      users: state.UserReducer.arrayData,
    };
  });

  const handleDelete = (id) => {
    dispatch(deleteUserData(id));
  };

  const showConfirm = (id) => {
    confirm({
      title: "Voulez-vous supprimer l'utilisateur ?",
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      cancelButtonProps: {
        type: 'white',
      },
      onOk() {
        handleDelete(id);
      },
      onCancel() {},
    });
  };

  const showAddModal = (user) => {
    if (user) {
      setSlectedUser(user);
    }
    setOpenAddModel(true);
  };

  const handleAddCancel = () => {
    setSlectedUser(null);
    setOpenAddModel(false);
  };

  const addSuccess = () => {
    handleAddCancel();
  };

  const updateSuccess = () => {
    handleAddCancel();
  };

  const addUser = (values) => {
    dispatch(addUserData(values, addSuccess));
  };
  const updateUser = (values) => {
    dispatch(updateUserData(values, updateSuccess));
  };

  useEffect(() => {
    const localData = [];

    users.map((element) => {
      localData.push({
        ...element,
        status:
          element.status == 1 ? (
            <span className={`status-text active`}>active</span>
          ) : (
            <span className={`status-text blocked`}>inactive</span>
          ),
        organisationInfo: (
          <div style={{ fontSize: '.8rem' }}>
            <div>{element.organisation}</div>
            <div>{element.adresse}</div>
            <div>{element.status ? 'Active' : 'Inactive'}</div>
            <div>{element.numTel}</div>
            <div>{element.email}</div>
          </div>
        ),
        action: (
          <div className="table-actions">
            <Link className="edit" to="#" onClick={() => showAddModal(element)}>
              <UilEdit />
            </Link>
            <Link
              className="delete"
              to="#"
              onClick={() => {
                showConfirm(element.id);
              }}
            >
              <UilTrashAlt />
            </Link>
          </div>
        ),
      });
    });
    setData(localData);
  }, [users]);

  const dataTableColumn = [
    {
      title: 'organisation',
      dataIndex: 'organisation',
      key: 'organisation',
    },
    {
      title: 'Telephone',
      dataIndex: 'numTel',
      key: 'numTel',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Adresse',
      dataIndex: 'adresse',
      key: 'adresse',
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '60px',
    },
  ];

  const responsiveDataTableColumn = [
    {
      title: 'Organisation',
      dataIndex: 'organisationInfo',
      key: 'organisationInfo',
      width: '250px',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '60px',
    },
  ];

  return (
    <>
      <Modal
        className="edulink-event-form"
        footer={null}
        type="primary"
        title={'Admin'}
        open={openAddModel}
        onCancel={handleAddCancel}
      >
        <UserForm userData={selectedUser} onHandleAddEvent={selectedUser ? updateUser : addUser} />
      </Modal>
      <Main>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
            <Cards headless>
              <DataTable
                filterOption
                filterOnchange
                buttonName="Ajouter User"
                tableData={data}
                btnAction={() => showAddModal()}
                columns={window.innerWidth < 990 ? responsiveDataTableColumn : dataTableColumn}
              />
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default User;
