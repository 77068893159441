import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  addExerciceBegin,
  addExerciceSuccess,
  addExerciceErr,
  updateExerciceBegin,
  updateExerciceSuccess,
  updateExerciceErr,
  viewExerciceBegin,
  viewExerciceSuccess,
  viewExerciceErr,
  viewSingleExerciceBegin,
  viewSingleExerciceSuccess,
  viewSingleExerciceErr,
  deleteExerciceBegin,
  deleteExerciceSuccess,
  deleteExerciceErr,
} = actions;

const viewExerciceData = () => {
  return async (dispatch) => {
    dispatch(viewExerciceBegin());
    try {
      const response = await DataService.get('/exercice/view');

      if (response.status === 200) {
        dispatch(viewExerciceSuccess(response.data.payload));
      } else {
        dispatch(viewExerciceErr('err'));
      }
    } catch (err) {
      dispatch(viewExerciceErr('err'));
    }
  };
};

const viewSingleExerciceData = (id) => {
  return async (dispatch) => {
    dispatch(viewSingleExerciceBegin());
    try {
      const response = await DataService.get(`/exercice/singleInfo/${id}`);

      if (response.status === 200) {
        dispatch(viewSingleExerciceSuccess(response.data.payload));
      } else {
        dispatch(viewSingleExerciceErr('err'));
      }
    } catch (err) {
      dispatch(viewSingleExerciceErr('err'));
    }
  };
};

const addExerciceData = (data, callback) => {
  return async (dispatch) => {
    dispatch(addExerciceBegin());
    try {
      const response = await DataService.post('/exercice/add', data, { 'Content-Type': 'multipart/form-data' });
      if (response.status === 201) {
        dispatch(addExerciceSuccess(response.data.payload));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(addExerciceErr('err'));
        
      }
    } catch (err) {
      dispatch(addExerciceErr('err'));
      
    }
  };
};
const deleteExerciceData = (id) => {
  return async (dispatch) => {
    dispatch(deleteExerciceBegin());
    try {
      const response = await DataService.delete(`/exercice/delete/${id}`);
      if (response.status === 200) {
        dispatch(deleteExerciceSuccess(id));
        message.success(response.data.message);
      } else {
        dispatch(deleteExerciceErr('err'));
        
      }
    } catch (err) {
      dispatch(deleteExerciceErr('err'));
      
    }
  };
};
const updateExerciceData = (id, data, callback) => {
  return async (dispatch) => {
    dispatch(updateExerciceBegin());
    try {
      const response = await DataService.put(`/exercice/updated/${id}`, data);
      if (response.status === 200) {
        dispatch(updateExerciceSuccess(id));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(updateExerciceErr('err'));
        
      }
    } catch (err) {
      dispatch(updateExerciceErr('err'));
    }
  };
};

export { viewExerciceData, viewSingleExerciceData, updateExerciceData, deleteExerciceData, addExerciceData };
