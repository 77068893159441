import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../../config/dataService/dataService';

const {
  addNoteBegin,
  addNoteSuccess,
  addNoteErr,
  updateNoteBegin,
  updateNoteSuccess,
  updateNoteErr,
  viewNoteBegin,
  viewNoteSuccess,
  viewNoteErr,
  viewBulletinBegin,
  viewBulletinSuccess,
  viewBulletinErr,
} = actions;

const viewBulletinData = (id) => {
  return async (dispatch) => {
    dispatch(viewBulletinBegin());
    try {
      const response = await DataService.get(`/bulletinP/moyenneEtudiant/${id}`);
      if (response.status === 200) {
        dispatch(viewBulletinSuccess(response.data));
      } else {
        dispatch(viewBulletinErr('err'));
      }
    } catch (err) {
      dispatch(viewBulletinErr('err'));
    }
  };
};

const viewNoteData = (id) => {
  return async (dispatch) => {
    dispatch(viewNoteBegin());
    try {
      const response = await DataService.get(`/bulletinP/viewClasse/${id}`);
      if (response.status === 200) {
        dispatch(viewNoteSuccess(response.data.payload));
      } else {
        dispatch(viewNoteErr('err'));
      }
    } catch (err) {
      dispatch(viewNoteErr('err'));
    }
  };
};

const addNoteData = (data) => {
  return async (dispatch) => {
    dispatch(addNoteBegin());
    try {
      const response = await DataService.post('/bulletinP/add', data);

      if (response.status === 200) {
        dispatch(addNoteSuccess(response.data.payload));
        message.success(response.data.message);
      } else {
        dispatch(addNoteErr('err'));
        
      }
    } catch (err) {
      dispatch(addNoteErr('err'));
      
    }
  };
};
const updateNoteData = (data) => {
  return async (dispatch) => {
    dispatch(updateNoteBegin());
    try {
      const response = await DataService.put(`/bulletinP/updated`, data);
      if (response.status === 200) {
        dispatch(updateNoteSuccess(response.data.payload));
        message.success(response.data.message);
      } else {
        dispatch(updateNoteErr('err'));
        
      }
    } catch (err) {
      dispatch(updateNoteErr('err'));
      
    }
  };
};

export { viewNoteData, updateNoteData, addNoteData, viewBulletinData };
