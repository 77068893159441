const actions = {
  VIEW_PERSONNEL_BEGIN: 'VIEW_PERSONNEL_BEGIN',
  VIEW_PERSONNEL_SUCCESS: 'VIEW_PERSONNEL_SUCCESS',
  VIEW_PERSONNEL_ERR: 'VIEW_PERSONNEL_ERR',

  VIEW_SINGLE_PERSONNEL_BEGIN: 'VIEW_SINGLE_PERSONNEL_BEGIN',
  VIEW_SINGLE_PERSONNEL_SUCCESS: 'VIEW_SINGLE_PERSONNEL_SUCCESS',
  VIEW_SINGLE_PERSONNEL_ERR: 'VIEW_SINGLE_PERSONNEL_ERR',

  ADD_PERSONNEL_BEGIN: 'ADD_PERSONNEL_BEGIN',
  ADD_PERSONNEL_SUCCESS: 'ADD_PERSONNEL_SUCCESS',
  ADD_PERSONNEL_ERR: 'ADD_PERSONNEL_ERR',

  DELETE_PERSONNEL_BEGIN: 'DELETE_PERSONNEL_BEGIN',
  DELETE_PERSONNEL_SUCCESS: 'DELETE_PERSONNEL_SUCCESS',
  DELETE_PERSONNEL_ERR: 'DELETE_PERSONNEL_ERR',

  UPDATE_PERSONNEL_BEGIN: 'UPDATE_PERSONNEL_BEGIN',
  UPDATE_PERSONNEL_SUCCESS: 'UPDATE_PERSONNEL_SUCCESS',
  UPDATE_PERSONNEL_ERR: 'UPDATE_PERSONNEL_ERR',

  viewPersonnelBegin: () => {
    return {
      type: actions.VIEW_PERSONNEL_BEGIN,
    };
  },

  viewPersonnelSuccess: (data) => {
    return {
      type: actions.VIEW_PERSONNEL_SUCCESS,
      data,
    };
  },

  viewPersonnelErr: (err) => {
    return {
      type: actions.VIEW_PERSONNEL_ERR,
      err,
    };
  },

  viewSinglePersonnelBegin: () => {
    return {
      type: actions.VIEW_SINGLE_PERSONNEL_BEGIN,
    };
  },

  viewSinglePersonnelSuccess: (data) => {
    return {
      type: actions.VIEW_SINGLE_PERSONNEL_SUCCESS,
      data,
    };
  },

  viewSinglePersonnelErr: (err) => {
    return {
      type: actions.VIEW_SINGLE_PERSONNEL_ERR,
      err,
    };
  },

  updatePersonnelBegin: () => {
    return {
      type: actions.UPDATE_PERSONNEL_BEGIN,
    };
  },

  updatePersonnelSuccess: (data) => {
    return {
      type: actions.UPDATE_PERSONNEL_SUCCESS,
      data,
    };
  },

  updatePersonnelErr: (err) => {
    return {
      type: actions.UPDATE_PERSONNEL_ERR,
      err,
    };
  },
  deletePersonnelBegin: () => {
    return {
      type: actions.DELETE_PERSONNEL_BEGIN,
    };
  },

  deletePersonnelSuccess: (data) => {
    return {
      type: actions.DELETE_PERSONNEL_SUCCESS,
      data,
    };
  },

  deletePersonnelErr: (err) => {
    return {
      type: actions.DELETE_PERSONNEL_ERR,
      err,
    };
  },
  addPersonnelBegin: () => {
    return {
      type: actions.ADD_PERSONNEL_BEGIN,
    };
  },

  addPersonnelSuccess: (data) => {
    return {
      type: actions.ADD_PERSONNEL_SUCCESS,
      data,
    };
  },

  addPersonnelErr: (err) => {
    return {
      type: actions.ADD_PERSONNEL_ERR,
      err,
    };
  },
};

export default actions;
