import React, { useState, useEffect } from 'react';
import { Row, Col, Modal } from 'antd';
import UilPricetagAlt from '@iconscout/react-unicons/icons/uil-pricetag-alt';
import { Main } from '../styled';
import CantineCard from '../../components/cards/CantineCard';
import { viewCantineData } from '../../redux/parent/cantine/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import UilMultiply from '@iconscout/react-unicons/icons/uil-multiply';
import { EventModalStyleWrap } from '../emploi_parent/Style';
import UilCalender from '@iconscout/react-unicons/icons/uil-calender';
import UilClock from '@iconscout/react-unicons/icons/uil-clock';

function ParentCantines() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedCantine, setSlectedCantine] = useState();
  const showModal = (cantine) => {
    if (cantine) {
      setSlectedCantine(cantine);
      setOpen(true);
    }
  };

  const handleCancel = () => {
    setSlectedCantine(null);
    setOpen(false);
  };
  const { cantines } = useSelector((state) => {
    return {
      cantines: state.ParentCantineReducer.data,
    };
  });
  useEffect(() => {
    dispatch(viewCantineData());
  }, []);

  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={handleCancel}
        closable={false}
        footer={null}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {selectedCantine && selectedCantine[0].jour}
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '7px', cursor: 'pointer' }}>
              <UilMultiply size={16} onClick={handleCancel} />
            </div>
          </div>
        }
      >
        <EventModalStyleWrap>
          {selectedCantine &&
            selectedCantine.map((item, index) => (
              <div className="edulink-event-details">
                <ul>
                  <li>
                    <span className="edulink-event-label">Repas {index + 1}:</span>
                    <span className="edulink-event-text">
                      <strong>{item.titre}</strong>
                    </span>
                  </li>
                  <li>
                    <UilClock />
                    <span className="edulink-event-label">Temps:</span>
                    <span className="edulink-event-text">
                      <strong>{formatTime(item.heur)}</strong>
                    </span>
                  </li>
                  <li>
                    <UilPricetagAlt />
                    <span className="edulink-event-label">Prix:</span>
                    <span className="edulink-event-text">
                      <strong>{item.heurF}</strong>
                    </span>
                  </li>
                  <li>
                    <UilClock />

                    <span className="edulink-event-label">Description:</span>
                    <span className="edulink-event-text">
                      <strong>{item.description}</strong>
                    </span>
                  </li>
                </ul>
              </div>
            ))}
        </EventModalStyleWrap>
      </Modal>
      <Main>
        <Row gutter={25} className="mt-sm-10">
          {Object.keys(cantines).map((value, index) => (
            <>
              <CantineCard
                key={index}
                index={index}
                jour={value}
                data={cantines[value]}
                onClick={() => showModal(cantines[value])}
              />
            </>
          ))}
          <Col xs={24}></Col>
        </Row>
      </Main>
    </>
  );
}

export default ParentCantines;
