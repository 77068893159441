import { message } from 'antd';
import actions from './actions';

const initialState = {
  arrayData: [],
  singleData: null,
  loading: false,
  error: null,
};

const {
  VIEW_CANTINE_BEGIN,
  VIEW_CANTINE_SUCCESS,
  VIEW_CANTINE_ERR,

  VIEW_SINGLE_CANTINE_BEGIN,
  VIEW_SINGLE_CANTINE_SUCCESS,
  VIEW_SINGLE_CANTINE_ERR,

  ADD_CANTINE_BEGIN,
  ADD_CANTINE_SUCCESS,
  ADD_CANTINE_ERR,

  DELETE_CANTINE_BEGIN,
  DELETE_CANTINE_SUCCESS,
  DELETE_CANTINE_ERR,

  UPDATE_CANTINE_BEGIN,
  UPDATE_CANTINE_SUCCESS,
  UPDATE_CANTINE_ERR,
} = actions;

const CantineReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_CANTINE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CANTINE_SUCCESS:
      const updatedData = state.arrayData.map((item) => (item.id == data.id ? data : item));
      return {
        ...state,
        arrayData: updatedData,
        loading: false,
      };
    case UPDATE_CANTINE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_CANTINE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_CANTINE_SUCCESS:
      return {
        ...state,
        arrayData: data,
        loading: false,
      };
    case VIEW_CANTINE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_SINGLE_CANTINE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_SINGLE_CANTINE_SUCCESS:
      return {
        ...state,
        singleData: data,
        loading: false,
      };
    case VIEW_SINGLE_CANTINE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_CANTINE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CANTINE_SUCCESS:
      const detetedData = state.arrayData.filter((item) => item.id !== data);
      return {
        ...state,
        arrayData: detetedData,
        loading: false,
      };
    case DELETE_CANTINE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_CANTINE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_CANTINE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_CANTINE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default CantineReducer;
