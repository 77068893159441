const actions = {
  VIEW_PAIMENT_BEGIN: 'VIEW_PAIMENT_BEGIN',
  VIEW_PAIMENT_SUCCESS: 'VIEW_PAIMENT_SUCCESS',
  VIEW_PAIMENT_ERR: 'VIEW_PAIMENT_ERR',

  VIEW_SINGLE_PAIMENT_BEGIN: 'VIEW_SINGLE_PAIMENT_BEGIN',
  VIEW_SINGLE_PAIMENT_SUCCESS: 'VIEW_SINGLE_PAIMENT_SUCCESS',
  VIEW_SINGLE_PAIMENT_ERR: 'VIEW_SINGLE_PAIMENT_ERR',

  ADD_PAIMENT_BEGIN: 'ADD_PAIMENT_BEGIN',
  ADD_PAIMENT_SUCCESS: 'ADD_PAIMENT_SUCCESS',
  ADD_PAIMENT_ERR: 'ADD_PAIMENT_ERR',

  viewPaimentBegin: () => {
    return {
      type: actions.VIEW_PAIMENT_BEGIN,
    };
  },

  viewPaimentSuccess: (data) => {
    return {
      type: actions.VIEW_PAIMENT_SUCCESS,
      data,
    };
  },

  viewPaimentErr: (err) => {
    return {
      type: actions.VIEW_PAIMENT_ERR,
      err,
    };
  },

  viewSinglePaimentBegin: () => {
    return {
      type: actions.VIEW_SINGLE_PAIMENT_BEGIN,
    };
  },

  viewSinglePaimentSuccess: (data) => {
    return {
      type: actions.VIEW_SINGLE_PAIMENT_SUCCESS,
      data,
    };
  },

  viewSinglePaimentErr: (err) => {
    return {
      type: actions.VIEW_SINGLE_PAIMENT_ERR,
      err,
    };
  },
  addPaimentBegin: () => {
    return {
      type: actions.ADD_PAIMENT_BEGIN,
    };
  },

  addPaimentSuccess: (data) => {
    return {
      type: actions.ADD_PAIMENT_SUCCESS,
      data,
    };
  },

  addPaimentErr: (err) => {
    return {
      type: actions.ADD_PAIMENT_ERR,
      err,
    };
  },
};

export default actions;
