import { Row, Col, Modal, Form, Input } from 'antd';
import { BasicFormWrapper, Main } from '../styled';
import { PricingCard, Badge } from './Style';
import Heading from '../../components/heading/heading';
import { Button } from '../../components/buttons/buttons';
import UilTrash from '@iconscout/react-unicons/icons/uil-trash-alt';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { addFraisData, updateFraisData, viewFraisData, deleteFraisData } from '../../redux/frais/actionCreator';
import { ReunionFormStyleWrap } from '../absence_eleve/Style';
import { ExclamationCircleFilled } from '@ant-design/icons';

function Tranches() {
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const dispatch = useDispatch();
  const [openAddModel, setOpenAddModel] = useState(false);
  const { frais } = useSelector((state) => {
    return {
      frais: state.FraisReducer.data,
    };
  });
  const showAddModal = () => {
    setOpenAddModel(true);
  };

  const handleAddCancel = () => {
    setOpenAddModel(false);
  };

  const addSuccess = () => {
    handleAddCancel();
  };

  const handleDelete = (index) => {
    frais.find((obj) => obj.tranche).tranche.splice(index, 1);
    frais.find((obj) => obj.montant).montant.splice(index, 1);
    const data = { listeFrais: frais };
    dispatch(deleteFraisData(data));
  };

  const showConfirm = (data) => {
    confirm({
      title: 'Voulez-vous supprimer la tranche ?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      cancelButtonProps: {
        type: 'white',
      },
      onOk() {
        handleDelete(data);
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    dispatch(viewFraisData());
  }, []);

  const handleSubmit = (values) => {
    if (!frais) {
      let list = [];
      let data = [];
      const { tranche, montant, ...autre } = values;
      data.push({ Methode: 'par tranche' });
      data.push({ tranche: [tranche] });
      data.push({ montant: [parseInt(montant)] });
      for (const [key, value] of Object.entries(autre)) {
        list.push({ [key]: parseInt(value) });
      }
      data.push({ autreFrais: list });
      dispatch(addFraisData({ listeFrais: data }, addSuccess));
    } else {
      let data = frais;
      const tranches = data[1].tranche;
      data[1].tranche = [...tranches, values.tranche];
      const montants = data[2].montant;
      data[2].montant = [...montants, parseInt(values.montant)];
      dispatch(updateFraisData({ listeFrais: data }, addSuccess));
    }
  };

  useEffect(() => {
  }, [frais]);
  return (
    <>
      <Modal
        className="edulink-event-form"
        footer={null}
        type="primary"
        title={'Ajouter une tranche'}
        open={openAddModel}
        onCancel={handleAddCancel}
      >
        <BasicFormWrapper>
          <ReunionFormStyleWrap>
            <Form form={form} name="addReunion" onFinish={handleSubmit}>
              <div className="edulink-event-form-input">
                <span className="edulink-event-form-label">Tranche</span>
                <Form.Item name="tranche" rules={[{ required: true, message: 'Matiere de reunion est obligatoire !' }]}>
                  <Input placeholder="Saisir le nom du tranche" />
                </Form.Item>
              </div>
              <div className="edulink-event-form-input">
                <span className="edulink-event-form-label">Montant</span>
                <Form.Item name="montant" rules={[{ required: true, message: 'Matiere de reunion est obligatoire !' }]}>
                  <Input placeholder="Saisir le montant du tranche" />
                </Form.Item>
              </div>
              {!frais && (
                <>
                  <div className="edulink-event-form-input">
                    <span className="edulink-event-form-label">Transport</span>
                    <Form.Item
                      name="fraisTransport"
                      rules={[{ required: true, message: 'Frais Transport est obligatoire !' }]}
                    >
                      <Input placeholder="Choisir une montant" />
                    </Form.Item>
                  </div>
                  <div className="edulink-event-form-input">
                    <span className="edulink-event-form-label">Cantine</span>
                    <Form.Item
                      name="fraisCantine"
                      rules={[{ required: true, message: 'Frais Cantine est obligatoire !' }]}
                    >
                      <Input placeholder="Choisir une montant" />
                    </Form.Item>
                  </div>
                  <div className="edulink-event-form-input">
                    <span className="edulink-event-form-label">Cantine</span>
                    <Form.Item
                      name="fraisScolarite"
                      rules={[{ required: true, message: 'Frais Scolarite est obligatoire !' }]}
                    >
                      <Input placeholder="Choisir une montant" />
                    </Form.Item>
                  </div>
                  <div className="edulink-event-form-input">
                    <span className="edulink-event-form-label">Mensuelle</span>
                    <Form.Item
                      name="fraisMensuelle"
                      rules={[{ required: true, message: 'Frais Mensuelle est obligatoire !' }]}
                    >
                      <Input placeholder="Choisir une montant" />
                    </Form.Item>
                  </div>
                </>
              )}

              <div className="add-event-footer text-right">
                <Button htmlType="submit" className="btn-save" type="primary">
                  Enregistrer
                </Button>
              </div>
            </Form>
          </ReunionFormStyleWrap>
        </BasicFormWrapper>
      </Modal>
      <Main>
        <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '20px' }}>
          <Button key="1" type="danger" size="default" style={{ borderRadius: '17px' }} onClick={showAddModal}>
            Ajouter une tranche
          </Button>
        </div>
        <Row gutter={25}>
          {frais &&
            frais[1].tranche.map((item, index) => (
              <Col xxl={8} lg={8} sm={12} xs={24}>
                <PricingCard style={{ marginBottom: 30 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Badge className="pricing-badge" type="primary">
                      {item}
                    </Badge>
                    <div style={{ display: 'flex', columnGap: '5px' }}>
                      <UilTrash size={17} color={'black'} onClick={() => showConfirm(index)} />
                    </div>
                  </div>
                  <Heading className="price-amount" as="h3">
                    <sup className="currency"></sup>
                    {frais[2].montant[index]} DT <sub className="pricing-validity">par tranche</sub>
                  </Heading>
                </PricingCard>
              </Col>
            ))}
        </Row>
      </Main>
    </>
  );
}

export default Tranches;
