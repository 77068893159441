import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const Chat = lazy(() => import('../../container/chat/ChatApp'));


function PagesRoute() {
  return (
    <Routes>
      <Route path='*' element={<Chat />} />
    </Routes>
  );
}

export default PagesRoute;
