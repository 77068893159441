import React, { useState, useEffect } from 'react';
import { Row, Col, Pagination, Modal } from 'antd';
import UilPricetagAlt from '@iconscout/react-unicons/icons/uil-pricetag-alt';
import { Main } from '../styled';
import EventCard from '../../components/cards/EventCard';
import { viewAllEventData, viewEventData } from '../../redux/parent/event/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import UilMultiply from '@iconscout/react-unicons/icons/uil-multiply';
import { EventModalStyleWrap } from '../emploi_parent/Style';
import UilCalender from '@iconscout/react-unicons/icons/uil-calender';
import UilClock from '@iconscout/react-unicons/icons/uil-clock';

function ParentEvents() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedClub, setSlectedClub] = useState();
  const showModal = (event) => {
    const [date, heur] = event.dateHeur.split(' ');
    event.date = date;
    event.heur = heur;
    setSlectedClub(event);
    setOpen(true);
  };

  const handleCancel = () => {
    setSlectedClub(null);
    setOpen(false);
  };
  const { events } = useSelector((state) => {
    return {
      events: state.ParentEventReducer.data,
    };
  });

  useEffect(() => {
    dispatch(viewAllEventData());
  }, []);

  const [state, setState] = useState({
    courses: events,
    current: 0,
    pageSize: 0,
  });

  const onShowSizeChange = (current, pageSize) => {
    setState({ ...state, current, pageSize });
  };

  const onHandleChange = (current, pageSize) => {
    setState({ ...state, current, pageSize });
  };
  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
  };
  return (
    <>
      <Modal
        open={open}
        onCancel={handleCancel}
        closable={false}
        footer={null}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {selectedClub && `Titre : ${selectedClub.titre}`}
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '7px', cursor: 'pointer' }}>
              <UilMultiply size={16} onClick={handleCancel} />
            </div>
          </div>
        }
      >
        <EventModalStyleWrap>
          {selectedClub && (
            <div className="edulink-event-details">
              <ul>
                <li>
                  <UilCalender />
                  <span className="edulink-event-label">Date:</span>
                  <span className="edulink-event-text">
                    <strong>{selectedClub.date}</strong>
                  </span>
                </li>
                <li>
                  <UilClock />
                  <span className="edulink-event-label">Temps:</span>
                  <span className="edulink-event-text">
                    <strong>{formatTime(selectedClub.heur)}</strong>
                  </span>
                </li>
                <li>
                  <UilPricetagAlt />
                  <span className="edulink-event-label">Prix:</span>
                  <span className="edulink-event-text">
                    <strong>{selectedClub.frais}</strong>
                  </span>
                </li>
                <li>
                  <span className="edulink-event-text">
                    <strong>{selectedClub.description}</strong>
                  </span>
                </li>
              </ul>
            </div>
          )}
        </EventModalStyleWrap>
      </Modal>
      <Main>
        <Row gutter={25} className="mt-sm-10">
          {events.map((value, index) => (
            <EventCard key={index} data={value} onClick={() => showModal(value)} />
          ))}
          <Col xs={24}></Col>
        </Row>
      </Main>
    </>
  );
}

export default ParentEvents;
