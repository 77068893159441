import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../../config/dataService/dataService';

const { viewCantineBegin, viewCantineSuccess, viewCantineErr } = actions;

const viewCantineData = () => {
  return async (dispatch) => {
    dispatch(viewCantineBegin());
    try {
      const today = new Date();
      const start_date = today.toISOString().split('T')[0];

      const end_date = new Date();
      end_date.setDate(today.getDate() + 7);
      const formatted_end_date = end_date.toISOString().split('T')[0];
      const response = await DataService.get(`cantines/index?start_date=${start_date}&end_date=${formatted_end_date}`);

      if (response.status === 200) {
        const groupedItems = response.data.payload.reduce((acc, item) => {
          if (!acc[item.date]) {
            acc[item.date] = [];
          }
          acc[item.date].push(item);
          return acc;
        }, {});

        dispatch(viewCantineSuccess(groupedItems));
      } else {
        dispatch(viewCantineErr('err'));
      }
    } catch (err) {
      dispatch(viewCantineErr('err'));
    }
  };
};

export { viewCantineData };
