import { Card, Col, Modal, notification } from 'antd';
import React, { useRef, useState } from 'react';
import { CourseCardWrap } from './Style';
import UilPrint from '@iconscout/react-unicons/icons/uil-print';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { addPaimentData } from '../../redux/paiment/actionCreator';
import { useDispatch } from 'react-redux';
import ClubTranches from './ClubTranches';
import { Badge } from '../../container/pages/style';

function AttachedClubCard({ data, id }) {
  const ref = useRef(null);
  const { confirm } = Modal;
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    if (data.ListTranche) {
      setIsOpen(true);
    } else {
      if (!data.paid) {
        showConfirm();
      }
    }
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const dispatch = useDispatch();

  const addPaiment = () => {
    dispatch(addPaimentData({ etudiant_id: id, club_id: data.id }));
  };

  const showConfirm = () => {
    confirm({
      title: 'Voulez-vous payer la tranche ?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Payer',
      okType: 'danger',
      cancelText: 'Annuler',
      cancelButtonProps: {
        type: 'white',
      },
      onOk() {
        addPaiment();
      },
      onCancel() {},
    });
  };

  return (
    <Col xxl={8} lg={12} sm={12} xs={24}>
      <ClubTranches open={isOpen} handleClose={handleClose} data={data.ListTranche} etudiant={id} club={data.id} />
      <CourseCardWrap className="edulink-course-card-single" onClick={handleOpen}>
        <Card bordered={false}>
          <div className="edulink-course-card-thumbnail">
            <img
              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${data.image}`}
              style={{ borderRadius: '10px', height: '180px', width: '100%', objectFit: 'cover' }}
            />
          </div>
          <div className="edulink-course-card-content">
            <div className="edulink-course-card-meta">
              <div className="edulink-course-card-meta__left">
                <span className="edulink-course-card-meta__pricing" style={{ color: 'black' }}>
                  {data.titre}{' '}
                  {!data.ListTranche ? (
                    <Badge className="pricing-badge" type={data.paid ? 'success' : 'danger'}>
                      {data.frais} DT
                    </Badge>
                  ) : (
                    <Badge className="pricing-badge" type={'primary'}>
                      {data.frais} DT
                    </Badge>
                  )}
                </span>
              </div>
            </div>
          </div>
        </Card>
      </CourseCardWrap>
    </Col>
  );
}

export default AttachedClubCard;
