import UilMultiply from '@iconscout/react-unicons/icons/uil-multiply';
import { Col, Form, Input, Modal, Row, Select, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button } from '../../components/buttons/buttons';
import { addClasseData, updateClasseData } from '../../redux/classe/actionCreator';
import { useDispatch } from 'react-redux';
import { ClassFormStyleWrap } from './style';

function ClassForm({ open, closeModal, title, data }) {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    titre: '',
    niveau: '',
    numéro: '1',
  });
  useEffect(() => {
    if (data != null) {
      setForm({
        titre: data.titre,
        niveau: data.niveau,
        numéro: data.numéro,
        id: data.id,
      });
    }
  }, [data]);
  const handleChange = (key, value) => {
    setForm((form) => {
      return { ...form, [key]: value };
    });
  };

  const addSuccess = () => {
    closeModal();
  };
  const updateSuccess = () => {
    closeModal();
  };

  const submit = () => {
    const submittedData = { classe: form.niveau + form.titre };
    if (data) {
      dispatch(updateClasseData(data.id, submittedData, updateSuccess));
    } else {
      dispatch(addClasseData(submittedData, addSuccess));
    }
  };
  return (
    <Modal
      open={open}
      onCancel={closeModal}
      closable={false}
      footer={null}
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {title}
          <div style={{ display: 'flex', alignItems: 'center', columnGap: '7px', cursor: 'pointer' }}>
            <UilMultiply size={16} onClick={closeModal} />
          </div>
        </div>
      }
    >
      <ClassFormStyleWrap>
        {' '}
        <Row gutter={30}>
          <Col md={24} xs={24}>
            <h4>Niveau :</h4>
            <Form.Item rules={[{ required: true, message: 'Date est obligatoire!' }]}>
              <Select
                defaultValue={form.niveau}
                value={form.niveau}
                onChange={(e) => handleChange('niveau', e)}
                placeholder="Choisir le niveau"
                options={[
                  { value: '1', label: 'niveau 1' },
                  { value: '2', label: 'niveau 2' },
                  { value: '3', label: 'niveau 3' },
                  { value: '4', label: 'niveau 4' },
                  { value: '5', label: 'niveau 5' },
                  { value: '6', label: 'niveau 6' },
                ]}
              />
            </Form.Item>
          </Col>
          <Col md={24} xs={24}>
            <h4>Nom du classe :</h4>
            <Form.Item rules={[{ required: true, message: 'Nom du classe est obligatoire!' }]}>
              <Input
                placeholder="Nom du classe"
                value={form.titre}
                onChange={(e) => handleChange('titre', e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col md={13} xs={12}></Col>
          <Col md={11} xs={12}>
            <Button key="back" onClick={closeModal}>
              Cancel
            </Button>
            <Button key="submit" type="danger" onClick={submit}>
              Confirmer
            </Button>
          </Col>
        </Row>
      </ClassFormStyleWrap>
    </Modal>
  );
}

export default ClassForm;
