import { UilCircle } from '@iconscout/react-unicons';

import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';

function MenueItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { role } = useSelector((state) => {
    return {
      role: state.auth.role,
    };
  });

  const path = `/${role}`;

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState([`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`]);

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items =
    role == 'admin'
      ? [
          getItem(t('Statistiques'), 'statistques', <UilCircle />, [
            getItem(
              <NavLink onClick={toggleCollapsed} to={`${path}/statistics/inscriptions`}>
                {t('Generales')}
              </NavLink>,
              'General',
            ),
            getItem(
              <NavLink onClick={toggleCollapsed} to={`${path}/statistics/clubs`}>
                {t('clubs')}
              </NavLink>,
              'clubsstates',
            ),
            getItem(
              <NavLink onClick={toggleCollapsed} to={`${path}/statistics/moyennes`}>
                {t('Moyennes')}
              </NavLink>,
              'moyennes',
            ),
          ]),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/clubs`}>
              {t('Clubs & Activités')}
            </NavLink>,
            'clubs',
            <NavLink className="menuItem-iocn" to={`${path}/clubs`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/events`}>
              {t('Evènements')}
            </NavLink>,
            'events',
            <NavLink className="menuItem-iocn" to={`${path}/events`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/actualite`}>
              {t('actualite')}
            </NavLink>,
            'actualite',
            <NavLink className="menuItem-iocn" to={`${path}/actualite`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/inscription`}>
              {t('inscription')}
            </NavLink>,
            'inscription',
            <NavLink className="menuItem-iocn" to={`${path}/inscription`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/cantine`}>
              {t('cantine')}
            </NavLink>,
            'cantine',
            <NavLink className="menuItem-iocn" to={`${path}/cantine`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/classes`}>
              {t('Classes')}
            </NavLink>,
            'classes',
            <NavLink className="menuItem-iocn" to={`${path}/classes`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/matiere`}>
              {t('matieres')}
            </NavLink>,
            'matiere',
            <NavLink className="menuItem-iocn" to={`${path}/matiere`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/emploi`}>
              {t('emploi du temps')}
            </NavLink>,
            'emploi',
            <NavLink className="menuItem-iocn" to={`${path}/emploi`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/absenceeleve`}>
              {t('absence elève')}
            </NavLink>,
            'absenceeleve',
            <NavLink className="menuItem-iocn" to={`${path}/absenceeleve`}>
              <UilCircle />
            </NavLink>,
          ),

          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/reunion`}>
              {t('Réunion')}
            </NavLink>,
            'reunion',
            <NavLink className="menuItem-iocn" to={`${path}/reunion`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/absenceprofs`}>
              {t('absence profs')}
            </NavLink>,
            'absenceprofs',
            <NavLink className="menuItem-iocn" to={`${path}/absenceprofs`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/notes`}>
              {t('notes')}
            </NavLink>,
            'notes',
            <NavLink className="menuItem-iocn" to={`${path}/notes`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/bulletin`}>
              {t('bulletins')}
            </NavLink>,
            'bulletin',
            <NavLink className="menuItem-iocn" to={`${path}/bulletin`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/paymentmethods`}>
              {t('methodes de payment')}
            </NavLink>,
            'paymentmethods',
            <NavLink className="menuItem-iocn" to={`${path}/paymentmethods`}>
              <UilCircle />
            </NavLink>,
          ),

          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/personnel`}>
              {t('personnel')}
            </NavLink>,
            'personnel',
            <NavLink className="menuItem-iocn" to={`${path}/personnel`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/charge`}>
              {t('charge')}
            </NavLink>,
            'charge',
            <NavLink className="menuItem-iocn" to={`${path}/charge`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/typecharge`}>
              {t('Type Charge')}
            </NavLink>,
            'Type Charge',
            <NavLink className="menuItem-iocn" to={`${path}/typecharge`}>
              <UilCircle />
            </NavLink>,
          ),
        ]
      : role == 'parents'
      ? [
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/profile/timeline`}>
              {t('profil')}
            </NavLink>,
            'profile',
            <NavLink className="menuItem-iocn" to={`${path}/profile/timeline`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/calendar`}>
              {t('calendrier')}
            </NavLink>,
            'calendar',
            <NavLink className="menuItem-iocn" to={`${path}/calendar`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/emploi`}>
              {t('emploi')}
            </NavLink>,
            'emploi',
            <NavLink className="menuItem-iocn" to={`${path}/emploi`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/clubs`}>
              {t('clubs')}
            </NavLink>,
            'clubs',
            <NavLink className="menuItem-iocn" to={`${path}/clubs`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/events`}>
              {t('events')}
            </NavLink>,
            'events',
            <NavLink className="menuItem-iocn" to={`${path}/events`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/cantine`}>
              {t('cantine')}
            </NavLink>,
            'cantine',
            <NavLink className="menuItem-iocn" to={`${path}/cantine`}>
              <UilCircle />
            </NavLink>,
          ),
        ]
      : role == 'professeur'
      ? [
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}`}>
              {t('absence elève')}
            </NavLink>,
            'absenceeleve',
            <NavLink className="menuItem-iocn" to={`${path}`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/exercices`}>
              {t('exercices')}
            </NavLink>,
            'exercices',
            <NavLink className="menuItem-iocn" to={`${path}/exercices`}>
              <UilCircle />
            </NavLink>,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/bulletin`}>
              {t('bulletins')}
            </NavLink>,
            'bulletin',
            <NavLink className="menuItem-iocn" to={`${path}/bulletin`}>
              <UilCircle />
            </NavLink>,
          ),

          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/notes`}>
              {t('notes')}
            </NavLink>,
            'notes',
            <NavLink className="menuItem-iocn" to={`${path}/notes`}>
              <UilCircle />
            </NavLink>,
          ),
        ]
      : [
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/`}>
              {t('admins')}
            </NavLink>,
            'admins',
            <NavLink className="menuItem-iocn" to={`${path}/`}>
              <UilCircle />
            </NavLink>,
          ),
        ];

  return (
    <>
      <img src={require(`../static/img/logo.png`)} style={{ width: '150px', margin: ' 0 0 20px 15px' }} />{' '}
      <Menu
        onOpenChange={onOpenChange}
        onClick={onClick}
        mode={'inline'}
        defaultSelectedKeys={[
          `${mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]}`,
        ]}
        defaultOpenKeys={[`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`]}
        overflowedIndicator={<UilEllipsisV />}
        openKeys={openKeys}
        items={items}
      />
    </>
  );
}

MenueItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenueItems;
