import React, { useState } from 'react';
import UilEnvelope from '@iconscout/react-unicons/icons/uil-envelope';
import UilClipboardNotes from '@iconscout/react-unicons/icons/uil-clipboard-notes';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row, Modal, Form, Input } from 'antd';
import { UserCard } from '../style';
import Heading from '../../../components/heading/heading';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Button } from '../../../components/buttons/buttons';

function BulletinCard({ user }) {
  const navigate = useNavigate();

  return (
    <>
      <UserCard>
        <div className="card user-card">
          <Cards headless>
            <figure></figure>
            <figcaption>
              <div className="card__content">
                <img
                  src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png"
                  height={100}
                  style={{ marginBottom: '10px' }}
                />
                <Heading className="card__name" as="h6">
                  <Link to="#">
                    {user.prenom} {user.nom}
                  </Link>
                </Heading>
              </div>
              <Link to={`details/${user.id}`} state={{ inscription: { ...user } }}>
                <div className="card__actions">
                  <Button size="default" type="white" onClick={() => navigate(`details/${user.id}`)} >
                    <UilClipboardNotes />
                    Afficher bulletin
                  </Button>
                </div>{' '}
              </Link>
            </figcaption>
          </Cards>
        </div>
      </UserCard>
    </>
  );
}

export default BulletinCard;
