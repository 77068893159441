import actions from './actions';
import { DataService } from '../../../config/dataService/dataService';

const { viewMatiereBegin, viewMatiereSuccess, viewMatiereErr } = actions;

const viewMatiereData = () => {
  return async (dispatch) => {
    dispatch(viewMatiereBegin());
    try {
      const response = await DataService.get('/matieresProf/get');
      if (response.status === 200) {
        dispatch(viewMatiereSuccess(response.data.payload));
      } else {
        dispatch(viewMatiereErr('err'));
      }
    } catch (err) {
      dispatch(viewMatiereErr('err'));
    }
  };
};

export { viewMatiereData };
