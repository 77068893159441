import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Row, Col, Skeleton, Modal, Form, Select } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { useDispatch, useSelector } from 'react-redux';
import AttachedEventCard from '../../../components/cards/AttachedEventCard';
import { useParams } from 'react-router-dom';
import { viewInscriptionEventsData } from '../../../redux/inscription/actionCreator';
import { Button } from '../../../components/buttons/buttons';
import { BasicFormWrapper } from '../../styled';
import { ReunionFormStyleWrap } from '../../reunion/Style';
import { AttachEvent, viewEventData } from '../../../redux/event/actionCreator';

const AllPosts = lazy(() => import('./timeline/Posts'));

function Events() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { facture, events } = useSelector((state) => {
    return {
      events: state.EventReducer.arrayData,
      facture: state.InscriptionReducer.facture,
    };
  });
  useEffect(() => {
    dispatch(viewInscriptionEventsData(id));
    dispatch(viewEventData());
  }, []);

  const [open, setOpen] = useState(false);
  const [event, setEvent] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const attachEventSuccess = () => {
    dispatch(viewInscriptionEventsData(id));
    handleClose();
  };
  const handleSubmit = () => {
    dispatch(AttachEvent(id, event, attachEventSuccess));
  };

  return (
    <>
      <Modal
        className="edulink-event-form"
        footer={null}
        closable
        type="primary"
        title={'Attacher club'}
        open={open}
        onCancel={handleClose}
      >
        <BasicFormWrapper>
          <ReunionFormStyleWrap>
            <div className="edulink-event-form-input">
              <span className="edulink-event-form-label">Evenement :</span>
              <Form.Item>
                <Select
                  onChange={(e) => setEvent(e)}
                  options={events.map((item) => {
                    return { value: item.id, label: item.titre };
                  })}
                  placeholder="Choisir evenement "
                />
              </Form.Item>
            </div>
            <div className="add-event-footer text-right">
              <Button htmlType="submit" className="btn-save" type="primary" onClick={handleSubmit}>
                Enregistrer
              </Button>
            </div>
          </ReunionFormStyleWrap>
        </BasicFormWrapper>
      </Modal>
      <Row gutter={25}>
        <Col lg={24} xs={24}>
          <Suspense
            fallback={
              <Cards headless>
                <Skeleton active />
              </Cards>
            }
          ></Suspense>
          <Col lg={4} md={4} xs={24}>
            <Button
              key="1"
              type="danger"
              size="default"
              style={{ borderRadius: '17px', marginBottom: '20px' }}
              onClick={handleOpen}
            >
              Attacher Evenement
            </Button>
          </Col>
          <Row gutter={25} className="mt-sm-10">
            {facture.eventRegistrations.map((value, index) => (
              <AttachedEventCard key={index} data={value} id={id} />
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Events;
