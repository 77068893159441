import { DatePicker, Form, Input, Select, TimePicker } from 'antd';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button } from '../../components/buttons/buttons';
import { BasicFormWrapper } from '../styled';
import { ReunionFormStyleWrap } from './Style';
import moment from 'moment';
import 'moment/locale/fr';

function CatineForm({ onHandleAddEvent, data }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        date: moment(data.date, 'YYYY-MM-DD') || null,
        heur: moment(data.heur, 'HH:mm') || null,
        heurF: moment(data.heurF, 'HH:mm') || null,
        description: data.description || null,
        titre: data.titre || null,
      });
    }
  }, [data]);

  const reset = () => {
    form.resetFields();
  };

  const handleSubmit = (values) => {
    values.heur = values.heur.format('HH:mm');
    values.heurF = values.heurF.format('HH:mm');
    values.date = values.date.format('yyyy-MM-DD');

    if (data) {
      values.id = data.id;
    }

    onHandleAddEvent(values);
    reset();
  };

  return (
    <BasicFormWrapper>
      <ReunionFormStyleWrap>
        <Form form={form} name="addReunion" onFinish={handleSubmit}>
          <div className="edulink-event-form-input">
            <span className="edulink-event-form-label">Titre</span>
            <Form.Item name="titre" rules={[{ required: true, message: 'Titre de repas est obligatoire !' }]}>
              <Input placeholder="Choisir un titre" />
            </Form.Item>
          </div>
          <div className="edulink-event-timeselection">
            <span className="edulink-event-timeselection__label">Date:</span>
            <div className="edulink-event-timeselection__input">
              <Form.Item name="date" rules={[{ required: true, message: 'Date de repas est obligatoire !' }]}>
                <DatePicker format="DD/MM/YYYY" />
              </Form.Item>
            </div>
          </div>{' '}
          <div className="edulink-event-timeselection">
            <span className="edulink-event-timeselection__label">Debut:</span>
            <div className="edulink-event-timeselection__input">
              <Form.Item name="heur" rules={[{ required: true, message: 'Heure debut de repas est obligatoire !' }]}>
                <TimePicker format="HH:mm" />
              </Form.Item>
            </div>
          </div>
          <div className="edulink-event-timeselection">
            <span className="edulink-event-timeselection__label">Fin:</span>
            <div className="edulink-event-timeselection__input">
              <Form.Item name="heurF" rules={[{ required: true, message: 'Heure fin de repas est obligatoire !' }]}>
                <TimePicker format="HH:mm" />
              </Form.Item>
            </div>
          </div>
          <div className="edulink-event-form-input">
            <span className="edulink-event-form-label">Description:</span>
            <Form.Item
              name="description"
              rules={[{ required: true, message: 'Description de repas est obligatoire !' }]}
            >
              <Input.TextArea placeholder="Ecrire une description" />
            </Form.Item>
          </div>
          <div className="add-event-footer text-right">
            <Button className="ant-btn ant-btn-white" onClick={reset}>
              Reset
            </Button>
            <Button htmlType="submit" className="btn-save" type="primary">
              Enregistrer
            </Button>
          </div>
        </Form>
      </ReunionFormStyleWrap>
    </BasicFormWrapper>
  );
}

export default CatineForm;
