/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Upload, TimePicker, DatePicker, notification, message } from 'antd';
import { FormValidationWrap, VerticalFormStyleWrap } from './Style';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import locale from 'antd/es/date-picker/locale/fr_FR'; // Import the French locale
import { updateEventData } from '../../redux/event/actionCreator';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { getItem } from '../../utility/localStorageControl';

function UpdateEventForm() {
  const location = useLocation();
  let { event } = location.state;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fileName, setFileName] = useState();

  useEffect(() => {
    if (event) {
      const [date, heur] = event.dateHeur.split(' ');
      form.setFieldsValue({
        titre: event.titre,
        date: moment(date),
        frais: event.frais,
        heur: moment(heur, 'HH:mm'),
        adresse: event.adresse,
        description: event.description,
        image: [
          {
            name: event.image,
            status: 'done',
            url: `${process.env.REACT_APP_IMAGE_BASE_URL}/${event.image}`,
          },
        ],
      });
      setFileName(event.image);
    }
  }, [event]);
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const updateSuccess = () => {
    navigate(-1);
  };

  const handleSubmit = (values) => {
    values.heur = values.heur.format('HH:mm');
    values.date = values.date.format('yyyy-MM-DD');
    values.dateHeur = `${values.date} ${values.heur}`;
    delete values['date'];
    delete values['heur'];
    values.id = event.id;
    values.image = fileName;


    dispatch(updateEventData(event.id, values, updateSuccess));
  };
  const props = {
    name: 'file',
    multiple: false,
    listType: 'picture',
    customRequest({ file, onSuccess, onError }) {
      const formData = new FormData();
      formData.append('file', file);
      axios
        .post('https://edulinkbackend.edulink.tn/api/image/upload', formData, {
          headers: {
            Authorization: `Bearer ${getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          onSuccess(response.data, file);
          setFileName(response.data.payload);
        });
    },
    beforeUpload(file) {
      return true;
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const [form] = Form.useForm();

  const handleAnnuler = () => {
    navigate(-1);
  };
  return (
    <>
      <Main>
        <Row gutter={25}>
          <Col xs={24}>
            <FormValidationWrap>
              <VerticalFormStyleWrap>
                <Cards headless>
                  <Form name="event-form" form={form} layout="vertical" onFinish={handleSubmit}>
                    <div className="form-title">Mise à jour evènement</div>
                    <Row gutter={30}>
                      <Col md={24} xs={24}>
                        <Form.Item
                          name="titre"
                          label="Titre d'évènement"
                          rules={[{ required: true, message: "Titre d'évènement est obligatoire !" }]}
                        >
                          <Input placeholder="Titre d'évènement" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="adresse"
                          label="Adresse"
                          rules={[{ required: true, message: 'Adresse est obligatoire!' }]}
                        >
                          <Input placeholder="Adresse" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="frais"
                          label="Frais"
                          rules={[{ required: true, message: 'Frais est obligatoire!' }]}
                        >
                          <Input placeholder="Frais" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="date"
                          label="Date de début"
                          rules={[{ required: true, message: 'Date de début est obligatoire!' }]}
                        >
                          <DatePicker locale={locale} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="heur"
                          label="Heure de debut"
                          rules={[{ required: true, message: 'Heure de debut est obligatoire!' }]}
                        >
                          <TimePicker format="HH:mm" locale={locale} />
                        </Form.Item>
                      </Col>
                      <Col md={24} xs={24}>
                        <Form.Item
                          name="description"
                          label="Description"
                          rules={[{ required: true, message: 'Description est obligatoire !' }]}
                        >
                          <Input.TextArea placeholder="Description ..." />
                        </Form.Item>
                      </Col>
                      <Col md={24} xs={24}>
                        <Form.Item
                          valuePropName="fileList"
                          name={'image'}
                          getValueFromEvent={normFile}
                          label="Photo d'évènement"
                        >
                          <Upload.Dragger listType="picture" name="files" maxCount={1} {...props}>
                            <p className="ant-upload-hint">sélectionner ou faire glisser des fichiers ici</p>
                            <p className="ant-upload-text">JPG, PNG or PDF, file size pas plus que 10MB</p>
                          </Upload.Dragger>
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="edulink-form-action mt-20">
                      <Button htmlType="reset" type="light" size="large" onClick={handleAnnuler}>
                        Annuler
                      </Button>
                      <Button htmlType="submit" type="danger" size="large">
                        Modifier
                      </Button>
                    </div>
                  </Form>
                </Cards>
              </VerticalFormStyleWrap>
            </FormValidationWrap>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default UpdateEventForm;
