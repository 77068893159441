import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Calendar from '../../container/calendar/Calendar';
import NotFound from '../../container/pages/404';
import EmploiParent from '../../container/emploi_parent/Calendar';
import MyProfile from '../../container/profile/myProfile/Index';
import Tranches from '../../container/profile/myProfile/overview/Tranches';
import ParentClubs from '../../container/parent_clubs/Index';
import ParentEvents from '../../container/parent_events/Index';
import ParentCantines from '../../container/parent_cantine/Index';
import Exercices from '../../container/profile/myProfile/overview/Exercices';
import Timeline from '../../container/profile/myProfile/overview/Timeline';
import Clubs from '../../container/profile/myProfile/overview/Club';
import Events from '../../container/profile/myProfile/overview/Event';
import AutreTranches from '../../container/profile/myProfile/overview/AutreTranches';

function PagesRoute() {
  return (
    <Routes>
      <Route path="calendar" element={<Calendar />} />
      <Route path="profile" element={<MyProfile />}>
        <Route path="timeline" element={<Timeline />} />
        <Route path="clubs" element={<Clubs />} />
        <Route path="events" element={<Events />} />
        <Route path="exercices" element={<Exercices />} />
        <Route path="tranches" element={<Tranches />} />
        <Route path="autretranches" element={<AutreTranches />} />
      </Route>
      <Route path="emploi" element={<EmploiParent />} />
      <Route path="clubs" element={<ParentClubs />} />
      <Route path="events" element={<ParentEvents />} />
      <Route path="cantine" element={<ParentCantines />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
