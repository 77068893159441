const actions = {
  VIEW_ALL_EVENT_BEGIN: 'VIEW_ALL_EVENT_BEGIN',
  VIEW_ALL_EVENT_SUCCESS: 'VIEW_ALL_EVENT_SUCCESS',
  VIEW_ALL_EVENT_ERR: 'VIEW_ALL_EVENT_ERR',
  VIEW_EVENT_BEGIN: 'VIEW_EVENT_BEGIN',
  VIEW_EVENT_SUCCESS: 'VIEW_EVENT_SUCCESS',
  VIEW_EVENT_ERR: 'VIEW_EVENT_ERR',

  viewEventBegin: () => {
    return {
      type: actions.VIEW_EVENT_BEGIN,
    };
  },

  viewEventSuccess: (data) => {
    return {
      type: actions.VIEW_EVENT_SUCCESS,
      data,
    };
  },

  viewEventErr: (err) => {
    return {
      type: actions.VIEW_EVENT_ERR,
      err,
    };
  },

  viewAllEventBegin: () => {
    return {
      type: actions.VIEW_ALL_EVENT_BEGIN,
    };
  },

  viewAllEventSuccess: (data) => {
    return {
      type: actions.VIEW_ALL_EVENT_SUCCESS,
      data,
    };
  },

  viewAllEventErr: (err) => {
    return {
      type: actions.VIEW_ALL_EVENT_ERR,
      err,
    };
  },
};

export default actions;
