export function calculateTotalPaid(data) {
    let totalPaid = 0;

    // Calculate paid amount from clubRegistrations
    if (data.clubRegistrations) {
        data.clubRegistrations.forEach(club => {
            if (club.method === 'UNE SEUL FOIS' && club.paid) {
                totalPaid += club.fees;
            } else if (club.method === 'PAR TRANCHE' && club.installments) {
                club.installments.forEach(installment => {
                    if (installment.paid) {
                        totalPaid += installment.amount;
                    }
                });
            }
        });
    }

    // Calculate paid amount from eventRegistrations
    if (data.eventRegistrations) {
        data.eventRegistrations.forEach(event => {
            if (event.paid) {
                totalPaid += event.fees;
            }
        });
    }

    // Calculate paid amount from installments
    if (data.installments) {
        data.installments.forEach(installment => {
            if (installment.paid) {
                totalPaid += installment.amount;
            }
        });
    }

    // Calculate paid amount from otherInstallments
    if (data.otherInstallments) {
        data.otherInstallments.forEach(installment => {
            if (installment.paid) {
                totalPaid += installment.amount;
            }
        });
    }

    return totalPaid;
}