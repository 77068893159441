import React, { useEffect, useState } from 'react';
import { Row, Col, Input, notification, Modal } from 'antd';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import DataTable from '../../components/table/DataTable';
import UilCheckCircle from '@iconscout/react-unicons/icons/uil-check-circle';
import UilTimesCircle from '@iconscout/react-unicons/icons/uil-times-circle';
import UilAngleLeft from '@iconscout/react-unicons/icons/uil-angle-left';
import UilAngleRight from '@iconscout/react-unicons/icons/uil-angle-right';
import { addDays, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { addAbsenceProfData, viewAbsenceProfData, deleteAbsenceProfData } from '../../redux/absence_prof/actionCreator';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { viewProfData } from '../../redux/professeur/actionCreator';

function Profs() {
  const { confirm } = Modal;
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const { prof, absences, absents } = useSelector((state) => {
    return {
      prof: state.ProfReducer.data,
      absences: state.AbsenceProfReducer.arrayData,
      absents: state.AbsenceProfReducer.absents,
    };
  });
  const [currentDate, setCurrentDate] = useState(new Date());
  useEffect(() => {
    dispatch(viewProfData());
  }, []);
  useEffect(() => {
    const currentStringDate = format(currentDate, 'yyyy-MM-dd');
    dispatch(viewAbsenceProfData(currentStringDate));
  }, [currentDate]);

  const addAbsence = (id) => {
    confirm({
      title: 'Ajouter une absence',
      icon: <ExclamationCircleFilled />,
      content: 'Voulez-vous ajouter une absence ?',
      onOk() {
        handleAddAbsence(id);
      },
      onCancel() {},
    });
  };
  const supprimerAbsence = (id) => {
    confirm({
      title: 'Supprimer une absence',
      icon: <ExclamationCircleFilled />,
      content: 'Voulez-vous supprimer cette absence ?',
      onOk() {
        handleRemoveAbsence(id);
      },
      onCancel() {},
    });
  };

  const handleAddAbsence = (id) => {
    dispatch(addAbsenceProfData(id));
  };
  const handleRemoveAbsence = (id) => {
    const [abcence] = absences.filter((item) => item.professeur_id === id);
    dispatch(deleteAbsenceProfData(abcence));
  };

  useEffect(() => {
    const localData = [];

    prof.map((element) => {
      localData.push({
        ...element,

        prof: (
          <div style={{ fontSize: '.8rem' }}>
            <div>
              {element.prenom} {element.nom}
            </div>
            <div>Né le : {element.dateNaiss}</div>
            <div>Role : {element.role}</div>
            <div>{element.num}</div>
          </div>
        ),

        action: (
          <div className="table-actions">
            {absents.includes(element.id) ? (
              <div className="absent" style={{ cursor: 'pointer' }} onClick={() => supprimerAbsence(element.id)}>
                <UilTimesCircle size={16} />
                <div>Absent</div>
              </div>
            ) : (
              <div className="present" style={{ cursor: 'pointer' }} onClick={() => addAbsence(element.id)}>
                <UilCheckCircle size={16} />
                <div>Present</div>
              </div>
            )}
          </div>
        ),
      });
    });
    setData(localData);
  }, [prof, absences, absents]);

  const dataTableColumn = [
    {
      title: 'Nom',
      dataIndex: 'nom',
      key: 'nom',
    },
    {
      title: 'Prenom',
      dataIndex: 'prenom',
      key: 'prenom',
    },
    {
      title: 'Telephone',
      dataIndex: 'num',
      key: 'num',
    },
    {
      title: 'Date Naissance',
      dataIndex: 'dateNaiss',
      key: 'dateNaiss',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '60px',
    },
  ];

  const responsiveDataTableColumn = [
    {
      title: 'Prof',
      dataIndex: 'prof',
      key: 'prof',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const handlePrevDay = () => {
    setCurrentDate((prevDate) => addDays(prevDate, -1));
  };

  const handleNextDay = () => {
    setCurrentDate((prevDate) => addDays(prevDate, 1));
  };

  return (
    <>
      <Main>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
            <Cards headless>
              <div className="calendar-header">
                <div className="calendar-header__left">
                  <div className="calender-head__navigation">
                    <button className="btn-navigate" type="button" onClick={() => handlePrevDay()}>
                      <UilAngleLeft />
                    </button>
                    <span className="date-label">{format(currentDate, 'dd MMM yyyy', { locale: fr })}</span>
                    <button className="btn-navigate" type="button" onClick={() => handleNextDay()}>
                      <UilAngleRight />
                    </button>
                  </div>
                </div>
              </div>
              <DataTable
                filterOnchange
                tableData={data}
                columns={window.innerWidth < 990 ? responsiveDataTableColumn : dataTableColumn}
              />
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default Profs;
