import React, { useEffect, useState } from 'react';
import CustomBarChart from '../CustomBarChart';
import { useDispatch, useSelector } from 'react-redux';
import { viewClubDepenseStatisticsData } from '../../../redux/statistics/actionCreator';
import { Cards } from '../../../components/cards/frame/cards-frame';
import CustomDoughnutChart from '../CustomDoughnutChart';

const GainStatistics = () => {
  const dispatch = useDispatch();
  const { statistics } = useSelector((state) => {
    return {
      statistics: state.StatisticsReducer.clubDepenses,
    };
  });

  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  useEffect(() => {
    dispatch(viewClubDepenseStatisticsData());
  }, []);

  useEffect(() => {
    if (statistics) {
      const keys = statistics.map((item) => item.club);
      const datasets = statistics.map((item) => item.gains);

      setLabels(keys);
      setDatasets(datasets);
    }
  }, [statistics]);

  return (
    <>
      {statistics && (
        <Cards headless>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '18px',
              fontWeight: '500',
              marginBottom: '10px',
              color: '#333',
            }}
          >
            Gain réalisé par club
          </div>
          <div style={{ height: '40vh', display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
            <CustomDoughnutChart labels={labels} datasets={datasets} beginAtZero={true} />
          </div>
        </Cards>
      )}
    </>
  );
};

export default GainStatistics;
