import { message } from 'antd';
import actions from './actions';

const initialState = {
  yearlyInscriptions: null,
  yearlyInscriptionsByLevel: null,
  monthlyInscriptions: [],
  clubInscriptions: [],
  clubDepenses: [],
  gradesPerLevel: [],
  grades: [],
  ranksPerLevel: [],
  ranks: [],
  fieldSuccesRate: [],
  levelFieldsSuccesRate: [],
  totalClubInscriptions: 0,
  gainsPrevisionnelsClubs: 0,
  gainsRealisesClubs: 0,
  financeOverview: null,
  financeCharge: null,
  financeTransport: null,
  loading: false,
  error: null,
};

const {
  VIEW_YEARLY_INSCRIPTION_BEGIN,
  VIEW_YEARLY_INSCRIPTION_SUCCESS,
  VIEW_YEARLY_INSCRIPTION_ERR,

  VIEW_YEARLY_INSCRIPTION_BY_LEVEL_BEGIN,
  VIEW_YEARLY_INSCRIPTION_BY_LEVEL_SUCCESS,
  VIEW_YEARLY_INSCRIPTION_BY_LEVEL_ERR,

  VIEW_MONTHLY_INSCRIPTION_BEGIN,
  VIEW_MONTHLY_INSCRIPTION_SUCCESS,
  VIEW_MONTHLY_INSCRIPTION_ERR,

  VIEW_CLUB_INSCRIPTION_BEGIN,
  VIEW_CLUB_INSCRIPTION_SUCCESS,
  VIEW_CLUB_INSCRIPTION_ERR,

  VIEW_TOTAL_CLUB_INSCRIPTION_BEGIN,
  VIEW_TOTAL_CLUB_INSCRIPTION_SUCCESS,
  VIEW_TOTAL_CLUB_INSCRIPTION_ERR,

  VIEW_TOTAL_GAIN_PREVESIONNELS_CLUB_BEGIN,
  VIEW_TOTAL_GAIN_PREVESIONNELS_CLUB_SUCCESS,
  VIEW_TOTAL_GAIN_PREVESIONNELS_CLUB_ERR,

  VIEW_TOTAL_GAIN_REALISES_CLUB_BEGIN,
  VIEW_TOTAL_GAIN_REALISES_CLUB_SUCCESS,
  VIEW_TOTAL_GAIN_REALISES_CLUB_ERR,

  VIEW_CLUB_DEPENSE_BEGIN,
  VIEW_CLUB_DEPENSE_SUCCESS,
  VIEW_CLUB_DEPENSE_ERR,

  VIEW_AVREAGE_GRADES_PER_LEVEL_BEGIN,
  VIEW_AVREAGE_GRADES_PER_LEVEL_SUCCESS,
  VIEW_AVREAGE_GRADES_PER_LEVEL_ERR,

  VIEW_AVREAGE_GRADES_BEGIN,
  VIEW_AVREAGE_GRADES_SUCCESS,
  VIEW_AVREAGE_GRADES_ERR,

  VIEW_FINANCE_OVERVIEW_BEGIN,
  VIEW_FINANCE_OVERVIEW_SUCCESS,
  VIEW_FINANCE_OVERVIEW_ERR,

  VIEW_FINANCE_CHARGE_BEGIN,
  VIEW_FINANCE_CHARGE_SUCCESS,
  VIEW_FINANCE_CHARGE_ERR,

  VIEW_RANK_PER_LEVEL_BEGIN,
  VIEW_RANK_PER_LEVEL_SUCCESS,
  VIEW_RANK_PER_LEVEL_ERR,

  VIEW_RANK_BEGIN,
  VIEW_RANK_SUCCESS,
  VIEW_RANK_ERR,

  VIEW_FIELD_SUCCESS_RATE_BEGIN,
  VIEW_FIELD_SUCCESS_RATE_SUCCESS,
  VIEW_FIELD_SUCCESS_RATE_ERR,

  VIEW_LEVEL_FIELDS_SUCCESS_RATE_BEGIN,
  VIEW_LEVEL_FIELDS_SUCCESS_RATE_SUCCESS,
  VIEW_LEVEL_FIELDS_SUCCESS_RATE_ERR,

  VIEW_FINANCE_TRANSPORT_BEGIN,
  VIEW_FINANCE_TRANSPORT_SUCCESS,
  VIEW_FINANCE_TRANSPORT_ERR,
} = actions;

const StatisticsReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case VIEW_YEARLY_INSCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_YEARLY_INSCRIPTION_SUCCESS:
      return {
        ...state,
        yearlyInscriptions: data,
        loading: false,
      };
    case VIEW_YEARLY_INSCRIPTION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_YEARLY_INSCRIPTION_BY_LEVEL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_YEARLY_INSCRIPTION_BY_LEVEL_SUCCESS:
      return {
        ...state,
        yearlyInscriptionsByLevel: data,
        loading: false,
      };
    case VIEW_YEARLY_INSCRIPTION_BY_LEVEL_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_MONTHLY_INSCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_MONTHLY_INSCRIPTION_SUCCESS:
      return {
        ...state,
        monthlyInscriptions: data,
        loading: false,
      };
    case VIEW_MONTHLY_INSCRIPTION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case VIEW_CLUB_INSCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_CLUB_INSCRIPTION_SUCCESS:
      return {
        ...state,
        clubInscriptions: data,
        loading: false,
      };
    case VIEW_CLUB_INSCRIPTION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case VIEW_TOTAL_CLUB_INSCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_TOTAL_CLUB_INSCRIPTION_SUCCESS:
      return {
        ...state,
        totalClubInscriptions: data,
        loading: false,
      };
    case VIEW_TOTAL_CLUB_INSCRIPTION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_TOTAL_GAIN_PREVESIONNELS_CLUB_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_TOTAL_GAIN_PREVESIONNELS_CLUB_SUCCESS:
      return {
        ...state,
        gainsPrevisionnelsClubs: data,
        loading: false,
      };
    case VIEW_TOTAL_GAIN_PREVESIONNELS_CLUB_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case VIEW_TOTAL_GAIN_REALISES_CLUB_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_TOTAL_GAIN_REALISES_CLUB_SUCCESS:
      return {
        ...state,
        gainsRealisesClubs: data,
        loading: false,
      };
    case VIEW_TOTAL_GAIN_REALISES_CLUB_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case VIEW_CLUB_DEPENSE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_CLUB_DEPENSE_SUCCESS:
      return {
        ...state,
        clubDepenses: data,
        loading: false,
      };
    case VIEW_CLUB_DEPENSE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_AVREAGE_GRADES_PER_LEVEL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_AVREAGE_GRADES_PER_LEVEL_SUCCESS:
      return {
        ...state,
        gradesPerLevel: data,
        loading: false,
      };
    case VIEW_AVREAGE_GRADES_PER_LEVEL_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_AVREAGE_GRADES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_AVREAGE_GRADES_SUCCESS:
      return {
        ...state,
        grades: data,
        loading: false,
      };
    case VIEW_AVREAGE_GRADES_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case VIEW_RANK_PER_LEVEL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_RANK_PER_LEVEL_SUCCESS:
      return {
        ...state,
        ranksPerLevel: data,
        loading: false,
      };
    case VIEW_RANK_PER_LEVEL_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_RANK_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_RANK_SUCCESS:
      return {
        ...state,
        ranks: data,
        loading: false,
      };
    case VIEW_RANK_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case VIEW_FINANCE_OVERVIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_FINANCE_OVERVIEW_SUCCESS:
      return {
        ...state,
        financeOverview: data,
        loading: false,
      };
    case VIEW_FINANCE_OVERVIEW_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_FINANCE_CHARGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_FINANCE_CHARGE_SUCCESS:
      return {
        ...state,
        financeCharge: data,
        loading: false,
      };
    case VIEW_FINANCE_CHARGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case VIEW_FIELD_SUCCESS_RATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_FIELD_SUCCESS_RATE_SUCCESS:
      return {
        ...state,
        fieldSuccesRate: data,
        loading: false,
      };
    case VIEW_FIELD_SUCCESS_RATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case VIEW_LEVEL_FIELDS_SUCCESS_RATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_LEVEL_FIELDS_SUCCESS_RATE_SUCCESS:
      return {
        ...state,
        levelFieldsSuccesRate: data,
        loading: false,
      };
    case VIEW_LEVEL_FIELDS_SUCCESS_RATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_FINANCE_TRANSPORT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_FINANCE_TRANSPORT_SUCCESS:
      return {
        ...state,
        financeTransport: data,
        loading: false,
      };
    case VIEW_FINANCE_TRANSPORT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export default StatisticsReducer;
