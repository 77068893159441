import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Modal, Checkbox, Divider, Form, message } from 'antd';
import { Badge, PricingCard } from '../../pages/style';
import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../../components/heading/heading';
import { viewFactureData } from '../../../redux/inscription/actionCreator';
import { useParams } from 'react-router-dom';
import { Button } from '../../../components/buttons/buttons';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { addPaimentData } from '../../../redux/paiment/actionCreator';
import { transformData } from '../../../utility/transformFactureData';
import { printInvoice } from '../../../utility/printInvoice';
function Tranches() {
  let { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [factureData, setFactureData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const [clubOptions, setClubOptions] = useState([]);
  const [eventOptions, setEventOptions] = useState([]);
  const [installmentOptions, setInstallmentOptions] = useState([]);
  const [otherInstallmentOptions, setOtherInstallmentOptions] = useState([]);

  const CheckboxGroup = Checkbox.Group;

  const { facture } = useSelector((state) => {
    return {
      facture: state.InscriptionReducer.facture,
    };
  });
  useEffect(() => {
    dispatch(viewFactureData(id));
  }, []);

  useEffect(() => {
    if (facture && facture.tranches) {
      setData(facture.tranches);
      const transformed = transformData(facture);

      setFactureData(transformData(facture));

      setClubOptions(
        transformed.clubRegistrations.map((club) => ({
          label: club.title,
          value: club.id,
        })),
      );

      setEventOptions(
        transformed.eventRegistrations.map((event) => ({
          label: event.title,
          value: event.id,
        })),
      );

      setInstallmentOptions(
        transformed.installments.map((installment) => ({
          label: installment.name,
          value: installment.name,
        })),
      );

      setOtherInstallmentOptions(
        transformed.otherInstallments.map((installment) => ({
          label: installment.name,
          value: installment.name,
        })),
      );
    }
  }, [facture]);

  const { confirm } = Modal;
  const addSuccess = () => {
    dispatch(viewFactureData(id));
  };

  const addPaiment = (data) => {
    dispatch(addPaimentData(data, addSuccess));
  };
  const showConfirm = (data) => {
    confirm({
      title: 'Voulez-vous payer la tranche ?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'payer',
      okType: 'danger',
      cancelText: 'Annuler',
      cancelButtonProps: {
        type: 'white',
      },
      onOk() {
        addPaiment(data);
      },
      onCancel() {},
    });
  };

  function filterData(filters) {
    const clubRegistrations =
      filters.clubs && factureData.clubRegistrations.filter((club) => filters.clubs.includes(club.id));
    const eventRegistrations =
      filters.events && factureData.eventRegistrations.filter((event) => filters.events.includes(event.id));
    const installments =
      filters.tranches && factureData.installments.filter((tranche) => filters.tranches.includes(tranche.name));

      const otherInstallments =
      filters.tranches && factureData.otherInstallments.filter((tranche) => filters.autreTranches.includes(tranche.name));

    return {
      ...factureData,
      clubRegistrations,
      eventRegistrations,
      installments,
      otherInstallments
    };
  }
  const handleSubmit = (values) => {
    if (values.clubs || values.events || values.tranches) {
      printInvoice(filterData(values));
      
    } else {
      message.error('Tous les champs obligatoires sont requis');
      return;
    }
  };
  return (
    <Row gutter={25}>
      <Col xxl={24} lg={24} sm={24} xs={24}>
        <Button
          key="1"
          type="danger"
          size="default"
          style={{ borderRadius: '17px', marginBottom: '20px' }}
          onClick={handleOpen}
        >
          Imprimer facture
        </Button>
      </Col>
      <Modal open={isOpen} onCancel={handleClose} footer={null} title={'Parametre facture'}>
        <Row gutter={25}>
          <Form form={form} onFinish={handleSubmit}>
            <Divider orientation="left">Clubs</Divider>
            <Form.Item name="clubs">
              <CheckboxGroup options={clubOptions} style={{ marginLeft: '30px', color: 'black' }} />
            </Form.Item>
            <Divider orientation="left">Events</Divider>
            <Form.Item name="events">
              <CheckboxGroup options={eventOptions} style={{ marginLeft: '30px', color: 'black' }} />
            </Form.Item>
            <Divider orientation="left">Tranches</Divider>
            <Form.Item name="tranches">
              <CheckboxGroup
                options={installmentOptions}
                style={{ display: 'flex', flexDirection: 'column', marginLeft: '30px' }}
              />
            </Form.Item>
            <Divider orientation="left">Autre Tranches</Divider>
            <Form.Item name="autreTranches">
              <CheckboxGroup
                options={otherInstallmentOptions}
                style={{ display: 'flex', flexDirection: 'column', marginLeft: '30px' }}
              />
            </Form.Item>
            <div className="add-event-footer text-right" style={{ width: '480px' }}>
              <Button htmlType="submit" className="btn-save" type="primary">
                Imprimer
              </Button>
            </div>
          </Form>
        </Row>
      </Modal>
      {data &&
        data.map((item, index) => {
          return (
            <>
              <Col xxl={8} lg={12} sm={12} xs={24} key={index}>
                <PricingCard
                  style={{ marginBottom: 30 }}
                  onClick={() => showConfirm({ etudiant_id: id, nomTranche: item.nomTranche })}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Badge className="pricing-badge" type={item.paid ? 'success' : 'danger'}>
                      Tranche : {item.nomTranche}
                    </Badge>
                    <div style={{ display: 'flex', columnGap: '5px' }}></div>
                  </div>
                  <Heading className="price-amount" as="h3">
                    <sup className="currency"></sup>
                    {item.montantTranche} DT <sub className="pricing-validity">par tranche</sub>
                  </Heading>
                </PricingCard>
              </Col>
            </>
          );
        })}
    </Row>
  );
}

export default Tranches;
