import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

const ParentMessage = React.memo(() => {
  const { roomId } = useSelector((state) => {
    return {
      roomId: state.auth.data.id,
    };
  });
  return (
    <div className="edulink-nav-actions__item edulink-nav-actions__message">
      <Link to={`/chat/private/${roomId}`} className="edulink-nav-action-link">
        <ReactSVG src={require('../../../static/img/icon/envelope.svg').default} />
      </Link>
    </div>
  );
});

export default ParentMessage;
