const actions = {
  VIEW_EXERCICE_BEGIN: 'VIEW_EXERCICE_BEGIN',
  VIEW_EXERCICE_SUCCESS: 'VIEW_EXERCICE_SUCCESS',
  VIEW_EXERCICE_ERR: 'VIEW_EXERCICE_ERR',

  VIEW_SINGLE_EXERCICE_BEGIN: 'VIEW_SINGLE_EXERCICE_BEGIN',
  VIEW_SINGLE_EXERCICE_SUCCESS: 'VIEW_SINGLE_EXERCICE_SUCCESS',
  VIEW_SINGLE_EXERCICE_ERR: 'VIEW_SINGLE_EXERCICE_ERR',

  ADD_EXERCICE_BEGIN: 'ADD_EXERCICE_BEGIN',
  ADD_EXERCICE_SUCCESS: 'ADD_EXERCICE_SUCCESS',
  ADD_EXERCICE_ERR: 'ADD_EXERCICE_ERR',

  DELETE_EXERCICE_BEGIN: 'DELETE_EXERCICE_BEGIN',
  DELETE_EXERCICE_SUCCESS: 'DELETE_EXERCICE_SUCCESS',
  DELETE_EXERCICE_ERR: 'DELETE_EXERCICE_ERR',

  UPDATE_EXERCICE_BEGIN: 'UPDATE_EXERCICE_BEGIN',
  UPDATE_EXERCICE_SUCCESS: 'UPDATE_EXERCICE_SUCCESS',
  UPDATE_EXERCICE_ERR: 'UPDATE_EXERCICE_ERR',

  viewExerciceBegin: () => {
    return {
      type: actions.VIEW_EXERCICE_BEGIN,
    };
  },

  viewExerciceSuccess: (data) => {
    return {
      type: actions.VIEW_EXERCICE_SUCCESS,
      data,
    };
  },

  viewExerciceErr: (err) => {
    return {
      type: actions.VIEW_EXERCICE_ERR,
      err,
    };
  },

  viewSingleExerciceBegin: () => {
    return {
      type: actions.VIEW_SINGLE_EXERCICE_BEGIN,
    };
  },

  viewSingleExerciceSuccess: (data) => {
    return {
      type: actions.VIEW_SINGLE_EXERCICE_SUCCESS,
      data,
    };
  },

  viewSingleExerciceErr: (err) => {
    return {
      type: actions.VIEW_SINGLE_EXERCICE_ERR,
      err,
    };
  },

  updateExerciceBegin: () => {
    return {
      type: actions.UPDATE_EXERCICE_BEGIN,
    };
  },

  updateExerciceSuccess: (data) => {
    return {
      type: actions.UPDATE_EXERCICE_SUCCESS,
      data,
    };
  },

  updateExerciceErr: (err) => {
    return {
      type: actions.UPDATE_EXERCICE_ERR,
      err,
    };
  },
  deleteExerciceBegin: () => {
    return {
      type: actions.DELETE_EXERCICE_BEGIN,
    };
  },

  deleteExerciceSuccess: (data) => {
    return {
      type: actions.DELETE_EXERCICE_SUCCESS,
      data,
    };
  },

  deleteExerciceErr: (err) => {
    return {
      type: actions.DELETE_EXERCICE_ERR,
      err,
    };
  },
  addExerciceBegin: () => {
    return {
      type: actions.ADD_EXERCICE_BEGIN,
    };
  },

  addExerciceSuccess: (data) => {
    return {
      type: actions.ADD_EXERCICE_SUCCESS,
      data,
    };
  },

  addExerciceErr: (err) => {
    return {
      type: actions.ADD_EXERCICE_ERR,
      err,
    };
  },
};

export default actions;
