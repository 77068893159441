const actions = {
  VIEW_FACTURE_BEGIN: 'VIEW_FACTURE_BEGIN',
  VIEW_FACTURE_SUCCESS: 'VIEW_FACTURE_SUCCESS',
  VIEW_FACTURE_ERR: 'VIEW_FACTURE_ERR',

  viewFactureBegin: () => {
    return {
      type: actions.VIEW_FACTURE_BEGIN,
    };
  },

  viewFactureSuccess: (data) => {
    return {
      type: actions.VIEW_FACTURE_SUCCESS,
      data,
    };
  },

  viewFactureErr: (err) => {
    return {
      type: actions.VIEW_FACTURE_ERR,
      err,
    };
  },
};

export default actions;
