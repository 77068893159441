import actions from './actions';

const initialState = {
  data: [],
  own: [],
  error: null,
};

const {
  VIEW_ALL_EVENT_BEGIN,
  VIEW_ALL_EVENT_SUCCESS,
  VIEW_ALL_EVENT_ERR,
  VIEW_EVENT_BEGIN,
  VIEW_EVENT_SUCCESS,
  VIEW_EVENT_ERR,
} = actions;

const EventReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case VIEW_ALL_EVENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_ALL_EVENT_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case VIEW_ALL_EVENT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_EVENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_EVENT_SUCCESS:
      return {
        ...state,
        own: data,
        loading: false,
      };
    case VIEW_EVENT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default EventReducer;
