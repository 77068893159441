import React, { lazy, Suspense } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { useLocation } from 'react-router-dom';
import { Main } from '../../../styled';
import { ProductDetailsWrapper } from '../../Style';
import { Cards } from '../../../../components/cards/frame/cards-frame';
const DetailsRight = lazy(() => import('./DetailsRight'));

function ProductDetails() {
  const location = useLocation();
  const { club } = location.state;
  
  return (
    <>
      <Main>
        <Cards headless>
          <ProductDetailsWrapper>
            <div className="product-details-box">
              <Row gutter={30}>
                <Col xs={24} lg={10}>
                  <div className="product-details-box__left pdbl">
                    <figure>
                      <img
                        style={{ width: '100%', height: '370px', objectFit: 'cover', borderRadius: '18px' }}
                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${club.image}`}
                        alt=""
                      />
                    </figure>
                  </div>
                </Col>
                <Col xs={24} lg={14}>
                  <Suspense
                    fallback={
                      <Cards headless>
                        <Skeleton active />
                      </Cards>
                    }
                  >
                    <DetailsRight club={club} />
                  </Suspense>
                </Col>
              </Row>
            </div>
          </ProductDetailsWrapper>
        </Cards>
      </Main>
    </>
  );
}

export default ProductDetails;
