import React from 'react';
import InscriptionClubStatistics from './club/ClubStatistics';
import DepenseStatistics from './club/DepenseStatistics';
import GainStatistics from './club/GainStatistics';
import { Col, Row } from 'antd';
import CustomPieChart from './CustomDoughnutChart';
import ClubCards from './club/ClubCards';

const ClubStatistics = () => {
  return (
    <div
      style={{
        margin: '25px',
      }}
    >
      <Row gutter={25}>
        <ClubCards/>
        <Col xxl={14} lg={14} sm={14} xs={24}>
          <InscriptionClubStatistics />
        </Col>
        <Col xxl={10} lg={10} sm={10} xs={24}>
          <GainStatistics />
        </Col>
      </Row>
    </div>
  );
};

export default ClubStatistics;
