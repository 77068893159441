import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
  forgotPasswordBegin,
  forgotPasswordSuccess,
  forgotPasswordErr,
} = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/login', values);
      if (response.status == 200) {
        dispatch(loginSuccess(response.data));
        callback();
      } else {
        dispatch(loginErr('err'));
      }
    } catch (err) {
      dispatch(loginErr('err'));
    }
  };
};

const forgotPassword = (values, callback) => {
  return async (dispatch) => {
    dispatch(forgotPasswordBegin());
    try {
      const response = await DataService.post('/forgotPassword', values);
      if (response.data.errors) {
        dispatch(forgotPasswordErr('Registration failed!'));
      } else {
        dispatch(forgotPasswordSuccess(false));
        message.success(response.data.message);
        callback();
      }
    } catch (err) {
      dispatch(forgotPasswordErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      dispatch(logoutSuccess(true));
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('role');
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, forgotPassword };
