import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Cards } from '../../../components/cards/frame/cards-frame';
import CustomDoughnutChart from '../CustomDoughnutChart';
import { Select } from 'antd';
import { viewLevelFieldsSuccesRateStatisticsData } from '../../../redux/statistics/actionCreator';
import { viewMatiereData } from '../../../redux/matiere/actionCreator';
import CustomBarChart from '../CustomBarChart';

const LevelSuccessRateStatistics = () => {
  const dispatch = useDispatch();
  const [matieresData, setMatieresData] = useState();
  const [matiere, setMatiere] = useState();

  const { statistics, matieres } = useSelector((state) => {
    return {
      statistics: state.StatisticsReducer.levelFieldsSuccesRate,
      matieres: state.MatiereReducer.arrayData,
    };
  });

  useEffect(() => {
    dispatch(viewMatiereData());
  }, []);

  useEffect(() => {
    if (matiere) {
      console.log(matiere);

      dispatch(viewLevelFieldsSuccesRateStatisticsData(matiere));
    }
  }, [matiere]);

  useEffect(() => {
    if (matieres) {
      const data = matieres
        .filter((item, index, self) => index === self.findIndex((t) => t.nom === item.nom))
        .map((item) => ({
          label: item.nom,
          value: item.nom,
        }));
      setMatieresData(data);
    }
  }, [matieres]);

  useEffect(() => {
    if (matieresData && matieresData.length > 0) {
      setMatiere(matieresData[0].value);
    }
  }, [matieresData]);

  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);

  useEffect(() => {
    if (statistics) {
      const keys = statistics.map((item) => item.niveau);
      const values = statistics.map((item) => parseFloat(item.success_rate));

      setLabels(keys);
      setDatasets(values);
    }
  }, [statistics]);

  return (
    <>
      {statistics && (
        <Cards headless>
          <div
            style={{
              height: '40vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontSize: '18px',
                fontWeight: '500',
                marginBottom: '10px',
                color: '#333',
                width: '100%',
              }}
            >
              <div>Taux de réussite par niveau</div>
              <Select
                style={{ height: '40px', width: '150px' }}
                placeholder="Classe"
                options={matieresData}
                value={matiere}
                onChange={(value) => setMatiere(value)}
              />
            </div>
            <CustomBarChart
              labels={labels}
              datasets={datasets}
              title="Inscriptions by Month for 2024"
              beginAtZero={true}
            />
          </div>
        </Cards>
      )}
    </>
  );
};

export default LevelSuccessRateStatistics;
