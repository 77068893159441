const actions = {
  VIEW_PROF_BEGIN: 'VIEW_PROF_BEGIN',
  VIEW_PROF_SUCCESS: 'VIEW_PROF_SUCCESS',
  VIEW_PROF_ERR: 'VIEW_PROF_ERR',

  viewProfBegin: () => {
    return {
      type: actions.VIEW_PROF_BEGIN,
    };
  },

  viewProfSuccess: (data) => {
    return {
      type: actions.VIEW_PROF_SUCCESS,
      data,
    };
  },

  viewProfErr: (err) => {
    return {
      type: actions.VIEW_PROF_ERR,
      err,
    };
  },
};

export default actions;
