import UilFile from '@iconscout/react-unicons/icons/uil-file-alt';
import UilHeart from '@iconscout/react-unicons/icons/uil-heart-sign';
import propTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { BlogCardStyleWrap } from './Style';

function BlogCard({ item, theme }) {
  const { content, title, img } = item;
  return (
    <BlogCardStyleWrap>
      <figure className={`edulink-blog edulink-blog-${theme}`}>
        <div className="edulink-blog-thumb">
          <img className="edulink-blog__image" src={require(`../../static/img/blogs/${img}`)} alt="edulink Blog" />
        </div>
        <figcaption>
          <div className="edulink-blog-meta edulink-blog-meta-theme-2">
            <span className="edulink-blog-meta__single edulink-category-meta">Web Development</span>
            <span className="edulink-blog-meta__single edulink-date-meta">01 July 2020</span>
          </div>
          <h2 className="edulink-blog__title">
            <Link to="#">{title}</Link>
          </h2>
          <p className="edulink-blog__text">{content}</p>
          <div className="edulink-blog__bottom">
            <div className="edulink-blog__author">
              <img className="edulink-blog__author-img" src={require('../../static/img/chat-author/t1.jpg')} alt="" />
              <span className="edulink-blog__author-name">Burns Marks</span>
            </div>
            <ul className="edulink-blog__meta">
              <li className="edulink-blog__meta--item">
                <span className="like">
                  <UilHeart />
                  <span>70</span>
                </span>
              </li>
              <li className="edulink-blog__meta--item">
                <span className="view">
                  <UilFile />
                  <span>120</span>
                </span>
              </li>
            </ul>
          </div>
        </figcaption>
      </figure>
    </BlogCardStyleWrap>
  );
}

BlogCard.propTypes = {
  item: propTypes.object,
  theme: propTypes.string,
};

BlogCard.defaultProps = {
  item: {
    id: 1,
    title: 'Technology Change the World',
    content: 'Lorem Ipsum is simply dummy text of the printer took a galley of type and scrambled',
    category: 'Web Development',
    img: '1.png',
    author: 'Machel Bold',
    authorImg: '1.png',
    postDate: '15 March 2021',
    favouriteBy: '15k',
    viewedBy: '20k',
  },
  theme: 'style-1',
};

export default BlogCard;
