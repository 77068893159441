import { DatePicker, Form, Input, Modal, Select } from 'antd';
import React, { useState } from 'react';
import { BasicFormWrapper } from '../styled';
import { Button } from '../../components/buttons/buttons';
import { ReunionFormStyleWrap } from './Style';
import moment from 'moment';
function Update({ open, handleUpdateCancel, action, data }) {
  const [form] = Form.useForm();
  const handleSubmit = (values) => {
    values.date = values.date.format('yyyy-MM-DD');
    action(values);
  };
  return (
    <Modal
      className="edulink-event-form"
      footer={null}
      closable
      type="primary"
      title={'Nouveau charge'}
      open={open}
      onCancel={handleUpdateCancel}
    >
      <BasicFormWrapper>
        <ReunionFormStyleWrap>
          <Form form={form} name="addReunion" onFinish={handleSubmit}>
            <div className="edulink-event-form-input">
              <span className="edulink-event-form-label">Code :</span>
              <Form.Item
                name="code"
                rules={[{ required: true, message: 'Le code est obligatoire !' }]}
                initialValue={data ? data.code : ''}
              >
                <Input placeholder="Choisir un nom" />
              </Form.Item>
            </div>

            <div className="edulink-event-form-input">
              <span className="edulink-event-form-label">Date :</span>
              <Form.Item
                name="date"
                rules={[{ required: true, message: 'La date est obligatoire !' }]}
                initialValue={data ? moment(data.date) : null}
              >
                <DatePicker format="YYYY-MM-DD" defaultValue={data ? moment(data.date) : null} />
              </Form.Item>
            </div>
            <div className="edulink-event-form-input">
              <span className="edulink-event-form-label">Montant :</span>
              <Form.Item
                name="montantF"
                rules={[{ required: true, message: 'Le montant est obligatoire !' }]}
                initialValue={data ? data.montantF : ''}
              >
                <Input placeholder="Choisir le montant" />
              </Form.Item>
            </div>
            <div className="edulink-event-form-input">
              <span className="edulink-event-form-label">Payé :</span>
              <Form.Item
                name="montantP"
                rules={[{ required: true, message: 'Le montant payée est obligatoire !' }]}
                initialValue={data ? data.montantP : ''}
              >
                <Input placeholder="Choisir le montant payé" />
              </Form.Item>
            </div>
            <div className="edulink-event-form-input">
              <span className="edulink-event-form-label">Reste :</span>
              <Form.Item
                name="rest"
                rules={[{ required: true, message: 'Le reste est obligatoire !' }]}
                initialValue={data ? data.rest : ''}
              >
                <Input placeholder="Choisir le montant restant" />
              </Form.Item>
            </div>

            <div className="add-event-footer text-right">
              <Button htmlType="submit" className="btn-save" type="primary">
                Enregistrer
              </Button>
            </div>
          </Form>
        </ReunionFormStyleWrap>
      </BasicFormWrapper>
    </Modal>
  );
}

export default Update;
