import { Col } from 'antd';
import { Main } from '../styled';
import { NavLink, Outlet } from 'react-router-dom';
import { SettingWrapper } from '../inscription/overview/Style';

function PaymentMethods() {
  return (
    <Main>
      <Col xxl={24} lg={24} md={24} xs={24}>
        <SettingWrapper>
          <div className="coverWrapper">
            <nav className="profileTab-menu">
              <ul>
                <li>
                  <NavLink to="tranches">Tranches</NavLink>
                </li>
                <li>
                  <NavLink to="autre">Autre tranches</NavLink>
                </li>
              </ul>
            </nav>
          </div>

          <Outlet />
        </SettingWrapper>
      </Col>
    </Main>
  );
}

export default PaymentMethods;
