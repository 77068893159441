import React from 'react';
import ClassStatistics from './moyenne/ClassStatistics';
import { Col, Row } from 'antd';
import LevelStatistics from './moyenne/LevelStatistics';
import RankByLevel from './moyenne/RankByLevel';
import RankByClass from './moyenne/RankByClass';
import LevelSuccessRate from './moyenne/LevelSuccessRate';
import ClassSuccessRate from './moyenne/ClassSuccessRate';

const MoyenneStatistics = () => {
  return (
    <div
      style={{
        margin: '25px',
      }}
    >
      <Row gutter={25}>
        <Col xxl={14} lg={14} sm={14} xs={24}>
          <ClassStatistics />
        </Col>
        <Col xxl={10} lg={10} sm={10} xs={24}>
          <LevelStatistics />
        </Col>
        <Col xxl={12} lg={12} sm={12} xs={24}>
          <LevelSuccessRate />
        </Col>
        <Col xxl={12} lg={12} sm={12} xs={24}>
          <ClassSuccessRate />
        </Col>
        <Col xxl={12} lg={12} sm={12} xs={24}>
          <RankByClass />
        </Col>
        <Col xxl={12} lg={12} sm={12} xs={24}>
          <RankByLevel />
        </Col>
      </Row>
    </div>
  );
};

export default MoyenneStatistics;
