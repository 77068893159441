const actions = {
  VIEW_NOTE_BEGIN: 'VIEW_NOTE_BEGIN',
  VIEW_NOTE_SUCCESS: 'VIEW_NOTE_SUCCESS',
  VIEW_NOTE_ERR: 'VIEW_NOTE_ERR',

  VIEW_BULLETIN_BEGIN: 'VIEW_BULLETIN_BEGIN',
  VIEW_BULLETIN_SUCCESS: 'VIEW_BULLETIN_SUCCESS',
  VIEW_BULLETIN_ERR: 'VIEW_BULLETIN_ERR',

  ADD_NOTE_BEGIN: 'ADD_NOTE_BEGIN',
  ADD_NOTE_SUCCESS: 'ADD_NOTE_SUCCESS',
  ADD_NOTE_ERR: 'ADD_NOTE_ERR',

  UPDATE_NOTE_BEGIN: 'UPDATE_NOTE_BEGIN',
  UPDATE_NOTE_SUCCESS: 'UPDATE_NOTE_SUCCESS',
  UPDATE_NOTE_ERR: 'UPDATE_NOTE_ERR',

  viewNoteBegin: () => {
    return {
      type: actions.VIEW_NOTE_BEGIN,
    };
  },

  viewNoteSuccess: (data) => {
    return {
      type: actions.VIEW_NOTE_SUCCESS,
      data,
    };
  },

  viewNoteErr: (err) => {
    return {
      type: actions.VIEW_NOTE_ERR,
      err,
    };
  },
  viewBulletinBegin: () => {
    return {
      type: actions.VIEW_BULLETIN_BEGIN,
    };
  },

  viewBulletinSuccess: (data) => {
    return {
      type: actions.VIEW_BULLETIN_SUCCESS,
      data,
    };
  },

  viewBulletinErr: (err) => {
    return {
      type: actions.VIEW_BULLETIN_ERR,
      err,
    };
  },

  updateNoteBegin: () => {
    return {
      type: actions.UPDATE_NOTE_BEGIN,
    };
  },

  updateNoteSuccess: (data) => {
    return {
      type: actions.UPDATE_NOTE_SUCCESS,
      data,
    };
  },

  updateNoteErr: (err) => {
    return {
      type: actions.UPDATE_NOTE_ERR,
      err,
    };
  },
  addNoteBegin: () => {
    return {
      type: actions.ADD_NOTE_BEGIN,
    };
  },

  addNoteSuccess: (data) => {
    return {
      type: actions.ADD_NOTE_SUCCESS,
      data,
    };
  },

  addNoteErr: (err) => {
    return {
      type: actions.ADD_NOTE_ERR,
      err,
    };
  },
};

export default actions;
