import UilAngleLeft from '@iconscout/react-unicons/icons/uil-angle-left';
import UilAngleRight from '@iconscout/react-unicons/icons/uil-angle-right';
import UilCalender from '@iconscout/react-unicons/icons/uil-calender';
import UilClock from '@iconscout/react-unicons/icons/uil-clock';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit-alt';
import UilTrash from '@iconscout/react-unicons/icons/uil-trash-alt';
import UilMultiply from '@iconscout/react-unicons/icons/uil-multiply';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';

import { Col, Modal, Row, Select } from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import { CalendarWrapper, EventModalStyleWrap } from './Style';
import { Main } from '../styled';
import { startOfWeek, addDays, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import AbsenceForm from './AbsenceForm';
import { Button } from '../../components/buttons/buttons';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { viewClasseData } from '../../redux/classe/actionCreator';
import { viewInscriptionData } from '../../redux/inscription/actionCreator';
import {
  addAbsenceData,
  deleteAbsenceData,
  updateAbsenceData,
  viewAbsenceData,
} from '../../redux/absence/actionCreator';
import UilUserTimes from '@iconscout/react-unicons/icons/uil-user-times';
import UilUserCheck from '@iconscout/react-unicons/icons/uil-user-check';

function Calendars() {
  const { confirm } = Modal;
  const dispatch = useDispatch();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [niveau, setNiveau] = useState();
  const [classesSelect, setClassesSelect] = useState();
  const [selectedClasse, setSelectedClasse] = useState();
  const [open, setOpen] = useState(false);
  const [openAddModel, setOpenAddModel] = useState(false);
  const [selectedEvent, setSlectedEvent] = useState();
  const [selectedCell, setSlectedCell] = useState();
  const [formTitle, setFormTitle] = useState('Ajouter un reunion');
  const [formAction, setFormAction] = useState(1);
  const [selectedEleve, setSelectedEleve] = useState();
  const [showAbsent, setShowAbsent] = useState(false);
  const [showPresent, setShowPresent] = useState(false);
  const { absences, classes, inscriptions } = useSelector((state) => {
    return {
      absences: state.AbsenceReducer.arrayData,
      classes: state.ClasseReducer.arrayData,
      inscriptions: state.InscriptionReducer.arrayData,
    };
  });

  const renderClendarData = () => {
    const rows = [];
    for (const hour of timeSlots) {
      const cells = [];
      for (const day of weekDays) {
        const highlighted = isHighlightedCell(format(day, 'yyyy-MM-dd'), hour);
        let cellData;
        if (highlighted) {
          let eventData = highlighted[0];
          eventData.day = moment(day, 'MM-DD-YYYY');
          cellData = {
            highlighted: true,
            data: eventData,
            height: `${(highlighted[1] - 1) * 50 + 45}px`,
            day: moment(day, 'MM-DD-YYYY'),
          };
        } else {
          cellData = {
            highlighted: false,
            day: moment(day, 'MM-DD-YYYY'),
          };
        }
        cells.push(cellData);
      }
      rows.push({ hour, cells });
    }
    setData(rows);
  };
  useEffect(() => {
    dispatch(viewClasseData());
    dispatch(viewInscriptionData());
  }, []);

  useEffect(() => {
    const start = moment(weekDays[0]).format('yyyy-MM-DD');
    const end = moment(weekDays[6]).format('yyyy-MM-DD');
    if (selectedClasse) {
      dispatch(viewAbsenceData(start, end, selectedClasse));
    }
  }, [currentDate, selectedClasse]);

  useEffect(() => {
    renderClendarData();
  }, [absences]);
  useEffect(() => {
    if (classes.length > 0) {
      setNiveau('1');
    }
  }, [classes]);
  useEffect(() => {
    if (niveau) {
      const data = classes.filter((item) => item.niveau == niveau);
      const selectClasseData = data.map((item) => {
        return { value: item.id, label: item.titre };
      });
      setClassesSelect([...selectClasseData]);
      setSelectedClasse(selectClasseData[0] && selectClasseData[0].value);
    }
  }, [niveau]);
  useEffect(() => {
    const data = inscriptions.filter((item) => item.classe.id === selectedClasse);
    setSelectedEleve(data);
  }, [selectedClasse]);

  const handleDelete = useCallback(
    (id) => {
      dispatch(deleteAbsenceData(id));
    },
    [dispatch],
  );

  const getWeekDays = (startDate) => {
    const days = [];
    for (let i = 1; i <= 7; i++) {
      days.push(addDays(startDate, i));
    }
    return days;
  };

  const getTimeSlots = () => {
    const timeSlots = [];
    for (let i = 0; i <= 23; i++) {
      timeSlots.push(i);
    }
    return timeSlots;
  };

  const isHighlightedCell = (day, hour) => {
    const item = absences.find((item) => {
      const [hours] = item.heurD.split(':');
      const intHours = parseInt(hours);

      return hour === intHours && day === item.date;
    });
    if (item) {
      const [start] = item.heurD.split(':');
      const [end] = item.heurF.split(':');

      return [item, end - start];
    }
  };

  const handlePrevWeek = () => {
    setCurrentDate((prevDate) => addDays(startOfWeek(prevDate), -1));
  };

  const handleNextWeek = () => {
    setCurrentDate((prevDate) => addDays(startOfWeek(prevDate), 7));
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
  };

  const showModal = (event) => {
    setSlectedEvent(event);
    setOpen(true);
  };

  const handleCancel = () => {
    setSlectedEvent(null);
    setOpen(false);
    setShowAbsent(false);
    setShowAbsent(false);
  };

  const showAddModal = (event) => {
    if (event != null) {
      setSlectedCell({
        id: event.id,
        matiere: event.matiere,
        heurD: moment(event.heurD, 'HH:mm'),
        heurF: moment(event.heurF, 'HH:mm'),
        date: event.date,
        ListEleve: event.id ? event.ListEleve : [],
      });
    }
    setOpen(false);
    setOpenAddModel(true);
  };

  const handleAddCancel = () => {
    setSlectedCell(null);
    setOpenAddModel(false);
  };

  const addSuccess = () => {
    handleAddCancel();
  };

  const updateSuccess = () => {
    handleAddCancel();
  };

  const addReunion = (values) => {
    values.classe_id = selectedClasse;
    dispatch(addAbsenceData(values, addSuccess));
  };
  const updateReunion = (values) => {
    dispatch(updateAbsenceData(values, updateSuccess));
  };

  const showConfirm = (id) => {
    handleCancel();
    confirm({
      title: "Voulez-vous supprimer l'événement ?",
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      cancelButtonProps: {
        type: 'white',
      },
      onOk() {
        handleDelete(id);
      },
      onCancel() {},
    });
  };

  const weekDays = getWeekDays(startOfWeek(currentDate));
  const timeSlots = getTimeSlots();

  return (
    <>
      <Main>
        <CalendarWrapper className="edulink-calendar-wrap">
          <Row gutter={25}>
            <Col xxl={24} xl={24} xs={24}>
              <Modal
                open={open}
                onCancel={handleCancel}
                closable={false}
                footer={null}
                title={
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {selectedEvent && selectedEvent.matiere}
                    <div style={{ display: 'flex', alignItems: 'center', columnGap: '7px', cursor: 'pointer' }}>
                      <UilEdit
                        size={16}
                        onClick={() => {
                          setFormTitle('Modifier le reunion');
                          setFormAction(2);
                          showAddModal(selectedEvent);
                        }}
                      />
                      <UilTrash size={16} onClick={() => showConfirm(selectedEvent.id)} />
                      <UilMultiply size={16} onClick={handleCancel} />
                    </div>
                  </div>
                }
              >
                <EventModalStyleWrap>
                  {selectedEvent && (
                    <div className="edulink-event-details">
                      <ul>
                        <li>
                          <UilCalender />
                          <span className="edulink-event-label">Date:</span>
                          <span className="edulink-event-text">
                            <strong>{selectedEvent.date}</strong>
                          </span>
                        </li>
                        <li>
                          <UilClock />
                          <span className="edulink-event-label">Temps:</span>
                          <span className="edulink-event-text">
                            <strong>
                              {formatTime(selectedEvent.heurD)} - {formatTime(selectedEvent.heurF)}
                            </strong>
                          </span>
                        </li>
                        <li style={{ cursor: 'pointer' }} onClick={() => setShowAbsent(!showAbsent)}>
                          <UilUserCheck />
                          <span className="edulink-event-label">Present:</span>
                          <span className="edulink-event-text">
                            <strong>
                              {selectedEleve.filter((item) => !selectedEvent.ListEleve.includes(item.id)).length} élèves
                            </strong>
                          </span>
                        </li>
                        {showAbsent && (
                          <div className="student-absence-container">
                            {selectedEleve
                              .filter((item) => !selectedEvent.ListEleve.includes(item.id))
                              .map((item, index) => (
                                <div key={index} class="present-student">
                                  {item.prenom} {item.nom}
                                </div>
                              ))}
                          </div>
                        )}
                        <li style={{ cursor: 'pointer' }} onClick={() => setShowPresent(!showPresent)}>
                          <UilUserTimes />

                          <span className="edulink-event-label">Absent:</span>
                          <span className="edulink-event-text">
                            <strong>
                              {selectedEleve.filter((item) => selectedEvent.ListEleve.includes(item.id)).length} élèves
                            </strong>
                          </span>
                        </li>
                        {showPresent && (
                          <div className="student-absence-container">
                            {selectedEleve
                              .filter((item) => selectedEvent.ListEleve.includes(item.id))
                              .map((item, index) => (
                                <div key={index} class="absent-student">
                                  {item.prenom} {item.nom}
                                </div>
                              ))}
                          </div>
                        )}
                      </ul>
                    </div>
                  )}
                </EventModalStyleWrap>
              </Modal>
              <Modal
                className="edulink-event-form"
                footer={null}
                type="primary"
                title={formTitle}
                open={openAddModel}
                onCancel={handleAddCancel}
              >
                <AbsenceForm
                  eventData={selectedCell}
                  onHandleAddEvent={formAction == 1 ? addReunion : updateReunion}
                  eleves={selectedEleve}
                  niveau={niveau}
                />
              </Modal>
              <div className="calendar-container">
                <div>
                  <CustomToolbar
                    startDay={weekDays[0]}
                    endDay={weekDays[6]}
                    nextWeek={handleNextWeek}
                    prevWeek={handlePrevWeek}
                    niveau={niveau}
                    setNiveau={setNiveau}
                    classesSelect={classesSelect}
                    selectedClasse={selectedClasse}
                    setSelectedClasse={setSelectedClasse}
                    clickAddModal={() => {
                      showAddModal();
                      setFormTitle('Ajouter absence');
                      setFormAction(1);
                    }}
                  />
                </div>
                <div className="calendar-cells-container">
                  <table className="calendar" rules="none">
                    <thead>
                      <tr>
                        <th className="cell">
                          <UilClock size={20} />
                        </th>
                        {weekDays.map((day) => (
                          <th key={day} className="cell">
                            {format(day, 'EEEE', { locale: fr })}
                            <br /> {format(day, 'd')}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index}>
                          <td className="cell">{`${item.hour < 10 ? '0' : ''}${item.hour}:00`}</td>
                          {item.cells.map((day, index) => (
                            <td key={index} className={`cell ${day.highlighted ? 'highlighted-cell' : ''}`}>
                              {day.highlighted ? (
                                <div
                                  onClick={() => {
                                    showModal(day.data);
                                  }}
                                  className={`meeting absences`}
                                  style={{
                                    height: day.height,
                                  }}
                                >
                                  <div className="from-to-container">
                                    <div className={`from-to-badge absences-badge`}>{formatTime(day.data.heurD)}</div>
                                    <div className={`from-to-badge absences-badge`}>{formatTime(day.data.heurF)}</div>
                                  </div>
                                  <div className={`cell-title absences-title`}>{day.data.matiere}</div>
                                </div>
                              ) : (
                                <div
                                  onClick={() => {
                                    setFormTitle('Ajouter absence');
                                    setFormAction(1);
                                    showAddModal({
                                      date: day.day,
                                      heurD: moment(item.hour, 'HH:mm'),
                                      heurF: moment(item.hour + 1, 'HH:mm'),
                                    });
                                  }}
                                  className="empty-cell"
                                ></div>
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        </CalendarWrapper>
      </Main>
    </>
  );
}

const CustomToolbar = ({
  startDay,
  endDay,
  nextWeek,
  prevWeek,
  clickAddModal,
  niveau,
  setNiveau,
  classesSelect,
  selectedClasse,
  setSelectedClasse,
}) => {
  useEffect(() => {
    const handleLinkClick = (event) => {
      const activatedLink = document.querySelector('.calendar-header__right a.active');
      if (activatedLink) activatedLink.classList.remove('active');
      event.target.classList.add('active');
    };

    const toolbarLinks = document.querySelectorAll('.calendar-header__right a');
    toolbarLinks.forEach((link) => {
      link.addEventListener('click', handleLinkClick);
    });

    const tags = document.querySelectorAll('.calendar-header_tags a');
    tags.forEach((tag) => {
      tag.addEventListener('click', handleLinkClick);
    });

    return () => {
      toolbarLinks.forEach((link) => {
        link.removeEventListener('click', handleLinkClick);
      });
      tags.forEach((tag) => {
        tag.removeEventListener('click', handleLinkClick);
      });
    };
  }, []);

  return (
    <div className="calendar-header">
      <div className="calendar-header__left">
        <div className="calender-head__navigation">
          <button className="btn-navigate" type="button" onClick={() => prevWeek()}>
            <UilAngleLeft />
          </button>
          <span className="date-label">
            {format(startDay, 'dd MMM yyyy', { locale: fr })} - {format(endDay, 'dd MMM yyyy', { locale: fr })}
          </span>
          <button className="btn-navigate" type="button" onClick={() => nextWeek()}>
            <UilAngleRight />
          </button>
        </div>
      </div>
      <div className="calendar-header__right">
        <Select
          style={{ width: '150px' }}
          value={niveau}
          onChange={(e) => setNiveau(e)}
          options={[
            { value: '1', label: '1ère année' },
            { value: '2', label: '2ème année' },
            { value: '3', label: '3ème année' },
            { value: '4', label: '4ème année' },
            { value: '5', label: '5ème année' },
            { value: '6', label: '6ème année' },
          ]}
        />
        <Select
          value={selectedClasse}
          style={{ width: '150px' }}
          options={classesSelect}
          onChange={(e) => setSelectedClasse(e)}
        />
        <Button
          key="1"
          type="danger"
          size="default"
          style={{ borderRadius: '17px', marginRight: '10px', display: 'flex', alignItems: 'center', height: '40px' }}
          onClick={() => clickAddModal()}
        >
          <UilPlus /> Ajouter Absence
        </Button>
      </div>
    </div>
  );
};

export default Calendars;
