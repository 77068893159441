const actions = {
  VIEW_FRAIS_BEGIN: 'VIEW_FRAIS_BEGIN',
  VIEW_FRAIS_SUCCESS: 'VIEW_FRAIS_SUCCESS',
  VIEW_FRAIS_ERR: 'VIEW_FRAIS_ERR',

  viewFraisBegin: () => {
    return {
      type: actions.VIEW_FRAIS_BEGIN,
    };
  },

  viewFraisSuccess: (data) => {
    return {
      type: actions.VIEW_FRAIS_SUCCESS,
      data,
    };
  },

  viewFraisErr: (err) => {
    return {
      type: actions.VIEW_FRAIS_ERR,
      err,
    };
  },
};

export default actions;
