import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';

Chart.register(ArcElement);

const CircularChart = ({ values }) => {
  const previsionnel = values[0] && parseFloat(values[0].replace(/[,DT]/g, ''));
  const reel = values[1] && parseFloat(values[1].replace(/[,DT]/g, ''));
  
  const outerData = {
    datasets: [
      {
        data: [(reel / previsionnel) * 100, 100 * (1 - reel / previsionnel)],
        backgroundColor: ['#74ABFF', '#f0f0f0'],
        borderWidth: 0,
        cutout: '92%',
      },
    ],
  };

  const innerData = {
    datasets: [
      {
        data: [previsionnel, 0],
        backgroundColor: ['#3354F4', '#f0f0f0'],
        borderWidth: 0,
        cutout: '93%',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    rotation: -90,
    circumference: 360,
    plugins: {
      tooltip: { enabled: false },
    },
  };

  return (
    <div className="chart-container">
      <div className="chart-wrapper">
        <div className="previsionnel-label">{previsionnel} TND</div>
        <Doughnut data={outerData} options={options} />
        <div className="inner-chart">
          <Doughnut data={innerData} options={options} />
        </div>
        <div className="reel-label">{reel} TND</div>
      </div>
      <div className="chart-legends">
        <div className="legend-item">
          <div className="legend-color-previsionnel"></div>
          <div className="legend-text">Prévisionnelle</div>
        </div>
        <div className="legend-item">
          <div className="legend-color-reel"></div>
          <div className="legend-text">Réel</div>
        </div>
      </div>
    </div>
  );
};

export default CircularChart;
