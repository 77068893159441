import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../../config/dataService/dataService';

const { viewInscriptionBegin, viewInscriptionSuccess, viewInscriptionErr } = actions;

const viewInscriptionData = () => {
  return async (dispatch) => {
    dispatch(viewInscriptionBegin());
    try {
      const response = await DataService.get('/inscriptionP/view');

      if (response.status === 200) {
        dispatch(viewInscriptionSuccess(response.data.payload));
      } else {
        dispatch(viewInscriptionErr('err'));
      }
    } catch (err) {
      dispatch(viewInscriptionErr('err'));
    }
  };
};

export { viewInscriptionData };
