const actions = {
  VIEW_ABSENCE_BEGIN: 'VIEW_ABSENCE_BEGIN',
  VIEW_ABSENCE_SUCCESS: 'VIEW_ABSENCE_SUCCESS',
  VIEW_ABSENCE_ERR: 'VIEW_ABSENCE_ERR',

  VIEW_SINGLE_ABSENCE_BEGIN: 'VIEW_SINGLE_ABSENCE_BEGIN',
  VIEW_SINGLE_ABSENCE_SUCCESS: 'VIEW_SINGLE_ABSENCE_SUCCESS',
  VIEW_SINGLE_ABSENCE_ERR: 'VIEW_SINGLE_ABSENCE_ERR',

  ADD_ABSENCE_BEGIN: 'ADD_ABSENCE_BEGIN',
  ADD_ABSENCE_SUCCESS: 'ADD_ABSENCE_SUCCESS',
  ADD_ABSENCE_ERR: 'ADD_ABSENCE_ERR',

  DELETE_ABSENCE_BEGIN: 'DELETE_ABSENCE_BEGIN',
  DELETE_ABSENCE_SUCCESS: 'DELETE_ABSENCE_SUCCESS',
  DELETE_ABSENCE_ERR: 'DELETE_ABSENCE_ERR',

  UPDATE_ABSENCE_BEGIN: 'UPDATE_ABSENCE_BEGIN',
  UPDATE_ABSENCE_SUCCESS: 'UPDATE_ABSENCE_SUCCESS',
  UPDATE_ABSENCE_ERR: 'UPDATE_ABSENCE_ERR',

  CHANGE_START_DATE_BEGIN: 'CHANGE_START_DATE_BEGIN',
  CHANGE_START_DATE_SUCCESS: 'CHANGE_START_DATE_SUCCESS',
  CHANGE_START_DATE_ERR: 'CHANGE_START_DATE_ERR',

  CHANGE_END_DATE_BEGIN: 'CHANGE_END_DATE_BEGIN',
  CHANGE_END_DATE_SUCCESS: 'CHANGE_END_DATE_SUCCESS',
  CHANGE_END_DATE_ERR: 'CHANGE_END_DATE_ERR',

  CHANGE_SELECTED_CLASSE_BEGIN: 'CHANGE_SELECTED_CLASSE_BEGIN',
  CHANGE_SELECTED_CLASSE_SUCCESS: 'CHANGE_SELECTED_CLASSE_SUCCESS',
  CHANGE_SELECTED_CLASSE_ERR: 'CHANGE_SELECTED_CLASSE_ERR',

  viewAbsenceBegin: () => {
    return {
      type: actions.VIEW_ABSENCE_BEGIN,
    };
  },

  viewAbsenceSuccess: (data) => {
    return {
      type: actions.VIEW_ABSENCE_SUCCESS,
      data,
    };
  },

  viewAbsenceErr: (err) => {
    return {
      type: actions.VIEW_ABSENCE_ERR,
      err,
    };
  },

  viewSingleAbsenceBegin: () => {
    return {
      type: actions.VIEW_SINGLE_ABSENCE_BEGIN,
    };
  },

  viewSingleAbsenceSuccess: (data) => {
    return {
      type: actions.VIEW_SINGLE_ABSENCE_SUCCESS,
      data,
    };
  },

  viewSingleAbsenceErr: (err) => {
    return {
      type: actions.VIEW_SINGLE_ABSENCE_ERR,
      err,
    };
  },

  updateAbsenceBegin: () => {
    return {
      type: actions.UPDATE_ABSENCE_BEGIN,
    };
  },

  updateAbsenceSuccess: (data) => {
    return {
      type: actions.UPDATE_ABSENCE_SUCCESS,
      data,
    };
  },

  updateAbsenceErr: (err) => {
    return {
      type: actions.UPDATE_ABSENCE_ERR,
      err,
    };
  },
  deleteAbsenceBegin: () => {
    return {
      type: actions.DELETE_ABSENCE_BEGIN,
    };
  },

  deleteAbsenceSuccess: (data) => {
    return {
      type: actions.DELETE_ABSENCE_SUCCESS,
      data,
    };
  },

  deleteAbsenceErr: (err) => {
    return {
      type: actions.DELETE_ABSENCE_ERR,
      err,
    };
  },
  addAbsenceBegin: () => {
    return {
      type: actions.ADD_ABSENCE_BEGIN,
    };
  },

  addAbsenceSuccess: (data) => {
    return {
      type: actions.ADD_ABSENCE_SUCCESS,
      data,
    };
  },

  addAbsenceErr: (err) => {
    return {
      type: actions.ADD_ABSENCE_ERR,
      err,
    };
  },
  changeStartDateBegin: () => {
    return {
      type: actions.CHANGE_START_DATE_BEGIN,
    };
  },

  changeStartDateSuccess: (data) => {
    return {
      type: actions.CHANGE_START_DATE_SUCCESS,
      data,
    };
  },

  changeStartDateErr: (err) => {
    return {
      type: actions.CHANGE_START_DATE_ERR,
      err,
    };
  },
  changeEndDateBegin: () => {
    return {
      type: actions.CHANGE_END_DATE_BEGIN,
    };
  },

  changeEndDateSuccess: (data) => {
    return {
      type: actions.CHANGE_END_DATE_SUCCESS,
      data,
    };
  },

  changeEndDateErr: (err) => {
    return {
      type: actions.CHANGE_END_DATE_ERR,
      err,
    };
  },
  changeSelectedClasseBegin: () => {
    return {
      type: actions.CHANGE_SELECTED_CLASSE_BEGIN,
    };
  },

  changeSelectedClasseSuccess: (data) => {
    return {
      type: actions.CHANGE_SELECTED_CLASSE_SUCCESS,
      data,
    };
  },

  changeSelectedClasseErr: (err) => {
    return {
      type: actions.CHANGE_SELECTED_CLASSE_ERR,
      err,
    };
  },
};

export default actions;
