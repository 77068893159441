import { message } from 'antd';
import actions from './actions';

const initialState = {
  data: null,
  singleData: null,
  loading: false,
  error: null,
};

const { VIEW_FRAIS_BEGIN, VIEW_FRAIS_SUCCESS, VIEW_FRAIS_ERR } = actions;

const ParentFraisReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case VIEW_FRAIS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_FRAIS_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case VIEW_FRAIS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default ParentFraisReducer;
