const actions = {
  VIEW_EXERCICE_BEGIN: 'VIEW_EXERCICE_BEGIN',
  VIEW_EXERCICE_SUCCESS: 'VIEW_EXERCICE_SUCCESS',
  VIEW_EXERCICE_ERR: 'VIEW_EXERCICE_ERR',

  viewExerciceBegin: () => {
    return {
      type: actions.VIEW_EXERCICE_BEGIN,
    };
  },

  viewExerciceSuccess: (data) => {
    return {
      type: actions.VIEW_EXERCICE_SUCCESS,
      data,
    };
  },

  viewExerciceErr: (err) => {
    return {
      type: actions.VIEW_EXERCICE_ERR,
      err,
    };
  },
};

export default actions;
