import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const { viewProfBegin, viewProfSuccess, viewProfErr } = actions;

const viewProfData = () => {
  return async (dispatch) => {
    dispatch(viewProfBegin());
    try {
      const response = await DataService.get('/personnel/prof');
      if (response.status === 200) {
        dispatch(viewProfSuccess(response.data.payload));
      } else {
        dispatch(viewProfErr('err'));
      }
    } catch (err) {
      dispatch(viewProfErr('err'));
    }
  };
};

export { viewProfData };
