import { message } from 'antd';
import actions from './actions';

const initialState = {
  arrayData: [],
  singleData:null,
  loading: false,
  error: null,
};

const {
  VIEW_EXERCICE_BEGIN,
  VIEW_EXERCICE_SUCCESS,
  VIEW_EXERCICE_ERR,

  VIEW_SINGLE_EXERCICE_BEGIN,
  VIEW_SINGLE_EXERCICE_SUCCESS,
  VIEW_SINGLE_EXERCICE_ERR,

  ADD_EXERCICE_BEGIN,
  ADD_EXERCICE_SUCCESS,
  ADD_EXERCICE_ERR,

  DELETE_EXERCICE_BEGIN,
  DELETE_EXERCICE_SUCCESS,
  DELETE_EXERCICE_ERR,

  UPDATE_EXERCICE_BEGIN,
  UPDATE_EXERCICE_SUCCESS,
  UPDATE_EXERCICE_ERR,
} = actions;

const ProfExerciceReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_EXERCICE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_EXERCICE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case UPDATE_EXERCICE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_EXERCICE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_EXERCICE_SUCCESS:
      return {
        ...state,
        arrayData:data,
        loading: false,
      };
    case VIEW_EXERCICE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_SINGLE_EXERCICE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_SINGLE_EXERCICE_SUCCESS:
      return {
        ...state,
        singleData:data,
        loading: false,
      };
    case VIEW_SINGLE_EXERCICE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_EXERCICE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_EXERCICE_SUCCESS:
      const arrayData = state.arrayData.filter(
        (item) => item.id !== data
      );
      return {
        ...state,
        arrayData,  
        loading: false,
      };
    case DELETE_EXERCICE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_EXERCICE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_EXERCICE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case ADD_EXERCICE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default ProfExerciceReducer;
