import { message } from 'antd';
import actions from './actions';

const initialState = {
  arrayData: [],
  clubs: [],
  events: [],
  singleData: null,
  facture: null,
  loading: false,
  error: null,
};

const {
  VIEW_INSCRIPTION_BEGIN,
  VIEW_INSCRIPTION_SUCCESS,
  VIEW_INSCRIPTION_ERR,

  VIEW_INSCRIPTION_CLUBS_BEGIN,
  VIEW_INSCRIPTION_CLUBS_SUCCESS,
  VIEW_INSCRIPTION_CLUBS_ERR,

  VIEW_INSCRIPTION_EVENTS_BEGIN,
  VIEW_INSCRIPTION_EVENTS_SUCCESS,
  VIEW_INSCRIPTION_EVENTS_ERR,

  VIEW_FACTURE_BEGIN,
  VIEW_FACTURE_SUCCESS,
  VIEW_FACTURE_ERR,

  VIEW_SINGLE_INSCRIPTION_BEGIN,
  VIEW_SINGLE_INSCRIPTION_SUCCESS,
  VIEW_SINGLE_INSCRIPTION_ERR,

  ADD_INSCRIPTION_BEGIN,
  ADD_INSCRIPTION_SUCCESS,
  ADD_INSCRIPTION_ERR,

  DELETE_INSCRIPTION_BEGIN,
  DELETE_INSCRIPTION_SUCCESS,
  DELETE_INSCRIPTION_ERR,

  UPDATE_INSCRIPTION_BEGIN,
  UPDATE_INSCRIPTION_SUCCESS,
  UPDATE_INSCRIPTION_ERR,
} = actions;

const InscriptionReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_INSCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_INSCRIPTION_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case UPDATE_INSCRIPTION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_INSCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_INSCRIPTION_SUCCESS:
      return {
        ...state,
        arrayData: data,
        loading: false,
      };
    case VIEW_INSCRIPTION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case VIEW_INSCRIPTION_CLUBS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_INSCRIPTION_CLUBS_SUCCESS:
      return {
        ...state,
        clubs: data,
        loading: false,
      };
    case VIEW_INSCRIPTION_CLUBS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case VIEW_INSCRIPTION_EVENTS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_INSCRIPTION_EVENTS_SUCCESS:
      return {
        ...state,
        events: data,
        loading: false,
      };
    case VIEW_INSCRIPTION_EVENTS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case VIEW_FACTURE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_FACTURE_SUCCESS:
      return {
        ...state,
        facture: data,
        loading: false,
      };
    case VIEW_FACTURE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_SINGLE_INSCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_SINGLE_INSCRIPTION_SUCCESS:
      return {
        ...state,
        singleData: data,
        loading: false,
      };
    case VIEW_SINGLE_INSCRIPTION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_INSCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_INSCRIPTION_SUCCESS:
      const arrayData = state.arrayData.filter((item) => item.id !== data);
      return {
        ...state,
        arrayData,
        loading: false,
      };
    case DELETE_INSCRIPTION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_INSCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_INSCRIPTION_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case ADD_INSCRIPTION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default InscriptionReducer;
