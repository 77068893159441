import { message } from 'antd';
import actions from './actions';

const initialState = {
  data: [],
  own: [],
  error: null,
};

const {
  VIEW_ALL_CLUB_BEGIN,
  VIEW_ALL_CLUB_SUCCESS,
  VIEW_ALL_CLUB_ERR,
  VIEW_CLUB_BEGIN,
  VIEW_CLUB_SUCCESS,
  VIEW_CLUB_ERR,
} = actions;

const ClubReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case VIEW_ALL_CLUB_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_ALL_CLUB_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case VIEW_ALL_CLUB_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_CLUB_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_CLUB_SUCCESS:
      return {
        ...state,
        own: data,
        loading: false,
      };
    case VIEW_CLUB_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default ClubReducer;
