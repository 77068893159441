import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Modal, Checkbox, Divider, Form, message } from 'antd';
import { Badge, PricingCard } from '../../pages/style';
import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../../components/heading/heading';
import { viewFactureData } from '../../../redux/inscription/actionCreator';
import { useParams } from 'react-router-dom';
import { Button } from '../../../components/buttons/buttons';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { addPaimentData } from '../../../redux/paiment/actionCreator';
import { transformData } from '../../../utility/transformFactureData';
import { printInvoice } from '../../../utility/printInvoice';
function AutreTranches() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { facture } = useSelector((state) => {
    return {
      facture: state.InscriptionReducer.facture,
    };
  });
  useEffect(() => {
    dispatch(viewFactureData(id));
  }, []);

  useEffect(() => {
    if (facture && facture.autreFrais) {
      setData(facture.autreFrais);
    }
  }, [facture]);

  const { confirm } = Modal;
  const addSuccess = () => {
    dispatch(viewFactureData(id));
  };

  const addPaiment = (data) => {
    dispatch(addPaimentData(data, addSuccess));
  };
  const showConfirm = (data) => {
    confirm({
      title: 'Voulez-vous payer la tranche ?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'payer',
      okType: 'danger',
      cancelText: 'Annuler',
      cancelButtonProps: {
        type: 'white',
      },
      onOk() {
        addPaiment(data);
      },
      onCancel() {},
    });
  };

  return (
    <Row gutter={25}>
      {data &&
        data.map((item, index) => {
          return (
            <>
              <Col xxl={8} lg={12} sm={12} xs={24} key={index}>
                <PricingCard
                  style={{ marginBottom: 30 }}
                  onClick={() => showConfirm({ etudiant_id: id, [`${item.nom}`]: true })}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Badge className="pricing-badge" type={item.paid ? 'success' : 'danger'}>
                      Tranche : {item.nom}
                    </Badge>
                    <div style={{ display: 'flex', columnGap: '5px' }}></div>
                  </div>
                  <Heading className="price-amount" as="h3">
                    <sup className="currency"></sup>
                    {item.montant} DT <sub className="pricing-validity">par tranche</sub>
                  </Heading>
                </PricingCard>
              </Col>
            </>
          );
        })}
    </Row>
  );
}

export default AutreTranches;
