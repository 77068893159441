import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  addAbsenceBegin,
  addAbsenceSuccess,
  addAbsenceErr,
  updateAbsenceBegin,
  updateAbsenceSuccess,
  updateAbsenceErr,
  viewAbsenceBegin,
  viewAbsenceSuccess,
  viewAbsenceErr,
  viewSingleAbsenceBegin,
  viewSingleAbsenceSuccess,
  viewSingleAbsenceErr,
  deleteAbsenceBegin,
  deleteAbsenceSuccess,
  deleteAbsenceErr,
  changeStartDateBegin,
  changeStartDateSuccess,
  changeStartDateErr,
  changeEndDateBegin,
  changeEndDateSuccess,
  changeEndDateErr,
  changeSelectedClasseBegin,
  changeSelectedClasseSuccess,
  changeSelectedClasseErr,
} = actions;

const viewAbsenceData = (start, end, classe) => {
  return async (dispatch) => {
    dispatch(viewAbsenceBegin());
    try {
      const response = await DataService.get('/absence/view', { start_date: start, end_date: end, classe_id: classe });
      if (response.status === 200) {
        dispatch(viewAbsenceSuccess(response.data.payload));
      } else {
        dispatch(viewAbsenceErr('err'));
      }
    } catch (err) {
      dispatch(viewAbsenceErr('err'));
    }
  };
};

const viewSingleAbsenceData = (id) => {
  return async (dispatch) => {
    dispatch(viewSingleAbsenceBegin());
    try {
      const response = await DataService.get(`/absence/singleInfo/${id}`);
      if (response.status === 200) {
        dispatch(viewSingleAbsenceSuccess(response.data.payload));
      } else {
        dispatch(viewSingleAbsenceErr('err'));
      }
    } catch (err) {
      dispatch(viewSingleAbsenceErr('err'));
    }
  };
};

const addAbsenceData = (data, callback) => {
  return async (dispatch) => {
    dispatch(addAbsenceBegin());
    try {
      const response = await DataService.post('/absence/add', data);
      if (response.status === 200) {
        dispatch(addAbsenceSuccess(response.data.payload));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(addAbsenceErr('err'));

      }
    } catch (err) {
      dispatch(addAbsenceErr('err'));
    }
  };
};
const deleteAbsenceData = (id) => {
  return async (dispatch) => {
    dispatch(deleteAbsenceBegin());
    try {
      const response = await DataService.delete(`/absence/delete/${id}`);
      if (response.status === 200) {
        dispatch(deleteAbsenceSuccess(id));
        message.success(response.data.message);
      } else {
        dispatch(deleteAbsenceErr('err'));
      }
    } catch (err) {
      console.error(err);
      dispatch(deleteAbsenceErr('err'));    }
  };
};
const updateAbsenceData = (data, callback) => {
  return async (dispatch) => {
    dispatch(updateAbsenceBegin());
    try {
      const response = await DataService.put(`/absence/updated/${data.id}`, data);
      if (response.status === 200) {
        dispatch(updateAbsenceSuccess(response.data.payload));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(updateAbsenceErr('err'));
      }
    } catch (err) {
      dispatch(updateAbsenceErr('err'));
    }
  };
};
const changeAbsenceDateData = (start, end, classe) => {
  return async (dispatch) => {
    dispatch(changeStartDateBegin());
    dispatch(changeEndDateBegin());
    dispatch(changeSelectedClasseBegin());
    try {
      dispatch(changeStartDateSuccess(start));
      dispatch(changeEndDateSuccess(end));
      dispatch(changeSelectedClasseSuccess(classe));

      viewAbsenceData(start, end, classe)(dispatch);
    } catch (err) {
      dispatch(changeStartDateErr('err'));
      dispatch(changeEndDateErr('err'));
      dispatch(changeSelectedClasseErr('err'));
    }
  };
};

export {
  changeAbsenceDateData,
  viewAbsenceData,
  viewSingleAbsenceData,
  updateAbsenceData,
  deleteAbsenceData,
  addAbsenceData,
};
