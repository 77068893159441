import { DatePicker, Form, Input, TimePicker, message } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button } from '../../components/buttons/buttons';
import { BasicFormWrapper } from '../styled';
import { ReunionFormStyleWrap } from './Style';
import { string } from 'prop-types';

function ReunionForm({ onHandleAddEvent, eventData }) {
  const [titre, setTitle] = useState('');
  const [heurD, setStart] = useState(null);
  const [heurF, setEnd] = useState(null);
  const [date, setDate] = useState(null);
  const [description, setDescription] = useState('');

  const [form] = Form.useForm();

  useEffect(() => {
    setTitle(eventData && eventData.titre ? eventData.titre : '');
    setStart(eventData && eventData.heurD ? eventData.heurD : null);
    setEnd(eventData && eventData.heurF ? eventData.heurF : null);
    setDate(eventData && eventData.date ? moment(eventData.date) : null);
    setDescription(eventData && eventData.description ? eventData.description : '');
  }, [eventData]);

  const reset = () => {
    setTitle('');
    setStart(null);
    setEnd(null);
    setDate(null);
    setDescription('');
  };

  const handleSubmit = () => {
    const data = {};
    data.titre = titre;
    data.heurD = heurD.format('HH:mm:ss');
    data.heurF = heurF.format('HH:mm:ss');
    if (heurD > heurF) {
      return message.error('Start hour must be before end hour');
    }
    data.date = date.format('yyyy-MM-DD');
    data.description = description;
    if (eventData && eventData.id) {
      data.id = eventData.id;
    }
    onHandleAddEvent(data);
  };

  return (
    <BasicFormWrapper>
      <ReunionFormStyleWrap>
        <Form form={form} name="addReunion" onFinish={handleSubmit}>
          <div className="edulink-event-form-input">
            <span className="edulink-event-form-label">Titre</span>
            <Form.Item rules={[{ required: true, message: 'Titre de reunion est obligatoire !' }]}>
              <Input placeholder="Choisir un titre" value={titre} onChange={(e) => setTitle(e.target.value)} />
            </Form.Item>
          </div>
          <div className="edulink-event-timeselection">
            <span className="edulink-event-timeselection__label">Date:</span>
            <div className="edulink-event-timeselection__input">
              <Form.Item rules={[{ required: true, message: 'Date de reunion est obligatoire !' }]}>
                <DatePicker format={'MM-DD-YYYY'} value={date && moment(date)} onChange={(e) => setDate(e)} />
              </Form.Item>
            </div>
          </div>
          <div className="edulink-event-timeselection">
            <span className="edulink-event-timeselection__label">Debut:</span>
            <div className="edulink-event-timeselection__input">
              <Form.Item rules={[{ required: true, message: 'Heure debut de reunion est obligatoire !' }]}>
                <TimePicker format="HH:mm" value={heurD} onChange={(e) => setStart(e)} />
              </Form.Item>
            </div>
          </div>
          <div className="edulink-event-timeselection">
            <span className="edulink-event-timeselection__label">Fin:</span>
            <div className="edulink-event-timeselection__input">
              <Form.Item rules={[{ required: true, message: 'Heure fin de reunion est obligatoire !' }]}>
                <TimePicker format="HH:mm" value={heurF} onChange={(e) => setEnd(e)} />
              </Form.Item>
            </div>
          </div>
          <div className="edulink-event-form-input">
            <span className="edulink-event-form-label">Description:</span>
            <Form.Item rules={[{ required: true, message: 'Description de reunion est obligatoire !' }]}>
              <Input.TextArea
                placeholder="Ecrire une description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="add-event-footer text-right">
            <Button className="ant-btn ant-btn-white" onClick={reset}>
              Reset
            </Button>
            <Button htmlType="submit" className="btn-save" type="primary">
              Enregistrer
            </Button>
          </div>
        </Form>
      </ReunionFormStyleWrap>
    </BasicFormWrapper>
  );
}

ReunionForm.propTypes = {
  // eventData: PropTypes.object,
  onHandleAddEvent: PropTypes.func,
};

export default ReunionForm;
