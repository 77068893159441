import { message } from 'antd';
import actions from './actions';

const initialState = {
  facture: null,
  loading: false,
  error: null,
};

const { VIEW_FACTURE_BEGIN, VIEW_FACTURE_SUCCESS, VIEW_FACTURE_ERR } = actions;

const ParentFactureReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case VIEW_FACTURE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_FACTURE_SUCCESS:
      return {
        ...state,
        facture: data,
        loading: false,
      };
    case VIEW_FACTURE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default ParentFactureReducer;
