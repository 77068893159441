import { message } from 'antd';
import actions from './actions';

const initialState = {
  arrayData: [],
  singleData: null,
  attached: null,
  loading: false,
  error: null,
};

const {
  VIEW_CLUB_BEGIN,
  VIEW_CLUB_SUCCESS,
  VIEW_CLUB_ERR,

  VIEW_SINGLE_CLUB_BEGIN,
  VIEW_SINGLE_CLUB_SUCCESS,
  VIEW_SINGLE_CLUB_ERR,

  ATTACH_CLUB_BEGIN,
  ATTACH_CLUB_SUCCESS,
  ATTACH_CLUB_ERR,

  ADD_CLUB_BEGIN,
  ADD_CLUB_SUCCESS,
  ADD_CLUB_ERR,

  DELETE_CLUB_BEGIN,
  DELETE_CLUB_SUCCESS,
  DELETE_CLUB_ERR,

  UPDATE_CLUB_BEGIN,
  UPDATE_CLUB_SUCCESS,
  UPDATE_CLUB_ERR,
} = actions;

const ClubReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_CLUB_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CLUB_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case UPDATE_CLUB_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_CLUB_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_CLUB_SUCCESS:
      return {
        ...state,
        arrayData: data,
        loading: false,
      };
    case VIEW_CLUB_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ATTACH_CLUB_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ATTACH_CLUB_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ATTACH_CLUB_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_SINGLE_CLUB_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_SINGLE_CLUB_SUCCESS:
      return {
        ...state,
        singleData: data,
        loading: false,
      };
    case VIEW_SINGLE_CLUB_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_CLUB_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CLUB_SUCCESS:
      const deletedData = state.arrayData.filter((item) => item.id != data);
      return {
        ...state,
        arrayData: deletedData,
        loading: false,
      };
    case DELETE_CLUB_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_CLUB_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_CLUB_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case ADD_CLUB_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default ClubReducer;
