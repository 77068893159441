import UilSearch from '@iconscout/react-unicons/icons/uil-search';
import { Input, Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { DataTableStyleWrap } from './Style';
import { TableWrapper } from '../../container/styled';
import { Button } from '../buttons/buttons';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import { Link } from 'react-router-dom';

function DataTable({ filterOption, buttonName, btnAction, rowSelection, tableData, columns, pagination = true }) {
  return (
    <DataTableStyleWrap>
      {filterOption ? (
        <div className="edulink-datatable-filter">
          <div className="edulink-datatable-filter__right"></div>
          <Link className="view" to={btnAction ? '#' : 'form'} onClick={btnAction}>
            <Button key="1" type="danger" size="default" style={{ borderRadius: '17px' }}>
              <UilPlus /> {buttonName}
            </Button>
          </Link>
        </div>
      ) : (
        ''
      )}

      <div className="ninjadasj-datatable">
        <TableWrapper className="table-data-view table-responsive">
          {rowSelection ? (
            <Table
              pagination={pagination}
              rowSelection={{
                ...rowSelection,
              }}
              dataSource={tableData}
              columns={columns}
            />
          ) : (
            <Table pagination={pagination} dataSource={tableData} columns={columns} />
          )}
        </TableWrapper>
      </div>
    </DataTableStyleWrap>
  );
}

DataTable.propTypes = {
  filterOption: PropTypes.bool,
  filterOnchange: PropTypes.bool,
  rowSelection: PropTypes.bool,
  tableData: PropTypes.array,
  columns: PropTypes.array,
};
export default DataTable;
