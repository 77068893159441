import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../../config/dataService/dataService';

const { viewReunionBegin, viewReunionSuccess, viewReunionErr } = actions;

const viewReunionData = (start, end) => {
  return async (dispatch) => {
    dispatch(viewReunionBegin());
    try {
      const response = await DataService.get('/reunions/index', { start_date: start, end_date: end });
      if (response.status === 200) {
        dispatch(viewReunionSuccess(response.data.reunions));
      } else {
        dispatch(viewReunionErr('err'));
      }
    } catch (err) {
      dispatch(viewReunionErr('err'));
    }
  };
};

export { viewReunionData };
