import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import NotFound from '../../container/pages/404';
import User from '../../container/users/User';
function PagesRoute() {
  return (
    <Routes>
      <Route index element={<User />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
