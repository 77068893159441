import UilCalendar from '@iconscout/react-unicons/icons/uil-calendar-alt';
import UilClock from '@iconscout/react-unicons/icons/uil-clock';
import { Card, Col } from 'antd';
import propTypes from 'prop-types';
import React from 'react';
import { CourseCardWrap } from './Style';

function EventCard({ data, onClick }) {
  const [date, heur] = data.dateHeur.split(' ');
  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
  };

  return (
    <Col xxl={6} lg={8} sm={12} xs={24}>
      <CourseCardWrap className="edulink-course-card-single" onClick={() => onClick()}>
        <Card bordered={false}>
          <div className="edulink-course-card-thumbnail">
            <img
              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${data.image}`}
              style={{ borderRadius: '10px', width: '100%', height: '180px', objectFit: 'cover' }}
            />
          </div>
          <div className="edulink-course-card-content">
            <h4 className="edulink-course-card-title">{data.titre}</h4>

            <div className="edulink-course-card-meta">
              <div className="edulink-course-card-meta__left">
                <span className="edulink-course-card-meta__pricing">{data.frais} DT</span>
              </div>
              <ul className="edulink-course-card-meta__right">
                <li className="bg-primary">
                  <UilCalendar />
                  <span>{date}</span>
                </li>
                <li className="bg-primary">
                  <UilClock />
                  <span>{formatTime(heur)}</span>
                </li>
              </ul>
            </div>
          </div>
        </Card>
      </CourseCardWrap>
    </Col>
  );
}

export default EventCard;
