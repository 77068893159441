import { DatePicker, Form, Input, Modal, Select } from 'antd';
import React, { useState } from 'react';
import { BasicFormWrapper } from '../styled';
import { Button } from '../../components/buttons/buttons';
import { ReunionFormStyleWrap } from './Style';

function Update({ open, handleAddCancel, action, typesCharge }) {
  const [form] = Form.useForm();
  const [type, setType] = useState();
  const handleChange = (value) => {
    setType(value);
  };

  const handleSubmit = (values) => {
    values.date = values.date.format('yyyy-MM-DD');
    action(values);
  };
  return (
    <Modal
      className="edulink-event-form"
      footer={null}
      closable
      type="primary"
      title={'Nouveau charge'}
      open={open}
      onCancel={handleAddCancel}
    >
      <BasicFormWrapper>
        <ReunionFormStyleWrap>
          <Form form={form} name="addReunion" onFinish={handleSubmit}>
            <div className="edulink-event-form-input">
              <span className="edulink-event-form-label">Code :</span>
              <Form.Item name="code" rules={[{ required: true, message: 'Le code est obligatoire !' }]}>
                <Input placeholder="Choisir un nom" />
              </Form.Item>
            </div>
            <div className="edulink-event-form-input">
              <span className="edulink-event-form-label">Type :</span>
              <Form.Item name="nomListCharge" rules={[{ required: true, message: 'Le type est obligatoire !' }]}>
                <Select
                  options={[
                    { value: '-1', label: 'Nouvelle charge' },
                    ...typesCharge.map((item) => {
                      return { value: item.nom, label: item.nom };
                    }),
                  ]}
                  onChange={handleChange}
                  placeholder="Choisir un type"
                />
              </Form.Item>
            </div>
            {type == '-1' && (
              <div className="edulink-event-form-input">
                <span className="edulink-event-form-label">Nom Type :</span>
                <Form.Item name="newType" rules={[{ required: true, message: 'Le type est obligatoire !' }]}>
                  <Input placeholder="Choisir un type" />
                </Form.Item>
              </div>
            )}
            <div className="edulink-event-form-input">
              <span className="edulink-event-form-label">Date :</span>
              <Form.Item name="date" rules={[{ required: true, message: 'La date est obligatoire !' }]}>
                <DatePicker format="YYYY-MM-DD" />
              </Form.Item>
            </div>
            <div className="edulink-event-form-input">
              <span className="edulink-event-form-label">Lié à :</span>
              <Form.Item name="nom">
                <Input />
              </Form.Item>
            </div>
            <div className="edulink-event-form-input">
              <span className="edulink-event-form-label">Montant :</span>
              <Form.Item name="montantF" rules={[{ required: true, message: 'Le montant est obligatoire !' }]}>
                <Input placeholder="Choisir le montant" />
              </Form.Item>
            </div>
            <div className="edulink-event-form-input">
              <span className="edulink-event-form-label">Payé :</span>
              <Form.Item name="montantP" rules={[{ required: true, message: 'Le montant payée est obligatoire !' }]}>
                <Input placeholder="Choisir le montant payé" defaultValue={'0'} />
              </Form.Item>
            </div>

            <div className="add-event-footer text-right">
              <Button htmlType="submit" className="btn-save" type="primary">
                Enregistrer
              </Button>
            </div>
          </Form>
        </ReunionFormStyleWrap>
      </BasicFormWrapper>
    </Modal>
  );
}

export default Update;
