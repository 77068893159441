import { message } from 'antd';
import actions from './actions';

const initialState = {
  rooms: [],
  currentMessages: [],
  loading: false,
  error: null,
};

const {
  GET_ROOMS_BEGIN,
  GET_ROOMS_SUCCESS,
  GET_ROOMS_ERR,

  GET_MESSAGES_BEGIN,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_ERR,

  ADD_MESSAGE_BEGIN,
  ADD_MESSAGE_SUCCESS,
  ADD_MESSAGE_ERR,

  UPDATE_CHAT_MESSAGES_BEGIN,
  UPDATE_CHAT_MESSAGES_SUCCESS,
  UPDATE_CHAT_MESSAGES_ERR,
} = actions;

const chatReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_ROOMS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ROOMS_SUCCESS:
      return {
        ...state,
        rooms: data,
        loading: false,
      };
    case GET_ROOMS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_MESSAGES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        currentMessages: data,
        loading: false,
      };
    case GET_MESSAGES_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_MESSAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_MESSAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_CHAT_MESSAGES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        currentMessages: [...state.currentMessages, data],
        loading: false,
      };
    case UPDATE_CHAT_MESSAGES_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { chatReducer };
