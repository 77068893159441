import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { viewFactureData } from '../inscription/actionCreator';

const {
  addEventBegin,
  addEventSuccess,
  addEventErr,
  updateEventBegin,
  updateEventSuccess,
  updateEventErr,
  viewEventBegin,
  viewEventSuccess,
  viewEventErr,
  viewSingleEventBegin,
  viewSingleEventSuccess,
  viewSingleEventErr,
  deleteEventBegin,
  deleteEventSuccess,
  deleteEventErr,
  attachEventBegin,
  attachEventSuccess,
  attachEventErr,
} = actions;

const viewEventData = () => {
  return async (dispatch) => {
    dispatch(viewEventBegin());
    try {
      const response = await DataService.get('/evenement/view');
      if (response.status === 200) {
        dispatch(viewEventSuccess(response.data.payload));
      } else {
        dispatch(viewEventErr('err'));
      }
    } catch (err) {
      dispatch(viewEventErr('err'));
    }
  };
};

const viewSingleEventData = (id) => {
  return async (dispatch) => {
    dispatch(viewSingleEventBegin());
    try {
      const response = await DataService.get(`/evenement/singleInfo/${id}`);
      if (response.data.errors) {
        dispatch(viewSingleEventSuccess(response.data.payload));
      } else {
        dispatch(viewSingleEventErr('err'));
      }
    } catch (err) {
      dispatch(viewSingleEventErr('err'));
    }
  };
};

const addEventData = (data, callback) => {
  return async (dispatch) => {
    dispatch(addEventBegin());
    try {
      const response = await DataService.post('/evenement/add', data);
      if (response.status === 200) {
        dispatch(addEventSuccess(true));
        message.success(response.data.message);
        callback();
      } else {
        dispatch(addEventErr('err'));
      }
    } catch (err) {
      dispatch(addEventErr('err'));
    }
  };
};
const deleteEventData = (id) => {
  return async (dispatch) => {
    dispatch(deleteEventBegin());
    try {
      const response = await DataService.delete(`/evenement/delete/${id}`);

      if (response.status === 200) {
        dispatch(deleteEventSuccess(id));
        message.success(response.data.message);
      } else {
        dispatch(deleteEventErr('err'));
      }
    } catch (err) {
      dispatch(deleteEventErr('err'));
    }
  };
};
const updateEventData = (id, data, callback) => {
  return async (dispatch) => {
    dispatch(updateEventBegin());
    try {
      const response = await DataService.post(`/evenement/updated/${id}`, data);
      if (response.status === 200) {
        dispatch(updateEventSuccess(true));
        message.success(response.data.message);
        callback();
      } else {
        dispatch(updateEventErr('err'));
      }
    } catch (err) {
      dispatch(updateEventErr('err'));
    }
  };
};
const AttachEvent = (id, club, callback) => {
  return async (dispatch) => {
    dispatch(attachEventBegin());
    try {
      const response = await DataService.post(`/inscription/attachEvenement/${id}/${club}`);
      if (response.status === 200) {
        dispatch(attachEventSuccess(response.data.payload));
        message.success(response.data.message);
        dispatch(viewFactureData(id));

        callback();
      } else {
        dispatch(attachEventErr('err'));
      }
    } catch (err) {
      dispatch(attachEventErr('err'));
    }
  };
};

export { viewEventData, viewSingleEventData, updateEventData, deleteEventData, addEventData, AttachEvent };
