import UilCalendar from '@iconscout/react-unicons/icons/uil-calendar-alt';
import { Card, Col } from 'antd';
import propTypes from 'prop-types';
import React from 'react';
import { CourseCardWrap } from './Style';

function CantineCard({ jour, data, onClick }) {
  return (
    <Col xxl={6} lg={8} sm={12} xs={24}>
      <CourseCardWrap className="edulink-course-card-single" onClick={() => onClick()}>
        <Card bordered={false}>
          <div className="edulink-course-card-thumbnail">
            <img
              src={require(`../../static/img/cantine/cantine.png`)}
              style={{ borderRadius: '10px', width: '100%', height: '180px', objectFit: 'cover' }}
            />
          </div>
          <div className="edulink-course-card-content">
            <div className="edulink-course-card-meta">
              <ul className="edulink-course-card-meta__right">
                <li className="bg-primary">
                  <UilCalendar />
                  <span>{jour}</span>
                </li>
                <li className="bg-primary">
                  <span>{data ? data.length : 'Aucun'} Repas</span>
                </li>
              </ul>
            </div>
          </div>
        </Card>
      </CourseCardWrap>
    </Col>
  );
}

CantineCard.propTypes = {
  courseData: propTypes.object,
};

export default CantineCard;
