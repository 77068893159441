import Styled from 'styled-components';



const VerticalFormStyleWrap = Styled.div`
    .form-title{
        color: ${({ theme }) => theme['danger-color']}; 
        text-align: center;
        font-size: 30px;
        line-height: 24px;
        margin-bottom:40px;
    }
    .ant-picker{
        min-height: 50px;
        width:100%;
    }
    .ant-picker-input > input{
        margin: 12px 20px;
        color:black;
        &::placeholder{
            color: #bfbfbf !important;
        }
    }
    .ant-upload.ant-upload-drag{
        border-radius: 29px;
        border: 1px dashed #00AEDB;
        border-spacing: 15px;
    }
    .ant-card{
        margin-bottom: 25px
    }
    .ant-input-affix-wrapper > input.ant-input{
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .ant-input-affix-wrapper .ant-input-prefix svg{
        width: 16px;
        height: 16px;
        color: ${({ theme }) => theme['gray-color']};
    }
    .edulink-form-action{
        margin: -7.5px;
        button{
            font-size: 14px;
            font-weight: 500;
            border-radius: 6px;
            margin: 7.5px;
            padding: 6.4px 19px;
            &.ant-btn-light{
                height: 44px;
                color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
                background-color: ${({ theme }) => theme[theme.mainContent]['border-color-default']};
                border-color: ${({ theme }) => theme[theme.mainContent]['border-color-default']};
            }
            &.ant-btn-danger{
                color: white;
                background: ${({ theme }) => theme['danger-color']};
                border:none;
            }
            &:hover{
                &.ant-btn-danger{
                    color: white;
                    background: ${({ theme }) => theme['danger-hover']};
                }
            }
        }
        .ant-form-item{
            margin-bottom: 25px !important;
        }
        .ant-btn-light{
            background-color: ${({ theme }) => theme[theme.mainContent]['main-background-light']};
        }
    }
`;
const FormValidationWrap = Styled.div`
    .ant-card-body{
        padding: 30px 25px 50px 25px !important;
    }
    .ant-form-item-has-error{
        .ant-form-item-explain{
            font-size: 13px;
        }
    }
    .ant-form-item{
        .ant-form-item-label{
            >label{
                color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
            }
        }
    }
    .sDash_agree-check{
        .ant-form-item-control-input{
            min-height: auto;
        }
        .ant-form-item-has-error{
            .ant-checkbox-wrapper{
                span{
                    font-size: 13px;
                    color: ${({ theme }) => theme['danger-color']};
                }
                .ant-checkbox{
                    border-color: ${({ theme }) => theme['danger-color']}30;
                    &.ant-checkbox-checked{
                        border-color: ${({ theme }) => theme['danger-color']}30;
                        &:after{
                            border-color: ${({ theme }) => theme['danger-color']}30;
                        }
                        .ant-checkbox-inner{
                            &:after{
                                border-color: ${({ theme }) => theme['danger-color']};
                            }
                        }
                    }
                    .ant-checkbox-inner{
                        border-color: ${({ theme }) => theme['danger-color']}30;
                        background-color: ${({ theme }) => theme['danger-color']}30;
                    }
                }
            }
        }
        .ant-form-item-explain{
            margin-left: 25px;
            font-size: 13px;
        }
    }
    .edulink-form-action{
        display:flex;
        justify-content:end;
        button{
            border-radius: 7px;
            padding: 6.4px 29.2px;
            height: 44px;
        }
    }
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, 
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover{
        background-color: ${({ theme }) => theme[theme.mainContent]['input-bg']};
    }
    .ant-picker-status-error {
         border-color: #ff4d4f;
    }
    .ant-upload-status-error{
        background-color:red;
    }
    
`;
const DetailsWrapper = Styled.div`
    .product-details-box{
        padding: 25px 15px;
        @media only screen and (max-width: 575px){
            padding: 0;
        }
    }
    .product-details-box__left{
        figure{
            margin-bottom: 0;
            img{
                border-radius: 10px;
            }
        }
    }
    .pdbl__slider{
        margin-top: 15px;
    }
    .pdbl__image{
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 6px;
        img{
            border-radius: 10px;
            max-width: 90px;
            @media only screen and (max-width: 991px){
                margin-bottom: 10px;
            }
        }
    }
    .product-details-box__right{
        @media only screen and (max-width: 991px){
            margin-top: 20px;
        }
        p{
            margin-bottom: 8px;
            color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
        }
        .ant-rate{
            margin-bottom: 6px;
            position: relative;
            top: -3px;
        }
        .ant-rate-star:not(:last-child){
            ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 2px !important;
        }
        .ant-rate-star-first,
        .ant-rate-star-second{
            .anticon svg{
                color: #FA8B0C;
            }
        }
    }
    .pdbr__title{
        margin-bottom: 10px;
        color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
    }
    .pdbr__rating{
        display: inline-block;
        margin: ${({ theme }) => (theme.rtl ? '0 8px 0 4px' : '0 4px 0 8px')};
        font-size: 15px;
        font-weight: 600;
        color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
    }
    .pdbr__review-count{
        font-size: 15px;
        font-weight: 400;
        color: ${({ theme }) => theme[theme.mainContent]['extra-light-text']};
    }
    .pdbr__brand-text{
        font-size: 13px;
        display: inline-block;
        margin: 0 6px 8px 0;
        color: ${({ theme }) => theme[theme.mainContent]['gray-light-text']};
    }
    .pdbr__brand-name{
        font-size: 13px;
        font-weight: 500;
        color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
    }
    .pdbr__new-price{
        font-size: 22px;
        font-weight: 500;
        margin: 18px 0 8px;
        color: ${({ theme }) => theme['primary-color']};
        .pdbr__currency{
            font-size: 14px;
            color: ${({ theme }) => theme[theme.mainContent]['gray-light-text']};
        }
    }
    .actualite-desc{
        font-size: 15px;
    }
    .pdbr__desc{
        font-size: 15px;
        max-width: 580px;
    }
    .pdbr__old-price{
        display: inline-flex;
        align-items: center;
        margin-bottom: 22px;
        del{
            font-size: 16px;
            font-weight: 400;
            color: ${({ theme }) => theme[theme.mainContent]['extra-light-text']};
        }
        .pdbr__offer-price{
            display: inline-block;
            ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 8px;
            font-size: 12px;
            color: ${({ theme }) => theme['secondary-color']};
        }
    }

    .pdbr__current-status{
        margin-top: 25px;
        p{
            margin-bottom: 2px;
        }
        .current-status-title{
            font-weight: 500;
            margin-right: 30px;
            color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
            @media only screen and (max-width: 1000px){
                margin-right: 15px;
            }
        }
        .stock-status{
            &.in-stock{
                font-weight: 500;
                color: ${({ theme }) => theme['success-color']};
            }
        }
        .shipping-cost{
            color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
        }
    }

    .pdbr__quantity{
        font-weight: 500;
        margin: 30px 0 30px !important;
        color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
        button{
            min-height: 38px;
            background-color: ${({ theme }) => theme[theme.mainContent]['general-background']};
            &.btn-inc{
                margin-right: 15px;
            }
            &.btn-dec{
                margin-left: 15px;
            }
            span{
                font-size: 16px;
                color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
            }
        }
        .pdbr__availability{
            font-size: 13px;
            font-weight: 400;
            margin-left: 15px;
            color: ${({ theme }) => theme[theme.mainContent]['gray-light-text']};
        }
    }
    .pdbr__Actions{
        margin-top:50px;
        @media only screen and (max-width: 1399px){
            flex-flow: column;
            align-items: flex-start;
        }
        .pdbr__product-action{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .btn-cart{
                padding: 0 26.35px;
            }
            .btn-buy{
                padding: 0 29.85px;
            }
            .btn-cart,
            .btn-buy{
                border-radius: 6px;
                height: 44px;
            }
            button,
            a{
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
                @media only screen and (max-width: 1399px){
                    margin-bottom: 20px;
                }
                &:focus{
                    svg{
                        fill: #5a5f7d;
                    }
                }
            }
            .btn-icon{
                height: 40px;
                padding: 0 13px;
                box-shadow: 0 5px 15px ${({ theme }) => theme['extra-light-color']}15;
                &:hover{
                    background: transparent;
                }
                i{
                    color: #707070;
                }
            }
            .btn-heart{
                svg{
                    fill: ${({ theme }) => theme[theme.mainContent]['extra-light-text']};
                }
                &.favourite{
                    fill: ${({ theme }) => theme['primary-color']}
                }
            }
        }
        .pdbr__socials{
            margin: 0px 0 0 5px;
            a{
                color: #666666;
                &:not(:last-child){
                    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 12px;
                }
                span{
                    font-size: 14px;
                    color: #666666;
                }
                &:hover{
                    span{
                        color: ${({ theme }) => theme[theme.mainContent]['menu-active']};
                    }
                }
            }
        }
    }

    .pdbr__list{
        &:not(:last-child){
            margin-bottom: 10px;
        }
        li{
            span{
                &:first-child{
                    display: inline-block;
                    min-width: 66px;
                    font-weight: 500;
                    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 25px;
                    color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
                }
            }
            span + span{
                color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
            }
        }
    }
    .btn-cart span {
        ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 6px;
    }
`;
export {
  VerticalFormStyleWrap,
  FormValidationWrap,
  DetailsWrapper
};
