import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  addReunionBegin,
  addReunionSuccess,
  addReunionErr,
  updateReunionBegin,
  updateReunionSuccess,
  updateReunionErr,
  viewReunionBegin,
  viewReunionSuccess,
  viewReunionErr,
  viewSingleReunionBegin,
  viewSingleReunionSuccess,
  viewSingleReunionErr,
  deleteReunionBegin,
  deleteReunionSuccess,
  deleteReunionErr,
  changeStartDateBegin,
  changeStartDateSuccess,
  changeStartDateErr,
  changeEndDateBegin,
  changeEndDateSuccess,
  changeEndDateErr,
  changeSelectedClasseBegin,
  changeSelectedClasseSuccess,
  changeSelectedClasseErr,
} = actions;

const viewReunionData = (start, end, classe) => {
  return async (dispatch) => {
    dispatch(viewReunionBegin());
    try {
      const response = await DataService.get('/reunion/view', { start_date: start, end_date: end, classe });
      if (response.status === 200) {
        dispatch(viewReunionSuccess(response.data.reunions));
      } else {
        dispatch(viewReunionErr('err'));
      }
    } catch (err) {
      dispatch(viewReunionErr('err'));
    }
  };
};

const viewSingleReunionData = (id) => {
  return async (dispatch) => {
    dispatch(viewSingleReunionBegin());
    try {
      const response = await DataService.get(`/reunion/singleInfo/${id}`);
      if (response.status === 200) {
        dispatch(viewSingleReunionSuccess(response.data.payload));
      } else {
        dispatch(viewSingleReunionErr('err'));
      }
    } catch (err) {
      dispatch(viewSingleReunionErr('err'));
    }
  };
};

const addReunionData = (data, callback, errCallback) => {
  return async (dispatch) => {
    dispatch(addReunionBegin());
    try {
      const response = await DataService.post('/reunion/add', data);

      if (response.status === 201) {
        dispatch(addReunionSuccess(response.data.payload));
        callback();
      } else {
        dispatch(addReunionErr('err'));
        errCallback();
      }
    } catch (err) {
      dispatch(addReunionErr('err'));
    }
  };
};
const addNiveauReunionData = (data, niveau, callback) => {
  return async (dispatch) => {
    dispatch(addReunionBegin());
    try {
      const response = await DataService.post(`/reunion/add?classe=${niveau}`, data);

      if (response.status === 201) {
        dispatch(addReunionSuccess(response.data.payload));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(addReunionErr('err'));
      }
    } catch (err) {
      dispatch(addReunionErr('err'));
    }
  };
};

const deleteReunionData = (id) => {
  return async (dispatch) => {
    dispatch(deleteReunionBegin());
    try {
      const response = await DataService.delete(`/reunion/delete/${id}`);
      dispatch(deleteReunionSuccess(id));
      message.success(response.data.message);
    } catch (err) {
      dispatch(deleteReunionErr('err'));
    }
  };
};

const updateReunionData = (data, callback) => {
  return async (dispatch) => {
    dispatch(updateReunionBegin());
    try {
      const response = await DataService.put(`/reunion/updated/${data.id}`, data);

      if (response.status === 200) {
        dispatch(updateReunionSuccess(response.data.payload));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(updateReunionErr('err'));
      }
    } catch (err) {
      dispatch(updateReunionErr('err'));
    }
  };
};
const changeReunionDateData = (start, end, classe) => {
  return async (dispatch) => {
    dispatch(changeStartDateBegin());
    dispatch(changeEndDateBegin());
    dispatch(changeSelectedClasseBegin());
    try {
      dispatch(changeStartDateSuccess(start));
      dispatch(changeEndDateSuccess(end));
      dispatch(changeSelectedClasseSuccess(classe));

      viewReunionData(start, end, classe)(dispatch);
    } catch (err) {
      dispatch(changeStartDateErr('err'));
      dispatch(changeEndDateErr('err'));
      dispatch(changeSelectedClasseErr('err'));
    }
  };
};

export {
  changeReunionDateData,
  viewReunionData,
  viewSingleReunionData,
  updateReunionData,
  deleteReunionData,
  addReunionData,
  addNiveauReunionData,
};
