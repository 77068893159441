const actions = {
  VIEW_EMPLOI_BEGIN: 'VIEW_EMPLOI_BEGIN',
  VIEW_EMPLOI_SUCCESS: 'VIEW_EMPLOI_SUCCESS',
  VIEW_EMPLOI_ERR: 'VIEW_EMPLOI_ERR',

  VIEW_SINGLE_EMPLOI_BEGIN: 'VIEW_SINGLE_EMPLOI_BEGIN',
  VIEW_SINGLE_EMPLOI_SUCCESS: 'VIEW_SINGLE_EMPLOI_SUCCESS',
  VIEW_SINGLE_EMPLOI_ERR: 'VIEW_SINGLE_EMPLOI_ERR',

  ADD_EMPLOI_BEGIN: 'ADD_EMPLOI_BEGIN',
  ADD_EMPLOI_SUCCESS: 'ADD_EMPLOI_SUCCESS',
  ADD_EMPLOI_ERR: 'ADD_EMPLOI_ERR',

  DELETE_EMPLOI_BEGIN: 'DELETE_EMPLOI_BEGIN',
  DELETE_EMPLOI_SUCCESS: 'DELETE_EMPLOI_SUCCESS',
  DELETE_EMPLOI_ERR: 'DELETE_EMPLOI_ERR',

  UPDATE_EMPLOI_BEGIN: 'UPDATE_EMPLOI_BEGIN',
  UPDATE_EMPLOI_SUCCESS: 'UPDATE_EMPLOI_SUCCESS',
  UPDATE_EMPLOI_ERR: 'UPDATE_EMPLOI_ERR',

  viewEmploiBegin: () => {
    return {
      type: actions.VIEW_EMPLOI_BEGIN,
    };
  },

  viewEmploiSuccess: (data) => {
    return {
      type: actions.VIEW_EMPLOI_SUCCESS,
      data,
    };
  },

  viewEmploiErr: (err) => {
    return {
      type: actions.VIEW_EMPLOI_ERR,
      err,
    };
  },

  viewSingleEmploiBegin: () => {
    return {
      type: actions.VIEW_SINGLE_EMPLOI_BEGIN,
    };
  },

  viewSingleEmploiSuccess: (data) => {
    return {
      type: actions.VIEW_SINGLE_EMPLOI_SUCCESS,
      data,
    };
  },

  viewSingleEmploiErr: (err) => {
    return {
      type: actions.VIEW_SINGLE_EMPLOI_ERR,
      err,
    };
  },

  updateEmploiBegin: () => {
    return {
      type: actions.UPDATE_EMPLOI_BEGIN,
    };
  },

  updateEmploiSuccess: (data) => {
    return {
      type: actions.UPDATE_EMPLOI_SUCCESS,
      data,
    };
  },

  updateEmploiErr: (err) => {
    return {
      type: actions.UPDATE_EMPLOI_ERR,
      err,
    };
  },
  deleteEmploiBegin: () => {
    return {
      type: actions.DELETE_EMPLOI_BEGIN,
    };
  },

  deleteEmploiSuccess: (data) => {
    return {
      type: actions.DELETE_EMPLOI_SUCCESS,
      data,
    };
  },

  deleteEmploiErr: (err) => {
    return {
      type: actions.DELETE_EMPLOI_ERR,
      err,
    };
  },
  addEmploiBegin: () => {
    return {
      type: actions.ADD_EMPLOI_BEGIN,
    };
  },

  addEmploiSuccess: (data) => {
    return {
      type: actions.ADD_EMPLOI_SUCCESS,
      data,
    };
  },

  addEmploiErr: (err) => {
    return {
      type: actions.ADD_EMPLOI_ERR,
      err,
    };
  },
};

export default actions;
