const actions = {
  VIEW_INSCRIPTION_BEGIN: 'VIEW_INSCRIPTION_BEGIN',
  VIEW_INSCRIPTION_SUCCESS: 'VIEW_INSCRIPTION_SUCCESS',
  VIEW_INSCRIPTION_ERR: 'VIEW_INSCRIPTION_ERR',

  VIEW_FACTURE_BEGIN: 'VIEW_FACTURE_BEGIN',
  VIEW_FACTURE_SUCCESS: 'VIEW_FACTURE_SUCCESS',
  VIEW_FACTURE_ERR: 'VIEW_FACTURE_ERR',

  VIEW_SINGLE_INSCRIPTION_BEGIN: 'VIEW_SINGLE_INSCRIPTION_BEGIN',
  VIEW_SINGLE_INSCRIPTION_SUCCESS: 'VIEW_SINGLE_INSCRIPTION_SUCCESS',
  VIEW_SINGLE_INSCRIPTION_ERR: 'VIEW_SINGLE_INSCRIPTION_ERR',

  VIEW_INSCRIPTION_CLUBS_BEGIN: 'VIEW_INSCRIPTION_CLUBS_BEGIN',
  VIEW_INSCRIPTION_CLUBS_SUCCESS: 'VIEW_INSCRIPTION_CLUBS_SUCCESS',
  VIEW_INSCRIPTION_CLUBS_ERR: 'VIEW_INSCRIPTION_CLUBS_ERR',

  VIEW_INSCRIPTION_EVENTS_BEGIN: 'VIEW_INSCRIPTION_EVENTS_BEGIN',
  VIEW_INSCRIPTION_EVENTS_SUCCESS: 'VIEW_INSCRIPTION_EVENTS_SUCCESS',
  VIEW_INSCRIPTION_EVENTS_ERR: 'VIEW_INSCRIPTION_EVENTS_ERR',

  ADD_INSCRIPTION_BEGIN: 'ADD_INSCRIPTION_BEGIN',
  ADD_INSCRIPTION_SUCCESS: 'ADD_INSCRIPTION_SUCCESS',
  ADD_INSCRIPTION_ERR: 'ADD_INSCRIPTION_ERR',

  DELETE_INSCRIPTION_BEGIN: 'DELETE_INSCRIPTION_BEGIN',
  DELETE_INSCRIPTION_SUCCESS: 'DELETE_INSCRIPTION_SUCCESS',
  DELETE_INSCRIPTION_ERR: 'DELETE_INSCRIPTION_ERR',

  UPDATE_INSCRIPTION_BEGIN: 'UPDATE_INSCRIPTION_BEGIN',
  UPDATE_INSCRIPTION_SUCCESS: 'UPDATE_INSCRIPTION_SUCCESS',
  UPDATE_INSCRIPTION_ERR: 'UPDATE_INSCRIPTION_ERR',

  viewInscriptionBegin: () => {
    return {
      type: actions.VIEW_INSCRIPTION_BEGIN,
    };
  },

  viewInscriptionSuccess: (data) => {
    return {
      type: actions.VIEW_INSCRIPTION_SUCCESS,
      data,
    };
  },

  viewInscriptionErr: (err) => {
    return {
      type: actions.VIEW_INSCRIPTION_ERR,
      err,
    };
  },

  viewInscriptionClubsBegin: () => {
    return {
      type: actions.VIEW_INSCRIPTION_CLUBS_BEGIN,
    };
  },

  viewInscriptionClubsSuccess: (data) => {
    return {
      type: actions.VIEW_INSCRIPTION_CLUBS_SUCCESS,
      data,
    };
  },

  viewInscriptionClubsErr: (err) => {
    return {
      type: actions.VIEW_INSCRIPTION_CLUBS_ERR,
      err,
    };
  },

  viewInscriptionEventsBegin: () => {
    return {
      type: actions.VIEW_INSCRIPTION_EVENTS_BEGIN,
    };
  },

  viewInscriptionEventsSuccess: (data) => {
    return {
      type: actions.VIEW_INSCRIPTION_EVENTS_SUCCESS,
      data,
    };
  },

  viewInscriptionEventsErr: (err) => {
    return {
      type: actions.VIEW_INSCRIPTION_EVENTS_ERR,
      err,
    };
  },
  viewFactureBegin: () => {
    return {
      type: actions.VIEW_FACTURE_BEGIN,
    };
  },

  viewFactureSuccess: (data) => {
    return {
      type: actions.VIEW_FACTURE_SUCCESS,
      data,
    };
  },

  viewFactureErr: (err) => {
    return {
      type: actions.VIEW_FACTURE_ERR,
      err,
    };
  },

  viewSingleInscriptionBegin: () => {
    return {
      type: actions.VIEW_SINGLE_INSCRIPTION_BEGIN,
    };
  },

  viewSingleInscriptionSuccess: (data) => {
    return {
      type: actions.VIEW_SINGLE_INSCRIPTION_SUCCESS,
      data,
    };
  },

  viewSingleInscriptionErr: (err) => {
    return {
      type: actions.VIEW_SINGLE_INSCRIPTION_ERR,
      err,
    };
  },

  updateInscriptionBegin: () => {
    return {
      type: actions.UPDATE_INSCRIPTION_BEGIN,
    };
  },

  updateInscriptionSuccess: (data) => {
    return {
      type: actions.UPDATE_INSCRIPTION_SUCCESS,
      data,
    };
  },

  updateInscriptionErr: (err) => {
    return {
      type: actions.UPDATE_INSCRIPTION_ERR,
      err,
    };
  },
  deleteInscriptionBegin: () => {
    return {
      type: actions.DELETE_INSCRIPTION_BEGIN,
    };
  },

  deleteInscriptionSuccess: (data) => {
    return {
      type: actions.DELETE_INSCRIPTION_SUCCESS,
      data,
    };
  },

  deleteInscriptionErr: (err) => {
    return {
      type: actions.DELETE_INSCRIPTION_ERR,
      err,
    };
  },
  addInscriptionBegin: () => {
    return {
      type: actions.ADD_INSCRIPTION_BEGIN,
    };
  },

  addInscriptionSuccess: (data) => {
    return {
      type: actions.ADD_INSCRIPTION_SUCCESS,
      data,
    };
  },

  addInscriptionErr: (err) => {
    return {
      type: actions.ADD_INSCRIPTION_ERR,
      err,
    };
  },
};

export default actions;
