import { message } from 'antd';
import actions from './actions';

const initialState = {
  data: null,
  singleData: null,
  loading: false,
  error: null,
};

const {
  VIEW_PAIMENT_BEGIN,
  VIEW_PAIMENT_SUCCESS,
  VIEW_PAIMENT_ERR,

  VIEW_SINGLE_PAIMENT_BEGIN,
  VIEW_SINGLE_PAIMENT_SUCCESS,
  VIEW_SINGLE_PAIMENT_ERR,

  ADD_PAIMENT_BEGIN,
  ADD_PAIMENT_SUCCESS,
  ADD_PAIMENT_ERR,
} = actions;

const PaimentReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case VIEW_PAIMENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_PAIMENT_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case VIEW_PAIMENT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_SINGLE_PAIMENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_SINGLE_PAIMENT_SUCCESS:
      return {
        ...state,
        singleData: data,
        loading: false,
      };
    case VIEW_SINGLE_PAIMENT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ADD_PAIMENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_PAIMENT_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case ADD_PAIMENT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default PaimentReducer;
