import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../../config/dataService/dataService';

const { viewEmploiBegin, viewEmploiSuccess, viewEmploiErr } = actions;

const viewEmploiData = () => {
  return async (dispatch) => {
    dispatch(viewEmploiBegin());
    try {
      const response = await DataService.get('/emplois/index');
      if (response.status === 200) {
        dispatch(viewEmploiSuccess(response.data.payload));
      } else {
        dispatch(viewEmploiErr('err'));
      }
    } catch (err) {
      dispatch(viewEmploiErr('err'));
    }
  };
};

export { viewEmploiData };
