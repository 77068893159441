import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const CustomDoughnutChart = ({ labels, datasets }) => {
  const colors = ['#A1E3CB', '#1C1C1C', '#A8C5DA', '#B1E3FF', '#95A4FC'];

  const data = {
    labels: labels,
    datasets: [
      {
        data: datasets,
        backgroundColor: datasets.map(() => colors[Math.floor(Math.random() * colors.length)]),
        borderWidth: 1,
        borderColor: '#fff',
        borderRadius: 5, // Rounded edges for each segment
        spacing: 3, // Spacing between segments
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: false,
      title: {
        display: false,
      },
    },
    cutout: '50%', 
  };

  return <Doughnut data={data} options={options} />;
};

export default CustomDoughnutChart;
