import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Upload, TimePicker, DatePicker, Select, notification, Switch } from 'antd';
import { FormValidationWrap, VerticalFormStyleWrap } from './Style';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { useDispatch, useSelector } from 'react-redux';
import { updatePersonnelData, viewSinglePersonnelData } from '../../redux/personnel/actionCreator';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
function UpdatePersonnelForm() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(viewSinglePersonnelData(id));
  }, []);
  const { personnel } = useSelector((state) => {
    return {
      personnel: state.PersonnelReducer.singleData,
    };
  });
  const [cnss, setCnss] = useState(false);
  const [prime, setPrime] = useState(false);
  const [nom, setNom] = useState();
  const [prenom, setPrenom] = useState();
  const [dateNaiss, setDateNaiss] = useState();
  const [num, setNum] = useState();
  const [salaire, setSalaire] = useState();
  const [nomCompltSalaire, setNomCompltSalaire] = useState();
  const [montantCompltSalaire, setMontantCompltSalaire] = useState();
  const [role, setRole] = useState();
  const [typeCNSS, setTypeCnss] = useState();
  const [montantCNSS, setMontantCNSS] = useState();
  const [montantPrime, setMontantPrime] = useState();

  useEffect(() => {
    if (personnel) {
      setCnss(personnel.typeCNSS != 'vide');
      setPrime(personnel.montantPrime != 0);
      setNom(personnel.nom);
      setPrenom(personnel.prenom);
      setDateNaiss(moment(personnel.dateNaiss));
      setNum(personnel.num);
      setSalaire(personnel.salaire);
      setNomCompltSalaire(personnel.nomCompltSalaire);
      setMontantCompltSalaire(personnel.montantCompltSalaire);
      setRole(personnel.role);
      if (personnel.montantCNSS !== 0) {
        setCnss(true);
        setTypeCnss(personnel.typeCNSS);
        setMontantCNSS(personnel.montantCNSS);
      }
      if (personnel.montantPrime !== 0) {
        setPrime(true);
        setMontantPrime(personnel.montantPrime);
      }
    }
  }, [personnel]);

  const [form] = Form.useForm();

  const submitSuccess = () => {
    navigate(-1);
  };

  const submit = () => {
    const data = {
      nom,
      prenom,
      dateNaiss: dateNaiss.format('YYYY-MM-DD'),
      num,
      salaire,
      nomCompltSalaire,
      montantCompltSalaire,
      role,
    };
    if (cnss) {
      data.typeCNSS = typeCNSS;
      data.montantCNSS = montantCNSS;
    }
    if (prime) {
      data.montantPrime = montantPrime;
    }
    data.checkCNSS = cnss;
    data.checkPrime = prime;
    dispatch(updatePersonnelData(personnel.id, data, submitSuccess));
  };
  const handleAnnuler = () => {
    navigate(-1);
  };
  return (
    <>
      <Main>
        <Row gutter={25}>
          <Col xs={24}>
            <FormValidationWrap>
              <VerticalFormStyleWrap>
                <Cards headless>
                  <Form name="add-personnel" form={form} layout="vertical" onFinish={submit}>
                    <div className="form-title">Ajouter Personnel</div>
                    <Row gutter={30}>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Prénom Personnel"
                          rules={[{ required: true, message: 'Prénom de personnel est obligatoire !' }]}
                        >
                          <Input placeholder="Nom personnel" value={nom} onChange={(e) => setNom(e.target.value)} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Nom Personnel"
                          rules={[{ required: true, message: 'Nom de Personnel est obligatoire !' }]}
                        >
                          <Input
                            placeholder="Prénom personnel"
                            value={prenom}
                            onChange={(e) => setPrenom(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Date De Naissance"
                          rules={[{ required: true, message: 'Date de naissance est obligatoire!' }]}
                        >
                          <DatePicker
                            placeholder="Date De Naissance"
                            value={dateNaiss}
                            onChange={(e) => setDateNaiss(e)}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Téléphone"
                          rules={[{ required: true, message: 'Téléphone est obligatoire!' }]}
                        >
                          <Input placeholder="Téléphone" value={num} onChange={(e) => setNum(e.target.value)} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item label="Salaire" rules={[{ required: true, message: 'Salaire est obligatoire !' }]}>
                          <Input placeholder="Salaire" value={salaire} onChange={(e) => setSalaire(e.target.value)} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item label="Contrat" rules={[{ required: true, message: 'Contrat est obligatoire!' }]}>
                          <Select
                            style={{ width: '100%' }}
                            value={nomCompltSalaire}
                            onChange={(e) => setNomCompltSalaire(e)}
                            placeholder="Contrat"
                            options={[
                              { value: 'CIVP', label: 'CIVP' },
                              { value: 'CDD', label: 'CDD' },
                              { value: 'CDI', label: 'CDI' },
                              { value: 'Karama', label: 'Karama' },
                              { value: 'Stagiaire', label: 'Stagiaire' },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Montant Complementaire"
                          rules={[{ required: true, message: 'Montant complementaire est obligatoire !' }]}
                        >
                          <Input
                            placeholder="Montant complementaire"
                            value={montantCompltSalaire}
                            onChange={(e) => setMontantCompltSalaire(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item label="Role" rules={[{ required: true, message: 'Role est obligatoire!' }]}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Role"
                            value={role}
                            onChange={(e) => setRole(e)}
                            options={[
                              { value: 'professeur', label: 'Professeur' },
                              { value: 'Administrateur', label: 'Administrateur' },
                              { value: 'Scolarité', label: 'Scolarité' },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="switch-container">
                      <div className="label">CNSS</div>{' '}
                      <Switch checked={cnss} onChange={(e) => setCnss(e)} size="large" />
                    </div>
                    {cnss && (
                      <Row gutter={30}>
                        <Col md={12} xs={24}>
                          <Form.Item label="Type" rules={[{ required: true, message: 'Type est obligatoire!' }]}>
                            <Select
                              style={{ width: '100%' }}
                              placeholder="Type"
                              value={typeCNSS}
                              onChange={(e) => setTypeCnss(e)}
                              options={[
                                { value: 'montant', label: 'Montant' },
                                { value: 'pourcentage', label: 'Pourcentage' },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                          <Form.Item
                            label="Montant CNSS"
                            rules={[{ required: true, message: 'Montant CNSS de personnel est obligatoire !' }]}
                          >
                            <Input
                              placeholder="Montant CNSS"
                              value={montantCNSS}
                              onChange={(e) => setMontantCNSS(e.target.value)}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    <div className="switch-container">
                      <div className="label">Prime</div>{' '}
                      <Switch checked={prime} onChange={(e) => setPrime(e)} size="large" />
                    </div>
                    {prime && (
                      <Row gutter={30}>
                        <Col md={24} xs={24}>
                          <Form.Item
                            label="Montant"
                            rules={[{ required: true, message: 'Montant du prime est obligatoire !' }]}
                          >
                            <Input
                              placeholder="Montant prime"
                              value={montantPrime}
                              onChange={(e) => setMontantPrime(e.target.value)}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    <div className="edulink-form-action mt-20">
                      <Button htmlType="reset" type="light" size="large" onClick={handleAnnuler}>
                        Annuler
                      </Button>
                      <Button htmlType="submit" type="danger" size="large">
                        Ajouter
                      </Button>
                    </div>
                  </Form>
                </Cards>
              </VerticalFormStyleWrap>
            </FormValidationWrap>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default UpdatePersonnelForm;
