import { calculateTotalPaid } from "./calculateTotalPaid";

// utils/printInvoice.js
export const printInvoice = (factureToPrintData) => {
  const total = calculateTotalPaid(factureToPrintData)
  const printWindow = window.open('', '_blank');

  const invoiceHtml = `
      <html>
        <head>
          <title>Facture</title>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap"
          rel="stylesheet"
        />
        <style>
            body {
                font-family: "Roboto", sans-serif;
                background-color: #f4f4f4;
                margin: 0;
                padding: 0;
            }
            .invoice-container {
                padding: 20mm;
            }
            .header {
                text-align: center;
                padding-bottom: 20px;
            }
            .header h1 {
                margin: 0;
                font-size: 18px;
                font-weight: 500;
            }
            .header p {
                margin: 5px 0;
                font-size: 8px;
                color: #555;
            }
            .section-title {
                font-size: 12px;
                font-weight: 500;
                margin: 20px 0 10px;
            }
            .table {
                width: 100%;
                border-collapse: collapse;
                margin-top: 10px;
            }
            .table th,
            .table td {
                padding: 10px;
                border: 1px solid #ddd;
                text-align: left;
                font-size: 8px;
            }
            .table th {
                font-weight: 500;
            }
            .total {
                text-align: right;
                margin-top: 20px;
            }
            .total h3 {
                margin: 0;
                font-size: 12px;
                font-weight: 500;
            }
            .footer {
                text-align: center;
                padding: 30px 0;
                marging-top: 30px;
                font-size: 1rem;
                border-top: 1px solid #ddd;
            }
            th {
    text-align: left;
    border: 1px solid #000;
    /* Instead of dynamically calculating width, use flexbox or percentages */
    width: 25%; /* Assuming you have 4 columns, adjust as necessary */
}
        </style>
        </head>
        <body>
          <div class="invoice-container">
            <div class="header">
              <h1>Facture</h1>
            </div>
  
            ${
              factureToPrintData && factureToPrintData.clubRegistrations
                ? `
              <div class="section-title">Paiements des clubs</div>
              <table class="table">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Frais</th>
                    <th>Tranches</th>
                    <th>Payé</th>
                  </tr>
                </thead>
                <tbody>
                  ${factureToPrintData.clubRegistrations
                    .map(
                      (club) => `
                    <tr>
                      <td rowspan="${club.method === 'PAR TRANCHE' ? club.installments.length + 1 : 1}">
                        ${club.title}
                      </td>
                      <td rowspan="${club.method === 'PAR TRANCHE' ? club.installments.length + 1 : 1}">
                        ${club.fees} TND
                      </td>
                      ${
                        club.method === 'UNE SEUL FOIS'
                          ? `
                        <td>Seul tranche</td>
                        <td>${club.paid ? 'Oui' : 'Non'}</td>
                      `
                          : ''
                      }
                    </tr>
                    ${
                      club.installments.length > 0
                        ? club.installments
                            .map(
                              (installment) => `
                      <tr>
                        <td>${installment.name} : ${installment.amount} TND</td>
                        <td>${installment.paid ? 'Oui' : 'Non'}</td>
                      </tr>
                    `,
                            )
                            .join('')
                        : ''
                    }
                  `,
                    )
                    .join('')}
                </tbody>
              </table>
            `
                : ''
            }
  
            ${
              factureToPrintData && factureToPrintData.eventRegistrations
                ? `
              <div class="section-title">Paiements d'événements</div>
              <table class="table">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Frais</th>
                    <th>Payé</th>
                  </tr>
                </thead>
                <tbody>
                  ${factureToPrintData.eventRegistrations
                    .map(
                      (event) => `
                    <tr>
                      <td>${event.title}</td>
                      <td>${event.fees} TND</td>
                      <td>${event.paid ? 'Oui' : 'Non'}</td>
                    </tr>
                  `,
                    )
                    .join('')}
                </tbody>
              </table>
            `
                : ''
            }
  
            ${
              factureToPrintData && factureToPrintData.installments
                ? `
              <div class="section-title">Tranches</div>
              <table class="table">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Montant</th>
                    <th>Payé</th>
                  </tr>
                </thead>
                <tbody>
                  ${factureToPrintData.installments
                    .map(
                      (installment) => `
                    <tr>
                      <td>${installment.name}</td>
                      <td>${installment.amount} TND</td>
                      <td>${installment.paid ? 'Oui' : 'Non'}</td>
                    </tr>
                  `,
                    )
                    .join('')}
                </tbody>
              </table>
            `
                : ''
            }
            
            ${
              factureToPrintData && factureToPrintData.otherInstallments
                ? `
              <div class="section-title">Autre Tranches</div>
              <table class="table">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Montant</th>
                    <th>Payé</th>
                  </tr>
                </thead>
                <tbody>
                  ${factureToPrintData.otherInstallments
                    .map(
                      (installment) => `
                    <tr>
                      <td>${installment.name}</td>
                      <td>${installment.amount} TND</td>
                      <td>${installment.paid ? 'Oui' : 'Non'}</td>
                    </tr>
                  `,
                    )
                    .join('')}
                </tbody>
              </table>
            `
                : ''
            }
            <br>
            total : ${total} TND
            <br>
  
          
          </div>
        </body>
      </html>
    `;

  printWindow.document.write(invoiceHtml);
  printWindow.document.close();
  printWindow.focus();
  printWindow.print();
  printWindow.addEventListener('afterprint', () => {
    printWindow.close();
  });
};
