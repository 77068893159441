import { message } from 'antd';
import actions from './actions';

const initialState = {
  arrayData: [],
  singleData: null,
  loading: false,
  error: null,
};

const {
  VIEW_EMPLOI_BEGIN,
  VIEW_EMPLOI_SUCCESS,
  VIEW_EMPLOI_ERR,

  VIEW_SINGLE_EMPLOI_BEGIN,
  VIEW_SINGLE_EMPLOI_SUCCESS,
  VIEW_SINGLE_EMPLOI_ERR,

  ADD_EMPLOI_BEGIN,
  ADD_EMPLOI_SUCCESS,
  ADD_EMPLOI_ERR,

  DELETE_EMPLOI_BEGIN,
  DELETE_EMPLOI_SUCCESS,
  DELETE_EMPLOI_ERR,

  UPDATE_EMPLOI_BEGIN,
  UPDATE_EMPLOI_SUCCESS,
  UPDATE_EMPLOI_ERR,
} = actions;

const EmploiReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_EMPLOI_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_EMPLOI_SUCCESS:
      const updatedData = state.arrayData.map((item) => (item.id == data.id ? data : item));
      return {
        ...state,
        arrayData: updatedData,
        loading: false,
      };
    case UPDATE_EMPLOI_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_EMPLOI_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_EMPLOI_SUCCESS:
      return {
        ...state,
        arrayData: data,
        loading: false,
      };
    case VIEW_EMPLOI_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_SINGLE_EMPLOI_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_SINGLE_EMPLOI_SUCCESS:
      return {
        ...state,
        singleData: data,
        loading: false,
      };
    case VIEW_SINGLE_EMPLOI_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_EMPLOI_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_EMPLOI_SUCCESS:
      const arrayData = state.arrayData.filter((item) => item.id !== data);
      return {
        ...state,
        arrayData,
        loading: false,
      };
    case DELETE_EMPLOI_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_EMPLOI_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_EMPLOI_SUCCESS:
      return {
        ...state,
        arrayData: [...state.arrayData, data],
        loading: false,
      };
    case ADD_EMPLOI_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default EmploiReducer;
