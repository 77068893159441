import { message } from 'antd';
import actions from './actions';

const initialState = {
  data: {},
  loading: false,
  error: null,
};

const { VIEW_CANTINE_BEGIN, VIEW_CANTINE_SUCCESS, VIEW_CANTINE_ERR } = actions;

const ParentCantineReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case VIEW_CANTINE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_CANTINE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case VIEW_CANTINE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default ParentCantineReducer;
