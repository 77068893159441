import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  addFraisBegin,
  addFraisSuccess,
  addFraisErr,
  updateFraisBegin,
  updateFraisSuccess,
  updateFraisErr,
  viewFraisBegin,
  viewFraisSuccess,
  viewFraisErr,
  viewSingleFraisBegin,
  viewSingleFraisSuccess,
  viewSingleFraisErr,
  deleteFraisBegin,
  deleteFraisSuccess,
  deleteFraisErr,
} = actions;

const viewFraisData = () => {
  return async (dispatch) => {
    dispatch(viewFraisBegin());
    try {
      const response = await DataService.get('/listFrais/view');
      if (response.status === 200) {
        dispatch(viewFraisSuccess(response.data.payload));
      } else {
        dispatch(viewFraisErr('err'));
      }
    } catch (err) {
      dispatch(viewFraisErr('err'));
    }
  };
};

const addFraisData = (data, callback) => {
  return async (dispatch) => {
    dispatch(addFraisBegin());
    try {
      const response = await DataService.post('/listFrais/add', data);
      if (response.status === 200) {
        dispatch(addFraisSuccess(JSON.parse(response.data.payload.description)));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(addFraisErr('err'));
      }
    } catch (err) {
      dispatch(addFraisErr('err'));
    }
  };
};
const deleteFraisData = (data) => {
  return async (dispatch) => {
    dispatch(deleteFraisBegin());
    try {
      const response = await DataService.put(`/listFrais/updated`, data);
      if (response.status === 200) {
        dispatch(deleteFraisSuccess(data.listeFrais));

        message.success(response.data.message);
      } else {
        dispatch(deleteFraisErr('err'));
      }
    } catch (err) {
      dispatch(deleteFraisErr(err));
    }
  };
};
const updateFraisData = (data, callback) => {
  return async (dispatch) => {
    dispatch(updateFraisBegin());

    try {
      const response = await DataService.put(`/listFrais/updated`, data);
      if (response.status === 200) {
        dispatch(updateFraisSuccess(JSON.parse(response.data.payload.description)));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(updateFraisErr('err'));
      }
    } catch (err) {
      dispatch(updateFraisErr('err'));
    }
  };
};
export { viewFraisData, updateFraisData, deleteFraisData, addFraisData };
