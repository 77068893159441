const actions = {
  GET_ROOMS_BEGIN: 'GET_ROOMS_BEGIN',
  GET_ROOMS_SUCCESS: 'GET_ROOMS_SUCCESS',
  GET_ROOMS_ERR: 'GET_ROOMS_ERR',

  GET_MESSAGES_BEGIN: 'GET_MESSAGES_BEGIN',
  GET_MESSAGES_SUCCESS: 'GET_MESSAGES_SUCCESS',
  GET_MESSAGES_ERR: 'GET_MESSAGES_ERR',

  ADD_MESSAGE_BEGIN: 'ADD_MESSAGE_BEGIN',
  ADD_MESSAGE_SUCCESS: 'ADD_MESSAGE_SUCCESS',
  ADD_MESSAGE_ERR: 'ADD_MESSAGE_ERR',

  UPDATE_CHAT_MESSAGES_BEGIN: 'UPDATE_CHAT_MESSAGES_BEGIN',
  UPDATE_CHAT_MESSAGES_SUCCESS: 'UPDATE_CHAT_MESSAGES_SUCCESS',
  UPDATE_CHAT_MESSAGES_ERR: 'UPDATE_CHAT_MESSAGES_ERR',


  getRoomsBegin: () => {
    return {
      type: actions.GET_ROOMS_BEGIN,
    };
  },

  getRoomsSuccess: (data) => {
    return {
      type: actions.GET_ROOMS_SUCCESS,
      data,
    };
  },

  getRoomsErr: (err) => {
    return {
      type: actions.GET_ROOMS_ERR,
      err,
    };
  },

  getMessagesBegin: () => {
    return {
      type: actions.GET_MESSAGES_BEGIN,
    };
  },

  getMessagesSuccess: (data) => {
    return {
      type: actions.GET_MESSAGES_SUCCESS,
      data,
    };
  },

  getMessagesErr: (err) => {
    return {
      type: actions.GET_MESSAGES_ERR,
      err,
    };
  },

  addMessageBegin: () => {
    return {
      type: actions.ADD_MESSAGE_BEGIN,
    };
  },

  addMessageSuccess: (data) => {
    return {
      type: actions.ADD_MESSAGE_SUCCESS,
      data,
    };
  },

  addMessageErr: (err) => {
    return {
      type: actions.ADD_MESSAGE_ERR,
      err,
    };
  },

  updateChatMessagesBegin: () => {
    return {
      type: actions.UPDATE_CHAT_MESSAGES_BEGIN,
    };
  },

  updateChatMessagesSuccess: (data) => {
    return {
      type: actions.UPDATE_CHAT_MESSAGES_SUCCESS,
      data,
    };
  },

  updateChatMessagesErr: (err) => {
    return {
      type: actions.UPDATE_CHAT_MESSAGES_ERR,
      err,
    };
  },
};

export default actions;
