import { message } from 'antd';
import actions from './actions';

const initialState = {
  arrayData: [],
  singleData: null,
  loading: false,
  start: null,
  end: null,
  classe:"1",
  error: null,
};

const {
  VIEW_ABSENCE_BEGIN,
  VIEW_ABSENCE_SUCCESS,
  VIEW_ABSENCE_ERR,

  VIEW_SINGLE_ABSENCE_BEGIN,
  VIEW_SINGLE_ABSENCE_SUCCESS,
  VIEW_SINGLE_ABSENCE_ERR,

  ADD_ABSENCE_BEGIN,
  ADD_ABSENCE_SUCCESS,
  ADD_ABSENCE_ERR,

  DELETE_ABSENCE_BEGIN,
  DELETE_ABSENCE_SUCCESS,
  DELETE_ABSENCE_ERR,

  UPDATE_ABSENCE_BEGIN,
  UPDATE_ABSENCE_SUCCESS,
  UPDATE_ABSENCE_ERR,

  CHANGE_START_DATE_BEGIN,
  CHANGE_START_DATE_SUCCESS,
  CHANGE_START_DATE_ERR,

  CHANGE_END_DATE_BEGIN,
  CHANGE_END_DATE_SUCCESS,
  CHANGE_END_DATE_ERR,

  CHANGE_SELECTED_CLASSE_BEGIN,
  CHANGE_SELECTED_CLASSE_SUCCESS,
  CHANGE_SELECTED_CLASSE_ERR,

} = actions;

const AbsenceReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_ABSENCE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ABSENCE_SUCCESS:
      return {
        ...state,
        arrayData: state.arrayData.map((item) => (item.id === data.id ? data : item)),
        loading: false,
      };
    case UPDATE_ABSENCE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_ABSENCE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_ABSENCE_SUCCESS:
      return {
        ...state,
        arrayData: data,
        loading: false,
      };
    case VIEW_ABSENCE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_SINGLE_ABSENCE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_SINGLE_ABSENCE_SUCCESS:
      return {
        ...state,
        singleData: data,
        loading: false,
      };
    case VIEW_SINGLE_ABSENCE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_ABSENCE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ABSENCE_SUCCESS:
      const arrayData = state.arrayData.filter((item) => item.id !== data);
      return {
        ...state,
        arrayData,
        loading: false,
      };
    case DELETE_ABSENCE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_ABSENCE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_ABSENCE_SUCCESS:
      return {
        ...state,
        arrayData: [...state.arrayData, data],
        loading: false,
      };
    case ADD_ABSENCE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CHANGE_START_DATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_START_DATE_SUCCESS:
      return {
        ...state,
        start: data,
        loading: false,
      };
    case CHANGE_START_DATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CHANGE_END_DATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_END_DATE_SUCCESS:
      return {
        ...state,
        end: data,
        loading: false,
      };
    case CHANGE_END_DATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
      case CHANGE_SELECTED_CLASSE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_SELECTED_CLASSE_SUCCESS:
      return {
        ...state,
        classe: data,
        loading: false,
      };
    case CHANGE_SELECTED_CLASSE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default AbsenceReducer;
