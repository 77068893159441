import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { viewAvreageGradesPerLevelStatisticsData } from '../../../redux/statistics/actionCreator';
import { Cards } from '../../../components/cards/frame/cards-frame';
import CustomDoughnutChart from '../CustomDoughnutChart';
import { Select } from 'antd';

const LevelStatistics = () => {
  const dispatch = useDispatch();
  const { statistics } = useSelector((state) => {
    return {
      statistics: state.StatisticsReducer.gradesPerLevel,
    };
  });

  useEffect(() => {
    dispatch(viewAvreageGradesPerLevelStatisticsData());
  }, []);

  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);

  useEffect(() => {
    if (statistics) {

      const keys = statistics.map((item) => `${item.niveau} ${item.niveau == 1 ? 'ère' : 'ème'} année `);
      const values = statistics.map((item) => item.average_grade);

      setLabels(keys);
      setDatasets(values);
    }
  }, [statistics]);

  return (
    <>
      {statistics && (
        <Cards headless>
          <div
            style={{
              height: '50vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontSize: '18px',
                fontWeight: '500',
                marginBottom: '10px',
                color: '#333',
              }}
            >
              Moyenne generale des niveaux
            </div>
            <div style={{ height: '40vh', display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
              <CustomDoughnutChart
                labels={labels}
                datasets={datasets}
                title="Inscriptions by Month for 2024"
                beginAtZero={true}
              />
            </div>
          </div>
        </Cards>
      )}
    </>
  );
};

export default LevelStatistics;
