const actions = {
  VIEW_TYPE_CHARGE_BEGIN: 'VIEW_TYPE_CHARGE_BEGIN',
  VIEW_TYPE_CHARGE_SUCCESS: 'VIEW_TYPE_CHARGE_SUCCESS',
  VIEW_TYPE_CHARGE_ERR: 'VIEW_TYPE_CHARGE_ERR',

  VIEW_SINGLE_TYPE_CHARGE_BEGIN: 'VIEW_SINGLE_TYPE_CHARGE_BEGIN',
  VIEW_SINGLE_TYPE_CHARGE_SUCCESS: 'VIEW_SINGLE_TYPE_CHARGE_SUCCESS',
  VIEW_SINGLE_TYPE_CHARGE_ERR: 'VIEW_SINGLE_TYPE_CHARGE_ERR',

  ADD_TYPE_CHARGE_BEGIN: 'ADD_TYPE_CHARGE_BEGIN',
  ADD_TYPE_CHARGE_SUCCESS: 'ADD_TYPE_CHARGE_SUCCESS',
  ADD_TYPE_CHARGE_ERR: 'ADD_TYPE_CHARGE_ERR',

  DELETE_TYPE_CHARGE_BEGIN: 'DELETE_TYPE_CHARGE_BEGIN',
  DELETE_TYPE_CHARGE_SUCCESS: 'DELETE_TYPE_CHARGE_SUCCESS',
  DELETE_TYPE_CHARGE_ERR: 'DELETE_TYPE_CHARGE_ERR',

  UPDATE_TYPE_CHARGE_BEGIN: 'UPDATE_TYPE_CHARGE_BEGIN',
  UPDATE_TYPE_CHARGE_SUCCESS: 'UPDATE_TYPE_CHARGE_SUCCESS',
  UPDATE_TYPE_CHARGE_ERR: 'UPDATE_TYPE_CHARGE_ERR',

  viewTypeChargeBegin: () => {
    return {
      type: actions.VIEW_TYPE_CHARGE_BEGIN,
    };
  },

  viewTypeChargeSuccess: (data) => {
    return {
      type: actions.VIEW_TYPE_CHARGE_SUCCESS,
      data,
    };
  },

  viewTypeChargeErr: (err) => {
    return {
      type: actions.VIEW_TYPE_CHARGE_ERR,
      err,
    };
  },

  viewSingleTypeChargeBegin: () => {
    return {
      type: actions.VIEW_SINGLE_TYPE_CHARGE_BEGIN,
    };
  },

  viewSingleTypeChargeSuccess: (data) => {
    return {
      type: actions.VIEW_SINGLE_TYPE_CHARGE_SUCCESS,
      data,
    };
  },

  viewSingleTypeChargeErr: (err) => {
    return {
      type: actions.VIEW_SINGLE_TYPE_CHARGE_ERR,
      err,
    };
  },

  updateTypeChargeBegin: () => {
    return {
      type: actions.UPDATE_TYPE_CHARGE_BEGIN,
    };
  },

  updateTypeChargeSuccess: (data) => {
    return {
      type: actions.UPDATE_TYPE_CHARGE_SUCCESS,
      data,
    };
  },

  updateTypeChargeErr: (err) => {
    return {
      type: actions.UPDATE_TYPE_CHARGE_ERR,
      err,
    };
  },
  deleteTypeChargeBegin: () => {
    return {
      type: actions.DELETE_TYPE_CHARGE_BEGIN,
    };
  },

  deleteTypeChargeSuccess: (data) => {
    return {
      type: actions.DELETE_TYPE_CHARGE_SUCCESS,
      data,
    };
  },

  deleteTypeChargeErr: (err) => {
    return {
      type: actions.DELETE_TYPE_CHARGE_ERR,
      err,
    };
  },
  addTypeChargeBegin: () => {
    return {
      type: actions.ADD_TYPE_CHARGE_BEGIN,
    };
  },

  addTypeChargeSuccess: (data) => {
    return {
      type: actions.ADD_TYPE_CHARGE_SUCCESS,
      data,
    };
  },

  addTypeChargeErr: (err) => {
    return {
      type: actions.ADD_TYPE_CHARGE_ERR,
      err,
    };
  },
};

export default actions;
