const actions = {
  VIEW_MATIERE_BEGIN: 'VIEW_MATIERE_BEGIN',
  VIEW_MATIERE_SUCCESS: 'VIEW_MATIERE_SUCCESS',
  VIEW_MATIERE_ERR: 'VIEW_MATIERE_ERR',

  viewMatiereBegin: () => {
    return {
      type: actions.VIEW_MATIERE_BEGIN,
    };
  },

  viewMatiereSuccess: (data) => {
    return {
      type: actions.VIEW_MATIERE_SUCCESS,
      data,
    };
  },

  viewMatiereErr: (err) => {
    return {
      type: actions.VIEW_MATIERE_ERR,
      err,
    };
  },
};

export default actions;
