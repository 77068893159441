import { Card, Col, Modal } from 'antd';
import React, { useRef } from 'react';
import { CourseCardWrap } from './Style';
import { useDispatch } from 'react-redux';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { addPaimentData } from '../../redux/paiment/actionCreator';
import { Badge } from '../../container/pages/style';

function AttachedEventCard({ data, id }) {
  const { confirm } = Modal;

  const dispatch = useDispatch();

  const addPaiment = (data) => {
    dispatch(addPaimentData(data));
  };
  const showConfirm = (data) => {
    confirm({
      title: "Voulez-vous payer l'evennement ?",
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'payer',
      okType: 'danger',
      cancelText: 'Annuler',
      cancelButtonProps: {
        type: 'white',
      },
      onOk() {
        addPaiment(data);
      },
      onCancel() {},
    });
  };

  return (
    <Col xxl={8} lg={12} sm={12} xs={24}>
      <CourseCardWrap
        className="edulink-course-card-single"
        onClick={() => !data.paid && showConfirm({ etudiant_id: id, event_id: data.id })}
      >
        <Card bordered={false}>
          <div className="edulink-course-card-thumbnail">
            <img
              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${data.image}`}
              style={{ borderRadius: '10px', height: '180px', width: '100%', objectFit: 'cover' }}
            />
          </div>
          <div className="edulink-course-card-content">
            <div className="edulink-course-card-meta">
              <div className="edulink-course-card-meta__left">
                <span className="edulink-course-card-meta__pricing" style={{ color: 'black' }}>
                  {data.titre}{' '}
                  {!data.ListTranche ? (
                    <Badge className="pricing-badge" type={data.paid ? 'success' : 'danger'}>
                      {data.frais} DT
                    </Badge>
                  ) : (
                    <Badge className="pricing-badge" type={'primary'}>
                      {data.frais} DT
                    </Badge>
                  )}
                </span>
              </div>
            </div>
          </div>
        </Card>
      </CourseCardWrap>
    </Col>
  );
}

export default AttachedEventCard;
