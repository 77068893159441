import { message } from 'antd';
import actions from './actions';

const initialState = {
  arrayData: [],
  loading: false,
  error: null,
};

const { VIEW_INSCRIPTION_BEGIN, VIEW_INSCRIPTION_SUCCESS, VIEW_INSCRIPTION_ERR } = actions;

const ProfInscriptionReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case VIEW_INSCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_INSCRIPTION_SUCCESS:
      return {
        ...state,
        arrayData: data,
        loading: false,
      };
    case VIEW_INSCRIPTION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default ProfInscriptionReducer;
