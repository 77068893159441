const actions = {
  VIEW_USER_BEGIN: 'VIEW_USER_BEGIN',
  VIEW_USER_SUCCESS: 'VIEW_USER_SUCCESS',
  VIEW_USER_ERR: 'VIEW_USER_ERR',

  VIEW_SINGLE_USER_BEGIN: 'VIEW_SINGLE_USER_BEGIN',
  VIEW_SINGLE_USER_SUCCESS: 'VIEW_SINGLE_USER_SUCCESS',
  VIEW_SINGLE_USER_ERR: 'VIEW_SINGLE_USER_ERR',

  ADD_USER_BEGIN: 'ADD_USER_BEGIN',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_ERR: 'ADD_USER_ERR',

  DELETE_USER_BEGIN: 'DELETE_USER_BEGIN',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_ERR: 'DELETE_USER_ERR',

  UPDATE_USER_BEGIN: 'UPDATE_USER_BEGIN',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERR: 'UPDATE_USER_ERR',

  viewUserBegin: () => {
    return {
      type: actions.VIEW_USER_BEGIN,
    };
  },

  viewUserSuccess: (data) => {
    return {
      type: actions.VIEW_USER_SUCCESS,
      data,
    };
  },

  viewUserErr: (err) => {
    return {
      type: actions.VIEW_USER_ERR,
      err,
    };
  },

  viewSingleUserBegin: () => {
    return {
      type: actions.VIEW_SINGLE_USER_BEGIN,
    };
  },

  viewSingleUserSuccess: (data) => {
    return {
      type: actions.VIEW_SINGLE_USER_SUCCESS,
      data,
    };
  },

  viewSingleUserErr: (err) => {
    return {
      type: actions.VIEW_SINGLE_USER_ERR,
      err,
    };
  },

  updateUserBegin: () => {
    return {
      type: actions.UPDATE_USER_BEGIN,
    };
  },

  updateUserSuccess: (data) => {
    return {
      type: actions.UPDATE_USER_SUCCESS,
      data,
    };
  },

  updateUserErr: (err) => {
    return {
      type: actions.UPDATE_USER_ERR,
      err,
    };
  },
  deleteUserBegin: () => {
    return {
      type: actions.DELETE_USER_BEGIN,
    };
  },

  deleteUserSuccess: (data) => {
    return {
      type: actions.DELETE_USER_SUCCESS,
      data,
    };
  },

  deleteUserErr: (err) => {
    return {
      type: actions.DELETE_USER_ERR,
      err,
    };
  },
  addUserBegin: () => {
    return {
      type: actions.ADD_USER_BEGIN,
    };
  },

  addUserSuccess: (data) => {
    return {
      type: actions.ADD_USER_SUCCESS,
      data,
    };
  },

  addUserErr: (err) => {
    return {
      type: actions.ADD_USER_ERR,
      err,
    };
  },
};

export default actions;
