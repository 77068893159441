import actions from './actions';
import { DataService } from '../../../config/dataService/dataService';
import { getItem } from '../../../utility/localStorageControl';

const { viewAllEventBegin, viewAllEventSuccess, viewAllEventErr, viewEventBegin, viewEventSuccess, viewEventErr } = actions;

const viewAllEventData = () => {
  return async (dispatch) => {
    dispatch(viewAllEventBegin());
    try {
      const userData = getItem('user');
      const response = await DataService.get(`/evenements/getInscriptionEvents/${userData.id}`);
      if (response.status === 200) {
        dispatch(viewAllEventSuccess(response.data.allEvents));
      } else {
        dispatch(viewAllEventErr('err'));
      }
    } catch (err) {
      console.error(err);
      dispatch(viewAllEventErr('err'));
    }
  };
};

const viewEventData = () => {
  return async (dispatch) => {
    dispatch(viewEventBegin());
    try {
      const userData = getItem('user');
      const response = await DataService.get(`/evenements/getInscriptionEvents/${userData.id}`);
      if (response.status === 200) {
        dispatch(viewEventSuccess(response.data.events));
      } else {
        dispatch(viewEventErr('err'));
      }
    } catch (err) {
      console.error(err);
      dispatch(viewEventErr('err'));
    }
  };
};

export { viewAllEventData, viewEventData };
