const actions = {
  VIEW_ABSENCE_PROF_BEGIN: 'VIEW_ABSENCE_PROF_BEGIN',
  VIEW_ABSENCE_PROF_SUCCESS: 'VIEW_ABSENCE_PROF_SUCCESS',
  VIEW_ABSENCE_PROF_ERR: 'VIEW_ABSENCE_PROF_ERR',

  VIEW_SINGLE_ABSENCE_PROF_BEGIN: 'VIEW_SINGLE_ABSENCE_PROF_BEGIN',
  VIEW_SINGLE_ABSENCE_PROF_SUCCESS: 'VIEW_SINGLE_ABSENCE_PROF_SUCCESS',
  VIEW_SINGLE_ABSENCE_PROF_ERR: 'VIEW_SINGLE_ABSENCE_PROF_ERR',

  ADD_ABSENCE_PROF_BEGIN: 'ADD_ABSENCE_PROF_BEGIN',
  ADD_ABSENCE_PROF_SUCCESS: 'ADD_ABSENCE_PROF_SUCCESS',
  ADD_ABSENCE_PROF_ERR: 'ADD_ABSENCE_PROF_ERR',

  DELETE_ABSENCE_PROF_BEGIN: 'DELETE_ABSENCE_PROF_BEGIN',
  DELETE_ABSENCE_PROF_SUCCESS: 'DELETE_ABSENCE_PROF_SUCCESS',
  DELETE_ABSENCE_PROF_ERR: 'DELETE_ABSENCE_PROF_ERR',

  UPDATE_ABSENCE_PROF_BEGIN: 'UPDATE_ABSENCE_PROF_BEGIN',
  UPDATE_ABSENCE_PROF_SUCCESS: 'UPDATE_ABSENCE_PROF_SUCCESS',
  UPDATE_ABSENCE_PROF_ERR: 'UPDATE_ABSENCE_PROF_ERR',

  viewAbsenceProfBegin: () => {
    return {
      type: actions.VIEW_ABSENCE_PROF_BEGIN,
    };
  },

  viewAbsenceProfSuccess: (data) => {
    return {
      type: actions.VIEW_ABSENCE_PROF_SUCCESS,
      data,
    };
  },

  viewAbsenceProfErr: (err) => {
    return {
      type: actions.VIEW_ABSENCE_PROF_ERR,
      err,
    };
  },

  viewSingleAbsenceProfBegin: () => {
    return {
      type: actions.VIEW_SINGLE_ABSENCE_PROF_BEGIN,
    };
  },

  viewSingleAbsenceProfSuccess: (data) => {
    return {
      type: actions.VIEW_SINGLE_ABSENCE_PROF_SUCCESS,
      data,
    };
  },

  viewSingleAbsenceProfErr: (err) => {
    return {
      type: actions.VIEW_SINGLE_ABSENCE_PROF_ERR,
      err,
    };
  },

  updateAbsenceProfBegin: () => {
    return {
      type: actions.UPDATE_ABSENCE_PROF_BEGIN,
    };
  },

  updateAbsenceProfSuccess: (data) => {
    return {
      type: actions.UPDATE_ABSENCE_PROF_SUCCESS,
      data,
    };
  },

  updateAbsenceProfErr: (err) => {
    return {
      type: actions.UPDATE_ABSENCE_PROF_ERR,
      err,
    };
  },
  deleteAbsenceProfBegin: () => {
    return {
      type: actions.DELETE_ABSENCE_PROF_BEGIN,
    };
  },

  deleteAbsenceProfSuccess: (data) => {
    return {
      type: actions.DELETE_ABSENCE_PROF_SUCCESS,
      data,
    };
  },

  deleteAbsenceProfErr: (err) => {
    return {
      type: actions.DELETE_ABSENCE_PROF_ERR,
      err,
    };
  },
  addAbsenceProfBegin: () => {
    return {
      type: actions.ADD_ABSENCE_PROF_BEGIN,
    };
  },

  addAbsenceProfSuccess: (data) => {
    return {
      type: actions.ADD_ABSENCE_PROF_SUCCESS,
      data,
    };
  },

  addAbsenceProfErr: (err) => {
    return {
      type: actions.ADD_ABSENCE_PROF_ERR,
      err,
    };
  },
};

export default actions;
