import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import { useDispatch, useSelector } from 'react-redux';
import { viewExerciceData } from '../../../../redux/parent/exercice/actionCreator';

const Exercice = lazy(() => import('./timeline/Exercice'));

function Exercices() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { exercices } = useSelector((state) => {
    return {
      exercices: state.ParentExerciceReducer.data,
    };
  });
  useEffect(() => {
    dispatch(viewExerciceData());
  }, []);

  useEffect(() => {
    if (exercices) {
      const localExercicesData = exercices.map((item) => {
        return {
          titre: item.titre,
          matiere: item.matiere,
          date: item.dateL,
          heur: item.heurL,
          description: item.description,
          created_at: item.created_at,
          image: item.image,
          pdf: item.pdf,
        };
      });
      let localData = [...localExercicesData];
      setData(localData);
    }
  }, [exercices]);
  useEffect(() => {
  }, [data]);
  return (
    <Row gutter={25}>
      <Col lg={24} xs={24}>
        <Suspense
          fallback={
            <Cards headless>
              <Skeleton active />
            </Cards>
          }
        ></Suspense>
        {data.map((item) => {
          return (
            <Suspense
              key={item}
              fallback={
                <Cards headless>
                  <Skeleton active />
                </Cards>
              }
            >
              <Exercice item={item} />
            </Suspense>
          );
        })}
      </Col>
    </Row>
  );
}

export default Exercices;
