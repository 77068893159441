import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Modal } from 'antd';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import DataTable from '../../components/table/DataTable';
import { Link } from 'react-router-dom';
import Heading from '../../components/heading/heading';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import UilTrashAlt from '@iconscout/react-unicons/icons/uil-trash-alt';
import { useDispatch } from 'react-redux';
import { deletePersonnelData, viewPersonnelData } from '../../redux/personnel/actionCreator';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ExclamationCircleFilled } from '@ant-design/icons';

function Personnel() {
  const { confirm } = Modal;
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewPersonnelData());
  }, []);

  const { personnels } = useSelector((state) => {
    return {
      personnels: state.PersonnelReducer.arrayData,
    };
  });
  const showConfirm = (id) => {
    confirm({
      title: 'Voulez-vous supprimer personnel ?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      cancelButtonProps: {
        type: 'white',
      },
      onOk() {
        handleDelete(id);
      },
      onCancel() {},
    });
  };

  const handleDelete = useCallback(
    (id) => {
      dispatch(deletePersonnelData(id));
    },
    [dispatch],
  );

  useEffect(() => {
    const localData = [];
    personnels.map((element) => {
      localData.push({
        ...element,
        cnss: element.typeCNSS != 'vide' ? `${element.montantCNSS} dt` : '-',
        salaire: `${element.salaire} dt`,
        role: (
          <span className={`status-text ${element.role.toUpperCase() == 'PROF' ? 'active' : 'primary'}`}>
            {element.role}
          </span>
        ),
        user: (
          <div className="user-info">
            <figure>
              <img
                style={{ width: '40px' }}
                src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png"
                alt=""
              />
            </figure>
            <figcaption>
              <Heading className="user-name" as="h6">
                {element.prenom} {element.nom}
              </Heading>
              <span className="user-designation">Né le {moment(element.dateNaiss).format('DD MMM YYYY')}</span>
            </figcaption>
          </div>
        ),
        action: (
          <div className="table-actions">
            {/* <Link className="view" to={`profile/${element.id}`}>
              <UilEye />
            </Link> */}
            <Link className="edit" to={`update/${element.id}`}>
              <UilEdit />
            </Link>
            <Link
              className="delete"
              to="#"
              onClick={() => {
                showConfirm(element.id);
              }}
            >
              <UilTrashAlt />
            </Link>
          </div>
        ),
      });
    });
    setData(localData);
  }, [personnels]);

  const dataTableColumn = [
    {
      title: 'Utilisateur',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Telephone',
      dataIndex: 'num',
      key: 'num',
    },
    {
      title: 'Salaire',
      dataIndex: 'salaire',
      key: 'salaire',
    },
    {
      title: 'CNSS',
      dataIndex: 'cnss',
      key: 'cnss',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '60px',
    },
  ];

  return (
    <>
      <Main>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
            <Cards headless>
              <DataTable
                filterOption
                filterOnchange
                buttonName="Ajouter Personnel"
                tableData={data}
                columns={dataTableColumn}
              />
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default Personnel;
