import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { Row, Col, Skeleton, Modal, Select, Form } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { useDispatch, useSelector } from 'react-redux';
import { AttachClub, viewClubData } from '../../../redux/club/actionCreator';
import AttachedClubCard from '../../../components/cards/AttachedClubCard';
import { Button } from '../../../components/buttons/buttons';
import { useParams } from 'react-router-dom';
import { viewFactureData, viewInscriptionClubsData } from '../../../redux/inscription/actionCreator';
import { BasicFormWrapper } from '../../styled';
import { ReunionFormStyleWrap } from '../../reunion/Style';

function Clubs() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { facture, clubs } = useSelector((state) => {
    return {
      facture: state.InscriptionReducer.facture,
      clubs: state.ClubReducer.arrayData,
    };
  });
  useEffect(() => {
    dispatch(viewInscriptionClubsData(id));
    dispatch(viewClubData());
    dispatch(viewFactureData(id));
  }, []);

  const [open, setOpen] = useState(false);
  const [club, setClub] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const attachClubSuccess = () => {
    dispatch(viewInscriptionClubsData(id));
    handleClose();
  };
  const handleSubmit = () => {
    dispatch(AttachClub(id, club, attachClubSuccess));
  };

  return (
    <>
      <Modal
        className="edulink-event-form"
        footer={null}
        closable
        type="primary"
        title={'Attacher club'}
        open={open}
        onCancel={handleClose}
      >
        <BasicFormWrapper>
          <ReunionFormStyleWrap>
            <div className="edulink-event-form-input">
              <span className="edulink-event-form-label">Club :</span>
              <Form.Item>
                <Select
                  onChange={(e) => setClub(e)}
                  options={clubs.map((item) => {
                    return { value: item.id, label: item.titre };
                  })}
                  placeholder="Choisir club "
                />
              </Form.Item>
            </div>
            <div className="add-event-footer text-right">
              <Button htmlType="submit" className="btn-save" type="primary" onClick={handleSubmit}>
                Enregistrer
              </Button>
            </div>
          </ReunionFormStyleWrap>
        </BasicFormWrapper>
      </Modal>
      <Row gutter={25}>
        <Col lg={24} xs={24}>
          <Suspense
            fallback={
              <Cards headless>
                <Skeleton active />
              </Cards>
            }
          ></Suspense>
          <Col lg={4} md={4} xs={24}>
            <Button
              key="1"
              type="danger"
              size="default"
              style={{ borderRadius: '17px', marginBottom: '20px' }}
              onClick={handleOpen}
            >
              Attacher club
            </Button>
          </Col>

          <Row gutter={25} className="mt-sm-10">
            {facture&&facture.clubRegistrations.map((value, index) => (
              <AttachedClubCard key={index} data={value} id={id} />
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Clubs;
