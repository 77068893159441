import { Checkbox, DatePicker, Form, Input, Select, TimePicker } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button } from '../../components/buttons/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { viewMatiereData } from '../../redux/matiere/actionCreator';
import { BasicFormWrapper } from '../styled';
import { ReunionFormStyleWrap } from '../emploi/Style';

function AbsenceForm({ onHandleAddEvent, eventData, eleves, niveau }) {
  const dispatch = useDispatch();
  const { matieres } = useSelector((state) => {
    return {
      matieres: state.MatiereReducer.arrayData,
    };
  });
  const [matieresData, setMatieresData] = useState([]);
  useEffect(() => {
    dispatch(viewMatiereData());
  }, []);
  const filterMatieres = (level) => {
    setMatieresData(
      matieres.filter((item) => item.niveau == level).map((item) => ({ label: item.nom, value: item.nom })),
    );
  };
  useEffect(() => {
    if (niveau && matieres) {
      filterMatieres(niveau);
    }
  }, [niveau, matieres]);
  const [matiere, setMatiere] = useState('');
  const [heurD, setStart] = useState(null);
  const [heurF, setEnd] = useState(null);
  const [date, setDate] = useState(null);
  const [presentEleve, setPresentEleve] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    setMatiere(eventData && eventData.matiere ? eventData.matiere : '');
    setStart(eventData && eventData.heurD ? eventData.heurD : null);
    setEnd(eventData && eventData.heurF ? eventData.heurF : null);
    setDate(eventData && eventData.date ? moment(eventData.date) : null);
    setPresentEleve(
      eventData && eventData.ListEleve
        ? typeof eventData.ListEleve == 'string'
          ? JSON.parse(eventData.ListEleve)
          : eventData.ListEleve
        : [],
    );
  }, [eventData]);

  const reset = () => {
    setMatiere('');
    setStart(null);
    setEnd(null);
    setDate(null);
    setPresentEleve([]);
  };

  const handleSubmit = () => {
    const data = {};
    data.matiere = matiere;
    data.heurD = heurD.format('HH:mm');
    data.heurF = heurF.format('HH:mm');
    data.date = date.format('yyyy-MM-DD');
    data.ListEleve = presentEleve;
    if (eventData && eventData.id) {
      data.id = eventData.id;
    }
    onHandleAddEvent(data);
    reset();
  };

  const onChange = (checkedValues) => {
    setPresentEleve(checkedValues);
  };

  return (
    <BasicFormWrapper>
      <ReunionFormStyleWrap>
        <Form form={form} name="addReunion" onFinish={handleSubmit}>
          <div className="edulink-event-form-input">
            <span className="edulink-event-form-label">Matiere</span>
            <Form.Item rules={[{ required: true, message: 'Matiere de reunion est obligatoire !' }]}>
              <Select
                placeholder="Choisir une matiere"
                value={matiere}
                onChange={(value) => setMatiere(value)}
                options={matieresData}
              />
            </Form.Item>
          </div>
          <div className="edulink-event-timeselection">
            <span className="edulink-event-timeselection__label">Date:</span>
            <div className="edulink-event-timeselection__input">
              <Form.Item rules={[{ required: true, message: 'Date de reunion est obligatoire !' }]}>
                <DatePicker format={'MM-DD-YYYY'} value={date && moment(date)} onChange={(e) => setDate(e)} />
              </Form.Item>
            </div>
          </div>
          <div className="edulink-event-timeselection">
            <span className="edulink-event-timeselection__label">Debut:</span>
            <div className="edulink-event-timeselection__input">
              <Form.Item rules={[{ required: true, message: 'Heure debut de reunion est obligatoire !' }]}>
                <TimePicker format="HH:mm" value={heurD} onChange={(e) => setStart(e)} />
              </Form.Item>
            </div>
          </div>
          <div className="edulink-event-timeselection">
            <span className="edulink-event-timeselection__label">Fin:</span>
            <div className="edulink-event-timeselection__input">
              <Form.Item rules={[{ required: true, message: 'Heure fin de reunion est obligatoire !' }]}>
                <TimePicker format="HH:mm" value={heurF} onChange={(e) => setEnd(e)} />
              </Form.Item>
            </div>
          </div>
          <div className="edulink-event-form-input">
            <span className="edulink-event-timeselection__label">Eleves:</span>
            <div className="student-list">
              <Checkbox.Group
                style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}
                options={[
                  ...eleves.map((item) => {
                    return { value: item.id, label: `${item.prenom} ${item.nom}` };
                  }),
                ]}
                value={presentEleve}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="add-event-footer text-right">
            <Button className="ant-btn ant-btn-white" onClick={reset}>
              Reset
            </Button>
            <Button htmlType="submit" className="btn-save" type="primary">
              Enregistrer
            </Button>
          </div>
        </Form>
      </ReunionFormStyleWrap>
    </BasicFormWrapper>
  );
}

export default AbsenceForm;
