import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, notification } from 'antd';
import { Main } from '../../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import DataTable from '../../../components/table/DataTable';
import { Link } from 'react-router-dom';
import UilEye from '@iconscout/react-unicons/icons/uil-eye';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import UilTrash from '@iconscout/react-unicons/icons/uil-trash-alt';
import UilCalender from '@iconscout/react-unicons/icons/uil-calender';
import { UilBookAlt } from '@iconscout/react-unicons';
import UilSubject from '@iconscout/react-unicons/icons/uil-subject';
import { Card, Modal } from 'antd';
import { EventModalStyleWrap } from '../../reunion/Style';
import { deleteExerciceData, viewExerciceData } from '../../../redux/prof/exercice/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { viewClasseData } from '../../../redux/prof/classe/actionCreator';
import { ExclamationCircleFilled } from '@ant-design/icons';

function Exercices() {
  const { confirm } = Modal;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedEvent, setSlectedEvent] = useState();
  const [data, setData] = useState([]);

  const showModal = (event) => {
    setSlectedEvent(event);
    setOpen(true);
  };
  const handleCancel = () => {
    setSlectedEvent(null);
    setOpen(false);
  };
  const { exercices } = useSelector((state) => {
    return {
      exercices: state.ProfExerciceReducer.arrayData,
    };
  });
  useEffect(() => {
    dispatch(viewClasseData());
    dispatch(viewExerciceData());
  }, []);

  const showConfirm = (id) => {
    confirm({
      title: "Voulez-vous supprimer l'exercice ?",
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      cancelButtonProps: {
        type: 'white',
      },
      onOk() {
        handleDelete(id);
      },
      onCancel() {},
    });
  };
  const handleDelete = useCallback(
    (id) => {
      dispatch(deleteExerciceData(id));
    },
    [dispatch],
  );

  useEffect(() => {
    const localData = [];
    exercices.map((element) => {
      localData.push({
        ...element,
        niveau: `${element.classe[0].niveau} ${element.classe[0].niveau == 1 ? 'ère' : 'ème'} année ${
          element.classe[0].titre
        }`,
        description:
          element.description.length > 20 ? `${element.description.substring(0, 15)} ...` : element.description,
        action: (
          <div className="table-actions">
            <Link className="view" to="#" onClick={() => showModal({ ...element, classe: element.classe })}>
              <UilEye />
            </Link>
            <Link className="edit" to={`update/${element.id}`}>
              <UilEdit />
            </Link>
            <Link
              className="delete"
              to="#"
              onClick={() => {
                showConfirm(element.id);
              }}
            >
              <UilTrash />
            </Link>
          </div>
        ),
      });
    });
    setData(localData);
  }, [exercices]);

  const dataTableColumn = [
    {
      title: 'Niveau',
      dataIndex: 'niveau',
      key: 'niveau',
    },
    {
      title: 'Matière',
      dataIndex: 'matiere',
      key: 'matiere',
    },
    {
      title: 'Date Debut',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Date Limite',
      dataIndex: 'dateL',
      key: 'dateL',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '60px',
    },
  ];

  return (
    <>
      <Main>
        <Modal
          open={open}
          onCancel={handleCancel}
          footer={null}
          title={`${
            selectedEvent &&
            selectedEvent.classe &&
            `Niveau ${selectedEvent.classe[0].niveau} : Classe ${selectedEvent.classe[0].titre}`
          }`}
        >
          <EventModalStyleWrap>
            {selectedEvent && (
              <div className="edulink-event-details">
                <ul>
                  <li>
                    <UilCalender />
                    <span className="edulink-event-label">Heur:</span>
                    <span className="edulink-event-text">
                      <strong>{selectedEvent.heur}</strong>
                    </span>
                  </li>
                  <li>
                    <UilCalender />
                    <span className="edulink-event-label">Heur Limite:</span>
                    <span className="edulink-event-text">
                      <strong>{selectedEvent.heurL}</strong>
                    </span>
                  </li>
                  <li>
                    <UilCalender />
                    <span className="edulink-event-label">Date:</span>
                    <span className="edulink-event-text">
                      <strong>{selectedEvent.date}</strong>
                    </span>
                  </li>
                  <li>
                    <UilCalender />
                    <span className="edulink-event-label">Date Limite:</span>
                    <span className="edulink-event-text">
                      <strong>{selectedEvent.dateL}</strong>
                    </span>
                  </li>
                  <li>
                    <UilBookAlt />
                    <span className="edulink-event-label">Matière:</span>
                    <span className="edulink-event-text">
                      <strong>{selectedEvent.matiere}</strong>
                    </span>
                  </li>
                  <li>
                    <UilSubject />
                    <span className="edulink-event-text">{selectedEvent.description}</span>
                  </li>
                </ul>
              </div>
            )}
          </EventModalStyleWrap>
        </Modal>
        <Card
          style={{
            width: '100%',
            height: '170px',
            borderRadius: '30px',
            marginBottom: '30px',
            backgroundColor: '#00AEDB',
          }}
        >
          <Row>
            <Col sm={19} xs={24}>
              <h1 style={{ fontSize: '36px', fontWeight: 700, color: '#FFF', margin: 0 }}>Exercices</h1>
              <p style={{ color: '#FFF' }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laborist.
              </p>
            </Col>
          </Row>
          <img
            src={require(`../../../static/img/pages/event.svg`).default}
            style={{ position: 'absolute', top: '-30px', right: '20px' }}
          />
        </Card>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
            <Cards headless>
              <DataTable
                filterOption
                filterOnchange
                buttonName="Ajouter Un Exercice"
                tableData={data}
                columns={dataTableColumn}
              />
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default Exercices;
