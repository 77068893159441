const actions = {
  VIEW_CHARGE_BEGIN: 'VIEW_CHARGE_BEGIN',
  VIEW_CHARGE_SUCCESS: 'VIEW_CHARGE_SUCCESS',
  VIEW_CHARGE_ERR: 'VIEW_CHARGE_ERR',

  VIEW_SINGLE_CHARGE_BEGIN: 'VIEW_SINGLE_CHARGE_BEGIN',
  VIEW_SINGLE_CHARGE_SUCCESS: 'VIEW_SINGLE_CHARGE_SUCCESS',
  VIEW_SINGLE_CHARGE_ERR: 'VIEW_SINGLE_CHARGE_ERR',

  ADD_CHARGE_BEGIN: 'ADD_CHARGE_BEGIN',
  ADD_CHARGE_SUCCESS: 'ADD_CHARGE_SUCCESS',
  ADD_CHARGE_ERR: 'ADD_CHARGE_ERR',

  DELETE_CHARGE_BEGIN: 'DELETE_CHARGE_BEGIN',
  DELETE_CHARGE_SUCCESS: 'DELETE_CHARGE_SUCCESS',
  DELETE_CHARGE_ERR: 'DELETE_CHARGE_ERR',

  UPDATE_CHARGE_BEGIN: 'UPDATE_CHARGE_BEGIN',
  UPDATE_CHARGE_SUCCESS: 'UPDATE_CHARGE_SUCCESS',
  UPDATE_CHARGE_ERR: 'UPDATE_CHARGE_ERR',

  viewChargeBegin: () => {
    return {
      type: actions.VIEW_CHARGE_BEGIN,
    };
  },

  viewChargeSuccess: (data) => {
    return {
      type: actions.VIEW_CHARGE_SUCCESS,
      data,
    };
  },

  viewChargeErr: (err) => {
    return {
      type: actions.VIEW_CHARGE_ERR,
      err,
    };
  },

  viewSingleChargeBegin: () => {
    return {
      type: actions.VIEW_SINGLE_CHARGE_BEGIN,
    };
  },

  viewSingleChargeSuccess: (data) => {
    return {
      type: actions.VIEW_SINGLE_CHARGE_SUCCESS,
      data,
    };
  },

  viewSingleChargeErr: (err) => {
    return {
      type: actions.VIEW_SINGLE_CHARGE_ERR,
      err,
    };
  },

  updateChargeBegin: () => {
    return {
      type: actions.UPDATE_CHARGE_BEGIN,
    };
  },

  updateChargeSuccess: (data) => {
    return {
      type: actions.UPDATE_CHARGE_SUCCESS,
      data,
    };
  },

  updateChargeErr: (err) => {
    return {
      type: actions.UPDATE_CHARGE_ERR,
      err,
    };
  },
  deleteChargeBegin: () => {
    return {
      type: actions.DELETE_CHARGE_BEGIN,
    };
  },

  deleteChargeSuccess: (data) => {
    return {
      type: actions.DELETE_CHARGE_SUCCESS,
      data,
    };
  },

  deleteChargeErr: (err) => {
    return {
      type: actions.DELETE_CHARGE_ERR,
      err,
    };
  },
  addChargeBegin: () => {
    return {
      type: actions.ADD_CHARGE_BEGIN,
    };
  },

  addChargeSuccess: (data) => {
    return {
      type: actions.ADD_CHARGE_SUCCESS,
      data,
    };
  },

  addChargeErr: (err) => {
    return {
      type: actions.ADD_CHARGE_ERR,
      err,
    };
  },
};

export default actions;
