import { combineReducers } from 'redux';
import { readMessageReducer } from './message/reducers';
import { chatReducer } from './chat/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import NoteReducer from './note/reducers';
import ClubReducer from './club/reducers';
import EventReducer from './event/reducers';
import ReunionReducer from './reunion/reducers';
import ClasseReducer from './classe/reducers';
import ExerciceReducer from './exercice/reducers';
import InscriptionReducer from './inscription/reducers';
import PersonnelReducer from './personnel/reducers';
import CantineReducer from './cantine/reducers';
import AbsenceReducer from './absence/reducers';
import AbsenceProfReducer from './absence_prof/reducers';
import ChargeReducer from './charge/reducers';
import EmploiReducer from './emploi/reducers';
import ParentReunionReducer from './parent/reunion/reducers';
import ParentAbsenceReducer from './parent/absence/reducers';
import ParentEmploiReducer from './parent/emploi/reducers';
import ParentEventReducer from './parent/event/reducers';
import ParentClubReducer from './parent/club/reducers';
import ParentCantineReducer from './parent/cantine/reducers';
import ParentExerciceReducer from './parent/exercice/reducers';
import { ParentChatReducer } from './parent/chat/reducers';
import FraisReducer from './frais/reducers';
import UserReducer from './superadmin/user/reducers';
import PaimentReducer from './paiment/reducers';
import ParentFraisReducer from './parent/frais/reducers';
import ProfAbsenceReducer from './prof/absence/reducers';
import ProfExerciceReducer from './prof/exercice/reducers';
import ProfNoteReducer from './prof/note/reducers';
import ProfClasseReducer from './prof/classe/reducers';
import ProfInscriptionReducer from './prof/inscription/reducers';
import { ProfChatReducer } from './prof/chat/reducers';
import ParentProfReducer from './parent/prof/reducers';
import ParentFactureReducer from './parent/facture/reducers';
import TypeChargeReducer from './typeCharge/reducers';
import ProfReducer from './professeur/reducers';
import StatisticsReducer from './statistics/reducers';
import MatiereReducer from './matiere/reducers';
import MatiereProfReducer from './prof/matiere/reducers';
const rootReducers = combineReducers({
  message: readMessageReducer,
  ChangeLayoutMode,
  chatReducer,
  NoteReducer,

  auth: authReducer,
  ClubReducer,
  EventReducer,
  ReunionReducer,
  ClasseReducer,
  ExerciceReducer,
  InscriptionReducer,
  PersonnelReducer,
  AbsenceReducer,
  CantineReducer,
  AbsenceProfReducer,
  ChargeReducer,
  TypeChargeReducer,
  EmploiReducer,
  ProfReducer,
  ParentReunionReducer,
  ParentAbsenceReducer,
  ParentEmploiReducer,
  ParentEventReducer,
  ParentClubReducer,
  ParentCantineReducer,
  ParentExerciceReducer,
  ParentChatReducer,
  ParentFraisReducer,
  ParentProfReducer,
  ParentFactureReducer,

  UserReducer,
  FraisReducer,
  PaimentReducer,

  ProfAbsenceReducer,
  ProfExerciceReducer,
  ProfNoteReducer,
  ProfClasseReducer,
  ProfInscriptionReducer,
  ProfChatReducer,
  MatiereProfReducer,

  StatisticsReducer,

  MatiereReducer,
});

export default rootReducers;
