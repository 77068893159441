const actions = {
  VIEW_CLASSE_BEGIN: 'VIEW_CLASSE_BEGIN',
  VIEW_CLASSE_SUCCESS: 'VIEW_CLASSE_SUCCESS',
  VIEW_CLASSE_ERR: 'VIEW_CLASSE_ERR',

  VIEW_SINGLE_CLASSE_BEGIN: 'VIEW_SINGLE_CLASSE_BEGIN',
  VIEW_SINGLE_CLASSE_SUCCESS: 'VIEW_SINGLE_CLASSE_SUCCESS',
  VIEW_SINGLE_CLASSE_ERR: 'VIEW_SINGLE_CLASSE_ERR',

  ADD_CLASSE_BEGIN: 'ADD_CLASSE_BEGIN',
  ADD_CLASSE_SUCCESS: 'ADD_CLASSE_SUCCESS',
  ADD_CLASSE_ERR: 'ADD_CLASSE_ERR',

  DELETE_CLASSE_BEGIN: 'DELETE_CLASSE_BEGIN',
  DELETE_CLASSE_SUCCESS: 'DELETE_CLASSE_SUCCESS',
  DELETE_CLASSE_ERR: 'DELETE_CLASSE_ERR',

  UPDATE_CLASSE_BEGIN: 'UPDATE_CLASSE_BEGIN',
  UPDATE_CLASSE_SUCCESS: 'UPDATE_CLASSE_SUCCESS',
  UPDATE_CLASSE_ERR: 'UPDATE_CLASSE_ERR',

  viewClasseBegin: () => {
    return {
      type: actions.VIEW_CLASSE_BEGIN,
    };
  },

  viewClasseSuccess: (data) => {
    return {
      type: actions.VIEW_CLASSE_SUCCESS,
      data,
    };
  },

  viewClasseErr: (err) => {
    return {
      type: actions.VIEW_CLASSE_ERR,
      err,
    };
  },

  viewSingleClasseBegin: () => {
    return {
      type: actions.VIEW_SINGLE_CLASSE_BEGIN,
    };
  },

  viewSingleClasseSuccess: (data) => {
    return {
      type: actions.VIEW_SINGLE_CLASSE_SUCCESS,
      data,
    };
  },

  viewSingleClasseErr: (err) => {
    return {
      type: actions.VIEW_SINGLE_CLASSE_ERR,
      err,
    };
  },

  updateClasseBegin: () => {
    return {
      type: actions.UPDATE_CLASSE_BEGIN,
    };
  },

  updateClasseSuccess: (data) => {
    return {
      type: actions.UPDATE_CLASSE_SUCCESS,
      data,
    };
  },

  updateClasseErr: (err) => {
    return {
      type: actions.UPDATE_CLASSE_ERR,
      err,
    };
  },
  deleteClasseBegin: () => {
    return {
      type: actions.DELETE_CLASSE_BEGIN,
    };
  },

  deleteClasseSuccess: (data) => {
    return {
      type: actions.DELETE_CLASSE_SUCCESS,
      data,
    };
  },

  deleteClasseErr: (err) => {
    return {
      type: actions.DELETE_CLASSE_ERR,
      err,
    };
  },
  addClasseBegin: () => {
    return {
      type: actions.ADD_CLASSE_BEGIN,
    };
  },

  addClasseSuccess: (data) => {
    return {
      type: actions.ADD_CLASSE_SUCCESS,
      data,
    };
  },

  addClasseErr: (err) => {
    return {
      type: actions.ADD_CLASSE_ERR,
      err,
    };
  },
};

export default actions;
