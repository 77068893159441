import Cookies from 'js-cookie';
import { message } from 'antd';
import actions from './actions';
import { getItem, setItem } from '../../utility/localStorageControl';

const {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_ERR,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  LOGOUT_ERR,
  FORGOT_PASSWORD_BEGIN,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERR,
} = actions;

const initState = {
  loading: false,
  data: getItem('user') || null,
  token: getItem('token') || null,
  error: null,
  role: getItem('role') || null,
};

const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      setItem('user', data.payload);
      setItem('token', data.access_token);
      setItem('role', data.role.toLowerCase());
      return {
        ...state,
        login: true,
        data: data.payload,
        token: data.access_token,
        loading: false,
        role: data.role.toLowerCase(),
      };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: false,
        loading: false,
        token: null,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case FORGOT_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        token: null,
      };
    case FORGOT_PASSWORD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default AuthReducer;
