import React, { useEffect, useState } from 'react';
import { Row, Col, Modal } from 'antd';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import DataTable from '../../components/table/DataTable';
import { Link } from 'react-router-dom';
import UilEye from '@iconscout/react-unicons/icons/uil-eye';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import UilTrash from '@iconscout/react-unicons/icons/uil-trash-alt';
import { Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { viewClubData } from '../../redux/club/actionCreator';
import { viewEventData } from '../../redux/event/actionCreator';

function AdminActualite() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { clubs, events } = useSelector((state) => {
    return {
      clubs: state.ClubReducer.arrayData,
      events: state.EventReducer.arrayData,
    };
  });
  useEffect(() => {
    dispatch(viewClubData());
    dispatch(viewEventData());
  }, []);

  useEffect(() => {
    if (clubs && events) {
      const localClubsData = clubs.map((item) => {
        return {
          titre: item.titre,
          date: item.jour,
          heur: item.heur,
          adresse: item.adresse,
          description: item.description,
          frais: item.frais,
          created_at: item.created_at,
          actuality: (
            <div style={{ fontSize: '.8rem' }}>
              <div>Club : {item.titre}</div>
              <div>Jour : {item.jour}</div>
              <div>Heur : {item.heur}</div>
              <div>Frais : {item.frais == 0 ? 'gratuit' : `${item.frais} TND`}</div>
            </div>
          ),
          badge: <span className={`status-text primary`}>Club</span>,
          action: (
            <div className="table-actions">
              <Link className="view" to="/admin/club" state={{ club: { ...item } }}>
                <UilEye />
              </Link>
              <Link className="edit" to="/admin/edit" state={{ club: { ...item } }}>
                <UilEdit />
              </Link>
            </div>
          ),
        };
      });
      const localEventsData = events.map((item) => {
        const [date, heur] = item.dateHeur.split(' ');
        return {
          titre: item.titre,
          date: date,
          heur: heur,
          adresse: item.adresse,
          description: item.description,
          frais: item.frais,
          created_at: item.created_at,
          actuality: (
            <div style={{ fontSize: '.8rem' }}>
              <div>Evenement : {item.titre}</div>
              <div>Date : {date}</div>
              <div>Heur : {heur}</div>
              <div>Frais : {item.frais == 0 ? 'gratuit' : `${item.frais} TND`}</div>
            </div>
          ),
          badge: <span className={`status-text active`}>Evenement</span>,
          action: (
            <div className="table-actions">
              <Link className="view" to="/admin/events/event" state={{ event: { ...item } }}>
                <UilEye />
              </Link>
              <Link className="edit" to="/admin/events/edit" state={{ event: { ...item } }}>
                <UilEdit />
              </Link>
            </div>
          ),
        };
      });
      let localData = [...localClubsData, ...localEventsData];
      localData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setData(localData);
    }
  }, [clubs, events]);

  const dataTableColumn = [
    {
      title: 'Title',
      dataIndex: 'titre',
      key: 'titre',
    },
    {
      title: 'Adresse',
      dataIndex: 'adresse',
      key: 'adresse',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Heur',
      dataIndex: 'heur',
      key: 'heur',
    },
    {
      title: 'Frais',
      dataIndex: 'frais',
      key: 'frais',
    },
    {
      title: 'Badge',
      dataIndex: 'badge',
      key: 'badge',
    },

    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '60px',
    },
  ];

  const responsiveDataTableColumn = [
    {
      title: 'Actualité',
      dataIndex: 'actuality',
      key: 'actuality',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '60px',
    },
  ];
  return (
    <>
      <Main>
        <Card
          style={{
            width: '100%',
            height: '170px',
            borderRadius: '30px',
            marginBottom: '30px',
            backgroundColor: '#00AEDB',
          }}
        >
          <Row>
            <Col sm={19} xs={24}>
              <h1 style={{ fontSize: '36px', fontWeight: 700, color: '#FFF', margin: 0 }}>Actualités</h1>
              <p style={{ color: '#FFF' }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laborist.
              </p>
            </Col>
          </Row>
          <img
            src={require(`../../static/img/pages/event.svg`).default}
            style={{ position: 'absolute', top: '-30px', right: '20px' }}
          />
        </Card>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
            <Cards headless>
              <DataTable
                filterOnchange
                tableData={data}
                columns={window.innerWidth < 990 ? responsiveDataTableColumn : dataTableColumn}
              />
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default AdminActualite;
