import { message } from 'antd';
import actions from './actions';

const initialState = {
  data: [],
  error: null,
};

const { VIEW_EXERCICE_BEGIN, VIEW_EXERCICE_SUCCESS, VIEW_EXERCICE_ERR } = actions;

const ExerciceReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case VIEW_EXERCICE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_EXERCICE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case VIEW_EXERCICE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default ExerciceReducer;
