import { message } from 'antd';
import actions from './actions';

const initialState = {
  arrayData: [],
  typeCharge:[],
  singleData:null,
  loading: false,
  error: null,
};

const {
  VIEW_CHARGE_BEGIN,
  VIEW_CHARGE_SUCCESS,
  VIEW_CHARGE_ERR,

  VIEW_SINGLE_CHARGE_BEGIN,
  VIEW_SINGLE_CHARGE_SUCCESS,
  VIEW_SINGLE_CHARGE_ERR,

  ADD_CHARGE_BEGIN,
  ADD_CHARGE_SUCCESS,
  ADD_CHARGE_ERR,

  DELETE_CHARGE_BEGIN,
  DELETE_CHARGE_SUCCESS,
  DELETE_CHARGE_ERR,

  UPDATE_CHARGE_BEGIN,
  UPDATE_CHARGE_SUCCESS,
  UPDATE_CHARGE_ERR,
} = actions;

const ChargeReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_CHARGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CHARGE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case UPDATE_CHARGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_CHARGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_CHARGE_SUCCESS:
      return {
        ...state,
        arrayData:data,
        loading: false,
      };
    case VIEW_CHARGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_SINGLE_CHARGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_SINGLE_CHARGE_SUCCESS:
      return {
        ...state,
        singleData:data,
        loading: false,
      };
    case VIEW_SINGLE_CHARGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_CHARGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CHARGE_SUCCESS:
      const arrayData = state.arrayData.filter(
        (item) => item.id !== data
      );
      return {
        ...state,
        arrayData,  
        loading: false,
      };
    case DELETE_CHARGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_CHARGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_CHARGE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case ADD_CHARGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default ChargeReducer;
