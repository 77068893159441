import React, { useState, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Pagination, Skeleton, Select } from 'antd';
import { UsercardWrapper } from '../pages/style';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import BulletinCard from '../pages/overview/BulletinCard';
import { viewClasseData } from '../../redux/classe/actionCreator';
import { viewInscriptionData } from '../../redux/inscription/actionCreator';

function Bulletins() {
  const dispatch = useDispatch();
  const [niveau, setNiveau] = useState();
  const [classesSelect, setClassesSelect] = useState();
  const [selectedClasse, setSelectedClasse] = useState();
  const [eleves, setEleves] = useState([]);

  const { classes, inscriptions } = useSelector((state) => {
    return {
      classes: state.ClasseReducer.arrayData,
      inscriptions: state.InscriptionReducer.arrayData,
    };
  });
  
  useEffect(() => {
    dispatch(viewClasseData());
    dispatch(viewInscriptionData());
  }, []);

  useEffect(() => {
    if (classes.length > 0) {
      setNiveau('1');
    }
  }, [classes]);

  useEffect(() => {
    if (niveau) {
      const data = classes.filter((item) => item.niveau == niveau);
      const selectClasseData = data.map((item) => {
        return { value: item.id, label: item.titre };
      });
      setClassesSelect([...selectClasseData]);
      setSelectedClasse(selectClasseData[0] && selectClasseData[0].value);
    }
  }, [niveau]);

  useEffect(() => {
    if (selectedClasse) {
      const data = inscriptions.filter((item) => item.classe.id == selectedClasse);
      setEleves(data);
    } else {
      setEleves([]);
    }
  }, [selectedClasse]);

  const GridView = React.memo(() => {
    return eleves.map((user, index) => {
      return (
        <Col key={index} xxl={6} xl={8} sm={12} xs={24}>
          <Suspense
            fallback={
              <Cards headless>
                <Skeleton avatar active />
              </Cards>
            }
          >
            <BulletinCard user={user} />
          </Suspense>
        </Col>
      );
    });
  });

  return (
    <>
      <Main>
        <div className="bulletin-classe-list-header">
          <Select
            style={{ width: '200px' }}
            placeholder="Choisir un niveau"
            value={niveau}
            onChange={(e) => setNiveau(e)}
            options={[
              { value: '1', label: '1ère année' },
              { value: '2', label: '2ème année' },
              { value: '3', label: '3ème année' },
              { value: '4', label: '4ème année' },
              { value: '5', label: '5ème année' },
              { value: '6', label: '6ème année' },
            ]}
          />
          <Select
            style={{ width: '200px' }}
            placeholder="Choisir un classe"
            options={classesSelect}
            value={selectedClasse}
            onChange={(e) => setSelectedClasse(e)}
          />
        </div>
        <UsercardWrapper>
          <Row gutter={25}>
            <GridView />
          </Row>
        </UsercardWrapper>
      </Main>
    </>
  );
}

export default Bulletins;
