import UilAngleLeft from '@iconscout/react-unicons/icons/uil-angle-left';
import UilAngleRight from '@iconscout/react-unicons/icons/uil-angle-right';
import UilCalender from '@iconscout/react-unicons/icons/uil-calender';
import UilClock from '@iconscout/react-unicons/icons/uil-clock';
import UilSubject from '@iconscout/react-unicons/icons/uil-subject';
import UilMultiply from '@iconscout/react-unicons/icons/uil-multiply';

import { Col, Modal, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import { CalendarWrapper, EventModalStyleWrap } from './Style';
import { Main } from '../styled';
import { startOfWeek, addDays, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { viewReunionData } from '../../redux/parent/reunion/actionCreator';
import { viewAbsenceData } from '../../redux/parent/absence/actionCreator';
import { Link } from 'react-router-dom';
import { viewExerciceData } from '../../redux/parent/exercice/actionCreator';

function Calendars() {
  const dispatch = useDispatch();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [calendarData, setCalendarData] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedEvent, setSlectedEvent] = useState();
  const [selectedTab, setSelectedTab] = useState(1);
  const { reunions, class_id, absences, userId, exercices } = useSelector((state) => {
    return {
      absences: state.ParentAbsenceReducer.data,
      reunions: state.ParentReunionReducer.data,
      exercices: state.ParentExerciceReducer.data,
      class_id: state.auth.data.classe_id,
      userId: state.auth.data.id,
    };
  });
  useEffect(() => {
    if (selectedTab == 1) {
      const reunionsData = reunions.map((item) => {
        const classes = item.classes.map((element) => element.id);
        return {
          ...item,
          classes,
          eleves: [userId],
        };
      });
      setData(reunionsData);
    }
    if (selectedTab == 2) {
      const exercicesData = exercices.map((item) => {
        return {
          ...item,
          titre: item.matiere,
          classes: [item.classe_id],
          date: item.dateL,
          heurD: item.heur,
          heurF: item.heurL,
          eleves: [userId],
        };
      });
      setData(exercicesData);
    }
    if (selectedTab == 3) {
      {
        const absencesData = absences.map((item) => {
          const eleves = typeof item.ListEleve == 'string' ? JSON.parse(item.ListEleve) : item.ListEleve;
          return {
            ...item,
            classes: [item.classe_id],
            eleves,
            titre: item.matiere,
          };
        });
        setData(absencesData);
      }
    }
  }, [selectedTab, currentDate, absences, reunions, exercices]);

  const renderClendarData = () => {
    const rows = [];
    for (const hour of timeSlots) {
      const cells = [];
      for (const day of weekDays) {
        const highlighted = isHighlightedCell(format(day, 'yyyy-MM-dd'), hour);
        let cellData;
        if (highlighted) {
          let eventData = highlighted[0];
          eventData.day = moment(day, 'MM-DD-YYYY');
          cellData = {
            highlighted: true,
            data: eventData,
            height: `${(highlighted[1] - 1) * 50 + 45}px`,
            day: moment(day, 'MM-DD-YYYY'),
          };
        } else {
          cellData = {
            highlighted: false,
            day: moment(day, 'MM-DD-YYYY'),
          };
        }
        cells.push(cellData);
      }
      rows.push({ hour, cells });
    }
    setCalendarData(rows);
  };

  useEffect(() => {
    const start = moment(weekDays[0]).format('yyyy-MM-DD');
    const end = moment(weekDays[6]).format('yyyy-MM-DD');
    dispatch(viewReunionData(start, end));
    dispatch(viewAbsenceData(start, end));
    dispatch(viewExerciceData());
  }, [currentDate]);

  useEffect(() => {
    renderClendarData();
  }, [data]);

  const getWeekDays = (startDate) => {
    const days = [];
    for (let i = 1; i <= 7; i++) {
      days.push(addDays(startDate, i));
    }
    return days;
  };

  const getTimeSlots = () => {
    const timeSlots = [];
    for (let i = 0; i <= 23; i++) {
      timeSlots.push(i);
    }
    return timeSlots;
  };

  const isHighlightedCell = (day, hour) => {
    const item = data.find((item) => {
      if (item.eleves.includes(userId) && item.classes.includes(class_id)) {
        const [hours] = item.heurD.split(':');
        const intHours = parseInt(hours);
        return hour === intHours && day === item.date;
      } else {
        return false;
      }
    });
    if (item) {
      const [start] = item.heurD.split(':');
      const [end] = item.heurF.split(':');

      return [item, end - start];
    }
  };

  const handlePrevWeek = () => {
    setCurrentDate((prevDate) => addDays(startOfWeek(prevDate), -1));
  };

  const handleNextWeek = () => {
    setCurrentDate((prevDate) => addDays(startOfWeek(prevDate), 7));
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
  };

  const showModal = (event) => {
    setSlectedEvent(event);
    setOpen(true);
  };

  const handleCancel = () => {
    setSlectedEvent(null);
    setOpen(false);
  };

  const weekDays = getWeekDays(startOfWeek(currentDate));
  const timeSlots = getTimeSlots();

  return (
    <>
      <Main>
        <CalendarWrapper className="edulink-calendar-wrap">
          <Row gutter={25}>
            <Col xxl={24} xl={24} xs={24}>
              <Modal
                open={open}
                onCancel={handleCancel}
                closable={false}
                footer={null}
                title={
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {selectedEvent && selectedEvent.titre}
                    <div style={{ display: 'flex', alignItems: 'center', columnGap: '7px', cursor: 'pointer' }}>
                      <UilMultiply size={16} onClick={handleCancel} />
                    </div>
                  </div>
                }
              >
                <EventModalStyleWrap>
                  {selectedEvent && (
                    <div className="edulink-event-details">
                      <ul>
                        <li>
                          <UilCalender />
                          <span className="edulink-event-label">Date:</span>
                          <span className="edulink-event-text">
                            <strong>{selectedEvent.date}</strong>
                          </span>
                        </li>
                        <li>
                          <UilClock />
                          <span className="edulink-event-label">Temps:</span>
                          <span className="edulink-event-text">
                            <strong>
                              {formatTime(selectedEvent.heurD)} - {formatTime(selectedEvent.heurF)}
                            </strong>
                          </span>
                        </li>
                        <li className="edulink-event-description">
                          <UilSubject />
                          <span className="edulink-event-text">{selectedEvent.description}</span>
                        </li>
                      </ul>
                    </div>
                  )}
                </EventModalStyleWrap>
              </Modal>

              <div className="calendar-container">
                <div>
                  <CustomToolbar
                    startDay={weekDays[0]}
                    endDay={weekDays[6]}
                    nextWeek={handleNextWeek}
                    prevWeek={handlePrevWeek}
                    setTab={setSelectedTab}
                  />
                </div>
                <div className="calendar-cells-container">
                  <table className="calendar" rules="none">
                    <thead>
                      <tr>
                        <th>
                          <UilClock size={20} />
                        </th>
                        {weekDays.map((day) => (
                          <th key={day} className="day-header">
                            {format(day, 'EEEE', { locale: fr })}
                            <br /> {format(day, 'd')}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {calendarData.map((item, index) => (
                        <tr key={index}>
                          <td className="cell">{`${item.hour < 10 ? '0' : ''}${item.hour}:00`}</td>
                          {item.cells.map((day, index) => (
                            <td key={index} className={`day-cell ${day.highlighted ? 'highlighted-cell' : ''}`}>
                              {day.highlighted ? (
                                <div
                                  onClick={() => {
                                    showModal(day.data);
                                  }}
                                  className={`meeting reunions`}
                                  style={{
                                    height: day.height,
                                  }}
                                >
                                  <div className="from-to-container">
                                    <div className={`from-to-badge reunions-badge`}>{formatTime(day.data.heurD)}</div>
                                    <div className={`from-to-badge reunions-badge`}>{formatTime(day.data.heurF)}</div>
                                  </div>
                                  <div className={`cell-title reunions-title`}>{day.data.titre}</div>
                                </div>
                              ) : (
                                <div className="empty-cell"></div>
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        </CalendarWrapper>
      </Main>
    </>
  );
}

const CustomToolbar = ({ startDay, endDay, nextWeek, prevWeek, setTab }) => {
  useEffect(() => {
    const handleLinkClick = (event) => {
      const activatedLink = document.querySelector('.calendar-header__right a.active');
      if (activatedLink) activatedLink.classList.remove('active');
      event.target.classList.add('active');
    };

    const toolbarLinks = document.querySelectorAll('.calendar-header__right a');
    toolbarLinks.forEach((link) => {
      link.addEventListener('click', handleLinkClick);
    });

    const tags = document.querySelectorAll('.calendar-header_tags a');
    tags.forEach((tag) => {
      tag.addEventListener('click', handleLinkClick);
    });

    return () => {
      toolbarLinks.forEach((link) => {
        link.removeEventListener('click', handleLinkClick);
      });
      tags.forEach((tag) => {
        tag.removeEventListener('click', handleLinkClick);
      });
    };
  }, []);

  return (
    <div className="calendar-header">
      <div className="calendar-header__left">
        <div className="calender-head__navigation">
          <button className="btn-navigate" type="button" onClick={() => prevWeek()}>
            <UilAngleLeft />
          </button>
          <span className="date-label">
            {format(startDay, 'dd MMM yyyy', { locale: fr })} - {format(endDay, 'dd MMM yyyy', { locale: fr })}
          </span>
          <button className="btn-navigate" type="button" onClick={() => nextWeek()}>
            <UilAngleRight />
          </button>
        </div>
      </div>
      <div className="calendar-header__right">
        <ul>
          <li>
            <Link className="active" to="#" onClick={() => setTab(1)}>
              Reunions
            </Link>
          </li>
          <li>
            <Link to="#" onClick={() => setTab(2)}>
              Exercices
            </Link>
          </li>
          <li>
            <Link to="#" onClick={() => setTab(3)}>
              Absences
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Calendars;
