import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import { useDispatch, useSelector } from 'react-redux';
import { viewAllClubData, viewClubData } from '../../../../redux/parent/club/actionCreator';
import { viewFactureData } from '../../../../redux/parent/facture/actionCreator';

const AllPosts = lazy(() => import('./timeline/Posts'));

function Clubs() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(viewClubData());
  }, []);
  const { clubs } = useSelector((state) => {
    return {
      clubs: state.ParentClubReducer.own,
    };
  });
  useEffect(() => {
    dispatch(viewClubData());
  }, []);

  useEffect(() => {
    if (clubs) {
      const localData = clubs.map((item) => {
        return {
          titre: item.titre,
          date: item.jour,
          heur: item.heur,
          adresse: item.adresse,
          description: item.description,
          frais: item.frais,
          created_at: item.created_at,
          image: item.image,
          type: 'Club',
        };
      });
      localData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setData(localData);
    }
  }, [clubs]);

  useEffect(() => {
  }, [data]);
  return (
    <Row gutter={25}>
      <Col lg={24} xs={24}>
        <Suspense
          fallback={
            <Cards headless>
              <Skeleton active />
            </Cards>
          }
        ></Suspense>
        {data.map((item) => {
          return (
            <Suspense
              key={item}
              fallback={
                <Cards headless>
                  <Skeleton active />
                </Cards>
              }
            >
              <AllPosts item={item} />
            </Suspense>
          );
        })}
      </Col>
    </Row>
  );
}

export default Clubs;
