import { Col, Modal, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { deleteAbsenceData } from '../../redux/absence/actionCreator';
import { Badge, PricingCard } from '../../container/payment_methods/Style';
import Heading from '../heading/heading';
import { addPaimentData } from '../../redux/paiment/actionCreator';

function ClubTranches({ open, handleClose, data, etudiant, club }) {
  const { confirm } = Modal;
  const dispatch = useDispatch();

  const addPaiment = (nomTranche) => {
    dispatch(addPaimentData({ etudiant_id: etudiant, nomTranche, club_id: club }));
  };

  const showConfirm = (tranche) => {
    confirm({
      title: 'Voulez-vous payer la tranche ?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Payer',
      okType: 'danger',
      cancelText: 'Annuler',
      cancelButtonProps: {
        type: 'white',
      },
      onOk() {
        addPaiment(tranche);
      },
      onCancel() {},
    });
  };

  return (
    <Modal open={open} onCancel={handleClose} closable={false} footer={null} title={'Tranches du club'} width={1000}>
      <Row gutter={25}>
        {data &&
          data.map((item, index) => (
            <Col xxl={8} lg={8} sm={8} xs={24}>
              <PricingCard
                style={{ marginBottom: 30 }}
                onClick={() => !item.paid && showConfirm(item.nomTranche)}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Badge className="pricing-badge" type={item.paid ? 'success' : 'danger'}>
                    {item.nomTranche}
                  </Badge>
                  <div style={{ display: 'flex', columnGap: '5px' }}></div>
                </div>
                <Heading className="price-amount" as="h6">
                  <sup className="currency"></sup>
                  {item.montantTranche} DT <sub className="pricing-validity">par tranche</sub>
                </Heading>
              </PricingCard>
            </Col>
          ))}
      </Row>
    </Modal>
  );
}

export default ClubTranches;
