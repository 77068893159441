const actions = {
  VIEW_ABSENCE_BEGIN: 'VIEW_ABSENCE_BEGIN',
  VIEW_ABSENCE_SUCCESS: 'VIEW_ABSENCE_SUCCESS',
  VIEW_ABSENCE_ERR: 'VIEW_ABSENCE_ERR',

  viewAbsenceBegin: () => {
    return {
      type: actions.VIEW_ABSENCE_BEGIN,
    };
  },

  viewAbsenceSuccess: (data) => {
    return {
      type: actions.VIEW_ABSENCE_SUCCESS,
      data,
    };
  },

  viewAbsenceErr: (err) => {
    return {
      type: actions.VIEW_ABSENCE_ERR,
      err,
    };
  },
};

export default actions;
