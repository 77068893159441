import React, { useState, lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Skeleton, Select } from 'antd';
import { UsercardWrapper } from '../pages/style';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { viewClasseData } from '../../redux/classe/actionCreator';
import { viewInscriptionData } from '../../redux/inscription/actionCreator';
import { viewNoteData } from '../../redux/note/actionCreator';
import { viewMatiereData } from '../../redux/matiere/actionCreator';

const User = lazy(() => import('../pages/overview/UserCard'));

function Notes() {
  const dispatch = useDispatch();
  const [niveau, setNiveau] = useState();
  const [classesSelect, setClassesSelect] = useState();
  const [selectedClasse, setSelectedClasse] = useState();
  const [eleves, setEleves] = useState([]);
  const [selectedMatiere, setSelectedMatiere] = useState();

  const { classes, inscriptions, note, matieres } = useSelector((state) => {
    return {
      classes: state.ClasseReducer.arrayData,
      inscriptions: state.InscriptionReducer.arrayData,
      note: state.NoteReducer.data,
      matieres: state.MatiereReducer.arrayData,
    };
  });
  const [matieresData, setMatieresData] = useState([]);
  useEffect(() => {
    dispatch(viewClasseData());
    dispatch(viewInscriptionData());
    dispatch(viewMatiereData());
  }, []);
  const filterMatieres = (level) => {
    setMatieresData(matieres.filter((item) => item.niveau == level).map((item) => ({ label: item.nom, value: item.nom })));
  };

  useEffect(() => {
    if (classes && classes.length > 0 && matieres) {
      setNiveau('1');
    }
  }, [classes, matieres]);

  useEffect(() => {
    if (niveau) {
      filterMatieres(niveau);
      const data = classes.filter((item) => item.niveau == niveau);
      const selectClasseData = data.map((item) => {
        return { value: item.id, label: item.titre };
      });
      setClassesSelect([...selectClasseData]);
      setSelectedClasse(selectClasseData[0] && selectClasseData[0].value);
    }
  }, [niveau]);

  useEffect(() => {
    if (selectedClasse) {
      dispatch(viewNoteData(selectedClasse));
      const data = inscriptions.filter((item) => item.classe.id == selectedClasse);
      setEleves(data);
    }
  }, [selectedClasse]);

  useEffect(() =>{
    if(matieresData && matieresData.length > 0){
      setSelectedMatiere(matieresData[0].value);
    }
  },[matieresData])

  const GridView = React.memo(() => {
    return note.map((note, index) => {
      const [etudiant] = eleves.filter((item) => item.id == note.etudiant_id);
      return (
        <Col key={index} xxl={6} xl={8} sm={12} xs={24}>
          <Suspense
            fallback={
              <Cards headless>
                <Skeleton avatar active />
              </Cards>
            }
          >
            <User id={note.etudiant_id} etudiant={etudiant} matiere={selectedMatiere} notes={note.description} />
          </Suspense>
        </Col>
      );
    });
  });

  return (
    <>
      <Main>
        <div className="bulletin-classe-list-header">
          <Select
            style={{ width: '200px' }}
            placeholder="Choisir un niveau"
            value={niveau}
            onChange={(e) => setNiveau(e)}
            options={[
              { value: '1', label: '1ère année' },
              { value: '2', label: '2ème année' },
              { value: '3', label: '3ème année' },
              { value: '4', label: '4ème année' },
              { value: '5', label: '5ème année' },
              { value: '6', label: '6ème année' },
            ]}
          />
          <Select
            style={{ width: '200px' }}
            placeholder="Choisir un classe"
            options={classesSelect}
            value={selectedClasse}
            onChange={(e) => setSelectedClasse(e)}
          />
          <Select
            style={{ width: '200px' }}
            placeholder="Choisir une matière"
            value={selectedMatiere}
            onChange={(e) => setSelectedMatiere(e)}
            options={matieresData}
          />
        </div>
        <UsercardWrapper>
          <Row gutter={25}>
            <GridView />
          </Row>
        </UsercardWrapper>
      </Main>
    </>
  );
}

export default Notes;
