import Styled from 'styled-components';

const VerticalFormStyleWrap = Styled.div`
    .form-title{
        color: ${({ theme }) => theme['danger-color']}; 
        text-align: center;
        font-size: 30px;
        line-height: 24px;
        margin-bottom:40px;
    }
    .ant-picker{
        min-height: 50px;
        width:100%;
    }
    .switch-container{
        display:flex;
        align-items:center;
        column-gap:10px;
        margin-bottom:20px;
    }
    .label{
        font-size: 14px;
        color:#0A0A0A;
        font-weight:500;
        width:30px;
    }
    .ant-picker-input > input{
        margin: 12px 20px;
        color:black;
        &::placeholder{
            color: #bfbfbf !important;
        }
    }
    .ant-select-selector{
        min-height: 50px;
        width:100%;
            .ant-select-selection-placeholder{
                padding-top:6px;
            }
            .ant-select-selection-item{
                padding-top:6px;
            }
    }
    .ant-upload.ant-upload-drag{
        border-radius: 29px;
        border: 1px dashed #00AEDB;
        border-spacing: 15px;
    }
    .ant-card{
        margin-bottom: 25px
    }
    .ant-input-affix-wrapper > input.ant-input{
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .ant-input-affix-wrapper .ant-input-prefix svg{
        width: 16px;
        height: 16px;
        color: ${({ theme }) => theme['gray-color']};
    }
    .edulink-form-action{
        margin: -7.5px;
        button{
            font-size: 14px;
            font-weight: 500;
            border-radius: 6px;
            margin: 7.5px;
            padding: 6.4px 19px;
            &.ant-btn-light{
                height: 44px;
                color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
                background-color: ${({ theme }) => theme[theme.mainContent]['border-color-default']};
                border-color: ${({ theme }) => theme[theme.mainContent]['border-color-default']};
            }
            &.ant-btn-danger{
                color: white;
                background: ${({ theme }) => theme['danger-color']};
                border:none;
            }
            &:hover{
                &.ant-btn-danger{
                    color: white;
                    background: ${({ theme }) => theme['danger-hover']};
                }
            }
        }
        .ant-form-item{
            margin-bottom: 25px !important;
        }
        .ant-btn-light{
            background-color: ${({ theme }) => theme[theme.mainContent]['main-background-light']};
        }
    }
`;
const FormValidationWrap = Styled.div`
    .ant-card-body{
        padding: 30px 25px 50px 25px !important;
    }
    .ant-form-item-has-error{
        .ant-form-item-explain{
            font-size: 13px;
        }
    }
    .ant-form-item{
        .ant-form-item-label{
            >label{
                color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
            }
        }
    }
    .sDash_agree-check{
        .ant-form-item-control-input{
            min-height: auto;
        }
        .ant-form-item-has-error{
            .ant-checkbox-wrapper{
                span{
                    font-size: 13px;
                    color: ${({ theme }) => theme['danger-color']};
                }
                .ant-checkbox{
                    border-color: ${({ theme }) => theme['danger-color']}30;
                    &.ant-checkbox-checked{
                        border-color: ${({ theme }) => theme['danger-color']}30;
                        &:after{
                            border-color: ${({ theme }) => theme['danger-color']}30;
                        }
                        .ant-checkbox-inner{
                            &:after{
                                border-color: ${({ theme }) => theme['danger-color']};
                            }
                        }
                    }
                    .ant-checkbox-inner{
                        border-color: ${({ theme }) => theme['danger-color']}30;
                        background-color: ${({ theme }) => theme['danger-color']}30;
                    }
                }
            }
        }
        .ant-form-item-explain{
            margin-left: 25px;
            font-size: 13px;
        }
    }
    .edulink-form-action{
        display:flex;
        justify-content:end;
        button{
            border-radius: 7px;
            padding: 6.4px 29.2px;
            height: 44px;
        }
    }
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, 
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover{
        background-color: ${({ theme }) => theme[theme.mainContent]['input-bg']};
    }
    .ant-picker-status-error {
         border-color: #ff4d4f;
    }
    .ant-upload-status-error{
        background-color:red;
    }
    
`;
const PricingCard = Styled.div`
  background: ${({ theme }) => theme[theme.mainContent]['white-background']};
  border-radius: 10px;
  box-shadow: ${({ theme }) => (theme.mainContent === 'lightMode' ? '0px 5px 20px #9299B830' : '0 0')};
  padding: 30px;
  .pricing-badge{
    height: 32px;
    padding: 6px 22.6px;
  }
  .price-amount{
    font-size: 36px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
    .currency{
      font-size: 16px;
      font-weight: 600;
      top: -12px;
      ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 2px;
      color: ${({ theme }) => theme[theme.mainContent]['extra-light-text']};
    }
    .pricing-validity{
      font-size: 13px;
      font-weight: 400;
      bottom: 0;
      ${({ theme }) => (!theme.rtl ? 'left' : 'right')}: -2px;
      color: ${({ theme }) => theme[theme.mainContent]['gray-light-text']};
    }
  }
  .package-user-type{
    font-size: 13px;
    font-weight: 500;
    color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
  }
  .pricing-title{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 8px;
    color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
  }
  button{
    padding: 0px 29.32px;
    height: 44px;
    border-radius: 6px;
    &.ant-btn-white{
      background-color: ${({ theme }) => theme[theme.mainContent]['white-background']};
      border: 1px solid ${({ theme }) => theme[theme.mainContent]['border-color-default']} !important;
      span{
        color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
      }
      &:hover{
        background-color: ${({ theme }) => theme[theme.mainContent]['input-bg']};
      }
    }
  }
`;

const ListGroup = Styled.div`
  margin: 28px 0 15px;
  min-height: 210px;
  .list-single{
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
    &:not(:last-child){
      margin-bottom: 12px;
    }
    .icon{
      display: inline-block;
      margin: ${({ theme }) => (theme.rtl ? '0px 0 -4px 10px' : '0px 10px -4px 0')};
    }
  }
`;

const Badge = Styled.span`
  display: inline-block;
  margin-bottom: 32px;
  padding: 5px 20px;
  border-radius: 16px;
  background: ${({ type, theme }) => theme[`${type}-color`]}10;
  color: ${({ type, theme }) => theme[`${type}-color`]};
  font-size: 13px;
  font-weight: 500;
  &.badge-dark{
    color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
    background-color: ${({ theme }) => theme[theme.mainContent]['dark-background']};
  }
`;

export { VerticalFormStyleWrap, FormValidationWrap, PricingCard, ListGroup, Badge };
