import React, { useState, lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Skeleton, Select } from 'antd';
import { UsercardWrapper } from '../../pages/style';
import { Main } from '../../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { viewClasseData } from '../../../redux/prof/classe/actionCreator';
import { viewInscriptionData } from '../../../redux/prof/inscription/actionCreator';
import { viewNoteData } from '../../../redux/prof/note/actionCreator';
import { viewMatiereData } from '../../../redux/prof/matiere/actionCreator';

const User = lazy(() => import('../../pages/overview/ProfUserCard'));

function Notes() {
  const dispatch = useDispatch();
  const [niveau, setNiveau] = useState('1');
  const [classesSelect, setClassesSelect] = useState();
  const [selectedClasse, setSelectedClasse] = useState();
  const [eleves, setEleves] = useState([]);
  const [selectedMatiere, setSelectedMatiere] = useState();
  const [matieresData, setMatieresData] = useState([]);

  const { classes, inscriptions, note, matieres } = useSelector((state) => {
    return {
      classes: state.ProfClasseReducer.arrayData,
      inscriptions: state.ProfInscriptionReducer.arrayData,
      note: state.ProfNoteReducer.data,
      matieres: state.MatiereProfReducer.data,
    };
  });
  useEffect(() => {
    dispatch(viewClasseData());
    dispatch(viewInscriptionData());
    dispatch(viewMatiereData());
  }, []);

  useEffect(() => {
    if (matieres.length > 0) {
      const data = matieres
        .filter((item, index, self) => index === self.findIndex((t) => t.nom === item.nom) && item.niveau == niveau)
        .map((item) => ({
          label: item.nom,
          value: item.nom,
        }));
      setMatieresData(data);
      setSelectedMatiere(data[0] && data[0].value);
    }
  }, [niveau, matieres]);

  useEffect(() => {
    if (classes.length > 0) {
      setNiveau('1');
    }
  }, [classes]);

  useEffect(() => {
    if (niveau) {
      const data = classes.filter((item) => item.niveau == niveau);
      const selectClasseData = data.map((item) => {
        return { value: item.id, label: item.titre };
      });
      setClassesSelect([...selectClasseData]);
      setSelectedClasse(selectClasseData[0] && selectClasseData[0].value);
    }
  }, [niveau]);

  useEffect(() => {
    if (selectedClasse) {
      dispatch(viewNoteData(selectedClasse));
      const data = inscriptions.filter((item) => item.classe.id == selectedClasse);
      setEleves(data);
    }
  }, [selectedClasse]);

  const GridView = React.memo(() => {
    return note.map((note, index) => {
      const [etudiant] = eleves.filter((item) => item.id == note.etudiant_id);
      return (
        <Col key={index} xxl={6} xl={8} sm={12} xs={24}>
          <Suspense
            fallback={
              <Cards headless>
                <Skeleton avatar active />
              </Cards>
            }
          >
            <User id={note.etudiant_id} etudiant={etudiant} matiere={selectedMatiere} notes={note.description} />
          </Suspense>
        </Col>
      );
    });
  });

  return (
    <>
      <Main>
        <div className="bulletin-classe-list-header">
          <Select
            style={{ width: '200px' }}
            placeholder="Choisir un niveau"
            value={niveau}
            onChange={(e) => setNiveau(e)}
            options={[
              { value: '1', label: '1ère année' },
              { value: '2', label: '2ème année' },
              { value: '3', label: '3ème année' },
              { value: '4', label: '4ème année' },
              { value: '5', label: '5ème année' },
              { value: '6', label: '6ème année' },
            ]}
          />
          <Select
            style={{ width: '200px' }}
            placeholder="Choisir un classe"
            options={classesSelect}
            value={selectedClasse}
            onChange={(e) => setSelectedClasse(e)}
          />
          <Select
            style={{ width: '200px' }}
            placeholder="Choisir une matière"
            value={selectedMatiere}
            onChange={(e) => setSelectedMatiere(e)}
            options={matieresData}
          />
        </div>
        <UsercardWrapper>
          <Row gutter={25}>
            <GridView />
          </Row>
        </UsercardWrapper>
      </Main>
    </>
  );
}

export default Notes;
