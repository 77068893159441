import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  addTypeChargeBegin,
  addTypeChargeSuccess,
  addTypeChargeErr,
  updateTypeChargeBegin,
  updateTypeChargeSuccess,
  updateTypeChargeErr,
  viewTypeChargeBegin,
  viewTypeChargeSuccess,
  viewTypeChargeErr,
  viewSingleTypeChargeBegin,
  viewSingleTypeChargeSuccess,
  viewSingleTypeChargeErr,
  deleteTypeChargeBegin,
  deleteTypeChargeSuccess,
  deleteTypeChargeErr,
} = actions;

const viewTypeChargeData = () => {
  return async (dispatch) => {
    dispatch(viewTypeChargeBegin());
    try {
      const response = await DataService.get('/ListCharge/get');
      if (response.status === 200) {
        dispatch(viewTypeChargeSuccess(response.data.payload));
      } else {
        dispatch(viewTypeChargeErr('err'));
      }
    } catch (err) {
      dispatch(viewTypeChargeErr('err'));
    }
  };
};

const viewSingleTypeChargeData = (id) => {
  return async (dispatch) => {
    dispatch(viewSingleTypeChargeBegin());
    try {
      const response = await DataService.get(`/charge/singleInfo/${id}`);

      if (response.status === 200) {
        dispatch(viewSingleTypeChargeSuccess(response.data.payload));
      } else {
        dispatch(viewSingleTypeChargeErr('err'));
      }
    } catch (err) {
      dispatch(viewSingleTypeChargeErr('err'));
    }
  };
};

const addTypeChargeData = (data, callback) => {
  return async (dispatch) => {
    dispatch(addTypeChargeBegin());
    try {
      const response = await DataService.post('/ListCharge/add', data);

      if (response.status === 201) {
        dispatch(addTypeChargeSuccess(response.data.payload));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(addTypeChargeErr('err'));
      }
    } catch (err) {
      console.error(err);
      dispatch(addTypeChargeErr('err'));
    }
  };
};
const deleteTypeChargeData = (id, callback) => {
  return async (dispatch) => {
    dispatch(deleteTypeChargeBegin());
    try {
      const response = await DataService.delete(`/ListCharge/delete/${id}`);
      if (response.status === 200) {
        dispatch(deleteTypeChargeSuccess(id));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(deleteTypeChargeErr('err'));
      }
    } catch (err) {
      dispatch(deleteTypeChargeErr('err'));
    }
  };
};
const updateTypeChargeData = (id, data, callback) => {
  return async (dispatch) => {
    dispatch(updateTypeChargeBegin());
    try {
      const response = await DataService.put(`/charge/updated/${id}`, data);

      if (response.status === 200) {
        dispatch(updateTypeChargeSuccess(response.data.payload));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(updateTypeChargeErr('err'));
      }
    } catch (err) {
      dispatch(updateTypeChargeErr('err'));
    }
  };
};

export { viewTypeChargeData, viewSingleTypeChargeData, updateTypeChargeData, deleteTypeChargeData, addTypeChargeData };
