import { message } from 'antd';
import actions from './actions';

const initialState = {
  data: [],
  error: null,
};

const { VIEW_ABSENCE_BEGIN, VIEW_ABSENCE_SUCCESS, VIEW_ABSENCE_ERR } = actions;

const AbsenceReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case VIEW_ABSENCE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_ABSENCE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case VIEW_ABSENCE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default AbsenceReducer;
