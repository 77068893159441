import React, { lazy, Suspense } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { NavLink, Outlet, Route, Routes } from 'react-router-dom';
import { SettingWrapper } from './overview/Style';
import { Main } from '../../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import Exercices from './overview/Exercices';
import Clubs from './overview/Club';
import Events from './overview/Event';
import Tranches from './overview/Tranches';

const UserBio = lazy(() => import('./overview/UserBio'));

function MyProfile() {
  const path = '.';
  return (
    <>
      <Main>
        <Row gutter={25}>
          <Col xxl={6} lg={8} md={10} xs={24}>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton active paragraph={{ rows: 10 }} />
                </Cards>
              }
            >
              <UserBio />
            </Suspense>
          </Col>
          <Col xxl={18} lg={16} md={14} xs={24}>
            <SettingWrapper>
              <Suspense
                fallback={
                  <Cards headless>
                    <Skeleton active />
                  </Cards>
                }
              >
                <div className="coverWrapper">
                  <nav className="profileTab-menu">
                    <ul>
                      <li>
                        <NavLink to={`${path}/timeline`}>Actualité</NavLink>
                      </li>
                      <li>
                        <NavLink to={`${path}/clubs`}>Clubs</NavLink>
                      </li>
                      <li>
                        <NavLink to={`${path}/events`}>Evenements</NavLink>
                      </li>
                      <li>
                        <NavLink to={`${path}/exercices`}>Exercices</NavLink>
                      </li>
                      <li>
                        <NavLink to={`${path}/tranches`}>Tranches</NavLink>
                      </li>
                      <li>
                        <NavLink to={`${path}/autretranches`}>Autre Tranches</NavLink>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Suspense>
              <Suspense
                fallback={
                  <Cards headless>
                    <Skeleton active paragraph={{ rows: 10 }} />
                  </Cards>
                }
              >
                <Outlet />
              </Suspense>
            </SettingWrapper>
          </Col>
        </Row>
      </Main>
    </>
  );
}

MyProfile.propTypes = {
  // match: propTypes.object,
};

export default MyProfile;
