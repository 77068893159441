import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../../config/dataService/dataService';

const {
  addUserBegin,
  addUserSuccess,
  addUserErr,
  updateUserBegin,
  updateUserSuccess,
  updateUserErr,
  viewUserBegin,
  viewUserSuccess,
  viewUserErr,
  viewSingleUserBegin,
  viewSingleUserSuccess,
  viewSingleUserErr,
  deleteUserBegin,
  deleteUserSuccess,
  deleteUserErr,
} = actions;

const viewUserData = () => {
  return async (dispatch) => {
    dispatch(viewUserBegin());
    try {
      const response = await DataService.get('/admin/view');
      if (response.status === 200) {
        dispatch(viewUserSuccess(response.data.Users));
      } else {
        dispatch(viewUserErr('err'));
      }
    } catch (err) {
      dispatch(viewUserErr('err'));
    }
  };
};

const viewSingleUserData = (id) => {
  return async (dispatch) => {
    dispatch(viewSingleUserBegin());
    try {
      const response = await DataService.get(`/admin/singleInfo/${id}`);

      if (response.status === 200) {
        dispatch(viewSingleUserSuccess(response.data.payload));
      } else {
        dispatch(viewSingleUserErr('err'));
      }
    } catch (err) {
      dispatch(viewSingleUserErr('err'));
    }
  };
};

const addUserData = (data, callback) => {
  return async (dispatch) => {
    dispatch(addUserBegin());
    try {
      const response = await DataService.post('/admin/add', data);
      if (response.status === 200) {
        dispatch(addUserSuccess(response.data.payload));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(addUserErr('err'));
        
      }
    } catch (err) {
      dispatch(addUserErr('err'));
      
    }
  };
};
const deleteUserData = (id) => {
  return async (dispatch) => {
    dispatch(deleteUserBegin());
    try {
      const response = await DataService.delete(`/admin/delete/${id}`);
      if (response.status === 200) {
        dispatch(deleteUserSuccess(id));
        message.success(response.data.message);
      } else {
        dispatch(deleteUserErr('err'));
        
      }
    } catch (err) {
      dispatch(deleteUserErr('err'));
      
    }
  };
};
const updateUserData = (data, callback) => {
  return async (dispatch) => {
    dispatch(updateUserBegin());
    try {
      const response = await DataService.put(`/admin/updated/${data.id}`, data);
      if (response.status === 200) {
        dispatch(updateUserSuccess(response.data.payload));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(updateUserErr('err'));
        
      }
    } catch (err) {
      dispatch(updateUserErr('err'));
      
    }
  };
};

export { viewUserData, viewSingleUserData, updateUserData, deleteUserData, addUserData };
