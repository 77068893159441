import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../../config/dataService/dataService';

const { viewClasseBegin, viewClasseSuccess, viewClasseErr } = actions;

const viewClasseData = () => {
  return async (dispatch) => {
    dispatch(viewClasseBegin());
    try {
      const response = await DataService.get('/classeP/view');

      if (response.status === 200) {
        dispatch(viewClasseSuccess(response.data.payload));
      } else {
        dispatch(viewClasseErr('err'));
      }
    } catch (err) {
      dispatch(viewClasseErr('err'));
    }
  };
};

export { viewClasseData };
