import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../../config/dataService/dataService';

const { viewAbsenceBegin, viewAbsenceSuccess, viewAbsenceErr } = actions;

const viewAbsenceData = (start, end) => {
  return async (dispatch) => {
    dispatch(viewAbsenceBegin());
    try {
      const response = await DataService.get('/absences/index', { start_date: start, end_date: end });
      if (response.status === 200) {
        dispatch(viewAbsenceSuccess(response.data.payload));
      } else {
        dispatch(viewAbsenceErr('err'));
      }
    } catch (err) {
      dispatch(viewAbsenceErr('err'));
    }
  };
};

export { viewAbsenceData };
